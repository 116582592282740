import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconMenu: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path
        d="M4 12H20"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M4 7H20"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M4 17H20"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </Svg>
  )
}
