import { gql } from '@apollo/client'

import {
  classSessionFragment,
  employeeFieldsFragment,
  libraryFragment,
  sessionFragment,
  StudioFragment,
} from './fragments'

export const SIGN_IN = gql`
  mutation SignIn($username: String!, $password: String!) {
    signIn(input: { username: $username, password: $password }) {
      user {
        firstName
        lastName
        id
        role
        isActiveAdmin
      }
      ...SessionFragment
    }
  }
  ${sessionFragment}
`

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      ...SessionFragment
    }
  }
  ${sessionFragment}
`

export const UPDATE_REPORT = gql`
  mutation UpdateReport($input: UpdateReportInput!) {
    admin_updateReport(input: $input) {
      id
    }
  }
`

export const UPDATE_REPORTED_USER = gql`
  mutation UpdateReportedUser($input: UpdateReportedUserInput!) {
    admin_updateReportedUser(input: $input) {
      id
    }
  }
`

export const BLOCK_USER = gql`
  mutation BlockUser($input: UserIdInput!) {
    admin_blockUser(input: $input) {
      id
    }
  }
`

export const UNBLOCK_USER = gql`
  mutation UnblockUser($input: UserIdInput!) {
    admin_unblockUser(input: $input) {
      id
    }
  }
`

export const DELETE_ADMIN = gql`
  mutation DeleteAdmin($input: UserIdInput!) {
    admin_deleteAdmin(input: $input) {
      id
    }
  }
`

export const ACTIVATE_ADMIN = gql`
  mutation ActivateAdmin($input: UserIdInput!) {
    admin_activateAdmin(input: $input) {
      id
    }
  }
`

export const DEACTIVATE_ADMIN = gql`
  mutation DeactivateAdmin($input: UserIdInput!) {
    admin_deactivateAdmin(input: $input) {
      id
    }
  }
`

export const CREATE_ADMIN = gql`
  mutation CreateAdmin($input: CreateAdminInput!) {
    admin_createAdmin(input: $input) {
      id
    }
  }
`

export const UPDATE_ADMIN = gql`
  mutation UpdateAdmin($input: UpdateAdminInput!) {
    admin_updateAdmin(input: $input) {
      id
    }
  }
`

export const CHANGE_BANNERS_ORDER = gql`
  mutation ChangeBannersOrder($input: [ChangeBannersOrderInput!]!) {
    admin_changeBannersOrder(input: $input) {
      id
    }
  }
`

export const CREATE_BANNER = gql`
  mutation CreateBanner($input: CreateBannerInput!) {
    admin_createBanner(input: $input) {
      id
    }
  }
`

export const UPDATE_BANNER = gql`
  mutation UpdateBanner($input: UpdateBannerInput!) {
    admin_updateBanner(input: $input) {
      id
    }
  }
`

export const DELETE_BANNER = gql`
  mutation DeleteBanner($input: DeleteBannerInput!) {
    admin_deleteBanner(input: $input) {
      success
    }
  }
`

export const CREATE_ON_DEMAND_SECTION = gql`
  mutation CreateOnDemandSection($input: CreateOnDemandSectionInput!) {
    admin_createOnDemandSection(input: $input) {
      id
    }
  }
`

export const UPDATE_ON_DEMAND_SECTION = gql`
  mutation UpdateOnDemandSection($input: UpdateOnDemandSectionInput!) {
    admin_updateOnDemandSection(input: $input) {
      id
    }
  }
`

export const DELETE_ON_DEMAND_SECTION = gql`
  mutation DeleteOnDemandSection($input: DeleteOnDemandSectionInput!) {
    admin_deleteOnDemandSection(input: $input) {
      success
    }
  }
`

export const CHANGE_ON_DEMAND_SECTIONS_ORDER = gql`
  mutation ChangeOnDemandSectionsOrder(
    $input: [ChangeOnDemandSectionsOrderInput!]!
  ) {
    admin_changeOnDemandSectionsOrder(input: $input) {
      id
    }
  }
`

export const CREATE_ON_DEMAND_COLLECTION_SECTION = gql`
  mutation CreateOnDemandCollectionSection(
    $input: CreateOnDemandCollectionSectionInput!
  ) {
    admin_createOnDemandCollectionSection(input: $input) {
      id
    }
  }
`

export const UPDATE_ON_DEMAND_COLLECTION_SECTION = gql`
  mutation UpdateOnDemandCollectionSection(
    $input: UpdateOnDemandCollectionSectionInput!
  ) {
    admin_updateOnDemandCollectionSection(input: $input) {
      id
    }
  }
`

export const DELETE_ON_DEMAND_COLLECTION_SECTION = gql`
  mutation DeleteOnDemandCollectionSection(
    $input: DeleteOnDemandCollectionSectionInput!
  ) {
    admin_deleteOnDemandCollectionSection(input: $input) {
      success
    }
  }
`

export const CHANGE_ON_DEMAND_COLLECTION_SECTIONS_ORDER = gql`
  mutation ChangeOnDemandCollectionSectionsOrder(
    $input: [ChangeOnDemandCollectionSectionsOrderInput!]!
  ) {
    admin_changeOnDemandCollectionSectionsOrder(input: $input) {
      id
    }
  }
`

export const CREATE_ON_DEMAND_COLLECTION = gql`
  mutation CreateOnDemandCollection($input: CreateOnDemandCollectionInput!) {
    admin_createOnDemandCollection(input: $input) {
      id
    }
  }
`

export const UPDATE_ON_DEMAND_COLLECTION = gql`
  mutation UpdateOnDemandCollection($input: UpdateOnDemandCollectionInput!) {
    admin_updateOnDemandCollection(input: $input) {
      id
    }
  }
`

export const DELETE_ON_DEMAND_COLLECTION = gql`
  mutation DeleteOnDemandCollection($input: DeleteOnDemandCollectionInput!) {
    admin_deleteOnDemandCollection(input: $input) {
      success
    }
  }
`

export const CHANGE_ON_DEMAND_COLLECTION_ORDER = gql`
  mutation ChangeOnDemandCollectionOrder(
    $input: [ChangeOnDemandCollectionsOrderInput!]!
  ) {
    admin_changeOnDemandCollectionOrder(input: $input) {
      id
    }
  }
`

export const ADMIN_CREATE_CAMPAIGN = gql`
  mutation AdminCreateCampaign($input: CreateCampaignInput!) {
    admin_createCampaign(input: $input) {
      id
    }
  }
`

export const ADMIN_UPDATE_CAMPAIGN = gql`
  mutation AdminUpdateCampaign($input: UpdateCampaignInput!) {
    admin_updateCampaign(input: $input) {
      id
    }
  }
`

export const ADMIN_DELETE_CAMPAIGN = gql`
  mutation AdminDeleteCampaign($input: DeleteCampaignInput!) {
    admin_deleteCampaign(input: $input) {
      success
    }
  }
`

export const GRANT_VOD_ACCESS = gql`
  mutation AdminGrantVODAccessToUser($input: GrantVODAccessToUserInput!) {
    admin_grantVODAccessToUser(input: $input) {
      success
    }
  }
`

export const REVOKE_VOD_ACCESS = gql`
  mutation AdminRevokeVODAccessFromUser($input: RevokeVODAccessToUserInput!) {
    admin_revokeVODAccessFromUser(input: $input) {
      success
    }
  }
`

export const ADMIN_SEND_EMAIL_TO_REPORTED_USER = gql`
  mutation AdminSendEmailToReportedUser($input: SendEmailToReportedUserInput!) {
    admin_sendEmailToReportedUser(input: $input) {
      success
    }
  }
`

export const ADMIN_CREATE_LIBRARY_ITEM = gql`
  mutation AdminCreateLibraryItem($input: CreateLibraryItemInput!) {
    admin_createLibraryItem(input: $input) {
      ...LibraryFragment
    }
  }
  ${libraryFragment}
`

export const ADMIN_UPDATE_LIBRARY_ITEM = gql`
  mutation AdminUpdateLibraryItem($input: UpdateLibraryItemInput!) {
    admin_updateLibraryItem(input: $input) {
      ...LibraryFragment
    }
  }
  ${libraryFragment}
`

export const ADMIN_DELETE_LIBRARY_ITEM = gql`
  mutation AdminDeleteLibraryItem($input: DeleteLibraryItemInput!) {
    admin_deleteLibraryItem(input: $input) {
      success
    }
  }
`

export const ADMIN_DELETE_PICTURE_FOR_REPORTED_USER = gql`
  mutation AdminDeletePictureForReportedUser(
    $input: DeletePictureForReportedUser!
  ) {
    admin_deletePictureForReportedUser(input: $input) {
      success
    }
  }
`

export const ADMIN_UPDATE_CLASS_SESSION = gql`
  mutation AdminUpdateClassSession($input: AdminUpdateClassSessionInput!) {
    admin_updateClassSession(input: $input) {
      ...ClassSessionFragment
    }
  }
  ${classSessionFragment}
`

export const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    admin_updateEmployee(input: $input) {
      ...EmployeeFieldsFragment
    }
  }
  ${employeeFieldsFragment}
`

export const UPDATE_SHOPIFY_PICKS = gql`
  mutation UpdateShopifyPicks($input: UpdateEmployeeShopifyPicksInput!) {
    admin_updateEmployeeShopifyPicks(input: $input) {
      id
      name
      url
      pictureUrl
    }
  }
`

export const CREATE_INSTRUCTOR_BANNER = gql`
  mutation CreateInstructorBanner($input: CreateInstructorBannerInput!) {
    admin_createInstructorBanner(input: $input) {
      ...InstructorBannerFragment
    }
  }
`

export const UPDATE_INSTRUCTOR_BANNER = gql`
  mutation UpdateInstructorBanner($input: UpdateInstructorBannerInput!) {
    admin_updateInstructorBanner(input: $input) {
      ...InstructorBannerFragment
    }
  }
`

export const DELETE_INSTRUCTOR_BANNER = gql`
  mutation DeleteInstructorBanner($input: DeleteInstructorBannerInput!) {
    admin_deleteInstructorBanner(input: $input) {
      success
    }
  }
`

export const ADMIN_UPDATE_STUDIO = gql`
  mutation AdminUpdateStudio($input: AdminUpdateStudioInput!) {
    admin_updateStudio(input: $input) {
      ...StudioFragment
    }
  }
  ${StudioFragment}
`

export const ADMIN_CHANGE_INSTRUCTOR_BANNERS_ORDER = gql`
  mutation AdminChangeInstructorBannersOrder(
    $input: [ChangeInstructorBannersOrderInput!]!
  ) {
    admin_changeInstructorBannersOrder(input: $input) {
      ...InstructorBannerFragment
    }
  }
`
