import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
};

export type AccessToken = {
  __typename?: 'AccessToken';
  accessToken: Scalars['String'];
  tokenType: Scalars['String'];
  expiresIn: Scalars['Float'];
  refreshToken: Scalars['String'];
  scope: Scalars['String'];
};

export type AccessTokenInput = {
  /** Code received from authorizaton call. Exemple: https://my-domain.com/callback?code=NApCCg..BkWtQ&state=34fFs29kd09 */
  code: Scalars['String'];
  /** If no redirectUri is provided the default uri will be use. Default: com.barrys.barrysathome.ios.dev://spotify */
  redirectUri?: Maybe<Scalars['String']>;
};

export type AdminCountLibraryItems = {
  __typename?: 'AdminCountLibraryItems';
  total: Scalars['Int'];
  audio: Scalars['Int'];
  previouslyLive: Scalars['Int'];
  video: Scalars['Int'];
};

export type AdminLibraryItemFilter = {
  title?: Maybe<Scalars['String']>;
  fullTextSearch?: Maybe<Scalars['String']>;
  bodyPartsFilters?: Maybe<Array<BodyPartFilterEnum>>;
  equipmentFilters?: Maybe<Array<EquipmentFilterEnum>>;
  equipmentFilters_v2?: Maybe<Array<EquipmentFilterEnum_V2>>;
  parentCategories?: Maybe<Array<LibraryItemCategoryEnum>>;
  instructorIDs?: Maybe<Array<Scalars['ID']>>;
  durations?: Maybe<Array<Scalars['Int']>>;
  /**
   * Possible values:
   * - true: returns only previously live content
   * - false: returns only non previously live content
   * - not set: returns all content no matter if it is previously live or not
   */
  previouslyLive?: Maybe<Scalars['Boolean']>;
  types?: Maybe<Array<LibraryItemTypeEnum>>;
  type?: Maybe<LibraryItemTypeEnum>;
  statuses?: Maybe<Array<LibraryItemStatusEnum>>;
  labels?: Maybe<Array<LibraryItemLabelEnum>>;
};

export type AdminListClassSessionsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  /** DateTime in UTC. Ex.: '2021-01-01T14:45:00Z' */
  minDateTime?: Maybe<Scalars['DateTime']>;
  /** DateTime in UTC. Ex.: '2021-01-01T14:45:00Z' */
  maxDateTime?: Maybe<Scalars['DateTime']>;
  classSessionTypeIDs?: Maybe<Array<Scalars['ID']>>;
  instructorIDs?: Maybe<Array<Scalars['ID']>>;
  /** Location ID of the class session (!) */
  locationIDs?: Maybe<Array<Scalars['ID']>>;
  /** Region ID of the class session (!) */
  regionIDs?: Maybe<Array<Scalars['ID']>>;
  includeNonVirtualClasses?: Maybe<Scalars['Boolean']>;
  /** Region ID of the instructor (!) */
  instructorRegionIDs?: Maybe<Array<Scalars['ID']>>;
  sort?: Maybe<SortInput>;
  /** Class IDs of classes to retrieve */
  searchIDs?: Maybe<Array<Scalars['ID']>>;
};

export type AdminListLibraryItemsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInput>;
  filter?: Maybe<AdminLibraryItemFilter>;
};

export type AdminListStudiosInput = {
  sort?: Maybe<SortInput>;
  filter?: Maybe<StudioFilter>;
};

export type AdminListUserFilter = {
  /** Search users by email, username, first name, last name or marianatek ID */
  query: Scalars['String'];
};

export type AdminListUsersInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInput>;
  filter?: Maybe<AdminListUserFilter>;
};

export type AdminStudioResult = {
  __typename?: 'AdminStudioResult';
  id: Scalars['ID'];
  name: Scalars['String'];
  regionName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberForTexting?: Maybe<Scalars['String']>;
};

export type AdminUpdateClassSessionInput = {
  classSessionID: Scalars['ID'];
  equipment?: Maybe<Array<EquipmentEnum>>;
  playlistID?: Maybe<Scalars['ID']>;
  spotifyPlaylistIDs?: Maybe<Array<SpotifyPlaylistInput>>;
  appleMusicPlaylistIDs?: Maybe<Array<Scalars['String']>>;
};

export type AdminUpdateStudioInput = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberForTexting?: Maybe<Scalars['String']>;
};

export type AttendanceEntry = {
  __typename?: 'AttendanceEntry';
  value: Scalars['String'];
  count: Scalars['Int'];
};

export type Auth = {
  __typename?: 'Auth';
  user: User;
  session: SessionObject;
};

export type BanUserFromSessionInput = {
  userID: Scalars['ID'];
  roomID: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type Banner = {
  __typename?: 'Banner';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  category: ListBannerCategory;
  /** Call to action text */
  CTAText: Scalars['String'];
  imageURL: Scalars['String'];
  link: Scalars['String'];
  order: Scalars['Int'];
};

/** BannerCategoryEnum desc */
export enum BannerCategoryEnum {
  Shop = 'SHOP',
  Community = 'COMMUNITY',
  News = 'NEWS'
}

export type BannerFilter = {
  category?: Maybe<BannerCategoryEnum>;
};

/** BodyPartEnum desc */
export enum BodyPartEnum {
  Abs = 'ABS',
  Ass = 'ASS',
  Arms = 'ARMS',
  Back = 'BACK',
  Bodyweight = 'BODYWEIGHT',
  Chest = 'CHEST',
  Legs = 'LEGS',
  LowerBody = 'LOWER_BODY',
  Neck = 'NECK',
  Shoulders = 'SHOULDERS',
  UpperBody = 'UPPER_BODY',
  TotalBody = 'TOTAL_BODY'
}

/** BodyPartEnum desc */
export enum BodyPartFilterEnum {
  ArmsAbs = 'ARMS_ABS',
  LowerFocus = 'LOWER_FOCUS',
  ChestBackAbs = 'CHEST_BACK_ABS',
  AssAbs = 'ASS_ABS',
  TotalBody = 'TOTAL_BODY',
  UpperFocus = 'UPPER_FOCUS'
}

export type BookingWindowEntity = {
  __typename?: 'BookingWindowEntity';
  bookingId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  endDateTime: Scalars['DateTime'];
};

export type Bookmark = {
  __typename?: 'Bookmark';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  libraryItem: Library;
  /** @deprecated Not needed */
  collection?: Maybe<OnDemandCollectionEntity>;
};

export type Campaign = {
  __typename?: 'Campaign';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  title: Scalars['String'];
  body: Scalars['String'];
  imageURL: Scalars['String'];
  sent: Scalars['Boolean'];
  sentDateTime?: Maybe<Scalars['DateTime']>;
  scheduledDateTime: Scalars['DateTime'];
};

export type CancelRequestResult = {
  __typename?: 'CancelRequestResult';
  success: Scalars['Boolean'];
  friend: User;
};

export type CancelUserMembershipInput = {
  membershipID: Scalars['ID'];
  reason: MembershipCancellationReasonEnum;
  note?: Maybe<Scalars['String']>;
};

export type Catalogue = {
  __typename?: 'Catalogue';
  classes: ProductClass;
  memberships: ProductMembership;
};

export type Challenge = {
  __typename?: 'Challenge';
  classes?: Maybe<Scalars['Float']>;
  classes_taken?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  daysUntilEnd?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['String']>;
};

export type ChangeBannersOrderInput = {
  bannerID: Scalars['ID'];
  order: Scalars['Int'];
};

export type ChangeInstructorBannersOrderInput = {
  instructorBannerID: Scalars['ID'];
  order: Scalars['Int'];
};

export type ChangeOnDemandCollectionSectionsOrderInput = {
  sectionID: Scalars['ID'];
  order: Scalars['Int'];
};

export type ChangeOnDemandCollectionsOrderInput = {
  collectionID: Scalars['ID'];
  order: Scalars['Int'];
};

export type ChangeOnDemandSectionsOrderInput = {
  sectionID: Scalars['ID'];
  order: Scalars['Int'];
};

export type ChangeRoomInput = {
  classSessionID: Scalars['ID'];
  reservationID: Scalars['ID'];
  roomIndex: Scalars['Int'];
};

export type ChangeRoomResult = {
  __typename?: 'ChangeRoomResult';
  success: Scalars['Boolean'];
};

export type ChangeRoomStatusToActiveInput = {
  roomID: Scalars['ID'];
};

export type ChangeRoomStatusToPreparationInput = {
  roomID: Scalars['ID'];
};

export type ChangeVideoPreferenceInput = {
  roomID: Scalars['ID'];
  value: VideoPreferenceEnum;
};

export type ClassSession = {
  __typename?: 'ClassSession';
  /** MarianaTek ID */
  id: Scalars['ID'];
  name: Scalars['String'];
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  /** Time when the Class Session room opens. */
  openingDateTime: Scalars['DateTime'];
  /** The class is full when the field is 0. */
  spotsCount: Scalars['Int'];
  duration: Scalars['Int'];
  participantsCount?: Maybe<Scalars['Int']>;
  hasReservation: Scalars['Boolean'];
  hasWaitlist: Scalars['Boolean'];
  waitlistRemainingCount?: Maybe<Scalars['Int']>;
  instructors?: Maybe<Array<Instructor>>;
  classroom?: Maybe<Classroom>;
  region: Region;
  activeReservationID?: Maybe<Scalars['String']>;
  activeReservation?: Maybe<Reservation>;
  location: Location;
  /** Users with reservation */
  participants: Array<User>;
  privateParticipantsCount: Scalars['Int'];
  joinStatus: JoinStatusEnum;
  rooms: Array<ClassSessionRoom>;
  isDigital: Scalars['Boolean'];
  playlistID?: Maybe<Scalars['String']>;
  spotifyPlaylistIDs?: Maybe<Array<SpotifyPlaylist>>;
  appleMusicPlaylistIDs?: Maybe<Array<Scalars['String']>>;
  equipment?: Maybe<Array<ListEquipment>>;
  workoutSummary?: Maybe<WorkoutSummary>;
  description?: Maybe<Scalars['String']>;
  isFreeClass: Scalars['Boolean'];
  userRated: Scalars['Boolean'];
  moderatorID?: Maybe<Scalars['ID']>;
  /** Warning: expensive, needs to call MarianaTek and 7Digital consecutively, fetch only when required and for single entity */
  playlist?: Maybe<PlaylistObject>;
};


export type ClassSessionParticipantsArgs = {
  input?: Maybe<PaginationInput>;
};

export type ClassSessionRoom = {
  __typename?: 'ClassSessionRoom';
  name: Scalars['String'];
  roomIndex: Scalars['Int'];
  availableSpots: Array<Scalars['Int']>;
  existingSpots: Array<Scalars['Int']>;
  /** Number of total spots in the room */
  roomCapacity: Scalars['Int'];
  /** Number of reserved spots in the room */
  reservedSpotsCount: Scalars['Int'];
  /** Returns visible participants of the current class session room, sorted by friendship and username */
  participants: Array<User>;
  /** Returns visible friends in the current class session room */
  friends: Array<User>;
  /** Returns IDs of hidden participants of the current class session room */
  hiddenParticipantIDs: Array<Scalars['String']>;
  /** Returns the count of hidden participants */
  hiddenParticipantsCount: Scalars['Int'];
  hasReservation: Scalars['Boolean'];
};


export type ClassSessionRoomParticipantsArgs = {
  input?: Maybe<PaginationInput>;
};


export type ClassSessionRoomFriendsArgs = {
  input?: Maybe<PaginationInput>;
};


export type ClassSessionRoomHiddenParticipantIDsArgs = {
  input?: Maybe<PaginationInput>;
};


export type ClassSessionRoomHiddenParticipantsCountArgs = {
  input?: Maybe<PaginationInput>;
};

export type ClassSessionType = {
  __typename?: 'ClassSessionType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Number of classes with this type that will take place. */
  upcomingCount: Scalars['Int'];
  /** Number of classes with this type that took place in the past. */
  pastCount: Scalars['Int'];
};

export type Classroom = {
  __typename?: 'Classroom';
  /** MarianaTek Classroom ID */
  id: Scalars['ID'];
  name: Scalars['String'];
  isListed: Scalars['Boolean'];
  region?: Maybe<Region>;
  location?: Maybe<Location>;
};

export type ConnectClientInput = {
  classSessionID: Scalars['ID'];
  /** Optional. In case user is an instructor, select the room to connect. If not, it will be ignored */
  roomIndex?: Maybe<Scalars['Int']>;
  /** Optional. This field will be used in the chat instead of real name of the moderator. This will be applied to all rooms */
  moderatorName?: Maybe<Scalars['String']>;
};

export type CountVodInstructorsInput = {
  query?: Maybe<Scalars['String']>;
  regionIDs?: Maybe<Array<Scalars['String']>>;
};

export type Country = {
  __typename?: 'Country';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  printableName?: Maybe<Scalars['String']>;
};

export type CountryInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  printableName?: Maybe<Scalars['String']>;
};

export type CreateAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type CreateBannerInput = {
  name: Scalars['String'];
  /** Call to action text */
  CTAText: Scalars['String'];
  category: BannerCategoryEnum;
  link: Scalars['String'];
  imageURL: Scalars['String'];
};

export type CreateCampaignInput = {
  title: Scalars['String'];
  body: Scalars['String'];
  imageURL: Scalars['String'];
  scheduledDateTime: Scalars['DateTime'];
};

export type CreateFreeClassGuestReservationInput = {
  classSessionID: Scalars['ID'];
  roomIndex: Scalars['Int'];
  /** This field will be ignored if sent together with "guestID" */
  guestEmail?: Maybe<Scalars['String']>;
  guestID?: Maybe<Scalars['ID']>;
};

export type CreateFreeClassReservationInput = {
  classSessionID: Scalars['ID'];
  roomIndex: Scalars['Int'];
};

export type CreateGuestReservationInput = {
  classSessionID: Scalars['ID'];
  roomIndex: Scalars['Int'];
  paymentOptionID: Scalars['ID'];
  /** This field will be ignored if sent together with "guestID" */
  guestEmail?: Maybe<Scalars['String']>;
  guestID?: Maybe<Scalars['ID']>;
};

export type CreateInstructorBannerInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Call to action text */
  CTAText: Scalars['String'];
  link: Scalars['String'];
  imageURL: Scalars['String'];
  regionID?: Maybe<Scalars['String']>;
  locationID?: Maybe<Scalars['String']>;
};

export type CreateLibraryItemInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  parentCategory?: Maybe<LibraryItemCategoryEnum>;
  bodyParts: Array<BodyPartEnum>;
  equipment: Array<EquipmentEnum>;
  instructorID: Scalars['ID'];
  labels?: Maybe<Array<LibraryItemLabelEnum>>;
  duration: Scalars['Int'];
  type: LibraryItemTypeEnum;
  imageURL: Scalars['String'];
  classID: Scalars['String'];
  /** Video + music + instructors voice (video file). The data is linked to metadata */
  fileID: Scalars['String'];
  /** Video + instructors voice (video file). The data is linked to metadataInstructorFile */
  instructorFileID?: Maybe<Scalars['String']>;
  /** Music (audio file) */
  audioID?: Maybe<Scalars['String']>;
  playlistID?: Maybe<Scalars['String']>;
  playlistTrackInfo?: Maybe<Scalars['String']>;
  hasClosedCaptions?: Maybe<Scalars['Boolean']>;
  status?: Maybe<LibraryItemStatusEnum>;
  statusEffectiveSince?: Maybe<Scalars['DateTime']>;
  statusComment?: Maybe<Scalars['String']>;
  additionalInstructorID?: Maybe<Scalars['String']>;
  classSessionID?: Maybe<Scalars['String']>;
  spotifyPlaylistIDs?: Maybe<Array<LibrarySpotifyPlaylistInput>>;
  appleMusicPlaylistIDs?: Maybe<Array<Scalars['String']>>;
};

export type CreateLiveSessionInput = {
  classSessionID: Scalars['String'];
};

export type CreateOnDemandCollectionInput = {
  name: Scalars['String'];
  sectionID: Scalars['ID'];
  imageURL: Scalars['String'];
  libraryItemIDs: Array<Scalars['ID']>;
};

export type CreateOnDemandCollectionSectionInput = {
  name: Scalars['String'];
};

export type CreateOnDemandSectionInput = {
  name: Scalars['String'];
  category: OnDemandCategoryEnum;
  libraryItemIDs: Array<Scalars['ID']>;
};

export type CreateRatingForClassSessionInput = {
  ratingQ1: RatingLevel;
  ratingQ2: RatingLevel;
  additionalComments: Scalars['String'];
  classSessionID: Scalars['ID'];
};

export type CreateRatingForLibraryItemInput = {
  ratingQ1: RatingLevel;
  ratingQ2: RatingLevel;
  additionalComments: Scalars['String'];
  libraryItemID: Scalars['ID'];
};

export type CreateReportInput = {
  /** ID of the user being reported */
  reportedUserID: Scalars['ID'];
  reason: ReportReasonEnum;
};

export type CreateReportResult = {
  __typename?: 'CreateReportResult';
  /** Report id */
  id: Scalars['ID'];
};

export type CreateReservationInput = {
  classSessionID: Scalars['ID'];
  roomIndex: Scalars['Int'];
  paymentOptionID: Scalars['ID'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  /** Min. length: 8 characters */
  password: Scalars['String'];
  homeLocationId: Scalars['ID'];
  isWaiverSigned: Scalars['Boolean'];
  isMarketingOptIn: Scalars['Boolean'];
  /** Ex.: 2010-01-01 */
  birthDate?: Maybe<Scalars['String']>;
  deviceID?: Maybe<Scalars['String']>;
  gympassUID?: Maybe<Scalars['String']>;
};

export type CreateWorkoutSummaryInput = {
  classSessionID?: Maybe<Scalars['String']>;
  libraryID?: Maybe<Scalars['String']>;
  totalTime: Scalars['Float'];
  activeCalories: Scalars['Int'];
  totalCalories: Scalars['Int'];
  heartRate: Scalars['Int'];
  maxHeartRate: Scalars['Int'];
  maxSpeed?: Maybe<Scalars['Float']>;
  miles?: Maybe<Scalars['Float']>;
  topMPH?: Maybe<Scalars['Float']>;
  averageMPH?: Maybe<Scalars['Float']>;
  averageBPM?: Maybe<Scalars['Int']>;
  treadmillCalories?: Maybe<Scalars['Int']>;
};

export type CreateWorkoutSummaryInput_V2 = {
  classSessionID?: Maybe<Scalars['String']>;
  libraryID?: Maybe<Scalars['String']>;
  totalTime: Scalars['Float'];
  activeCalories?: Maybe<Scalars['Int']>;
  totalCalories?: Maybe<Scalars['Int']>;
  heartRate?: Maybe<Scalars['Int']>;
  maxHeartRate?: Maybe<Scalars['Int']>;
  miles?: Maybe<Scalars['Float']>;
  topMPH?: Maybe<Scalars['Float']>;
  averageBPM?: Maybe<Scalars['Int']>;
  bpmChart?: Maybe<Array<WorkoutSummaryChartItemInput>>;
};

export type Credit = {
  __typename?: 'Credit';
  id: Scalars['ID'];
  /** Total number of credits */
  transactionAmount: Scalars['Int'];
  transactionCurrency: Scalars['String'];
  expirationDateTime?: Maybe<Scalars['DateTime']>;
  creditName: Scalars['String'];
  isExpired: Scalars['Boolean'];
  /** Number of credits left */
  remainingCredits: Scalars['Int'];
  expiredUsages: Scalars['Int'];
};


export type DeleteAllInstructorLibraryItemsInput = {
  instructorMarianaTekID: Scalars['String'];
};

export type DeleteBannerInput = {
  id: Scalars['ID'];
};

export type DeleteBannerResult = {
  __typename?: 'DeleteBannerResult';
  success: Scalars['Boolean'];
};

export type DeleteCampaignInput = {
  id: Scalars['ID'];
};

export type DeleteCampaignResult = {
  __typename?: 'DeleteCampaignResult';
  success: Scalars['Boolean'];
};

export type DeleteFeedResult = {
  __typename?: 'DeleteFeedResult';
  success: Scalars['Boolean'];
};

export type DeleteInstructorBannerInput = {
  id: Scalars['ID'];
};

export type DeleteInstructorBannerResult = {
  __typename?: 'DeleteInstructorBannerResult';
  success: Scalars['Boolean'];
};

export type DeleteLibraryItemInput = {
  id: Scalars['ID'];
};

export type DeleteLibraryItemResult = {
  __typename?: 'DeleteLibraryItemResult';
  success: Scalars['Boolean'];
};

export type DeleteOnDemandCollectionInput = {
  id: Scalars['ID'];
};

export type DeleteOnDemandCollectionResult = {
  __typename?: 'DeleteOnDemandCollectionResult';
  success: Scalars['Boolean'];
};

export type DeleteOnDemandCollectionSectionInput = {
  id: Scalars['ID'];
};

export type DeleteOnDemandCollectionSectionResult = {
  __typename?: 'DeleteOnDemandCollectionSectionResult';
  success: Scalars['Boolean'];
};

export type DeleteOnDemandSectionInput = {
  id: Scalars['ID'];
};

export type DeleteOnDemandSectionResult = {
  __typename?: 'DeleteOnDemandSectionResult';
  success: Scalars['Boolean'];
};

export type DeletePictureForReportedUser = {
  userID: Scalars['ID'];
};

export type DeletePictureForReportedUserResult = {
  __typename?: 'DeletePictureForReportedUserResult';
  success: Scalars['Boolean'];
};

export type DeleteProfileResult = {
  __typename?: 'DeleteProfileResult';
  success: Scalars['Boolean'];
};

export type Device = {
  __typename?: 'Device';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  token: Scalars['String'];
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deviceID: Scalars['String'];
  deviceType: Scalars['String'];
  appVersion: Scalars['String'];
  browserType: Scalars['String'];
  browserVersion: Scalars['String'];
  osType: Scalars['String'];
  osVersion: Scalars['String'];
  locale: Scalars['String'];
};

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DisconnectClientInput = {
  roomID: Scalars['ID'];
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  employeeID: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  location?: Maybe<AdminStudioResult>;
  region?: Maybe<Region>;
  regionName?: Maybe<Scalars['String']>;
  isPublished: Scalars['Boolean'];
  promoCode?: Maybe<Scalars['String']>;
  instagramHandle?: Maybe<Scalars['String']>;
  spotifyLink?: Maybe<Scalars['String']>;
  tiktokUsername?: Maybe<Scalars['String']>;
  linkedinUsername?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  isManager: Scalars['Boolean'];
  isInstructor: Scalars['Boolean'];
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberForTexting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  locationID?: Maybe<Scalars['String']>;
  regionID?: Maybe<Scalars['String']>;
  shopifyPicksCount: Scalars['Int'];
  pictureUrl?: Maybe<Scalars['String']>;
  pictureUrlHq?: Maybe<Scalars['String']>;
};

/** EquipmentEnum desc */
export enum EquipmentEnum {
  LightWeights_1 = 'LIGHT_WEIGHTS_1',
  LightWeights_2 = 'LIGHT_WEIGHTS_2',
  MediumWeights_1 = 'MEDIUM_WEIGHTS_1',
  MediumWeights_2 = 'MEDIUM_WEIGHTS_2',
  HeavyWeights_1 = 'HEAVY_WEIGHTS_1',
  HeavyWeights_2 = 'HEAVY_WEIGHTS_2',
  BootyBand = 'BOOTY_BAND',
  LongBand = 'LONG_BAND',
  MiniBand = 'MINI_BAND',
  Bike = 'BIKE',
  Treadmill = 'TREADMILL',
  FoamRoller = 'FOAM_ROLLER',
  Hypervolt = 'HYPERVOLT',
  BodyWeight = 'BODY_WEIGHT'
}

/** EquipmentFilterEnum desc */
export enum EquipmentFilterEnum {
  Weights = 'WEIGHTS',
  Bands = 'BANDS',
  Bike = 'BIKE',
  Treadmill = 'TREADMILL',
  FoamRoller = 'FOAM_ROLLER',
  Hypervolt = 'HYPERVOLT'
}

/** EquipmentFilterEnum_v2 desc */
export enum EquipmentFilterEnum_V2 {
  Weights = 'WEIGHTS',
  BodyWeight = 'BODY_WEIGHT',
  Bands = 'BANDS',
  Bike = 'BIKE',
  Treadmill = 'TREADMILL',
  FoamRoller = 'FOAM_ROLLER',
  Hypervolt = 'HYPERVOLT'
}

export type Feed = {
  __typename?: 'Feed';
  userIDs: Scalars['ID'];
  users: Array<User>;
  classSession: ClassSession;
};

export type FetchMtDataInput = {
  count: Scalars['Float'];
};

export type FileUrl = {
  __typename?: 'FileURL';
  /** Music + instructors voice. Could be video or audio. */
  main: Scalars['String'];
  /** Instructors voice. Could be video or audio. */
  instructor?: Maybe<Scalars['String']>;
  /** Music. Just audio file. */
  music?: Maybe<Scalars['String']>;
};

export type FileUrl_V3 = {
  __typename?: 'FileURL_v3';
  /** stream with all audio tracks associated */
  main: Scalars['String'];
  /** Name of the instructor track */
  instructor?: Maybe<Scalars['String']>;
  /** URL of the pure audio track stream */
  music?: Maybe<Scalars['String']>;
};

export type FinishSessionInput = {
  sessionID: Scalars['ID'];
};

export type Fitfam = {
  __typename?: 'Fitfam';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  userID: Scalars['String'];
  userFullName: Scalars['String'];
  userPictureUrl?: Maybe<Scalars['String']>;
  actionDateTime: Scalars['DateTime'];
  actionType: FitfamItemType;
  libraryItemID?: Maybe<Scalars['String']>;
  libraryItemName?: Maybe<Scalars['String']>;
  libraryItemImage?: Maybe<Scalars['String']>;
  classSessionID?: Maybe<Scalars['String']>;
  classSessionName?: Maybe<Scalars['String']>;
  classSessionStartTime?: Maybe<Scalars['DateTime']>;
  classSessionLocation?: Maybe<Scalars['String']>;
  /** @deprecated Use classSessionInstructorNames field instead */
  classSessionInstructorName?: Maybe<Scalars['String']>;
  /** @deprecated Use classSessionInstructorImages field instead */
  classSessionInstructorImage?: Maybe<Scalars['String']>;
  milestoneType?: Maybe<Scalars['String']>;
  milestoneCount?: Maybe<Scalars['String']>;
  challengeName?: Maybe<Scalars['String']>;
  challengeUrl?: Maybe<Scalars['String']>;
  challengeImageUrl?: Maybe<Scalars['String']>;
  challengeAction?: Maybe<Scalars['String']>;
  loyaltyTier?: Maybe<LoyaltyTierType>;
  workoutSummaryID?: Maybe<Scalars['String']>;
  userFullNames?: Maybe<Array<Scalars['String']>>;
  userPictureUrls?: Maybe<Array<Scalars['String']>>;
  userIDs?: Maybe<Array<Scalars['String']>>;
  workoutSummary?: Maybe<WorkoutSummary>;
  reactions?: Maybe<Reactions>;
  workoutSummaryClassName?: Maybe<Scalars['String']>;
  libraryItemInstructorNames?: Maybe<Array<Scalars['String']>>;
  libraryItemInstructorImages?: Maybe<Array<Scalars['String']>>;
  workoutSummaryInstructorNames?: Maybe<Array<Scalars['String']>>;
  workoutSummaryInstructorImages?: Maybe<Array<Scalars['String']>>;
  classSessionParticipantPictures?: Maybe<Array<Scalars['String']>>;
  classSessionParticipantsCount?: Maybe<Scalars['Int']>;
  userLoyaltyTier?: Maybe<LoyaltyTierType>;
  classSessionInstructorNames?: Maybe<Array<Scalars['String']>>;
  classSessionInstructorImages?: Maybe<Array<Scalars['String']>>;
};

export enum FitfamItemType {
  Challenge = 'CHALLENGE',
  Loyalty = 'LOYALTY',
  Milestones = 'MILESTONES',
  Reservation = 'RESERVATION',
  Vod = 'VOD',
  WorkoutSummary = 'WORKOUT_SUMMARY'
}

export type ForceSoundOffInput = {
  userID: Scalars['ID'];
  roomID: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type ForceVideoOffInput = {
  userID: Scalars['ID'];
  roomID: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type FriendAttendingClass = {
  __typename?: 'FriendAttendingClass';
  friend: User;
  attending: Scalars['Boolean'];
};

export type Friendship = {
  __typename?: 'Friendship';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  friend: User;
  status: FriendshipStatusEnum;
  requester: User;
};


export type FriendshipFriendArgs = {
  input?: Maybe<GetFriendInput>;
};

/** Friendship status */
export enum FriendshipStatusEnum {
  Requested = 'REQUESTED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED'
}

export type FuelBar = {
  __typename?: 'FuelBar';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  marianaTekID: Scalars['String'];
  name: Scalars['String'];
  imageURL?: Maybe<Scalars['String']>;
  locations: Array<LocationEntity>;
};

export type FuelBarFilter = {
  name?: Maybe<Scalars['String']>;
};

export type GetCreditTransactionPenaltyInput = {
  creditTransactionID: Scalars['ID'];
};

export type GetCreditTransactionPenaltyResult = {
  __typename?: 'GetCreditTransactionPenaltyResult';
  message: Scalars['String'];
  isPenalty: Scalars['Boolean'];
  fee: Scalars['Float'];
  currency: Scalars['String'];
};

export type GetEmployeeResult = {
  __typename?: 'GetEmployeeResult';
  employeeID: Scalars['String'];
  isManager: Scalars['Boolean'];
  isInstructor: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  pictureUrlHq?: Maybe<Scalars['String']>;
  locationID?: Maybe<Scalars['String']>;
  regionID?: Maybe<Scalars['String']>;
  location?: Maybe<AdminStudioResult>;
  region?: Maybe<Region>;
  isPublished: Scalars['Boolean'];
  promoCode?: Maybe<Scalars['String']>;
  instagramHandle?: Maybe<Scalars['String']>;
  spotifyLink?: Maybe<Scalars['String']>;
  tiktokUsername?: Maybe<Scalars['String']>;
  linkedinUsername?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  shopifyPicks: Array<ShopifyPicksResult>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberForTexting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  upcomingClasses: Array<UpcomingClassesResult>;
  globalBanners: Array<InstructorBanner>;
  regionalBanners: Array<InstructorBanner>;
  studioBanners: Array<InstructorBanner>;
};

export type GetFileUrlInput = {
  libraryItemID: Scalars['ID'];
  /** videoStreamType: { option: "hls" | "dash" }. Default option when the field is empty is hls */
  videoStreamType?: Maybe<Scalars['String']>;
};

export type GetFileUrlInput_V2 = {
  libraryItemID: Scalars['ID'];
  videoStream?: Maybe<LibraryVideoStreamType>;
};

export type GetFileUrlInput_V3 = {
  libraryItemID: Scalars['ID'];
  videoStream?: Maybe<LibraryVideoStreamType>;
};

export type GetFriendInput = {
  /** Optional. Takes current user if this parameter is not sent */
  userID?: Maybe<Scalars['ID']>;
};

export type GetLibraryItemCategoriesTotalInput = {
  previouslyLive?: Maybe<Scalars['Boolean']>;
  types?: Maybe<Array<LibraryItemTypeEnum>>;
};

export type GetLiveSessionInput = {
  id: Scalars['ID'];
};

export type GetMembershipTransactionPenaltyInput = {
  membershipTransactionID: Scalars['ID'];
};

export type GetMembershipTransactionPenaltyResult = {
  __typename?: 'GetMembershipTransactionPenaltyResult';
  message: Scalars['String'];
  isPenalty: Scalars['Boolean'];
  fee: Scalars['Float'];
  currency: Scalars['String'];
};

export type GetPlaylistInput = {
  playlistID: Scalars['String'];
};

export type GetSignedUrlInput = {
  extension?: Maybe<Scalars['String']>;
};

export type GetSignedUrlResult = {
  __typename?: 'GetSignedUrlResult';
  /** Signed url */
  url: Scalars['String'];
  expiresAt: Scalars['Float'];
  /** @deprecated Use 'application/octet-stream' instead */
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  /** The public URL. If the upload is successful, this URL should be used. */
  publicUrl: Scalars['String'];
};

export type GetSpotifyPlaylistInput = {
  playlistID: Scalars['String'];
};

export type GetTwilioTokenInput = {
  roomID: Scalars['ID'];
};

export type GetTwilioTokenResult = {
  __typename?: 'GetTwilioTokenResult';
  /** Null when the room is not created */
  accessToken?: Maybe<Scalars['String']>;
  /** Twilio's room name to be connected to */
  twilioRoomName: Scalars['String'];
};

export type GrantVodAccessResult = {
  __typename?: 'GrantVODAccessResult';
  success: Scalars['Boolean'];
};

export type GrantVodAccessToUserInput = {
  userID: Scalars['ID'];
  grantedVODAccessNote?: Maybe<Scalars['String']>;
};

/** Events supported by the gympass events api. */
export enum GympassEventEnum {
  Signup = 'SIGNUP',
  Signin = 'SIGNIN',
  Signout = 'SIGNOUT',
  Audio = 'AUDIO',
  Video = 'VIDEO',
  Cancellation = 'CANCELLATION',
  Chat = 'CHAT',
  ContentView = 'CONTENT_VIEW',
  CreateRecord = 'CREATE_RECORD',
  NoShow = 'NO_SHOW',
  OneOnOneSession = 'ONE_ON_ONE_SESSION',
  Renew = 'RENEW',
  Other = 'OTHER'
}

export type GympassUser = {
  __typename?: 'GympassUser';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  gympassUID: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  userStatus?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type Instructor = {
  __typename?: 'Instructor';
  /** MarianaTek Employee ID */
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  pictureUrlHq?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  instagramHandle?: Maybe<Scalars['String']>;
  lessonsCount?: Maybe<Scalars['Int']>;
  /** Instructor's home location */
  location?: Maybe<Location>;
  /** Instructor's home region */
  region?: Maybe<Region>;
  libraryItems: Array<Library>;
  fullName?: Maybe<Scalars['String']>;
  hasVOD: Scalars['Boolean'];
};


export type InstructorLibraryItemsArgs = {
  input: ListInstructorLibraryItemsInput;
};

export type InstructorBanner = {
  __typename?: 'InstructorBanner';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Call to action text */
  CTAText: Scalars['String'];
  imageURL: Scalars['String'];
  link: Scalars['String'];
  regionID?: Maybe<Scalars['String']>;
  locationID?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  region?: Maybe<Region>;
  location?: Maybe<Location>;
};

export type InstructorBannerFilter = {
  name?: Maybe<Scalars['String']>;
  isRegional: Scalars['Boolean'];
  isStudioLevel?: Maybe<Scalars['Boolean']>;
  regionID?: Maybe<Scalars['String']>;
  locationID?: Maybe<Scalars['String']>;
};

export type InstructorCache = {
  __typename?: 'InstructorCache';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  pictureUrlHq?: Maybe<Scalars['String']>;
  instagramHandle?: Maybe<Scalars['String']>;
};

/** InstructorClassType desc */
export enum InstructorClassType {
  LiveClass = 'LIVE_CLASS',
  Vod = 'VOD'
}

export type InstructorListItemsFilter = {
  types?: Maybe<Array<LibraryItemTypeEnum>>;
  instructorIDs?: Maybe<Array<Scalars['String']>>;
  previouslyLive?: Maybe<Scalars['Boolean']>;
  classType?: Maybe<Array<LibraryItemClassTypeEnum>>;
};

export type InviteToClassResult = {
  __typename?: 'InviteToClassResult';
  success: Scalars['Boolean'];
};

/** JoinStatusEnum */
export enum JoinStatusEnum {
  JoinClass = 'JOIN_CLASS',
  JoinWaitlist = 'JOIN_WAITLIST',
  Full = 'FULL'
}

export type Library = {
  __typename?: 'Library';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  title: Scalars['String'];
  description: Scalars['String'];
  parentCategory?: Maybe<ListLibraryItemCategory>;
  bodyParts: Array<ListBodyPart>;
  equipment: Array<ListEquipment>;
  instructorID: Scalars['ID'];
  instructorCache: InstructorCache;
  labels: Array<ListLibraryItemLabel>;
  duration: ListLibraryItemDuration;
  imageURL: Scalars['String'];
  classID: Scalars['String'];
  fileID: Scalars['String'];
  type: LibraryItemTypeEnum;
  /** Instructors voice (video | audio file). When is video, the data is linked to metadataInstructorFile */
  instructorFileID?: Maybe<Scalars['String']>;
  /** Music (audio file) */
  audioID?: Maybe<Scalars['String']>;
  playlistID?: Maybe<Scalars['String']>;
  viewed: Scalars['Boolean'];
  /** Closed captions related to the fileID and classID */
  hasClosedCaptions: Scalars['Boolean'];
  /** Closed captions related to the instructorFileID */
  hasClosedCaptionsInstructorFile: Scalars['Boolean'];
  fileURL?: Maybe<Scalars['String']>;
  deletedAt: Scalars['DateTime'];
  status: LibraryItemStatusEnum;
  statusEffectiveSince: Scalars['DateTime'];
  statusComment?: Maybe<Scalars['String']>;
  additionalInstructorID?: Maybe<Scalars['String']>;
  classSessionID?: Maybe<Scalars['String']>;
  spotifyPlaylistIDs?: Maybe<Array<LibrarySpotifyPlaylist>>;
  appleMusicPlaylistIDs?: Maybe<Array<Scalars['String']>>;
  fileName: Scalars['String'];
  bodyweight: Scalars['Boolean'];
  equipment_v2: Array<ListEquipment>;
  instructor: Instructor;
  additionalInstructor?: Maybe<Instructor>;
  playlist?: Maybe<PlaylistObject>;
  userRated: Scalars['Boolean'];
  isBookmarked: Scalars['Boolean'];
  hasWorkoutSummary: Scalars['Boolean'];
};


export type LibraryFileUrlArgs = {
  videoStreamType?: Maybe<VideoStreamType>;
};

/** LibraryItemCategoryEnum desc */
export enum LibraryItemCategoryEnum {
  Cardio = 'CARDIO',
  Strength = 'STRENGTH',
  StrengthCardio = 'STRENGTH_CARDIO',
  Stretch = 'STRETCH'
}

/** LibraryItemClassTypeEnum desc */
export enum LibraryItemClassTypeEnum {
  Audio = 'AUDIO',
  Video = 'VIDEO',
  PreviouslyLive = 'PREVIOUSLY_LIVE'
}

export type LibraryItemFilter = {
  bodyParts?: Maybe<Array<BodyPartEnum>>;
  bodyPartsFilters?: Maybe<Array<BodyPartFilterEnum>>;
  equipmentFilters?: Maybe<Array<EquipmentFilterEnum>>;
  equipmentFilters_v2?: Maybe<Array<EquipmentFilterEnum_V2>>;
  equipment?: Maybe<Array<EquipmentEnum>>;
  parentCategories?: Maybe<Array<LibraryItemCategoryEnum>>;
  instructorIDs?: Maybe<Array<Scalars['ID']>>;
  durations?: Maybe<Array<Scalars['Int']>>;
  previouslyLive?: Maybe<Scalars['Boolean']>;
  types?: Maybe<Array<LibraryItemTypeEnum>>;
  classType?: Maybe<Array<LibraryItemClassTypeEnum>>;
};

/** LibraryItemLabelEnum desc */
export enum LibraryItemLabelEnum {
  Hardcore = 'HARDCORE',
  Christmas = 'CHRISTMAS',
  FullBody = 'FULL_BODY'
}

/** LibraryItemStatusEnum desc */
export enum LibraryItemStatusEnum {
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED',
  Hidden = 'HIDDEN'
}

/** LibraryItemTypeEnum desc */
export enum LibraryItemTypeEnum {
  Audio = 'AUDIO',
  Video = 'VIDEO',
  PreviouslyLive = 'PREVIOUSLY_LIVE'
}

export type LibrarySpotifyPlaylist = {
  __typename?: 'LibrarySpotifyPlaylist';
  playlistID: Scalars['String'];
  imageURL: Scalars['String'];
  title: Scalars['String'];
};

export type LibrarySpotifyPlaylistInput = {
  playlistID: Scalars['String'];
  imageURL: Scalars['String'];
  title: Scalars['String'];
};

/** The supported stream types. */
export enum LibraryVideoStreamType {
  /** The default type. */
  Hls = 'HLS',
  /** Type for devices that dont support hls. */
  Dash = 'DASH'
}

export type ListBannerCategory = {
  __typename?: 'ListBannerCategory';
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ListBannersInput = {
  sort?: Maybe<SortInput>;
  filter?: Maybe<BannerFilter>;
};

export type ListBodyPart = {
  __typename?: 'ListBodyPart';
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ListBodyPartFilter = {
  __typename?: 'ListBodyPartFilter';
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ListCampaignsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInput>;
};

export type ListClassSessionInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  /** DateTime in UTC. Ex.: '2021-01-01T14:45:00Z' */
  minDateTime?: Maybe<Scalars['DateTime']>;
  /** DateTime in UTC. Ex.: '2021-01-01T14:45:00Z' */
  maxDateTime?: Maybe<Scalars['DateTime']>;
  classSessionTypeIDs?: Maybe<Array<Scalars['ID']>>;
  instructorIDs?: Maybe<Array<Scalars['ID']>>;
  /** Location ID of the class session (!) */
  locationIDs?: Maybe<Array<Scalars['ID']>>;
  /** Region ID of the class session (!) */
  regionIDs?: Maybe<Array<Scalars['ID']>>;
  includeNonVirtualClasses?: Maybe<Scalars['Boolean']>;
  /** Region ID of the instructor (!) */
  instructorRegionIDs?: Maybe<Array<Scalars['ID']>>;
};

export type ListClassSessionTypesInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  includeNonVirtualClasses: Scalars['Boolean'];
};

export type ListDeviceInfoInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  userID?: Maybe<Scalars['String']>;
};

export type ListEmployeesFilterInput = {
  isManager: Scalars['Boolean'];
  query?: Maybe<Scalars['String']>;
};

export type ListEmployeesFilter_V2 = {
  regionIDs?: Maybe<Array<Scalars['String']>>;
  /**
   * Possible values:
   * - [VOD]: returns instructors with VOD content
   * - [LIVE_CLASS]: returns instructors with a live class scheduled
   */
  classType?: Maybe<Array<InstructorClassType>>;
};

export type ListEmployeesInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  filter: ListEmployeesFilterInput;
  sort?: Maybe<SortInput>;
};

export type ListEmployeesPublicFilterInput = {
  query?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isManager?: Maybe<Scalars['Boolean']>;
};

export type ListEmployeesPublicInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  filter?: Maybe<ListEmployeesPublicFilterInput>;
};

export type ListEquipment = {
  __typename?: 'ListEquipment';
  name: Scalars['String'];
  type: Scalars['String'];
  image: Scalars['String'];
};

export type ListEquipmentFilter = {
  __typename?: 'ListEquipmentFilter';
  name: Scalars['String'];
  type: Scalars['String'];
  image: Scalars['String'];
};

export type ListFeedInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

export type ListFriendshipAttendingClass = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<FriendshipStatusEnum>>;
  /** Optional. Takes current user if this parameter is not sent */
  userID?: Maybe<Scalars['ID']>;
  classSessionId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type ListFriendshipInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<FriendshipStatusEnum>>;
  /** Optional. Takes current user if this parameter is not sent */
  userID?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
};

export type ListFuelBarItemsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  filter?: Maybe<FuelBarFilter>;
};

export type ListGroupedBannersResult = {
  __typename?: 'ListGroupedBannersResult';
  category: Scalars['String'];
  items: Array<Banner>;
};

export type ListInstructorBannersInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInput>;
  filter: InstructorBannerFilter;
};

export type ListInstructorLibraryItemsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInput>;
  filter?: Maybe<InstructorListItemsFilter>;
};

export type ListInstructorVodFilters = {
  __typename?: 'ListInstructorVodFilters';
  classTypeFilters: Array<LibraryItemClassTypeEnum>;
};

export type ListInstructorVodFiltersInput = {
  instructorId: Scalars['String'];
};

export type ListInstructorsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  regionIDs?: Maybe<Array<Scalars['String']>>;
  IDs?: Maybe<Array<Scalars['String']>>;
  /**
   * Possible values:
   * - true: returns only instructors with VOD content
   * - false: returns only instructors without VOD content
   * - null/not set: returns all instructors no matter if VOD content is present or not
   */
  withVodContent?: Maybe<Scalars['Boolean']>;
  /**
   * Possible values:
   * - true: returns only instructors with Previously Live content
   * - false: returns only instructors without Previously Live content
   * - null/not set: returns all instructors no matter if Previously Live content is present or not
   */
  withPreviouslyLive?: Maybe<Scalars['Boolean']>;
  /**
   * Possible values:
   * - true: returns all instructors
   * - false: returns only instructors suggested for allowed regions
   */
  ignoreSuggestedRegionFilter?: Maybe<Scalars['Boolean']>;
};

export type ListInstructorsInput_V2 = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  filter?: Maybe<ListEmployeesFilter_V2>;
};

export type ListLibraryItemCategory = {
  __typename?: 'ListLibraryItemCategory';
  name: Scalars['String'];
  type: Scalars['String'];
  image: Scalars['String'];
  verticalImage: Scalars['String'];
  /** Total of workouts under this category */
  total?: Maybe<Scalars['Int']>;
  /** Total of workouts under this category [v2] */
  total_v2?: Maybe<Scalars['Int']>;
};


export type ListLibraryItemCategoryTotalArgs = {
  input?: Maybe<GetLibraryItemCategoriesTotalInput>;
};


export type ListLibraryItemCategoryTotal_V2Args = {
  input?: Maybe<GetLibraryItemCategoriesTotalInput>;
};

export type ListLibraryItemDuration = {
  __typename?: 'ListLibraryItemDuration';
  name: Scalars['String'];
  type: Scalars['Int'];
};

export type ListLibraryItemLabel = {
  __typename?: 'ListLibraryItemLabel';
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ListLibraryItemStatus = {
  __typename?: 'ListLibraryItemStatus';
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ListLibraryItemType = {
  __typename?: 'ListLibraryItemType';
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ListLibraryItemsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInput>;
  filter?: Maybe<LibraryItemFilter>;
};

export type ListOnDemandCategory = {
  __typename?: 'ListOnDemandCategory';
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ListOnDemandSectionInput = {
  sort?: Maybe<SortInput>;
  filter?: Maybe<OnDemandSectionFilter>;
};

export type ListPaymentOptionsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  classSessionID: Scalars['ID'];
  /** When true, returns the payment options that allow inviting guests */
  reservedForGuest?: Maybe<Scalars['Boolean']>;
};

export type ListProductsInput = {
  platform: PlatformEnum;
};

export type ListRegionsInput = {
  /** Filter regions by instructors with some VOD content */
  withVodContent?: Maybe<Scalars['Boolean']>;
  /** Include non Barrys X regions */
  includeNonVirtualClasses?: Maybe<Scalars['Boolean']>;
  /** Returns only the regions of instructors that have upcoming live classes */
  fromSuggestedInstructors?: Maybe<Scalars['Boolean']>;
};

export type ListRegionsResult = {
  __typename?: 'ListRegionsResult';
  id: Scalars['ID'];
  name: Scalars['String'];
  country: Country;
  locations: Array<LocationObject>;
};

export type ListReportReason = {
  __typename?: 'ListReportReason';
  name: Scalars['String'];
  type: ReportReasonEnum;
};

export type ListReportsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInput>;
  filter?: Maybe<ReportFilter>;
};

export type ListReservationsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<ReservationSortingEnum>;
  time?: Maybe<ReservationTimeEnum>;
  status?: Maybe<Array<ReservationStatusEnum>>;
  /** Optional. If omitted will send reservations for the user making the request. */
  userID?: Maybe<Scalars['ID']>;
  classSessionID?: Maybe<Scalars['ID']>;
  includeNonVirtualClasses?: Maybe<Scalars['Boolean']>;
};

export type ListStudiosInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  UserID?: Maybe<Scalars['String']>;
};

export type ListStudiosResult = {
  __typename?: 'ListStudiosResult';
  name: Scalars['String'];
  regions: Array<ListRegionsResult>;
};

export type ListUserBookmarksInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInput>;
};

export type ListUserClassSessionsInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<ReservationSortingEnum>;
  status?: Maybe<Array<ReservationStatusEnum>>;
  time?: Maybe<ReservationTimeEnum>;
  /** Optional. If omitted will send reservations for the user making the request. */
  userID?: Maybe<Scalars['ID']>;
  includeNonVirtualClasses?: Maybe<Scalars['Boolean']>;
};

export type ListUserInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type ListUserInputAttendingClass = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  query: Scalars['String'];
  classSessionId: Scalars['String'];
};

export type ListUserOrdersInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

export type ListUserPurchasesInput = {
  type: PurchaseTypeEnum;
  platform?: Maybe<PlatformEnum>;
};

export type ListVodFilters = {
  __typename?: 'ListVodFilters';
  bodyParts?: Maybe<Array<ListBodyPart>>;
  bodyPartsFilters: Array<ListBodyPartFilter>;
  /** @deprecated DEPRECATED: too granular, use equipmentFilters instead and do not even send this field back to backend */
  equipment?: Maybe<Array<ListEquipment>>;
  equipmentFilters: Array<ListEquipmentFilter>;
  equipmentFilters_v2: Array<ListEquipmentFilter>;
  labels: Array<ListLibraryItemLabel>;
  types: Array<ListLibraryItemType>;
  durations: Array<ListLibraryItemDuration>;
  statuses: Array<ListLibraryItemStatus>;
  classTypeFilters: Array<LibraryItemClassTypeEnum>;
};

export type ListVodFiltersInput = {
  category?: Maybe<LibraryItemCategoryEnum>;
};

export type ListWorkoutSummaryInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  libraryID: Scalars['String'];
};

export type LiveSession = {
  __typename?: 'LiveSession';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  /** @deprecated DEPRECATED: use roomID from rooms */
  roomID?: Maybe<Scalars['ID']>;
  /** @deprecated DEPRECATED: use url from rooms */
  url?: Maybe<Scalars['String']>;
  /** @deprecated Use LiveSessionRoom.status instead */
  status?: Maybe<LiveSessionStatusEnum>;
  classSession?: Maybe<ClassSession>;
  rooms: Array<LiveSessionRoom>;
  reservedRoom?: Maybe<LiveSessionRoom>;
};

export type LiveSessionConnectResult = {
  __typename?: 'LiveSessionConnectResult';
  /** Null when the room is not created */
  accessToken?: Maybe<Scalars['String']>;
  /** Twilio's room name to be connected to */
  twilioRoomName: Scalars['String'];
  liveSession: LiveSession;
  /** Room information that user is supposed to connect */
  room: LiveSessionRoom;
};

export type LiveSessionRoom = {
  __typename?: 'LiveSessionRoom';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  liveSessionID: Scalars['String'];
  name: Scalars['String'];
  roomIndex: Scalars['Int'];
  /** Twilio room id */
  roomID?: Maybe<Scalars['ID']>;
  /** Twilio room url */
  url?: Maybe<Scalars['String']>;
  /** Unique name to create twilio room */
  uniqueName?: Maybe<Scalars['String']>;
  liveSession: LiveSession;
  status?: Maybe<LiveSessionRoomStatusEnum>;
  /** Returns visible participants of the live session room, sorted by friendship and username */
  participants: Array<User>;
  /** Returns IDs of hidden participants of the current class session room */
  hiddenParticipantIDs: Array<Scalars['String']>;
  /** Returns the count of hidden participants */
  hiddenParticipantsCount: Scalars['Int'];
};


export type LiveSessionRoomParticipantsArgs = {
  input?: Maybe<PaginationInput>;
};


export type LiveSessionRoomHiddenParticipantIDsArgs = {
  input?: Maybe<PaginationInput>;
};


export type LiveSessionRoomHiddenParticipantsCountArgs = {
  input?: Maybe<PaginationInput>;
};

/** LiveSessionRoomStatusEnum description */
export enum LiveSessionRoomStatusEnum {
  WaitingRoom = 'WAITING_ROOM',
  PreparationRoom = 'PREPARATION_ROOM',
  Active = 'ACTIVE',
  Finished = 'FINISHED'
}

/** LiveSessionStatusEnum */
export enum LiveSessionStatusEnum {
  WaitingRoom = 'WAITING_ROOM',
  Active = 'ACTIVE',
  Finished = 'FINISHED'
}

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
};

export type LocationEntity = {
  __typename?: 'LocationEntity';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  marianaTekID: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberForTexting?: Maybe<Scalars['String']>;
};

export type LocationObject = {
  __typename?: 'LocationObject';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum LoyaltyTierType {
  Black = 'BLACK',
  Member = 'MEMBER',
  Red = 'RED',
  Silver = 'SILVER',
  OptedOut = 'OPTED_OUT'
}

export type Membership = {
  __typename?: 'Membership';
  id: Scalars['ID'];
  membershipName: Scalars['String'];
  renewalRate: Scalars['String'];
  renewalCurrency: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  purchaseDate: Scalars['DateTime'];
  cancellationDate?: Maybe<Scalars['DateTime']>;
  isCancellable: Scalars['Boolean'];
  paymentInterval: Scalars['String'];
  usageIntervalLimit?: Maybe<Scalars['Float']>;
};

/** MembershipCancellationReasonEnum desc */
export enum MembershipCancellationReasonEnum {
  AnotherDigitalProduct = 'ANOTHER_DIGITAL_PRODUCT',
  IWentBackToTheStudio = 'I_WENT_BACK_TO_THE_STUDIO',
  Price = 'PRICE',
  MissingOnDemandContent = 'MISSING_ON_DEMAND_CONTENT',
  BadTiming = 'BAD_TIMING',
  InjurySurgery = 'INJURY_SURGERY',
  IPreferInPersonClasses = 'I_PREFER_IN_PERSON_CLASSES',
  NotWhatIExpected = 'NOT_WHAT_I_EXPECTED',
  Other = 'OTHER'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Requests to be friends of another user.
   *     The Friendship is created with a REQUESTED state.
   */
  requestFriendship: Friendship;
  /** Cancel the requests sent to another user. */
  cancelFriendshipRequest: CancelRequestResult;
  /** Updates the Friendship to ACCEPTED status. The friendship must be in REQUESTED state. */
  acceptFriendship: Friendship;
  /** Updates Friendship to DECLINED status. The friendship must be in REQUESTED state. */
  declineFriendship: Friendship;
  /** Deletes a Friendship. */
  removeFriendship: Scalars['Boolean'];
  /** Invite friend to class */
  inviteToClass: InviteToClassResult;
  /** Updates a notification. Use this to set the `isUnread` status. */
  updateNotification: Notification;
  /** Set all notifications which were `isUnread = true` for current user as read. */
  readAllNotifications: ReadAllNotificationsResult;
  /** Mutation for testing notifications */
  sendNotification: Scalars['Boolean'];
  /** Creates a new user (Sign up). */
  createUser: Auth;
  /** Updates the current user annd returns the user object. */
  updateUser: UpdateUserResult;
  /** Sets all unread friendships of current user to 0. */
  readAllFriendRequests: User;
  /** Sets all unread friendships of current user to 0. */
  readAllFitfamActivities: Scalars['Boolean'];
  /** Updates a reported user (Admin only). */
  admin_updateReportedUser: UpdateReportedUserResult;
  /** Removes image for reported user (Admin only). */
  admin_deletePictureForReportedUser: DeletePictureForReportedUserResult;
  /** Assigns ADMIN role to a user (Admin only). */
  admin_createAdmin: User;
  /** Updates an admin (Admin only). */
  admin_updateAdmin: UpdateAdminResult;
  /** Blocks the user from the application (Admin only). */
  admin_blockUser: User;
  /** Unblocks the user from the application (Admin only). */
  admin_unblockUser: User;
  /** Deactivate an admin user (Admin only). */
  admin_deactivateAdmin: User;
  /** Active an admin user (Admin only). */
  admin_activateAdmin: User;
  /** Deletes an admin user (Admin only). */
  admin_deleteAdmin: User;
  /** Deletes an user. */
  deleteProfile: DeleteProfileResult;
  /** Grand VOD access to selected user (Admin only). */
  admin_grantVODAccessToUser: GrantVodAccessResult;
  /** Revoke VOD access from selected user (Admin only). */
  admin_revokeVODAccessFromUser: RevokeVodAccessResult;
  /** Trigger the code for fetching data */
  triggerUsersMtFetch: Scalars['Boolean'];
  /** Updates the current user section Personal, returns the user object. */
  updateUserPersonal: UpdateUserResult;
  /** Updates the current user section Personal, returns the user object. */
  updateUserSocial: UpdateUserResult;
  /** Updates the current user section Emergency, returns the user object. */
  updateUserEmergency: UpdateUserResult;
  /** Updates the current user section Address, returns the user object. */
  updateUserAddress: UpdateUserResult;
  /** Signs in the user with email and password */
  signIn: Auth;
  /** Resets user password */
  resetPassword: PasswordResetResult;
  /** Gets a new Authorization token from a Refresh token */
  refreshToken: Auth;
  /** Creates a Report of an user. */
  createReport: CreateReportResult;
  /** Marks a report as seen or resolved (Admin only). */
  admin_updateReport: Report;
  /** Send email to a reported user (admin only) */
  admin_sendEmailToReportedUser: SendEmailToReportedUserResult;
  /** Send email to a user (admin only) */
  admin_sendEmailToUser: SendEmailToUserResult;
  /** Update notification setting for selected type for current user */
  updateNotificationSettings: Scalars['Boolean'];
  /** Updates equipment and playlistID of library item associated with class session. */
  admin_updateClassSession: ClassSession;
  /** Verify on AppStore if a receipt is valid */
  verifyPurchaseIOS: VerifyIosPurchaseObject;
  /** Verify multiple transactions from AppStore */
  verifyPurchasesIOS: Array<VerifyIosPurchaseObject>;
  /** Verify on Google Play if a purchase token is valid */
  verifyPurchaseAndroid: VerifyAndroidPurchaseObject;
  /** Verify multiple purchases on Google Play at once */
  verifyPurchasesAndroid: Array<VerifyAndroidPurchaseObject>;
  /**
   * Saves a device with its corresponding notification token.
   *     If the same token is sent with a different userID, the userID will be updated for that token.
   */
  registerDevice: Device;
  /** Removes a device by token or device ID. */
  removeDevice: Scalars['Boolean'];
  /** Store information about the current device for the current user. */
  storeDeviceInfo: DeviceInfo;
  /** Report event to gympass. */
  reportGympassEvent: Scalars['Boolean'];
  /** Create and plan new Campaign (admin only) */
  admin_createCampaign: Campaign;
  /** Update selected Campaign (admin only) */
  admin_updateCampaign: Campaign;
  /** Delete selected Campaign (admin only) */
  admin_deleteCampaign: DeleteCampaignResult;
  admin_updateEmployee: Employee;
  admin_updateEmployeeShopifyPicks: Array<ShopifyPicks>;
  /** Get detail of selected studio */
  admin_updateStudio: AdminStudioResult;
  /** Create new video or audio (admin only) */
  admin_createLibraryItem: Library;
  /** Update the library item (admin only) */
  admin_updateLibraryItem: Library;
  /** Delete a library item by its ID (admin only) */
  admin_deleteLibraryItem: DeleteLibraryItemResult;
  /** Delete all library items related to selected instuctor */
  admin_deleteAllInstructorLibraryItems: DeleteLibraryItemResult;
  /** Creates a new bookmark for a libraryItem. */
  createBookmarkForLibraryItem: Scalars['Boolean'];
  /** Creates a new bookmark for a collection. */
  createBookmarkForCollection: Scalars['Boolean'];
  /** Removes a bookmark by ID. */
  removeBookmark: Scalars['Boolean'];
  /** Removes a bookmark by library item ID. */
  removeBookmarkByLibraryItemID: Scalars['Boolean'];
  /** Create new on demand section for selected category (admin only) */
  admin_createOnDemandSection: OnDemandSectionEntity;
  /** Update detail of selected on demand section (admin only) */
  admin_updateOnDemandSection: OnDemandSectionEntity;
  /** Delete on demand section with all its items (admin only) */
  admin_deleteOnDemandSection: DeleteOnDemandSectionResult;
  /** Change the order for on demand sections of the same category (admin only) */
  admin_changeOnDemandSectionsOrder: Array<OnDemandSectionEntity>;
  /** Create new collection section (admin only) */
  admin_createOnDemandCollectionSection: OnDemandCollectionSectionEntity;
  /** Update collection section detail (admin only) */
  admin_updateOnDemandCollectionSection: OnDemandCollectionSectionEntity;
  /** Delete collection section with all its collections (admin only) */
  admin_deleteOnDemandCollectionSection: DeleteOnDemandCollectionSectionResult;
  /** Change the order for collection sections (admin only) */
  admin_changeOnDemandCollectionSectionsOrder: Array<OnDemandCollectionSectionEntity>;
  /** Create new collection for selected section (admin only) */
  admin_createOnDemandCollection: OnDemandCollectionEntity;
  /** Update collection detail (admin only) */
  admin_updateOnDemandCollection: OnDemandCollectionEntity;
  /** Remove collection and all its items (admin only) */
  admin_deleteOnDemandCollection: DeleteOnDemandCollectionResult;
  /** Change the order of collections of the same section (admin only) */
  admin_changeOnDemandCollectionOrder: Array<OnDemandCollectionEntity>;
  createRatingForClassSession: Scalars['Boolean'];
  createRatingForLibraryItem: Scalars['Boolean'];
  /** Trigger the code for generating instructor cache data */
  fillInstructorCache: Scalars['Boolean'];
  /** Update data for single fuel bar item (admin only) */
  admin_updateFuelBarItem: FuelBar;
  /** Trigger the code for generating fuel bar items to our database */
  triggerFillFuelBar: Scalars['Boolean'];
  /** Creates a reservation (books a class). */
  createReservation: Reservation;
  /** Creates a reservation (free class). */
  createReservationForFreeClass: Reservation;
  /** Creates a reservation for a guest (books a class) */
  createGuestReservation: Reservation;
  /** Creates a reservation for a guest (free class) */
  createGuestReservationForFreeClass: Reservation;
  /** Change room for class session */
  changeRoom: ChangeRoomResult;
  /** Cancel a reservation. */
  cancelReservation: Scalars['Boolean'];
  /** Check in into a class. */
  checkInReservation: Reservation;
  /** Check out from a class */
  checkOutReservation: Reservation;
  /** Creates a new workout summary. */
  createWorkoutSummary: WorkoutSummary;
  /** Creates a new workout summary [v2]. */
  createWorkoutSummary_v2: WorkoutSummary;
  /** Shares a workout summary with fitfam. */
  shareWorkoutSummaryWithFitfam: ShareWorkoutSummaryResult;
  /** Deletes a feed item. */
  deleteFeedItem: DeleteFeedResult;
  /** Adds a reaction to a feed */
  addReactionToFeed: ReactionUpdateResponse;
  /** Adds a reaction to a feed */
  removeReactionFromFeed: ReactionUpdateResponse;
  /** Create new banner (admin only) */
  admin_createBanner: Banner;
  /** Update data for single banner (admin only) */
  admin_updateBanner: Banner;
  /** Remove banner (admin only) */
  admin_deleteBanner: DeleteBannerResult;
  /** Change order for banners of same category (admin only) */
  admin_changeBannersOrder: Array<Banner>;
  /** @deprecated use "saveVideoAnalyticsEventWithMetadata" query instead */
  saveVideoAnalyticsEvent: Scalars['Boolean'];
  saveVideoAnalyticsWithMetadataEvent: SaveEventObject;
  connectClient: LiveSessionConnectResult;
  disconnectClient: Scalars['Boolean'];
  /** Create new live session (Instructor only). */
  createLiveSession: LiveSession;
  /** Start showing song titles from playlist to clients (Instructor only). */
  startMusicPlayback: StartMusicPlaybackResult;
  /** Update song title times from playlist to clients (Instructor only). */
  updateMusicPlaybackTimes: StartMusicPlaybackResult;
  /** @deprecated Use "sendRoomMessage" instead. */
  sendMessage: Scalars['Boolean'];
  /** Finish selected session. (Instructor only) */
  finishSession: Scalars['Boolean'];
  /** Force sound off/on for selected user in selected session. (Instructor only) */
  forceSoundOff: Scalars['Boolean'];
  /** Force video off/on for selected user in selected session. (Instructor only) */
  forceVideoOff: Scalars['Boolean'];
  /** Ban or unban selected user from session. (Instructor only) */
  banUserFromSession: Scalars['Boolean'];
  /** Turn off/on red filter in session for current user */
  toggleRedFilter: Scalars['Boolean'];
  /** Change who can see current user's video */
  changeVideoPreference: Scalars['Boolean'];
  /** Force sound off/on for all participants in all rooms for selected session. (Instructor only) */
  muteAll: Scalars['Boolean'];
  sendRoomMessage: Scalars['Boolean'];
  /** Change status of selected room to "Preparation" */
  changeRoomStatusToPreparation: Scalars['Boolean'];
  /** Start selected session room. (Instructor only) */
  changeRoomStatusToActive: Scalars['Boolean'];
  /** Cancel membership for the current user */
  cancelUserMembership: Membership;
  /** Cancel membership for the current user with specifying reason */
  cancelUserMembership_v2: Membership;
  /** Create new instructor banner (admin only) */
  admin_createInstructorBanner: InstructorBanner;
  /** Update data for single instructor banner (admin only) */
  admin_updateInstructorBanner: InstructorBanner;
  /** Remove instructor banner (admin only) */
  admin_deleteInstructorBanner: DeleteInstructorBannerResult;
  /** Change order for instructors banners for specific region or location (admin only) */
  admin_changeInstructorBannersOrder: Array<InstructorBanner>;
};


export type MutationRequestFriendshipArgs = {
  input: RequestFriendshipInput;
};


export type MutationCancelFriendshipRequestArgs = {
  input: RequestFriendshipInput;
};


export type MutationAcceptFriendshipArgs = {
  friendshipID: Scalars['ID'];
};


export type MutationDeclineFriendshipArgs = {
  friendshipID: Scalars['ID'];
};


export type MutationRemoveFriendshipArgs = {
  friendshipID: Scalars['ID'];
};


export type MutationInviteToClassArgs = {
  classSessionID: Scalars['ID'];
  friendID: Scalars['ID'];
};


export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput;
};


export type MutationSendNotificationArgs = {
  type: NotificationTypeEnum;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationAdmin_UpdateReportedUserArgs = {
  input: UpdateReportedUserInput;
};


export type MutationAdmin_DeletePictureForReportedUserArgs = {
  input: DeletePictureForReportedUser;
};


export type MutationAdmin_CreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationAdmin_UpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationAdmin_BlockUserArgs = {
  input: UserIdInput;
};


export type MutationAdmin_UnblockUserArgs = {
  input: UserIdInput;
};


export type MutationAdmin_DeactivateAdminArgs = {
  input: UserIdInput;
};


export type MutationAdmin_ActivateAdminArgs = {
  input: UserIdInput;
};


export type MutationAdmin_DeleteAdminArgs = {
  input: UserIdInput;
};


export type MutationAdmin_GrantVodAccessToUserArgs = {
  input: GrantVodAccessToUserInput;
};


export type MutationAdmin_RevokeVodAccessFromUserArgs = {
  input: RevokeVodAccessToUserInput;
};


export type MutationTriggerUsersMtFetchArgs = {
  input: FetchMtDataInput;
};


export type MutationUpdateUserPersonalArgs = {
  input: UpdateUserPersonalInput;
};


export type MutationUpdateUserSocialArgs = {
  input: UpdateUserSocialInput;
};


export type MutationUpdateUserEmergencyArgs = {
  input: UpdateUserEmergencyInput;
};


export type MutationUpdateUserAddressArgs = {
  input: UpdateUserAddressInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationResetPasswordArgs = {
  input: PasswordResetInput;
};


export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationCreateReportArgs = {
  input: CreateReportInput;
};


export type MutationAdmin_UpdateReportArgs = {
  input: UpdateReportInput;
};


export type MutationAdmin_SendEmailToReportedUserArgs = {
  input: SendEmailToReportedUserInput;
};


export type MutationAdmin_SendEmailToUserArgs = {
  input: SendEmailToUserInput;
};


export type MutationUpdateNotificationSettingsArgs = {
  input: UpdateSettingsInput;
};


export type MutationAdmin_UpdateClassSessionArgs = {
  input: AdminUpdateClassSessionInput;
};


export type MutationVerifyPurchaseIosArgs = {
  input: VerifyPurchaseIosInput;
};


export type MutationVerifyPurchasesIosArgs = {
  input: VerifyPurchasesIosInput;
};


export type MutationVerifyPurchaseAndroidArgs = {
  input: VerifyPurchaseAndroidInput;
};


export type MutationVerifyPurchasesAndroidArgs = {
  input: Array<VerifyPurchaseAndroidInput>;
};


export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};


export type MutationRemoveDeviceArgs = {
  tokenOrDeviceID: Scalars['String'];
};


export type MutationStoreDeviceInfoArgs = {
  input: StoreDeviceInfoInput;
};


export type MutationReportGympassEventArgs = {
  eventType: GympassEventEnum;
};


export type MutationAdmin_CreateCampaignArgs = {
  input: CreateCampaignInput;
};


export type MutationAdmin_UpdateCampaignArgs = {
  input: UpdateCampaignInput;
};


export type MutationAdmin_DeleteCampaignArgs = {
  input: DeleteCampaignInput;
};


export type MutationAdmin_UpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};


export type MutationAdmin_UpdateEmployeeShopifyPicksArgs = {
  input: UpdateEmployeeShopifyPicksInput;
};


export type MutationAdmin_UpdateStudioArgs = {
  input: AdminUpdateStudioInput;
};


export type MutationAdmin_CreateLibraryItemArgs = {
  input: CreateLibraryItemInput;
};


export type MutationAdmin_UpdateLibraryItemArgs = {
  input: UpdateLibraryItemInput;
};


export type MutationAdmin_DeleteLibraryItemArgs = {
  input: DeleteLibraryItemInput;
};


export type MutationAdmin_DeleteAllInstructorLibraryItemsArgs = {
  input: DeleteAllInstructorLibraryItemsInput;
};


export type MutationCreateBookmarkForLibraryItemArgs = {
  libraryItemID: Scalars['ID'];
};


export type MutationCreateBookmarkForCollectionArgs = {
  collectionID: Scalars['ID'];
};


export type MutationRemoveBookmarkArgs = {
  bookmarkID: Scalars['ID'];
};


export type MutationRemoveBookmarkByLibraryItemIdArgs = {
  libraryItemID: Scalars['ID'];
};


export type MutationAdmin_CreateOnDemandSectionArgs = {
  input: CreateOnDemandSectionInput;
};


export type MutationAdmin_UpdateOnDemandSectionArgs = {
  input: UpdateOnDemandSectionInput;
};


export type MutationAdmin_DeleteOnDemandSectionArgs = {
  input: DeleteOnDemandSectionInput;
};


export type MutationAdmin_ChangeOnDemandSectionsOrderArgs = {
  input: Array<ChangeOnDemandSectionsOrderInput>;
};


export type MutationAdmin_CreateOnDemandCollectionSectionArgs = {
  input: CreateOnDemandCollectionSectionInput;
};


export type MutationAdmin_UpdateOnDemandCollectionSectionArgs = {
  input: UpdateOnDemandCollectionSectionInput;
};


export type MutationAdmin_DeleteOnDemandCollectionSectionArgs = {
  input: DeleteOnDemandCollectionSectionInput;
};


export type MutationAdmin_ChangeOnDemandCollectionSectionsOrderArgs = {
  input: Array<ChangeOnDemandCollectionSectionsOrderInput>;
};


export type MutationAdmin_CreateOnDemandCollectionArgs = {
  input: CreateOnDemandCollectionInput;
};


export type MutationAdmin_UpdateOnDemandCollectionArgs = {
  input: UpdateOnDemandCollectionInput;
};


export type MutationAdmin_DeleteOnDemandCollectionArgs = {
  input: DeleteOnDemandCollectionInput;
};


export type MutationAdmin_ChangeOnDemandCollectionOrderArgs = {
  input: Array<ChangeOnDemandCollectionsOrderInput>;
};


export type MutationCreateRatingForClassSessionArgs = {
  input: CreateRatingForClassSessionInput;
};


export type MutationCreateRatingForLibraryItemArgs = {
  input: CreateRatingForLibraryItemInput;
};


export type MutationAdmin_UpdateFuelBarItemArgs = {
  input: UpdateFuelBarItemInput;
};


export type MutationCreateReservationArgs = {
  input: CreateReservationInput;
};


export type MutationCreateReservationForFreeClassArgs = {
  input: CreateFreeClassReservationInput;
};


export type MutationCreateGuestReservationArgs = {
  input: CreateGuestReservationInput;
};


export type MutationCreateGuestReservationForFreeClassArgs = {
  input: CreateFreeClassGuestReservationInput;
};


export type MutationChangeRoomArgs = {
  input: ChangeRoomInput;
};


export type MutationCancelReservationArgs = {
  reservationID: Scalars['ID'];
};


export type MutationCheckInReservationArgs = {
  reservationID: Scalars['ID'];
};


export type MutationCheckOutReservationArgs = {
  reservationID: Scalars['ID'];
};


export type MutationCreateWorkoutSummaryArgs = {
  input: CreateWorkoutSummaryInput;
};


export type MutationCreateWorkoutSummary_V2Args = {
  input: CreateWorkoutSummaryInput_V2;
};


export type MutationShareWorkoutSummaryWithFitfamArgs = {
  input: ShareWorkoutSummaryInput;
};


export type MutationDeleteFeedItemArgs = {
  feedID: Scalars['String'];
};


export type MutationAddReactionToFeedArgs = {
  input?: Maybe<ReactionInput>;
};


export type MutationRemoveReactionFromFeedArgs = {
  input?: Maybe<ReactionInput>;
};


export type MutationAdmin_CreateBannerArgs = {
  input: CreateBannerInput;
};


export type MutationAdmin_UpdateBannerArgs = {
  input: UpdateBannerInput;
};


export type MutationAdmin_DeleteBannerArgs = {
  input: DeleteBannerInput;
};


export type MutationAdmin_ChangeBannersOrderArgs = {
  input: Array<ChangeBannersOrderInput>;
};


export type MutationSaveVideoAnalyticsEventArgs = {
  input: SaveEventInput;
};


export type MutationSaveVideoAnalyticsWithMetadataEventArgs = {
  input: SaveEventInput;
};


export type MutationConnectClientArgs = {
  input: ConnectClientInput;
};


export type MutationDisconnectClientArgs = {
  input: DisconnectClientInput;
};


export type MutationCreateLiveSessionArgs = {
  input: CreateLiveSessionInput;
};


export type MutationStartMusicPlaybackArgs = {
  input: StartMusicPlaybackInput;
};


export type MutationUpdateMusicPlaybackTimesArgs = {
  input: UpdateMusicPlaybackInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationFinishSessionArgs = {
  input: FinishSessionInput;
};


export type MutationForceSoundOffArgs = {
  input: ForceSoundOffInput;
};


export type MutationForceVideoOffArgs = {
  input: ForceVideoOffInput;
};


export type MutationBanUserFromSessionArgs = {
  input: BanUserFromSessionInput;
};


export type MutationToggleRedFilterArgs = {
  input: ToggleRedFilterInput;
};


export type MutationChangeVideoPreferenceArgs = {
  input: ChangeVideoPreferenceInput;
};


export type MutationMuteAllArgs = {
  input: MuteAllInput;
};


export type MutationSendRoomMessageArgs = {
  input: SendRoomMessageInput;
};


export type MutationChangeRoomStatusToPreparationArgs = {
  input: ChangeRoomStatusToPreparationInput;
};


export type MutationChangeRoomStatusToActiveArgs = {
  input: ChangeRoomStatusToActiveInput;
};


export type MutationCancelUserMembershipArgs = {
  membershipID: Scalars['ID'];
};


export type MutationCancelUserMembership_V2Args = {
  input: CancelUserMembershipInput;
};


export type MutationAdmin_CreateInstructorBannerArgs = {
  input: CreateInstructorBannerInput;
};


export type MutationAdmin_UpdateInstructorBannerArgs = {
  input: UpdateInstructorBannerInput;
};


export type MutationAdmin_DeleteInstructorBannerArgs = {
  input: DeleteInstructorBannerInput;
};


export type MutationAdmin_ChangeInstructorBannersOrderArgs = {
  input: Array<ChangeInstructorBannersOrderInput>;
};

export type MuteAllInput = {
  liveSessionID: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  type: NotificationTypeEnum;
  user: User;
  title: Scalars['String'];
  body: Scalars['String'];
  isUnread: Scalars['Boolean'];
  /** User on notification context */
  friend?: Maybe<User>;
  /** Class Session on notification context */
  classSession?: Maybe<ClassSession>;
  /** Campaign on notification context */
  campaign?: Maybe<Campaign>;
};

/** NotificationSettingTypeEnum desc */
export enum NotificationSettingTypeEnum {
  NewFriendRequest = 'NEW_FRIEND_REQUEST',
  FriendRequestAccepted = 'FRIEND_REQUEST_ACCEPTED',
  ClassInvitationFromFriend = 'CLASS_INVITATION_FROM_FRIEND',
  ClassPurchasedFromFriend = 'CLASS_PURCHASED_FROM_FRIEND',
  FriendAttendingClass = 'FRIEND_ATTENDING_CLASS',
  UpcomingClass = 'UPCOMING_CLASS'
}

/** NotificationTypeEnum desc */
export enum NotificationTypeEnum {
  FriendshipRequested = 'FRIENDSHIP_REQUESTED',
  CancelFriendshipRequest = 'CANCEL_FRIENDSHIP_REQUEST',
  FriendshipAccepted = 'FRIENDSHIP_ACCEPTED',
  FriendInvitedClass = 'FRIEND_INVITED_CLASS',
  FriendPurchasedClass = 'FRIEND_PURCHASED_CLASS',
  FriendJoinedClass = 'FRIEND_JOINED_CLASS',
  ClassStarting = 'CLASS_STARTING',
  ProfileImageRemoved = 'PROFILE_IMAGE_REMOVED',
  Campaign = 'CAMPAIGN',
  UserCreditsUpdated = 'USER_CREDITS_UPDATED',
  JoinChallenge = 'JOIN_CHALLENGE',
  MilestoneReached = 'MILESTONE_REACHED',
  ClassMilestone = 'CLASS_MILESTONE',
  LoyaltyTierChanged = 'LOYALTY_TIER_CHANGED',
  Test = 'TEST'
}

/** OnDemandCategoryEnum desc */
export enum OnDemandCategoryEnum {
  ForYou = 'FOR_YOU',
  Audio = 'AUDIO'
}

export type OnDemandCollectionEntity = {
  __typename?: 'OnDemandCollectionEntity';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  section: OnDemandCollectionSectionEntity;
  imageURL: Scalars['String'];
  order: Scalars['Int'];
  collectionItems: Array<OnDemandCollectionItemEntity>;
  collectionItemsCount: Scalars['Int'];
};


export type OnDemandCollectionEntityCollectionItemsArgs = {
  input?: Maybe<PaginationInput>;
};

export type OnDemandCollectionItemEntity = {
  __typename?: 'OnDemandCollectionItemEntity';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  collection: OnDemandCollectionEntity;
  libraryItem: Library;
  order: Scalars['Int'];
};

export type OnDemandCollectionSectionEntity = {
  __typename?: 'OnDemandCollectionSectionEntity';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  order: Scalars['Int'];
  collections: Array<OnDemandCollectionEntity>;
};


export type OnDemandCollectionSectionEntityCollectionsArgs = {
  input?: Maybe<PaginationInput>;
};

export type OnDemandSectionEntity = {
  __typename?: 'OnDemandSectionEntity';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  category: ListOnDemandCategory;
  order: Scalars['Int'];
  items: Array<OnDemandSectionItemEntity>;
};


export type OnDemandSectionEntityItemsArgs = {
  input?: Maybe<PaginationInput>;
};

export type OnDemandSectionFilter = {
  category?: Maybe<OnDemandCategoryEnum>;
};

export type OnDemandSectionItemEntity = {
  __typename?: 'OnDemandSectionItemEntity';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  section: OnDemandSectionEntity;
  libraryItem: Library;
  order: Scalars['Int'];
  deletedAt: Scalars['DateTime'];
};

export type PaginationInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

export type PasswordResetInput = {
  email: Scalars['String'];
};

export type PasswordResetResult = {
  __typename?: 'PasswordResetResult';
  email: Scalars['String'];
};

export type PaymentOption = {
  __typename?: 'PaymentOption';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  count: Scalars['Int'];
  expirationDateTime: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
};

/** Platform enum */
export enum PlatformEnum {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type PlaylistObject = {
  __typename?: 'PlaylistObject';
  id: Scalars['String'];
  name: Scalars['String'];
  tracks: Array<TrackObject>;
};

/** User profile privacy setting */
export enum PrivacyEnum {
  Public = 'PUBLIC',
  Friends = 'FRIENDS',
  Private = 'PRIVATE'
}

export type ProductClass = {
  __typename?: 'ProductClass';
  name: Scalars['String'];
  description: Scalars['String'];
  items: Array<ProductClassItem>;
};

export type ProductClassItem = {
  __typename?: 'ProductClassItem';
  id: Scalars['ID'];
  description: Scalars['String'];
  allowGuests: Scalars['Boolean'];
  expirationInterval: TimePeriodEnum;
  expirationLength: Scalars['Int'];
};

export type ProductMembership = {
  __typename?: 'ProductMembership';
  name: Scalars['String'];
  description: Scalars['String'];
  items: Array<ProductMembershipItem>;
};

export type ProductMembershipItem = {
  __typename?: 'ProductMembershipItem';
  id: Scalars['ID'];
  description: Scalars['String'];
  allowGuests: Scalars['Boolean'];
  expirationInterval: TimePeriodEnum;
  expirationLength: Scalars['Int'];
};

export type Purchase = {
  __typename?: 'Purchase';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  platform: PlatformEnum;
  productID: Scalars['String'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  trialEndsAt?: Maybe<Scalars['DateTime']>;
  usageIntervalLimit?: Maybe<Scalars['Int']>;
  hasVODAccess: Scalars['Boolean'];
};

export type PurchaseHistoryResult = {
  __typename?: 'PurchaseHistoryResult';
  orderNumber: Scalars['String'];
  amount: Scalars['Float'];
  currency: Scalars['String'];
  date: Scalars['DateTime'];
  summary: Array<Scalars['String']>;
};

export type PurchaseIapHistoryResult = {
  __typename?: 'PurchaseIAPHistoryResult';
  orderNumber?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  currency: Scalars['String'];
  date: Scalars['DateTime'];
  summary: Array<Scalars['String']>;
};

export enum PurchaseTypeEnum {
  Consumable = 'CONSUMABLE',
  Subscription = 'SUBSCRIPTION'
}

export type Query = {
  __typename?: 'Query';
  health: Scalars['String'];
  currentTime: Scalars['DateTime'];
  /**
   * Lists all friendships of the user.
   * @deprecated Removed to allow for sorting by firstName and lastName
   */
  listFriendships: Array<Friendship>;
  /** Lists all friendships of the user. */
  listFriendships_v2: Array<Friendship>;
  /** Lists all friendships of the user showing who's already attending the classe of the classSessionId provided on input. */
  listFriendshipsAttendingClass: Array<FriendAttendingClass>;
  listNotifications: Array<Notification>;
  getNotification: Notification;
  /** Get the number of unread notifications for the current user. */
  getUnreadNotificationsCount: Scalars['Int'];
  /** Get information about the current user. */
  getUser: User;
  /** Search users. */
  listUsers: Array<User>;
  /** Search users and show if they are attending the class. */
  listUsersAttendingClass: Array<UserAttendingClass>;
  /** Checks if a username is available or not. */
  usernameExists: Scalars['Boolean'];
  /** Lists all admin users (admin only) */
  admin_listAdmins: Array<User>;
  /** Get information about selected user (admin only) */
  admin_getUser: User;
  /** List all users (admin only) */
  admin_listUsers: Array<User>;
  /** Return history of all purchases directly in MT API for current user */
  listUserOrders: Array<PurchaseHistoryResult>;
  /** Return history of all in-app purchases for current user */
  listUserIAPOrders: Array<PurchaseIapHistoryResult>;
  hasUserUnlimitedMembership: Scalars['Boolean'];
  /** Get information about the current user, section Personal. */
  getUserPersonal: UserPersonal;
  /** Get information about the current user, section Social section. */
  getUserSocial: UserSocial;
  /** Get information about the current user, section Emergency. */
  getUserEmergency: UserEmergency;
  /** Get information about the current user, section Address section. */
  getUserAddress: UserAddress;
  /** Lists all available Report reasons for creating a report. */
  listReportReasons: Array<ListReportReason>;
  /** Lists all reports matching the filter (Admin only). */
  admin_listReports: Array<Report>;
  admin_getReport: Report;
  /** Lists the user's favorite Instructors, sorted by attendance in classes. */
  listFavoriteInstructors: Array<Instructor>;
  /** Get details about a single Instructor. */
  getInstructor: Instructor;
  /** Lists all active instructors. */
  listInstructors: Array<Instructor>;
  /** [v2] Lists all active instructors. */
  listInstructors_v2: Array<Instructor>;
  /** Count all VOD instructors. */
  countVODInstructors: Scalars['Int'];
  /** Return settings entity for current user */
  getSettingsForUser: Setting;
  /** List all Classes (Class Sessions). */
  listClassSessions: Array<ClassSession>;
  /** List all Classes for admin (Class Sessions). */
  admin_listClassSessions: Array<ClassSession>;
  /** List Classes reserved by the user */
  listUserClassSessions: Array<ClassSession>;
  /** Gets information about a single Class */
  getClassSession: ClassSession;
  /** Check if classSessionID is valid and class session for the ID exists */
  validateClassSessionID: ValidateClassSessionIdResult;
  /** List all available equipments for library items */
  listEquipmentForClassSession: Array<ListEquipment>;
  /** List purchases of a user filtered by type */
  listUserPurchases: Array<Purchase>;
  /** List all devices of the user */
  listDevices: Array<Device>;
  /** List the device information stored in the db. */
  listDeviceInfos: Array<DeviceInfo>;
  /** Fetch gympass user info. */
  getGympassUserData: GympassUser;
  /** Get detail about single Campaign */
  getCampaign: Campaign;
  /** list all Campaigns (admin only) */
  admin_listCampaigns: Array<Campaign>;
  /** Get detail about single Campaign (admin only) */
  admin_getCampaign: Campaign;
  /** List available products (credits, memberships, etc.) */
  listProducts: Catalogue;
  /** Get details about a single employee. */
  admin_getEmployee: Employee;
  /** Lists all employees */
  admin_listEmployees: Array<Employee>;
  /** Lists shopify picks for employee */
  admin_listEmployeeShopifyPicks: Array<ShopifyPicks>;
  /** Get public profile for selected employee */
  getEmployee: GetEmployeeResult;
  /** List employees */
  listEmployees: Array<Employee>;
  /** List all studios matching the filters */
  admin_listStudios: Array<AdminStudioResult>;
  /** Get detail of selected studio */
  admin_getStudio: AdminStudioResult;
  /** List all available body parts for library items */
  listBodyParts: Array<ListBodyPart>;
  /** List all available body part filters for library items */
  listBodyPartsFilters: Array<ListBodyPartFilter>;
  /** List all available equipments for library items */
  listEquipment: Array<ListEquipment>;
  /** List all available equipment filters for library items */
  listEquipmentFilters: Array<ListEquipmentFilter>;
  /** List all available equipment filters for library items */
  listEquipmentFilters_v2: Array<ListEquipmentFilter>;
  /** List all available library item labels */
  listLibraryItemLabels: Array<ListLibraryItemLabel>;
  /** List all available library item types */
  listLibraryItemTypes: Array<ListLibraryItemType>;
  /** List all available library item durations */
  listLibraryItemDurations: Array<ListLibraryItemDuration>;
  /** List all available filters for library items */
  listVodFilters: ListVodFilters;
  /** List all available filters for a particular instructor */
  listInstructorVodFilters: ListInstructorVodFilters;
  /** List all library items matching selected filters */
  listLibraryItems: Array<Library>;
  /** Get detail about selected library item (admin only) */
  admin_getLibraryItem: Library;
  /** Get detail about selected library item */
  getLibraryItem: Library;
  /** List all library items matching selected filters (admin only) */
  admin_listLibraryItems: Array<Library>;
  /** Count all library items matching selected filters */
  countLibraryItems: Scalars['Int'];
  /** Count all library items matching selected filters */
  admin_countLibraryItems: AdminCountLibraryItems;
  /** @deprecated Use getFileURL_v2 instead to be able to select dual audio or mono audio option and also improve selection of stream types */
  getFileURL: Scalars['String'];
  getFileURL_v2: FileUrl;
  getFileURL_v3: FileUrl_V3;
  /** List all available library item categories */
  listLibraryItemCategories: Array<ListLibraryItemCategory>;
  /** Fetch user bookmarks. */
  listBookmarks: Array<Bookmark>;
  /** List all on demand section matching the filter (admin only) */
  admin_listOnDemandSections: Array<OnDemandSectionEntity>;
  /** List all on demand section matching the filter */
  listOnDemandSections: Array<OnDemandSectionEntity>;
  /** Get detail of selected section (admin only) */
  admin_getOnDemandSection: OnDemandSectionEntity;
  /** Get detail of selected section */
  getOnDemandSection: OnDemandSectionEntity;
  /** List all possible categories for on demand sections */
  listOnDemandSectionCategories: Array<ListOnDemandCategory>;
  /** List all available collection sections with their collections (admin only) */
  admin_listOnDemandCollectionSections: Array<OnDemandCollectionSectionEntity>;
  /** List all available collection sections with their collections */
  listOnDemandCollectionSections: Array<OnDemandCollectionSectionEntity>;
  /** Return detail of selected collection (admin only) */
  admin_getOnDemandCollection: OnDemandCollectionEntity;
  /** Return detail of selected collection */
  getOnDemandCollection: OnDemandCollectionEntity;
  /** Gets Signed URL for uploading public files. */
  getSignedUrl: GetSignedUrlResult;
  /** Gets Signed URL for uploading private files. */
  admin_getPrivateSignedUrl: GetSignedUrlResult;
  /** Ordered list of rating questions */
  listQuestions: Array<Scalars['String']>;
  /** Lists all available Regions */
  listRegions: Array<ListRegionsResult>;
  /** Lists all available studios with hierarchy (Country - Region - Studio) */
  listStudios: Array<ListStudiosResult>;
  /** List all fuel bar items matching the filters (admin only) */
  admin_listFuelBarItems: Array<FuelBar>;
  /** Get detail about single fuel bar item (admin only) */
  admin_getFuelBarItem: FuelBar;
  /** Lists Reservations (booked classes) of a user. */
  listReservations: Array<Reservation>;
  /** Lists all VOD workout summaries. */
  listWorkoutSummaries: Array<WorkoutSummary>;
  /**
   * Lists all the entries of the Fitfam feed.
   * @deprecated To be able to show different types of content use listFeed_v2 instead
   */
  listFeed: Array<Feed>;
  /** Lists all the entries of the Fitfam feed. */
  listFeed_v2: Array<Fitfam>;
  /** Get a playlist name [admin use only] */
  getPlaylist?: Maybe<PlaylistObject>;
  /** Get all users that have reacted to a feed */
  getUsersForReaction: Array<User>;
  /** List all banners matching the filters (admin only) */
  admin_listBanners: Array<Banner>;
  /** List all banners grouped by category */
  listGroupedBanners: Array<ListGroupedBannersResult>;
  /** Get detail about single banner (admin only) */
  admin_getBanner: Banner;
  /** List all available banner categories (admin only) */
  admin_listBannerCategories: Array<ListBannerCategory>;
  /** Get Statistical data of a user for the Stats screen. */
  getUserStats: UserStatsResult;
  /** Gets the attendance of classes of a user in a chart-friendly format. */
  getUserAttendance: Array<UserAttendanceResult>;
  /** List of visited studios. */
  listVisitedStudios: Array<Studio>;
  getLiveSession: LiveSession;
  getTwilioToken: GetTwilioTokenResult;
  /** List all the active Class Session types. */
  listClassSessionTypes: Array<ClassSessionType>;
  /** List Payment Options for a given class */
  listPaymentOptions: Array<PaymentOption>;
  /** Returns the remaining amount of credits for the current user */
  getUserCreditsCount: Scalars['Int'];
  /** List the credit packages the user has purchased */
  listUserCredits: Array<Credit>;
  /** Get the penalty for canceling credit transaction */
  getCreditTransactionPenalty: GetCreditTransactionPenaltyResult;
  /** List active memberships from the current user */
  listUserMemberships: Array<Membership>;
  /** Get the penalty for canceling membership transaction */
  getMembershipTransactionPenalty: GetMembershipTransactionPenaltyResult;
  /** Returns the remaining amount of membership usages for the current user */
  getUserMembershipUsageCount: Scalars['Int'];
  /** Returns the default booking window */
  getDefaultBookingWindow: BookingWindowEntity;
  /** Lists all available Countries */
  listCountries: Array<Country>;
  /** List all instructor banners matching the filters (admin only) */
  admin_listInstructorBanners: Array<InstructorBanner>;
  /** Get detail about single instructor banner (admin only) */
  admin_getInstructorBanner: InstructorBanner;
  /** Lists all frequency challenges */
  listFrequencyChallenges: Array<Challenge>;
  /** Lists current frequency challenges */
  listCurrentFrequencyChallenges: Array<Challenge>;
  /** Lists past frequency challenges */
  listPastFrequencyChallenges: Array<Challenge>;
  /** Spotify: Get access token with duration of 1 hour to spotify api requests. */
  getSpotifyAccessToken: SpotifyAccessToken;
  /** Spotify: Access tokens are deliberately set to expire after a short time, after which new tokens may be granted by supplying the refresh token originally obtained during the authorization code exchange. */
  getSpotifyRefreshToken: SpotifyRefreshToken;
  /** Spotify: get playlist information from spotify api. */
  getSpotifyPlaylist: SpotifyPlaylistResponse;
};


export type QueryListFriendshipsArgs = {
  input?: Maybe<ListFriendshipInput>;
};


export type QueryListFriendships_V2Args = {
  input?: Maybe<ListFriendshipInput>;
};


export type QueryListFriendshipsAttendingClassArgs = {
  input?: Maybe<ListFriendshipAttendingClass>;
};


export type QueryListNotificationsArgs = {
  input: PaginationInput;
};


export type QueryGetNotificationArgs = {
  notificationID: Scalars['ID'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryListUsersArgs = {
  input: ListUserInput;
};


export type QueryListUsersAttendingClassArgs = {
  input: ListUserInputAttendingClass;
};


export type QueryUsernameExistsArgs = {
  username: Scalars['String'];
};


export type QueryAdmin_GetUserArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_ListUsersArgs = {
  input: AdminListUsersInput;
};


export type QueryListUserOrdersArgs = {
  input: ListUserOrdersInput;
};


export type QueryListUserIapOrdersArgs = {
  input: ListUserOrdersInput;
};


export type QueryGetUserPersonalArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserSocialArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserEmergencyArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserAddressArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_ListReportsArgs = {
  input?: Maybe<ListReportsInput>;
};


export type QueryAdmin_GetReportArgs = {
  id: Scalars['ID'];
};


export type QueryListFavoriteInstructorsArgs = {
  userID?: Maybe<Scalars['ID']>;
};


export type QueryGetInstructorArgs = {
  id: Scalars['ID'];
};


export type QueryListInstructorsArgs = {
  input: ListInstructorsInput;
};


export type QueryListInstructors_V2Args = {
  input: ListInstructorsInput_V2;
};


export type QueryCountVodInstructorsArgs = {
  input: CountVodInstructorsInput;
};


export type QueryListClassSessionsArgs = {
  input: ListClassSessionInput;
};


export type QueryAdmin_ListClassSessionsArgs = {
  input: AdminListClassSessionsInput;
};


export type QueryListUserClassSessionsArgs = {
  input: ListUserClassSessionsInput;
};


export type QueryGetClassSessionArgs = {
  id: Scalars['ID'];
};


export type QueryValidateClassSessionIdArgs = {
  classSessionID: Scalars['ID'];
};


export type QueryListUserPurchasesArgs = {
  input: ListUserPurchasesInput;
};


export type QueryListDevicesArgs = {
  userID: Scalars['ID'];
};


export type QueryListDeviceInfosArgs = {
  input?: Maybe<ListDeviceInfoInput>;
};


export type QueryGetGympassUserDataArgs = {
  gympassUID: Scalars['String'];
};


export type QueryGetCampaignArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_ListCampaignsArgs = {
  input?: Maybe<ListCampaignsInput>;
};


export type QueryAdmin_GetCampaignArgs = {
  id: Scalars['ID'];
};


export type QueryListProductsArgs = {
  input: ListProductsInput;
};


export type QueryAdmin_GetEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_ListEmployeesArgs = {
  input: ListEmployeesInput;
};


export type QueryAdmin_ListEmployeeShopifyPicksArgs = {
  employeeID: Scalars['ID'];
};


export type QueryGetEmployeeArgs = {
  username: Scalars['String'];
};


export type QueryListEmployeesArgs = {
  input: ListEmployeesPublicInput;
};


export type QueryAdmin_ListStudiosArgs = {
  input?: Maybe<AdminListStudiosInput>;
};


export type QueryAdmin_GetStudioArgs = {
  id: Scalars['ID'];
};


export type QueryListBodyPartsFiltersArgs = {
  input?: Maybe<ListVodFiltersInput>;
};


export type QueryListEquipmentArgs = {
  input?: Maybe<ListVodFiltersInput>;
};


export type QueryListEquipmentFiltersArgs = {
  input?: Maybe<ListVodFiltersInput>;
};


export type QueryListEquipmentFilters_V2Args = {
  input?: Maybe<ListVodFiltersInput>;
};


export type QueryListVodFiltersArgs = {
  input: ListVodFiltersInput;
};


export type QueryListInstructorVodFiltersArgs = {
  input: ListInstructorVodFiltersInput;
};


export type QueryListLibraryItemsArgs = {
  input?: Maybe<ListLibraryItemsInput>;
};


export type QueryAdmin_GetLibraryItemArgs = {
  id: Scalars['ID'];
};


export type QueryGetLibraryItemArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_ListLibraryItemsArgs = {
  input?: Maybe<AdminListLibraryItemsInput>;
};


export type QueryCountLibraryItemsArgs = {
  input?: Maybe<ListLibraryItemsInput>;
};


export type QueryAdmin_CountLibraryItemsArgs = {
  input?: Maybe<AdminListLibraryItemsInput>;
};


export type QueryGetFileUrlArgs = {
  input: GetFileUrlInput;
};


export type QueryGetFileUrl_V2Args = {
  input: GetFileUrlInput_V2;
};


export type QueryGetFileUrl_V3Args = {
  input: GetFileUrlInput_V3;
};


export type QueryListLibraryItemCategoriesArgs = {
  input?: Maybe<GetLibraryItemCategoriesTotalInput>;
};


export type QueryListBookmarksArgs = {
  input?: Maybe<ListUserBookmarksInput>;
};


export type QueryAdmin_ListOnDemandSectionsArgs = {
  input?: Maybe<ListOnDemandSectionInput>;
};


export type QueryListOnDemandSectionsArgs = {
  input?: Maybe<ListOnDemandSectionInput>;
};


export type QueryAdmin_GetOnDemandSectionArgs = {
  id: Scalars['ID'];
};


export type QueryGetOnDemandSectionArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_GetOnDemandCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryGetOnDemandCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryGetSignedUrlArgs = {
  options: GetSignedUrlInput;
};


export type QueryAdmin_GetPrivateSignedUrlArgs = {
  input: GetSignedUrlInput;
};


export type QueryListRegionsArgs = {
  input?: Maybe<ListRegionsInput>;
};


export type QueryAdmin_ListFuelBarItemsArgs = {
  input?: Maybe<ListFuelBarItemsInput>;
};


export type QueryAdmin_GetFuelBarItemArgs = {
  id: Scalars['ID'];
};


export type QueryListReservationsArgs = {
  input: ListReservationsInput;
};


export type QueryListWorkoutSummariesArgs = {
  input: ListWorkoutSummaryInput;
};


export type QueryListFeedArgs = {
  input?: Maybe<ListFeedInput>;
};


export type QueryListFeed_V2Args = {
  input?: Maybe<ListFeedInput>;
};


export type QueryGetPlaylistArgs = {
  input: GetPlaylistInput;
};


export type QueryGetUsersForReactionArgs = {
  input?: Maybe<ReactionInput>;
};


export type QueryAdmin_ListBannersArgs = {
  input?: Maybe<ListBannersInput>;
};


export type QueryAdmin_GetBannerArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserStatsArgs = {
  userID?: Maybe<Scalars['ID']>;
};


export type QueryGetUserAttendanceArgs = {
  userID: Scalars['ID'];
  format: Scalars['String'];
};


export type QueryListVisitedStudiosArgs = {
  input?: Maybe<ListStudiosInput>;
};


export type QueryGetLiveSessionArgs = {
  input: GetLiveSessionInput;
};


export type QueryGetTwilioTokenArgs = {
  input: GetTwilioTokenInput;
};


export type QueryListClassSessionTypesArgs = {
  input?: Maybe<ListClassSessionTypesInput>;
};


export type QueryListPaymentOptionsArgs = {
  input: ListPaymentOptionsInput;
};


export type QueryListUserCreditsArgs = {
  input: PaginationInput;
};


export type QueryGetCreditTransactionPenaltyArgs = {
  input: GetCreditTransactionPenaltyInput;
};


export type QueryListUserMembershipsArgs = {
  input: PaginationInput;
};


export type QueryGetMembershipTransactionPenaltyArgs = {
  input: GetMembershipTransactionPenaltyInput;
};


export type QueryAdmin_ListInstructorBannersArgs = {
  input?: Maybe<ListInstructorBannersInput>;
};


export type QueryAdmin_GetInstructorBannerArgs = {
  id: Scalars['ID'];
};


export type QueryGetSpotifyAccessTokenArgs = {
  input: AccessTokenInput;
};


export type QueryGetSpotifyRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type QueryGetSpotifyPlaylistArgs = {
  input: GetSpotifyPlaylistInput;
};

export enum RatingLevel {
  Bad = 'BAD',
  Meh = 'MEH',
  Cool = 'COOL',
  Good = 'GOOD',
  Great = 'GREAT'
}

/** Reactions for fitfam items */
export enum Reaction {
  Dead = 'DEAD',
  Fire = 'FIRE',
  Heart = 'HEART',
  Muscle = 'MUSCLE'
}

export type ReactionInput = {
  type: Reaction;
  feedID: Scalars['String'];
};

export type ReactionResponse = {
  __typename?: 'ReactionResponse';
  count: Scalars['Float'];
  included: Scalars['Boolean'];
};

export type ReactionUpdateResponse = {
  __typename?: 'ReactionUpdateResponse';
  success: Scalars['Boolean'];
};

export type Reactions = {
  __typename?: 'Reactions';
  DEAD: ReactionResponse;
  FIRE: ReactionResponse;
  HEART: ReactionResponse;
  MUSCLE: ReactionResponse;
};

export type ReadAllNotificationsResult = {
  __typename?: 'ReadAllNotificationsResult';
  success: Scalars['Boolean'];
};

export type RefreshToken = {
  __typename?: 'RefreshToken';
  accessToken: Scalars['String'];
  tokenType: Scalars['String'];
  expiresIn: Scalars['Float'];
  scope: Scalars['String'];
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  /** MarianaTek Region ID */
  id: Scalars['ID'];
  name: Scalars['String'];
  country: Country;
  locations?: Maybe<Array<Location>>;
};

export type RegisterDeviceInput = {
  token: Scalars['String'];
  deviceID?: Maybe<Scalars['ID']>;
  platform?: Maybe<PlatformEnum>;
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  reporting: User;
  reported: User;
  seen: Scalars['Boolean'];
  resolved: Scalars['Boolean'];
  reason: ReportReason;
};

export type ReportFilter = {
  reported?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
};

export type ReportReason = {
  __typename?: 'ReportReason';
  name: Scalars['String'];
  type: Scalars['String'];
};

/** Report type */
export enum ReportReasonEnum {
  InappropriatePicture = 'INAPPROPRIATE_PICTURE',
  InappropriateProfile = 'INAPPROPRIATE_PROFILE',
  InappropriateBehavior = 'INAPPROPRIATE_BEHAVIOR',
  FakeProfile = 'FAKE_PROFILE'
}

export type RequestFriendshipInput = {
  /** ID of the user being added as friend */
  userID: Scalars['ID'];
};

export type Reservation = {
  __typename?: 'Reservation';
  id: Scalars['ID'];
  streamURL?: Maybe<Scalars['String']>;
  streamWindow?: Maybe<Scalars['Float']>;
  reservationType: Scalars['String'];
  status: ReservationStatusEnum;
  type: ReservationTypeEnum;
  checkInDate?: Maybe<Scalars['String']>;
  creationDate: Scalars['String'];
  classSession: ClassSession;
  /** ID of reserved spot. Null if user is on "waiting list" */
  spotID?: Maybe<Scalars['String']>;
  /** Name of reserved spot */
  spotName?: Maybe<Scalars['String']>;
  membershipTransactions: Array<Transaction>;
  creditTransactions: Array<Transaction>;
  roomIndex?: Maybe<Scalars['Int']>;
};

/** Reservation Sorting */
export enum ReservationSortingEnum {
  ClassSessionStartDatetimeAsc = 'CLASS_SESSION_START_DATETIME_ASC',
  ClassSessionStartDatetimeDesc = 'CLASS_SESSION_START_DATETIME_DESC'
}

/** ReservationStatusEnum desc */
export enum ReservationStatusEnum {
  Pending = 'PENDING',
  CheckIn = 'CHECK_IN',
  StandardCancel = 'STANDARD_CANCEL',
  PenaltyCancel = 'PENALTY_CANCEL',
  GracedCancel = 'GRACED_CANCEL',
  PenaltyNoShow = 'PENALTY_NO_SHOW',
  GracedNoShow = 'GRACED_NO_SHOW',
  Removed = 'REMOVED',
  Penalty = 'PENALTY',
  ClassCancelled = 'CLASS_CANCELLED',
  Upcoming = 'UPCOMING',
  Inactive = 'INACTIVE'
}

/** ReservationTimeEnum desc */
export enum ReservationTimeEnum {
  Upcoming = 'UPCOMING',
  Past = 'PAST'
}

/** ReservationTypeEnum desc */
export enum ReservationTypeEnum {
  Standard = 'STANDARD',
  Standby = 'STANDBY',
  Waitlist = 'WAITLIST'
}

export type RevokeVodAccessResult = {
  __typename?: 'RevokeVODAccessResult';
  success: Scalars['Boolean'];
};

export type RevokeVodAccessToUserInput = {
  userID: Scalars['ID'];
};

/** RoleEnum desc */
export enum RoleEnum {
  User = 'USER',
  Moderator = 'MODERATOR',
  Admin = 'ADMIN'
}

export type SaveEventInput = {
  idempotencyKey: Scalars['String'];
  playedTime: Scalars['Float'];
  libraryItemID: Scalars['ID'];
};

export type SaveEventObject = {
  __typename?: 'SaveEventObject';
  result: Scalars['String'];
  watched: Scalars['Boolean'];
};

export type SendEmailToReportedUserInput = {
  userToID: Scalars['ID'];
  message: Scalars['String'];
};

export type SendEmailToReportedUserResult = {
  __typename?: 'SendEmailToReportedUserResult';
  success: Scalars['Boolean'];
};

export type SendEmailToUserInput = {
  email: Scalars['String'];
  message: Scalars['String'];
};

export type SendEmailToUserResult = {
  __typename?: 'SendEmailToUserResult';
  success: Scalars['Boolean'];
};

export type SendMessageInput = {
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type SendRoomMessageInput = {
  /** Live session room ID */
  id: Scalars['ID'];
  message: Scalars['String'];
  /** User ID who should receive the message */
  recipientID?: Maybe<Scalars['ID']>;
};

export type SessionObject = {
  __typename?: 'SessionObject';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  /** Ex.: 2020-10-21T12:27:36.826Z */
  expiresAt: Scalars['String'];
};

export type Setting = {
  __typename?: 'Setting';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  user: User;
  newFriendRequest: Scalars['Boolean'];
  friendRequestAccepted: Scalars['Boolean'];
  classInvitationFromFriend: Scalars['Boolean'];
  classPurchasedFromFriend: Scalars['Boolean'];
  friendAttendingClass: Scalars['Boolean'];
  upcomingClass: Scalars['Boolean'];
};

export type ShareWorkoutSummaryInput = {
  workoutSummaryID: Scalars['String'];
};

export type ShareWorkoutSummaryResult = {
  __typename?: 'ShareWorkoutSummaryResult';
  success: Scalars['Boolean'];
};

export type ShopifyPickInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  url: Scalars['String'];
  pictureUrl: Scalars['String'];
};

export type ShopifyPicks = {
  __typename?: 'ShopifyPicks';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  url: Scalars['String'];
  pictureUrl: Scalars['String'];
};

export type ShopifyPicksResult = {
  __typename?: 'ShopifyPicksResult';
  name: Scalars['String'];
  url: Scalars['String'];
  pictureUrl: Scalars['String'];
};

export type SignInInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type SortInput = {
  column: Scalars['String'];
  direction?: Maybe<Direction>;
};

export type SpotifyAccessToken = {
  __typename?: 'SpotifyAccessToken';
  spotifyAccessToken: AccessToken;
};

export type SpotifyExternalUrl = {
  __typename?: 'SpotifyExternalURL';
  spotify?: Maybe<Scalars['String']>;
};

export type SpotifyImages = {
  __typename?: 'SpotifyImages';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type SpotifyPlaylist = {
  __typename?: 'SpotifyPlaylist';
  playlistID: Scalars['String'];
  imageURL: Scalars['String'];
  title: Scalars['String'];
};

export type SpotifyPlaylistInput = {
  playlistID: Scalars['String'];
  imageURL: Scalars['String'];
  title: Scalars['String'];
};

export type SpotifyPlaylistResponse = {
  __typename?: 'SpotifyPlaylistResponse';
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  external_urls?: Maybe<SpotifyExternalUrl>;
  images?: Maybe<Array<SpotifyImages>>;
  public?: Maybe<Scalars['Boolean']>;
  tracks?: Maybe<SpotifyTracks>;
};

export type SpotifyRefreshToken = {
  __typename?: 'SpotifyRefreshToken';
  spotifyRefreshToken: RefreshToken;
};

export type SpotifyTracks = {
  __typename?: 'SpotifyTracks';
  total?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
};

export type StartMusicPlaybackInput = {
  classSessionID: Scalars['String'];
};

export type StartMusicPlaybackResult = {
  __typename?: 'StartMusicPlaybackResult';
  isSuccessful: Scalars['Boolean'];
  message: Scalars['String'];
};

export type StoreDeviceInfoInput = {
  deviceID: Scalars['String'];
  deviceType: Scalars['String'];
  appVersion?: Maybe<Scalars['String']>;
  browserType?: Maybe<Scalars['String']>;
  browserVersion?: Maybe<Scalars['String']>;
  osType: Scalars['String'];
  osVersion: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
};

export type Studio = {
  __typename?: 'Studio';
  numVisited: Scalars['Int'];
  region: StudioRegion;
};

export type StudioFilter = {
  name?: Maybe<Scalars['String']>;
};

export type StudioLocation = {
  __typename?: 'StudioLocation';
  name: Scalars['String'];
};

export type StudioRegion = {
  __typename?: 'StudioRegion';
  name: Scalars['String'];
  location: StudioLocation;
};

export enum TimePeriodEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type ToggleRedFilterInput = {
  roomID: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type TrackObject = {
  __typename?: 'TrackObject';
  title: Scalars['String'];
  artistName: Scalars['String'];
  artistImageURL?: Maybe<Scalars['String']>;
  trackImageURL?: Maybe<Scalars['String']>;
  /** Full duration of the track in seconds */
  duration: Scalars['Int'];
  startSecond?: Maybe<Scalars['Float']>;
  isrc: Scalars['String'];
  labelName: Scalars['String'];
  licensorName: Scalars['String'];
};

export type Transaction = {
  __typename?: 'Transaction';
  type: Scalars['String'];
  id: Scalars['String'];
};

export type UpcomingClassesResult = {
  __typename?: 'UpcomingClassesResult';
  name: Scalars['String'];
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  region: Region;
  location: Location;
};

export type UpdateAdminInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type UpdateAdminResult = {
  __typename?: 'UpdateAdminResult';
  id: Scalars['ID'];
};

export type UpdateBannerInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Call to action text */
  CTAText: Scalars['String'];
  link: Scalars['String'];
  imageURL: Scalars['String'];
};

export type UpdateCampaignInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  imageURL: Scalars['String'];
  scheduledDateTime: Scalars['DateTime'];
};

export type UpdateEmployeeInput = {
  id: Scalars['String'];
  regionID?: Maybe<Scalars['String']>;
  locationID?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  tiktokUsername?: Maybe<Scalars['String']>;
  linkedinUsername?: Maybe<Scalars['String']>;
  instagramHandle?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  isPublished: Scalars['Boolean'];
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberForTexting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateEmployeeShopifyPicksInput = {
  employeeID: Scalars['String'];
  shopifyPicks: Array<ShopifyPickInput>;
};

export type UpdateFuelBarItemInput = {
  id: Scalars['ID'];
  imageURL?: Maybe<Scalars['String']>;
};

export type UpdateInstructorBannerInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Call to action text */
  CTAText: Scalars['String'];
  link: Scalars['String'];
  imageURL: Scalars['String'];
  regionID?: Maybe<Scalars['String']>;
  locationID?: Maybe<Scalars['String']>;
};

export type UpdateLibraryItemInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  parentCategory?: Maybe<LibraryItemCategoryEnum>;
  bodyParts: Array<BodyPartEnum>;
  equipment: Array<EquipmentEnum>;
  instructorID: Scalars['ID'];
  labels?: Maybe<Array<LibraryItemLabelEnum>>;
  duration: Scalars['Int'];
  type?: Maybe<LibraryItemTypeEnum>;
  imageURL: Scalars['String'];
  classID: Scalars['String'];
  /** Video + music + instructors voice (video file). The data is linked to metadata */
  fileID: Scalars['String'];
  /** Video + instructors voice (video file). The data is linked to metadataInstructorFile */
  instructorFileID?: Maybe<Scalars['String']>;
  /** Music (audio file) */
  audioID?: Maybe<Scalars['String']>;
  playlistID?: Maybe<Scalars['String']>;
  playlistTrackInfo?: Maybe<Scalars['String']>;
  hasClosedCaptions?: Maybe<Scalars['Boolean']>;
  hasClosedCaptionsInstructorFile?: Maybe<Scalars['Boolean']>;
  status?: Maybe<LibraryItemStatusEnum>;
  statusEffectiveSince?: Maybe<Scalars['DateTime']>;
  statusComment?: Maybe<Scalars['String']>;
  additionalInstructorID?: Maybe<Scalars['String']>;
  classSessionID?: Maybe<Scalars['String']>;
  spotifyPlaylistIDs?: Maybe<Array<LibrarySpotifyPlaylistInput>>;
  appleMusicPlaylistIDs?: Maybe<Array<Scalars['String']>>;
};

export type UpdateMusicPlaybackInput = {
  classSessionID: Scalars['String'];
  /** The isrc of the track that the playlist was skipped to. */
  currentIsrc: Scalars['String'];
};

export type UpdateNotificationInput = {
  notificationID: Scalars['ID'];
  isUnread?: Maybe<Scalars['Boolean']>;
};

export type UpdateOnDemandCollectionInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  imageURL: Scalars['String'];
  libraryItemIDs: Array<Scalars['ID']>;
};

export type UpdateOnDemandCollectionSectionInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateOnDemandSectionInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  libraryItemIDs: Array<Scalars['ID']>;
};

export type UpdateReportInput = {
  id: Scalars['ID'];
  seen: Scalars['Boolean'];
  resolved: Scalars['Boolean'];
};

export type UpdateReportedUserInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
};

export type UpdateReportedUserResult = {
  __typename?: 'UpdateReportedUserResult';
  id: Scalars['ID'];
};

export type UpdateSettingsInput = {
  type: NotificationSettingTypeEnum;
  value: Scalars['Boolean'];
};

export type UpdateUserAddressInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  country?: Maybe<CountryInput>;
  postalCode?: Maybe<Scalars['String']>;
};

export type UpdateUserEmergencyInput = {
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  /** Ex.: 2010-01-01 */
  emergencyContactEmail?: Maybe<Scalars['String']>;
  /** +2348012345678 */
  emergencyContactPhone?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  /** Validation Regex can be found on Confluence. */
  username?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** +2348012345678 */
  phoneNumber?: Maybe<Scalars['String']>;
  homeLocationId?: Maybe<Scalars['ID']>;
  /** Ex.: 2010-01-01 */
  birthDate?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  privacy?: Maybe<PrivacyEnum>;
  isOnboarded?: Maybe<Scalars['Boolean']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  /** Ex.: 2010-01-01 */
  emergencyContactEmail?: Maybe<Scalars['String']>;
  /** +2348012345678 */
  emergencyContactPhone?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  country?: Maybe<CountryInput>;
  postalCode?: Maybe<Scalars['String']>;
};

export type UpdateUserPersonalInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** Ex.: 2010-01-01 */
  birthDate?: Maybe<Scalars['String']>;
  /** +2348012345678 */
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UpdateUserResult = {
  __typename?: 'UpdateUserResult';
  id: Scalars['ID'];
  user: User;
};

export type UpdateUserSocialInput = {
  /** Validation Regex can be found on Confluence. */
  username?: Maybe<Scalars['String']>;
  homeLocationId?: Maybe<Scalars['ID']>;
  bio?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  marianaTekID: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  isOnboarded: Scalars['Boolean'];
  role: RoleEnum;
  privacy: PrivacyEnum;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  lastMtFetch?: Maybe<Scalars['DateTime']>;
  emergencyContactEmail?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactPhone?: Maybe<Scalars['String']>;
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  homeLocation?: Maybe<Location>;
  birthDate?: Maybe<Scalars['String']>;
  isWaiverSigned?: Maybe<Scalars['Boolean']>;
  isMarketingOptIn?: Maybe<Scalars['Boolean']>;
  memberSince?: Maybe<Scalars['DateTime']>;
  reports: Array<Report>;
  /** If `null`, users are not friends. */
  friendship?: Maybe<Friendship>;
  isBlocked: Scalars['Boolean'];
  isActiveAdmin: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  instructor?: Maybe<Instructor>;
  unseenFriendRequestCount: Scalars['Int'];
  hasGrantedVODAccess: Scalars['Boolean'];
  grantedVODAccessNote: Scalars['String'];
  speedRecord: Scalars['Float'];
  loyaltyTier?: Maybe<LoyaltyTierType>;
  gympassUID?: Maybe<Scalars['String']>;
  unseenFitfamActivityCount: Scalars['Int'];
  country?: Maybe<Country>;
  hasVODAccess?: Maybe<Scalars['Boolean']>;
  friendsCount: Scalars['Int'];
  geozone: Scalars['String'];
  isInRestrictedArea: Scalars['Boolean'];
};

export type UserAddress = {
  __typename?: 'UserAddress';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
};

export type UserAttendanceResult = {
  __typename?: 'UserAttendanceResult';
  value: Scalars['String'];
  type: Scalars['String'];
  entries: Array<AttendanceEntry>;
};

export type UserAttendingClass = {
  __typename?: 'UserAttendingClass';
  user: User;
  attending: Scalars['Boolean'];
};

export type UserEmergency = {
  __typename?: 'UserEmergency';
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  emergencyContactPhone?: Maybe<Scalars['String']>;
  emergencyContactEmail?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
};

export type UserIdInput = {
  id: Scalars['ID'];
};

export type UserPersonal = {
  __typename?: 'UserPersonal';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
};

export type UserSocial = {
  __typename?: 'UserSocial';
  username?: Maybe<Scalars['String']>;
  homeLocation?: Maybe<Location>;
  bio?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
};

export type UserStatsResult = {
  __typename?: 'UserStatsResult';
  totalClasses?: Maybe<Scalars['Int']>;
  upcomingClasses?: Maybe<Scalars['Int']>;
  /** @deprecated Use digitalClasses instead. */
  atHomeClasses?: Maybe<Scalars['Int']>;
  digitalClasses?: Maybe<Scalars['Int']>;
  redRoomClasses?: Maybe<Scalars['Int']>;
  studiosVisited?: Maybe<Array<Studio>>;
  favStudio?: Maybe<Studio>;
  longestStreak?: Maybe<Scalars['Int']>;
  bestWeek?: Maybe<Scalars['Int']>;
  favoriteDay?: Maybe<Scalars['String']>;
  favoriteTime?: Maybe<Scalars['String']>;
  vodClasses?: Maybe<Scalars['Int']>;
  cardioVODClasses?: Maybe<Scalars['Int']>;
  strengthVODClasses?: Maybe<Scalars['Int']>;
  stretchVODClasses?: Maybe<Scalars['Int']>;
  strengthCardioVODClasses?: Maybe<Scalars['Int']>;
  workouts: Array<WorkoutStats>;
  instructors: Array<Instructor>;
};


export type UserStatsResultStudiosVisitedArgs = {
  input?: Maybe<PaginationInput>;
};


export type UserStatsResultInstructorsArgs = {
  input?: Maybe<PaginationInput>;
};

export type ValidateClassSessionIdResult = {
  __typename?: 'ValidateClassSessionIDResult';
  valid: Scalars['Boolean'];
};

export type VerifyAndroidPurchaseObject = {
  __typename?: 'VerifyAndroidPurchaseObject';
  isActive: Scalars['Boolean'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  productID: Scalars['String'];
  hasVODAccess?: Maybe<Scalars['Boolean']>;
};

export type VerifyIosPurchaseObject = {
  __typename?: 'VerifyIOSPurchaseObject';
  isActive: Scalars['Boolean'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  transactionID: Scalars['String'];
  productID?: Maybe<Scalars['String']>;
  hasVODAccess?: Maybe<Scalars['Boolean']>;
};

export type VerifyPurchaseAndroidInput = {
  packageName: Scalars['String'];
  /** Ex.: com.barrys.membership.8 */
  productID: Scalars['String'];
  purchaseToken: Scalars['String'];
};

export type VerifyPurchaseIosInput = {
  receiptData: Scalars['String'];
  /** Ex.: com.barrys.barrysathome.ios.membership.8 */
  productID?: Maybe<Scalars['String']>;
  transactionID: Scalars['String'];
};

export type VerifyPurchasesIosInput = {
  receiptData: Scalars['String'];
  transactionIDs: Array<Scalars['String']>;
};

/** VideoPreferenceEnum desc */
export enum VideoPreferenceEnum {
  Off = 'OFF',
  Instructor = 'INSTRUCTOR',
  InstructorAndFriends = 'INSTRUCTOR_AND_FRIENDS',
  All = 'ALL'
}

/** videoStreamType: { option: "hls" | "dash" }. Default option when the field is empty is hls */
export type VideoStreamType = {
  option?: Maybe<Scalars['String']>;
};

export type WorkoutStats = {
  __typename?: 'WorkoutStats';
  name: Scalars['String'];
  count: Scalars['Int'];
};

export type WorkoutSummary = {
  __typename?: 'WorkoutSummary';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  classSessionID?: Maybe<Scalars['String']>;
  userID: Scalars['String'];
  libraryID?: Maybe<Scalars['String']>;
  totalTime: Scalars['Float'];
  activeCalories?: Maybe<Scalars['Float']>;
  totalCalories?: Maybe<Scalars['Float']>;
  heartRate?: Maybe<Scalars['Float']>;
  maxHeartRate?: Maybe<Scalars['Float']>;
  miles?: Maybe<Scalars['Float']>;
  topMPH?: Maybe<Scalars['Float']>;
  averageBPM?: Maybe<Scalars['Float']>;
  sharedWithFitfam: Scalars['Boolean'];
  lastTopMPH?: Maybe<Scalars['Float']>;
  newTopMPH?: Maybe<Scalars['Float']>;
  bpmChart?: Maybe<Array<WorkoutSummaryChartItem>>;
};

export type WorkoutSummaryChartItem = {
  __typename?: 'WorkoutSummaryChartItem';
  timestamp: Scalars['DateTime'];
  value: Scalars['Int'];
};

export type WorkoutSummaryChartItemInput = {
  timestamp: Scalars['DateTime'];
  value: Scalars['Int'];
};

export type UserFieldsFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'username' | 'pictureUrl' | 'email' | 'isBlocked' | 'hasVODAccess' | 'hasGrantedVODAccess' | 'bio' | 'birthDate' | 'marianaTekID' | 'role'>
  & { homeLocation?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'name'>
  )> }
);

export type ReportFieldsFragmentFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'id' | 'createdAt' | 'seen' | 'resolved'>
  & { reporting: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  ), reported: (
    { __typename?: 'User' }
    & UserFieldsFragmentFragment
  ), reason: (
    { __typename?: 'ReportReason' }
    & Pick<ReportReason, 'name'>
  ) }
);

export type EmployeeFieldsFragmentFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'firstName' | 'lastName' | 'title' | 'pictureUrl' | 'isPublished' | 'locationID' | 'promoCode' | 'emailAddress' | 'tiktokUsername' | 'linkedinUsername' | 'shopifyPicksCount' | 'instagramHandle' | 'regionName'>
  & { region?: Maybe<(
    { __typename?: 'Region' }
    & Pick<Region, 'id' | 'name'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'code'>
    ) }
  )>, location?: Maybe<(
    { __typename?: 'AdminStudioResult' }
    & Pick<AdminStudioResult, 'id' | 'name'>
  )> }
);

export type InstructorBannerFragmentFragment = (
  { __typename?: 'InstructorBanner' }
  & Pick<InstructorBanner, 'id' | 'createdAt' | 'name' | 'description' | 'CTAText' | 'imageURL' | 'link' | 'order'>
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )>, region?: Maybe<(
    { __typename?: 'Region' }
    & Pick<Region, 'id' | 'name'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'code' | 'name'>
    ) }
  )> }
);

export type SessionFragmentFragment = (
  { __typename?: 'Auth' }
  & { session: (
    { __typename?: 'SessionObject' }
    & Pick<SessionObject, 'accessToken' | 'expiresAt' | 'refreshToken'>
  ) }
);

export type InstructorFragmentFragment = (
  { __typename?: 'Instructor' }
  & Pick<Instructor, 'firstName' | 'lastName' | 'fullName' | 'id'>
);

export type InstructorCacheFragmentFragment = (
  { __typename?: 'InstructorCache' }
  & Pick<InstructorCache, 'firstName' | 'lastName' | 'id'>
);

export type BannerFragmentFragment = (
  { __typename?: 'Banner' }
  & Pick<Banner, 'id' | 'createdAt' | 'name' | 'CTAText' | 'imageURL' | 'link' | 'order'>
  & { category: (
    { __typename?: 'ListBannerCategory' }
    & Pick<ListBannerCategory, 'name' | 'type'>
  ) }
);

export type StudioFragmentFragment = (
  { __typename?: 'AdminStudioResult' }
  & Pick<AdminStudioResult, 'id' | 'name' | 'regionName' | 'email' | 'phoneNumber' | 'phoneNumberForTexting'>
);

export type ClassSessionFragmentFragment = (
  { __typename?: 'ClassSession' }
  & Pick<ClassSession, 'id' | 'name' | 'appleMusicPlaylistIDs' | 'startDateTime' | 'endDateTime'>
  & { spotifyPlaylistIDs?: Maybe<Array<(
    { __typename?: 'SpotifyPlaylist' }
    & Pick<SpotifyPlaylist, 'playlistID' | 'imageURL' | 'title'>
  )>>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  ), region: (
    { __typename?: 'Region' }
    & Pick<Region, 'id' | 'name'>
  ), instructors?: Maybe<Array<(
    { __typename?: 'Instructor' }
    & Pick<Instructor, 'id' | 'firstName' | 'lastName' | 'fullName'>
  )>>, equipment?: Maybe<Array<(
    { __typename?: 'ListEquipment' }
    & Pick<ListEquipment, 'name' | 'type' | 'image'>
  )>> }
);

export type LibraryFragmentFragment = (
  { __typename?: 'Library' }
  & Pick<Library, 'id' | 'title' | 'description' | 'createdAt' | 'appleMusicPlaylistIDs' | 'imageURL' | 'classID' | 'classSessionID' | 'fileID' | 'instructorFileID' | 'audioID' | 'hasClosedCaptionsInstructorFile' | 'hasClosedCaptions' | 'status' | 'statusEffectiveSince' | 'statusComment' | 'type'>
  & { spotifyPlaylistIDs?: Maybe<Array<(
    { __typename?: 'LibrarySpotifyPlaylist' }
    & Pick<LibrarySpotifyPlaylist, 'playlistID' | 'imageURL' | 'title'>
  )>>, instructor: (
    { __typename?: 'Instructor' }
    & InstructorFragmentFragment
  ), additionalInstructor?: Maybe<(
    { __typename?: 'Instructor' }
    & InstructorFragmentFragment
  )>, parentCategory?: Maybe<(
    { __typename?: 'ListLibraryItemCategory' }
    & Pick<ListLibraryItemCategory, 'name' | 'type'>
  )>, bodyParts: Array<(
    { __typename?: 'ListBodyPart' }
    & Pick<ListBodyPart, 'name' | 'type'>
  )>, equipment: Array<(
    { __typename?: 'ListEquipment' }
    & Pick<ListEquipment, 'name' | 'type'>
  )>, labels: Array<(
    { __typename?: 'ListLibraryItemLabel' }
    & Pick<ListLibraryItemLabel, 'name' | 'type'>
  )>, duration: (
    { __typename?: 'ListLibraryItemDuration' }
    & Pick<ListLibraryItemDuration, 'name' | 'type'>
  ) }
);

export type SignInMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignInMutation = (
  { __typename?: 'Mutation' }
  & { signIn: (
    { __typename?: 'Auth' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'id' | 'role' | 'isActiveAdmin'>
    ) }
    & SessionFragmentFragment
  ) }
);

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken: (
    { __typename?: 'Auth' }
    & SessionFragmentFragment
  ) }
);

export type UpdateReportMutationVariables = Exact<{
  input: UpdateReportInput;
}>;


export type UpdateReportMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'id'>
  ) }
);

export type UpdateReportedUserMutationVariables = Exact<{
  input: UpdateReportedUserInput;
}>;


export type UpdateReportedUserMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateReportedUser: (
    { __typename?: 'UpdateReportedUserResult' }
    & Pick<UpdateReportedUserResult, 'id'>
  ) }
);

export type BlockUserMutationVariables = Exact<{
  input: UserIdInput;
}>;


export type BlockUserMutation = (
  { __typename?: 'Mutation' }
  & { admin_blockUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UnblockUserMutationVariables = Exact<{
  input: UserIdInput;
}>;


export type UnblockUserMutation = (
  { __typename?: 'Mutation' }
  & { admin_unblockUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type DeleteAdminMutationVariables = Exact<{
  input: UserIdInput;
}>;


export type DeleteAdminMutation = (
  { __typename?: 'Mutation' }
  & { admin_deleteAdmin: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type ActivateAdminMutationVariables = Exact<{
  input: UserIdInput;
}>;


export type ActivateAdminMutation = (
  { __typename?: 'Mutation' }
  & { admin_activateAdmin: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type DeactivateAdminMutationVariables = Exact<{
  input: UserIdInput;
}>;


export type DeactivateAdminMutation = (
  { __typename?: 'Mutation' }
  & { admin_deactivateAdmin: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type CreateAdminMutationVariables = Exact<{
  input: CreateAdminInput;
}>;


export type CreateAdminMutation = (
  { __typename?: 'Mutation' }
  & { admin_createAdmin: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UpdateAdminMutationVariables = Exact<{
  input: UpdateAdminInput;
}>;


export type UpdateAdminMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateAdmin: (
    { __typename?: 'UpdateAdminResult' }
    & Pick<UpdateAdminResult, 'id'>
  ) }
);

export type ChangeBannersOrderMutationVariables = Exact<{
  input: Array<ChangeBannersOrderInput> | ChangeBannersOrderInput;
}>;


export type ChangeBannersOrderMutation = (
  { __typename?: 'Mutation' }
  & { admin_changeBannersOrder: Array<(
    { __typename?: 'Banner' }
    & Pick<Banner, 'id'>
  )> }
);

export type CreateBannerMutationVariables = Exact<{
  input: CreateBannerInput;
}>;


export type CreateBannerMutation = (
  { __typename?: 'Mutation' }
  & { admin_createBanner: (
    { __typename?: 'Banner' }
    & Pick<Banner, 'id'>
  ) }
);

export type UpdateBannerMutationVariables = Exact<{
  input: UpdateBannerInput;
}>;


export type UpdateBannerMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateBanner: (
    { __typename?: 'Banner' }
    & Pick<Banner, 'id'>
  ) }
);

export type DeleteBannerMutationVariables = Exact<{
  input: DeleteBannerInput;
}>;


export type DeleteBannerMutation = (
  { __typename?: 'Mutation' }
  & { admin_deleteBanner: (
    { __typename?: 'DeleteBannerResult' }
    & Pick<DeleteBannerResult, 'success'>
  ) }
);

export type CreateOnDemandSectionMutationVariables = Exact<{
  input: CreateOnDemandSectionInput;
}>;


export type CreateOnDemandSectionMutation = (
  { __typename?: 'Mutation' }
  & { admin_createOnDemandSection: (
    { __typename?: 'OnDemandSectionEntity' }
    & Pick<OnDemandSectionEntity, 'id'>
  ) }
);

export type UpdateOnDemandSectionMutationVariables = Exact<{
  input: UpdateOnDemandSectionInput;
}>;


export type UpdateOnDemandSectionMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateOnDemandSection: (
    { __typename?: 'OnDemandSectionEntity' }
    & Pick<OnDemandSectionEntity, 'id'>
  ) }
);

export type DeleteOnDemandSectionMutationVariables = Exact<{
  input: DeleteOnDemandSectionInput;
}>;


export type DeleteOnDemandSectionMutation = (
  { __typename?: 'Mutation' }
  & { admin_deleteOnDemandSection: (
    { __typename?: 'DeleteOnDemandSectionResult' }
    & Pick<DeleteOnDemandSectionResult, 'success'>
  ) }
);

export type ChangeOnDemandSectionsOrderMutationVariables = Exact<{
  input: Array<ChangeOnDemandSectionsOrderInput> | ChangeOnDemandSectionsOrderInput;
}>;


export type ChangeOnDemandSectionsOrderMutation = (
  { __typename?: 'Mutation' }
  & { admin_changeOnDemandSectionsOrder: Array<(
    { __typename?: 'OnDemandSectionEntity' }
    & Pick<OnDemandSectionEntity, 'id'>
  )> }
);

export type CreateOnDemandCollectionSectionMutationVariables = Exact<{
  input: CreateOnDemandCollectionSectionInput;
}>;


export type CreateOnDemandCollectionSectionMutation = (
  { __typename?: 'Mutation' }
  & { admin_createOnDemandCollectionSection: (
    { __typename?: 'OnDemandCollectionSectionEntity' }
    & Pick<OnDemandCollectionSectionEntity, 'id'>
  ) }
);

export type UpdateOnDemandCollectionSectionMutationVariables = Exact<{
  input: UpdateOnDemandCollectionSectionInput;
}>;


export type UpdateOnDemandCollectionSectionMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateOnDemandCollectionSection: (
    { __typename?: 'OnDemandCollectionSectionEntity' }
    & Pick<OnDemandCollectionSectionEntity, 'id'>
  ) }
);

export type DeleteOnDemandCollectionSectionMutationVariables = Exact<{
  input: DeleteOnDemandCollectionSectionInput;
}>;


export type DeleteOnDemandCollectionSectionMutation = (
  { __typename?: 'Mutation' }
  & { admin_deleteOnDemandCollectionSection: (
    { __typename?: 'DeleteOnDemandCollectionSectionResult' }
    & Pick<DeleteOnDemandCollectionSectionResult, 'success'>
  ) }
);

export type ChangeOnDemandCollectionSectionsOrderMutationVariables = Exact<{
  input: Array<ChangeOnDemandCollectionSectionsOrderInput> | ChangeOnDemandCollectionSectionsOrderInput;
}>;


export type ChangeOnDemandCollectionSectionsOrderMutation = (
  { __typename?: 'Mutation' }
  & { admin_changeOnDemandCollectionSectionsOrder: Array<(
    { __typename?: 'OnDemandCollectionSectionEntity' }
    & Pick<OnDemandCollectionSectionEntity, 'id'>
  )> }
);

export type CreateOnDemandCollectionMutationVariables = Exact<{
  input: CreateOnDemandCollectionInput;
}>;


export type CreateOnDemandCollectionMutation = (
  { __typename?: 'Mutation' }
  & { admin_createOnDemandCollection: (
    { __typename?: 'OnDemandCollectionEntity' }
    & Pick<OnDemandCollectionEntity, 'id'>
  ) }
);

export type UpdateOnDemandCollectionMutationVariables = Exact<{
  input: UpdateOnDemandCollectionInput;
}>;


export type UpdateOnDemandCollectionMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateOnDemandCollection: (
    { __typename?: 'OnDemandCollectionEntity' }
    & Pick<OnDemandCollectionEntity, 'id'>
  ) }
);

export type DeleteOnDemandCollectionMutationVariables = Exact<{
  input: DeleteOnDemandCollectionInput;
}>;


export type DeleteOnDemandCollectionMutation = (
  { __typename?: 'Mutation' }
  & { admin_deleteOnDemandCollection: (
    { __typename?: 'DeleteOnDemandCollectionResult' }
    & Pick<DeleteOnDemandCollectionResult, 'success'>
  ) }
);

export type ChangeOnDemandCollectionOrderMutationVariables = Exact<{
  input: Array<ChangeOnDemandCollectionsOrderInput> | ChangeOnDemandCollectionsOrderInput;
}>;


export type ChangeOnDemandCollectionOrderMutation = (
  { __typename?: 'Mutation' }
  & { admin_changeOnDemandCollectionOrder: Array<(
    { __typename?: 'OnDemandCollectionEntity' }
    & Pick<OnDemandCollectionEntity, 'id'>
  )> }
);

export type AdminCreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;


export type AdminCreateCampaignMutation = (
  { __typename?: 'Mutation' }
  & { admin_createCampaign: (
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id'>
  ) }
);

export type AdminUpdateCampaignMutationVariables = Exact<{
  input: UpdateCampaignInput;
}>;


export type AdminUpdateCampaignMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateCampaign: (
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id'>
  ) }
);

export type AdminDeleteCampaignMutationVariables = Exact<{
  input: DeleteCampaignInput;
}>;


export type AdminDeleteCampaignMutation = (
  { __typename?: 'Mutation' }
  & { admin_deleteCampaign: (
    { __typename?: 'DeleteCampaignResult' }
    & Pick<DeleteCampaignResult, 'success'>
  ) }
);

export type AdminGrantVodAccessToUserMutationVariables = Exact<{
  input: GrantVodAccessToUserInput;
}>;


export type AdminGrantVodAccessToUserMutation = (
  { __typename?: 'Mutation' }
  & { admin_grantVODAccessToUser: (
    { __typename?: 'GrantVODAccessResult' }
    & Pick<GrantVodAccessResult, 'success'>
  ) }
);

export type AdminRevokeVodAccessFromUserMutationVariables = Exact<{
  input: RevokeVodAccessToUserInput;
}>;


export type AdminRevokeVodAccessFromUserMutation = (
  { __typename?: 'Mutation' }
  & { admin_revokeVODAccessFromUser: (
    { __typename?: 'RevokeVODAccessResult' }
    & Pick<RevokeVodAccessResult, 'success'>
  ) }
);

export type AdminSendEmailToReportedUserMutationVariables = Exact<{
  input: SendEmailToReportedUserInput;
}>;


export type AdminSendEmailToReportedUserMutation = (
  { __typename?: 'Mutation' }
  & { admin_sendEmailToReportedUser: (
    { __typename?: 'SendEmailToReportedUserResult' }
    & Pick<SendEmailToReportedUserResult, 'success'>
  ) }
);

export type AdminCreateLibraryItemMutationVariables = Exact<{
  input: CreateLibraryItemInput;
}>;


export type AdminCreateLibraryItemMutation = (
  { __typename?: 'Mutation' }
  & { admin_createLibraryItem: (
    { __typename?: 'Library' }
    & LibraryFragmentFragment
  ) }
);

export type AdminUpdateLibraryItemMutationVariables = Exact<{
  input: UpdateLibraryItemInput;
}>;


export type AdminUpdateLibraryItemMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateLibraryItem: (
    { __typename?: 'Library' }
    & LibraryFragmentFragment
  ) }
);

export type AdminDeleteLibraryItemMutationVariables = Exact<{
  input: DeleteLibraryItemInput;
}>;


export type AdminDeleteLibraryItemMutation = (
  { __typename?: 'Mutation' }
  & { admin_deleteLibraryItem: (
    { __typename?: 'DeleteLibraryItemResult' }
    & Pick<DeleteLibraryItemResult, 'success'>
  ) }
);

export type AdminDeletePictureForReportedUserMutationVariables = Exact<{
  input: DeletePictureForReportedUser;
}>;


export type AdminDeletePictureForReportedUserMutation = (
  { __typename?: 'Mutation' }
  & { admin_deletePictureForReportedUser: (
    { __typename?: 'DeletePictureForReportedUserResult' }
    & Pick<DeletePictureForReportedUserResult, 'success'>
  ) }
);

export type AdminUpdateClassSessionMutationVariables = Exact<{
  input: AdminUpdateClassSessionInput;
}>;


export type AdminUpdateClassSessionMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateClassSession: (
    { __typename?: 'ClassSession' }
    & ClassSessionFragmentFragment
  ) }
);

export type UpdateEmployeeMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateEmployee: (
    { __typename?: 'Employee' }
    & EmployeeFieldsFragmentFragment
  ) }
);

export type UpdateShopifyPicksMutationVariables = Exact<{
  input: UpdateEmployeeShopifyPicksInput;
}>;


export type UpdateShopifyPicksMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateEmployeeShopifyPicks: Array<(
    { __typename?: 'ShopifyPicks' }
    & Pick<ShopifyPicks, 'id' | 'name' | 'url' | 'pictureUrl'>
  )> }
);

export type CreateInstructorBannerMutationVariables = Exact<{
  input: CreateInstructorBannerInput;
}>;


export type CreateInstructorBannerMutation = (
  { __typename?: 'Mutation' }
  & { admin_createInstructorBanner: (
    { __typename?: 'InstructorBanner' }
    & InstructorBannerFragmentFragment
  ) }
);

export type UpdateInstructorBannerMutationVariables = Exact<{
  input: UpdateInstructorBannerInput;
}>;


export type UpdateInstructorBannerMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateInstructorBanner: (
    { __typename?: 'InstructorBanner' }
    & InstructorBannerFragmentFragment
  ) }
);

export type DeleteInstructorBannerMutationVariables = Exact<{
  input: DeleteInstructorBannerInput;
}>;


export type DeleteInstructorBannerMutation = (
  { __typename?: 'Mutation' }
  & { admin_deleteInstructorBanner: (
    { __typename?: 'DeleteInstructorBannerResult' }
    & Pick<DeleteInstructorBannerResult, 'success'>
  ) }
);

export type AdminUpdateStudioMutationVariables = Exact<{
  input: AdminUpdateStudioInput;
}>;


export type AdminUpdateStudioMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateStudio: (
    { __typename?: 'AdminStudioResult' }
    & StudioFragmentFragment
  ) }
);

export type AdminChangeInstructorBannersOrderMutationVariables = Exact<{
  input: Array<ChangeInstructorBannersOrderInput> | ChangeInstructorBannersOrderInput;
}>;


export type AdminChangeInstructorBannersOrderMutation = (
  { __typename?: 'Mutation' }
  & { admin_changeInstructorBannersOrder: Array<(
    { __typename?: 'InstructorBanner' }
    & InstructorBannerFragmentFragment
  )> }
);

export type GetCurrentUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  ) }
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { admin_getUser: (
    { __typename?: 'User' }
    & { reports: Array<(
      { __typename?: 'Report' }
      & ReportFieldsFragmentFragment
    )> }
    & UserFieldsFragmentFragment
  ) }
);

export type GetReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetReportQuery = (
  { __typename?: 'Query' }
  & { admin_getReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'seen' | 'resolved'>
    & { reason: (
      { __typename?: 'ReportReason' }
      & Pick<ReportReason, 'name'>
    ), reported: (
      { __typename?: 'User' }
      & Pick<User, 'isBlocked'>
      & { reports: Array<(
        { __typename?: 'Report' }
        & ReportFieldsFragmentFragment
      )> }
      & UserFieldsFragmentFragment
    ) }
  ) }
);

export type ListReportsQueryVariables = Exact<{
  input?: Maybe<ListReportsInput>;
}>;


export type ListReportsQuery = (
  { __typename?: 'Query' }
  & { admin_listReports: Array<(
    { __typename?: 'Report' }
    & ReportFieldsFragmentFragment
  )> }
);

export type LibraryFormOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type LibraryFormOptionsQuery = (
  { __typename?: 'Query' }
  & { listLibraryItemLabels: Array<(
    { __typename?: 'ListLibraryItemLabel' }
    & Pick<ListLibraryItemLabel, 'name' | 'type'>
  )>, listLibraryItemCategories: Array<(
    { __typename?: 'ListLibraryItemCategory' }
    & Pick<ListLibraryItemCategory, 'name' | 'type'>
  )>, listBodyParts: Array<(
    { __typename?: 'ListBodyPart' }
    & Pick<ListBodyPart, 'name' | 'type'>
  )>, listLibraryItemDurations: Array<(
    { __typename?: 'ListLibraryItemDuration' }
    & Pick<ListLibraryItemDuration, 'name' | 'type'>
  )> }
);

export type ListEquipmentQueryVariables = Exact<{
  input?: Maybe<ListVodFiltersInput>;
}>;


export type ListEquipmentQuery = (
  { __typename?: 'Query' }
  & { listEquipment: Array<(
    { __typename?: 'ListEquipment' }
    & Pick<ListEquipment, 'name' | 'type'>
  )> }
);

export type ListEquipmentForClassSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type ListEquipmentForClassSessionQuery = (
  { __typename?: 'Query' }
  & { listEquipmentForClassSession: Array<(
    { __typename?: 'ListEquipment' }
    & Pick<ListEquipment, 'name' | 'type'>
  )> }
);

export type ListInstructorsQueryVariables = Exact<{
  input: ListInstructorsInput;
}>;


export type ListInstructorsQuery = (
  { __typename?: 'Query' }
  & { listInstructors: Array<(
    { __typename?: 'Instructor' }
    & InstructorFragmentFragment
  )> }
);

export type ListAdminsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAdminsQuery = (
  { __typename?: 'Query' }
  & { admin_listAdmins: Array<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'isActiveAdmin' | 'isBlocked' | 'id' | 'email' | 'note' | 'createdAt'>
  )> }
);

export type GetSignedUrlQueryVariables = Exact<{
  options: GetSignedUrlInput;
}>;


export type GetSignedUrlQuery = (
  { __typename?: 'Query' }
  & { getSignedUrl: (
    { __typename?: 'GetSignedUrlResult' }
    & Pick<GetSignedUrlResult, 'url' | 'publicUrl' | 'expiresAt'>
  ) }
);

export type AdminGetPrivateSignedUrlQueryVariables = Exact<{
  input: GetSignedUrlInput;
}>;


export type AdminGetPrivateSignedUrlQuery = (
  { __typename?: 'Query' }
  & { admin_getPrivateSignedUrl: (
    { __typename?: 'GetSignedUrlResult' }
    & Pick<GetSignedUrlResult, 'url' | 'publicUrl' | 'fileName'>
  ) }
);

export type ListBannersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListBannersQuery = (
  { __typename?: 'Query' }
  & { admin_listBanners: Array<(
    { __typename?: 'Banner' }
    & BannerFragmentFragment
  )> }
);

export type AdminGetBannerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminGetBannerQuery = (
  { __typename?: 'Query' }
  & { admin_getBanner: (
    { __typename?: 'Banner' }
    & BannerFragmentFragment
  ) }
);

export type AdminGetOnDemandCollectionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminGetOnDemandCollectionQuery = (
  { __typename?: 'Query' }
  & { admin_getOnDemandCollection: (
    { __typename?: 'OnDemandCollectionEntity' }
    & Pick<OnDemandCollectionEntity, 'id'>
  ) }
);

export type AdminGetOnDemandSectionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminGetOnDemandSectionQuery = (
  { __typename?: 'Query' }
  & { admin_getOnDemandSection: (
    { __typename?: 'OnDemandSectionEntity' }
    & Pick<OnDemandSectionEntity, 'id' | 'order' | 'name'>
    & { items: Array<(
      { __typename?: 'OnDemandSectionItemEntity' }
      & Pick<OnDemandSectionItemEntity, 'createdAt' | 'id' | 'order'>
      & { libraryItem: (
        { __typename?: 'Library' }
        & LibraryFragmentFragment
      ) }
    )> }
  ) }
);

export type AdminListOnDemandCollectionSectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminListOnDemandCollectionSectionsQuery = (
  { __typename?: 'Query' }
  & { admin_listOnDemandCollectionSections: Array<(
    { __typename?: 'OnDemandCollectionSectionEntity' }
    & Pick<OnDemandCollectionSectionEntity, 'id' | 'name' | 'createdAt'>
    & { collections: Array<(
      { __typename?: 'OnDemandCollectionEntity' }
      & Pick<OnDemandCollectionEntity, 'id' | 'name' | 'createdAt' | 'imageURL'>
      & { collectionItems: Array<(
        { __typename?: 'OnDemandCollectionItemEntity' }
        & Pick<OnDemandCollectionItemEntity, 'id'>
        & { libraryItem: (
          { __typename?: 'Library' }
          & LibraryFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type AdminListOnDemandSectionsQueryVariables = Exact<{
  input?: Maybe<ListOnDemandSectionInput>;
}>;


export type AdminListOnDemandSectionsQuery = (
  { __typename?: 'Query' }
  & { admin_listOnDemandSections: Array<(
    { __typename?: 'OnDemandSectionEntity' }
    & Pick<OnDemandSectionEntity, 'id' | 'name' | 'createdAt'>
    & { category: (
      { __typename?: 'ListOnDemandCategory' }
      & Pick<ListOnDemandCategory, 'type'>
    ) }
  )> }
);

export type AdminListLibraryItemsQueryVariables = Exact<{
  input?: Maybe<AdminListLibraryItemsInput>;
}>;


export type AdminListLibraryItemsQuery = (
  { __typename?: 'Query' }
  & { admin_listLibraryItems: Array<(
    { __typename?: 'Library' }
    & { instructorCache: (
      { __typename?: 'InstructorCache' }
      & InstructorCacheFragmentFragment
    ) }
    & LibraryFragmentFragment
  )> }
);

export type GetCampaignQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCampaignQuery = (
  { __typename?: 'Query' }
  & { admin_getCampaign: (
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'body' | 'scheduledDateTime' | 'imageURL' | 'sent' | 'title' | 'sentDateTime'>
  ) }
);

export type ListCampaignsQueryVariables = Exact<{
  input?: Maybe<ListCampaignsInput>;
}>;


export type ListCampaignsQuery = (
  { __typename?: 'Query' }
  & { admin_listCampaigns: Array<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'body' | 'scheduledDateTime' | 'imageURL' | 'sent' | 'title' | 'sentDateTime'>
  )> }
);

export type GetLibraryItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLibraryItemQuery = (
  { __typename?: 'Query' }
  & { admin_getLibraryItem: (
    { __typename?: 'Library' }
    & LibraryFragmentFragment
  ) }
);

export type ValidateClassSessionIdQueryVariables = Exact<{
  classSessionId: Scalars['ID'];
}>;


export type ValidateClassSessionIdQuery = (
  { __typename?: 'Query' }
  & { validateClassSessionID: (
    { __typename?: 'ValidateClassSessionIDResult' }
    & Pick<ValidateClassSessionIdResult, 'valid'>
  ) }
);

export type ListVodFiltersQueryVariables = Exact<{
  category?: Maybe<LibraryItemCategoryEnum>;
}>;


export type ListVodFiltersQuery = (
  { __typename?: 'Query' }
  & { listVodFilters: (
    { __typename?: 'ListVodFilters' }
    & { bodyParts?: Maybe<Array<(
      { __typename?: 'ListBodyPart' }
      & Pick<ListBodyPart, 'name' | 'type'>
    )>>, bodyPartsFilters: Array<(
      { __typename?: 'ListBodyPartFilter' }
      & Pick<ListBodyPartFilter, 'name' | 'type'>
    )>, equipment?: Maybe<Array<(
      { __typename?: 'ListEquipment' }
      & Pick<ListEquipment, 'name' | 'type' | 'image'>
    )>>, equipmentFilters: Array<(
      { __typename?: 'ListEquipmentFilter' }
      & Pick<ListEquipmentFilter, 'name' | 'type' | 'image'>
    )>, labels: Array<(
      { __typename?: 'ListLibraryItemLabel' }
      & Pick<ListLibraryItemLabel, 'name' | 'type'>
    )>, types: Array<(
      { __typename?: 'ListLibraryItemType' }
      & Pick<ListLibraryItemType, 'name' | 'type'>
    )>, durations: Array<(
      { __typename?: 'ListLibraryItemDuration' }
      & Pick<ListLibraryItemDuration, 'name' | 'type'>
    )>, statuses: Array<(
      { __typename?: 'ListLibraryItemStatus' }
      & Pick<ListLibraryItemStatus, 'name' | 'type'>
    )> }
  ) }
);

export type ListLibraryItemCategoriesQueryVariables = Exact<{
  input?: Maybe<GetLibraryItemCategoriesTotalInput>;
  totalInput?: Maybe<GetLibraryItemCategoriesTotalInput>;
}>;


export type ListLibraryItemCategoriesQuery = (
  { __typename?: 'Query' }
  & { listLibraryItemCategories: Array<(
    { __typename?: 'ListLibraryItemCategory' }
    & Pick<ListLibraryItemCategory, 'name' | 'type' | 'verticalImage' | 'total'>
  )> }
);

export type CountLibraryItemsQueryVariables = Exact<{
  input?: Maybe<AdminListLibraryItemsInput>;
}>;


export type CountLibraryItemsQuery = (
  { __typename?: 'Query' }
  & { admin_countLibraryItems: (
    { __typename?: 'AdminCountLibraryItems' }
    & Pick<AdminCountLibraryItems, 'total' | 'audio' | 'video' | 'previouslyLive'>
  ) }
);

export type ListClassSessionsQueryVariables = Exact<{
  input: AdminListClassSessionsInput;
}>;


export type ListClassSessionsQuery = (
  { __typename?: 'Query' }
  & { admin_listClassSessions: Array<(
    { __typename?: 'ClassSession' }
    & ClassSessionFragmentFragment
  )> }
);

export type GetClassSessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClassSessionQuery = (
  { __typename?: 'Query' }
  & { getClassSession: (
    { __typename?: 'ClassSession' }
    & Pick<ClassSession, 'id' | 'name' | 'startDateTime' | 'endDateTime'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    ), region: (
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'name'>
    ), instructors?: Maybe<Array<(
      { __typename?: 'Instructor' }
      & Pick<Instructor, 'id' | 'firstName' | 'lastName' | 'fullName'>
    )>> }
  ) }
);

export type ListUsersQueryVariables = Exact<{
  input: AdminListUsersInput;
}>;


export type ListUsersQuery = (
  { __typename?: 'Query' }
  & { admin_listUsers: Array<(
    { __typename?: 'User' }
    & UserFieldsFragmentFragment
  )> }
);

export type ListRegionsQueryVariables = Exact<{
  input?: Maybe<ListRegionsInput>;
}>;


export type ListRegionsQuery = (
  { __typename?: 'Query' }
  & { listRegions: Array<(
    { __typename?: 'ListRegionsResult' }
    & Pick<ListRegionsResult, 'id' | 'name'>
  )> }
);

export type ListStudiosQueryVariables = Exact<{ [key: string]: never; }>;


export type ListStudiosQuery = (
  { __typename?: 'Query' }
  & { listStudios: Array<(
    { __typename?: 'ListStudiosResult' }
    & Pick<ListStudiosResult, 'name'>
    & { regions: Array<(
      { __typename?: 'ListRegionsResult' }
      & Pick<ListRegionsResult, 'id' | 'name'>
      & { locations: Array<(
        { __typename?: 'LocationObject' }
        & Pick<LocationObject, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ListEmployeesQueryVariables = Exact<{
  input: ListEmployeesInput;
}>;


export type ListEmployeesQuery = (
  { __typename?: 'Query' }
  & { admin_listEmployees: Array<(
    { __typename?: 'Employee' }
    & EmployeeFieldsFragmentFragment
  )> }
);

export type GetEmployeeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEmployeeQuery = (
  { __typename?: 'Query' }
  & { admin_getEmployee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'instagramHandle' | 'spotifyLink' | 'phoneNumber' | 'employeeID' | 'username'>
    & EmployeeFieldsFragmentFragment
  ) }
);

export type ListShopifyPicksQueryVariables = Exact<{
  employeeID: Scalars['ID'];
}>;


export type ListShopifyPicksQuery = (
  { __typename?: 'Query' }
  & { admin_listEmployeeShopifyPicks: Array<(
    { __typename?: 'ShopifyPicks' }
    & Pick<ShopifyPicks, 'id' | 'name' | 'url' | 'pictureUrl'>
  )> }
);

export type ListInstructorBannersQueryVariables = Exact<{
  input?: Maybe<ListInstructorBannersInput>;
}>;


export type ListInstructorBannersQuery = (
  { __typename?: 'Query' }
  & { admin_listInstructorBanners: Array<(
    { __typename?: 'InstructorBanner' }
    & InstructorBannerFragmentFragment
  )> }
);

export type GetInstructorBannerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInstructorBannerQuery = (
  { __typename?: 'Query' }
  & { admin_getInstructorBanner: (
    { __typename?: 'InstructorBanner' }
    & InstructorBannerFragmentFragment
  ) }
);

export type AdminListStudiosQueryVariables = Exact<{
  input?: Maybe<AdminListStudiosInput>;
}>;


export type AdminListStudiosQuery = (
  { __typename?: 'Query' }
  & { admin_listStudios: Array<(
    { __typename?: 'AdminStudioResult' }
    & StudioFragmentFragment
  )> }
);

export type AdminGetStudioQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminGetStudioQuery = (
  { __typename?: 'Query' }
  & { admin_getStudio: (
    { __typename?: 'AdminStudioResult' }
    & StudioFragmentFragment
  ) }
);

export const UserFieldsFragmentFragmentDoc = gql`
    fragment UserFieldsFragment on User {
  id
  firstName
  lastName
  username
  pictureUrl
  email
  isBlocked
  hasVODAccess
  hasGrantedVODAccess
  bio
  birthDate
  marianaTekID
  role
  homeLocation {
    name
  }
}
    `;
export const ReportFieldsFragmentFragmentDoc = gql`
    fragment ReportFieldsFragment on Report {
  id
  reporting {
    firstName
    lastName
  }
  reported {
    ...UserFieldsFragment
  }
  createdAt
  reason {
    name
  }
  seen
  resolved
}
    ${UserFieldsFragmentFragmentDoc}`;
export const EmployeeFieldsFragmentFragmentDoc = gql`
    fragment EmployeeFieldsFragment on Employee {
  id
  firstName
  lastName
  title
  pictureUrl
  isPublished
  region {
    id
    name
    country {
      code
    }
  }
  locationID
  location {
    id
    name
  }
  promoCode
  emailAddress
  tiktokUsername
  linkedinUsername
  shopifyPicksCount
  instagramHandle
  regionName
}
    `;
export const InstructorBannerFragmentFragmentDoc = gql`
    fragment InstructorBannerFragment on InstructorBanner {
  id
  createdAt
  name
  description
  CTAText
  imageURL
  link
  location {
    id
    name
  }
  order
  region {
    id
    name
    country {
      code
      name
    }
  }
}
    `;
export const SessionFragmentFragmentDoc = gql`
    fragment SessionFragment on Auth {
  session {
    accessToken
    expiresAt
    refreshToken
  }
}
    `;
export const InstructorCacheFragmentFragmentDoc = gql`
    fragment InstructorCacheFragment on InstructorCache {
  firstName
  lastName
  id
}
    `;
export const BannerFragmentFragmentDoc = gql`
    fragment BannerFragment on Banner {
  id
  createdAt
  name
  category {
    name
    type
  }
  CTAText
  imageURL
  link
  order
}
    `;
export const StudioFragmentFragmentDoc = gql`
    fragment StudioFragment on AdminStudioResult {
  id
  name
  regionName
  email
  phoneNumber
  phoneNumberForTexting
}
    `;
export const ClassSessionFragmentFragmentDoc = gql`
    fragment ClassSessionFragment on ClassSession {
  id
  name
  spotifyPlaylistIDs {
    playlistID
    imageURL
    title
  }
  appleMusicPlaylistIDs
  startDateTime
  endDateTime
  location {
    id
    name
  }
  region {
    id
    name
  }
  instructors {
    id
    firstName
    lastName
    fullName
  }
  equipment {
    name
    type
    image
  }
}
    `;
export const InstructorFragmentFragmentDoc = gql`
    fragment InstructorFragment on Instructor {
  firstName
  lastName
  fullName
  id
}
    `;
export const LibraryFragmentFragmentDoc = gql`
    fragment LibraryFragment on Library {
  id
  title
  description
  createdAt
  spotifyPlaylistIDs {
    playlistID
    imageURL
    title
  }
  appleMusicPlaylistIDs
  instructor {
    ...InstructorFragment
  }
  additionalInstructor {
    ...InstructorFragment
  }
  parentCategory {
    name
    type
  }
  bodyParts {
    name
    type
  }
  equipment: equipment_v2 {
    name
    type
  }
  labels {
    name
    type
  }
  duration {
    name
    type
  }
  imageURL
  classID
  classSessionID
  fileID
  instructorFileID
  audioID
  hasClosedCaptionsInstructorFile
  hasClosedCaptions
  status
  statusEffectiveSince
  statusComment
  type
}
    ${InstructorFragmentFragmentDoc}`;
export const SignInDocument = gql`
    mutation SignIn($username: String!, $password: String!) {
  signIn(input: {username: $username, password: $password}) {
    user {
      firstName
      lastName
      id
      role
      isActiveAdmin
    }
    ...SessionFragment
  }
}
    ${SessionFragmentFragmentDoc}`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    ...SessionFragment
  }
}
    ${SessionFragmentFragmentDoc}`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const UpdateReportDocument = gql`
    mutation UpdateReport($input: UpdateReportInput!) {
  admin_updateReport(input: $input) {
    id
  }
}
    `;
export type UpdateReportMutationFn = Apollo.MutationFunction<UpdateReportMutation, UpdateReportMutationVariables>;

/**
 * __useUpdateReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportMutation, { data, loading, error }] = useUpdateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportMutation, UpdateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportMutation, UpdateReportMutationVariables>(UpdateReportDocument, options);
      }
export type UpdateReportMutationHookResult = ReturnType<typeof useUpdateReportMutation>;
export type UpdateReportMutationResult = Apollo.MutationResult<UpdateReportMutation>;
export type UpdateReportMutationOptions = Apollo.BaseMutationOptions<UpdateReportMutation, UpdateReportMutationVariables>;
export const UpdateReportedUserDocument = gql`
    mutation UpdateReportedUser($input: UpdateReportedUserInput!) {
  admin_updateReportedUser(input: $input) {
    id
  }
}
    `;
export type UpdateReportedUserMutationFn = Apollo.MutationFunction<UpdateReportedUserMutation, UpdateReportedUserMutationVariables>;

/**
 * __useUpdateReportedUserMutation__
 *
 * To run a mutation, you first call `useUpdateReportedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportedUserMutation, { data, loading, error }] = useUpdateReportedUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportedUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportedUserMutation, UpdateReportedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportedUserMutation, UpdateReportedUserMutationVariables>(UpdateReportedUserDocument, options);
      }
export type UpdateReportedUserMutationHookResult = ReturnType<typeof useUpdateReportedUserMutation>;
export type UpdateReportedUserMutationResult = Apollo.MutationResult<UpdateReportedUserMutation>;
export type UpdateReportedUserMutationOptions = Apollo.BaseMutationOptions<UpdateReportedUserMutation, UpdateReportedUserMutationVariables>;
export const BlockUserDocument = gql`
    mutation BlockUser($input: UserIdInput!) {
  admin_blockUser(input: $input) {
    id
  }
}
    `;
export type BlockUserMutationFn = Apollo.MutationFunction<BlockUserMutation, BlockUserMutationVariables>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockUserMutation(baseOptions?: Apollo.MutationHookOptions<BlockUserMutation, BlockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(BlockUserDocument, options);
      }
export type BlockUserMutationHookResult = ReturnType<typeof useBlockUserMutation>;
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<BlockUserMutation, BlockUserMutationVariables>;
export const UnblockUserDocument = gql`
    mutation UnblockUser($input: UserIdInput!) {
  admin_unblockUser(input: $input) {
    id
  }
}
    `;
export type UnblockUserMutationFn = Apollo.MutationFunction<UnblockUserMutation, UnblockUserMutationVariables>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnblockUserMutation(baseOptions?: Apollo.MutationHookOptions<UnblockUserMutation, UnblockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(UnblockUserDocument, options);
      }
export type UnblockUserMutationHookResult = ReturnType<typeof useUnblockUserMutation>;
export type UnblockUserMutationResult = Apollo.MutationResult<UnblockUserMutation>;
export type UnblockUserMutationOptions = Apollo.BaseMutationOptions<UnblockUserMutation, UnblockUserMutationVariables>;
export const DeleteAdminDocument = gql`
    mutation DeleteAdmin($input: UserIdInput!) {
  admin_deleteAdmin(input: $input) {
    id
  }
}
    `;
export type DeleteAdminMutationFn = Apollo.MutationFunction<DeleteAdminMutation, DeleteAdminMutationVariables>;

/**
 * __useDeleteAdminMutation__
 *
 * To run a mutation, you first call `useDeleteAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminMutation, { data, loading, error }] = useDeleteAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminMutation, DeleteAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminMutation, DeleteAdminMutationVariables>(DeleteAdminDocument, options);
      }
export type DeleteAdminMutationHookResult = ReturnType<typeof useDeleteAdminMutation>;
export type DeleteAdminMutationResult = Apollo.MutationResult<DeleteAdminMutation>;
export type DeleteAdminMutationOptions = Apollo.BaseMutationOptions<DeleteAdminMutation, DeleteAdminMutationVariables>;
export const ActivateAdminDocument = gql`
    mutation ActivateAdmin($input: UserIdInput!) {
  admin_activateAdmin(input: $input) {
    id
  }
}
    `;
export type ActivateAdminMutationFn = Apollo.MutationFunction<ActivateAdminMutation, ActivateAdminMutationVariables>;

/**
 * __useActivateAdminMutation__
 *
 * To run a mutation, you first call `useActivateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAdminMutation, { data, loading, error }] = useActivateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateAdminMutation(baseOptions?: Apollo.MutationHookOptions<ActivateAdminMutation, ActivateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateAdminMutation, ActivateAdminMutationVariables>(ActivateAdminDocument, options);
      }
export type ActivateAdminMutationHookResult = ReturnType<typeof useActivateAdminMutation>;
export type ActivateAdminMutationResult = Apollo.MutationResult<ActivateAdminMutation>;
export type ActivateAdminMutationOptions = Apollo.BaseMutationOptions<ActivateAdminMutation, ActivateAdminMutationVariables>;
export const DeactivateAdminDocument = gql`
    mutation DeactivateAdmin($input: UserIdInput!) {
  admin_deactivateAdmin(input: $input) {
    id
  }
}
    `;
export type DeactivateAdminMutationFn = Apollo.MutationFunction<DeactivateAdminMutation, DeactivateAdminMutationVariables>;

/**
 * __useDeactivateAdminMutation__
 *
 * To run a mutation, you first call `useDeactivateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateAdminMutation, { data, loading, error }] = useDeactivateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateAdminMutation, DeactivateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateAdminMutation, DeactivateAdminMutationVariables>(DeactivateAdminDocument, options);
      }
export type DeactivateAdminMutationHookResult = ReturnType<typeof useDeactivateAdminMutation>;
export type DeactivateAdminMutationResult = Apollo.MutationResult<DeactivateAdminMutation>;
export type DeactivateAdminMutationOptions = Apollo.BaseMutationOptions<DeactivateAdminMutation, DeactivateAdminMutationVariables>;
export const CreateAdminDocument = gql`
    mutation CreateAdmin($input: CreateAdminInput!) {
  admin_createAdmin(input: $input) {
    id
  }
}
    `;
export type CreateAdminMutationFn = Apollo.MutationFunction<CreateAdminMutation, CreateAdminMutationVariables>;

/**
 * __useCreateAdminMutation__
 *
 * To run a mutation, you first call `useCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminMutation, { data, loading, error }] = useCreateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminMutation, CreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, options);
      }
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdminMutation>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<CreateAdminMutation, CreateAdminMutationVariables>;
export const UpdateAdminDocument = gql`
    mutation UpdateAdmin($input: UpdateAdminInput!) {
  admin_updateAdmin(input: $input) {
    id
  }
}
    `;
export type UpdateAdminMutationFn = Apollo.MutationFunction<UpdateAdminMutation, UpdateAdminMutationVariables>;

/**
 * __useUpdateAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminMutation, { data, loading, error }] = useUpdateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminMutation, UpdateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, options);
      }
export type UpdateAdminMutationHookResult = ReturnType<typeof useUpdateAdminMutation>;
export type UpdateAdminMutationResult = Apollo.MutationResult<UpdateAdminMutation>;
export type UpdateAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAdminMutation, UpdateAdminMutationVariables>;
export const ChangeBannersOrderDocument = gql`
    mutation ChangeBannersOrder($input: [ChangeBannersOrderInput!]!) {
  admin_changeBannersOrder(input: $input) {
    id
  }
}
    `;
export type ChangeBannersOrderMutationFn = Apollo.MutationFunction<ChangeBannersOrderMutation, ChangeBannersOrderMutationVariables>;

/**
 * __useChangeBannersOrderMutation__
 *
 * To run a mutation, you first call `useChangeBannersOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBannersOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBannersOrderMutation, { data, loading, error }] = useChangeBannersOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeBannersOrderMutation(baseOptions?: Apollo.MutationHookOptions<ChangeBannersOrderMutation, ChangeBannersOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeBannersOrderMutation, ChangeBannersOrderMutationVariables>(ChangeBannersOrderDocument, options);
      }
export type ChangeBannersOrderMutationHookResult = ReturnType<typeof useChangeBannersOrderMutation>;
export type ChangeBannersOrderMutationResult = Apollo.MutationResult<ChangeBannersOrderMutation>;
export type ChangeBannersOrderMutationOptions = Apollo.BaseMutationOptions<ChangeBannersOrderMutation, ChangeBannersOrderMutationVariables>;
export const CreateBannerDocument = gql`
    mutation CreateBanner($input: CreateBannerInput!) {
  admin_createBanner(input: $input) {
    id
  }
}
    `;
export type CreateBannerMutationFn = Apollo.MutationFunction<CreateBannerMutation, CreateBannerMutationVariables>;

/**
 * __useCreateBannerMutation__
 *
 * To run a mutation, you first call `useCreateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBannerMutation, { data, loading, error }] = useCreateBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateBannerMutation, CreateBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBannerMutation, CreateBannerMutationVariables>(CreateBannerDocument, options);
      }
export type CreateBannerMutationHookResult = ReturnType<typeof useCreateBannerMutation>;
export type CreateBannerMutationResult = Apollo.MutationResult<CreateBannerMutation>;
export type CreateBannerMutationOptions = Apollo.BaseMutationOptions<CreateBannerMutation, CreateBannerMutationVariables>;
export const UpdateBannerDocument = gql`
    mutation UpdateBanner($input: UpdateBannerInput!) {
  admin_updateBanner(input: $input) {
    id
  }
}
    `;
export type UpdateBannerMutationFn = Apollo.MutationFunction<UpdateBannerMutation, UpdateBannerMutationVariables>;

/**
 * __useUpdateBannerMutation__
 *
 * To run a mutation, you first call `useUpdateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBannerMutation, { data, loading, error }] = useUpdateBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBannerMutation, UpdateBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBannerMutation, UpdateBannerMutationVariables>(UpdateBannerDocument, options);
      }
export type UpdateBannerMutationHookResult = ReturnType<typeof useUpdateBannerMutation>;
export type UpdateBannerMutationResult = Apollo.MutationResult<UpdateBannerMutation>;
export type UpdateBannerMutationOptions = Apollo.BaseMutationOptions<UpdateBannerMutation, UpdateBannerMutationVariables>;
export const DeleteBannerDocument = gql`
    mutation DeleteBanner($input: DeleteBannerInput!) {
  admin_deleteBanner(input: $input) {
    success
  }
}
    `;
export type DeleteBannerMutationFn = Apollo.MutationFunction<DeleteBannerMutation, DeleteBannerMutationVariables>;

/**
 * __useDeleteBannerMutation__
 *
 * To run a mutation, you first call `useDeleteBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBannerMutation, { data, loading, error }] = useDeleteBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBannerMutation, DeleteBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBannerMutation, DeleteBannerMutationVariables>(DeleteBannerDocument, options);
      }
export type DeleteBannerMutationHookResult = ReturnType<typeof useDeleteBannerMutation>;
export type DeleteBannerMutationResult = Apollo.MutationResult<DeleteBannerMutation>;
export type DeleteBannerMutationOptions = Apollo.BaseMutationOptions<DeleteBannerMutation, DeleteBannerMutationVariables>;
export const CreateOnDemandSectionDocument = gql`
    mutation CreateOnDemandSection($input: CreateOnDemandSectionInput!) {
  admin_createOnDemandSection(input: $input) {
    id
  }
}
    `;
export type CreateOnDemandSectionMutationFn = Apollo.MutationFunction<CreateOnDemandSectionMutation, CreateOnDemandSectionMutationVariables>;

/**
 * __useCreateOnDemandSectionMutation__
 *
 * To run a mutation, you first call `useCreateOnDemandSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnDemandSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnDemandSectionMutation, { data, loading, error }] = useCreateOnDemandSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnDemandSectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnDemandSectionMutation, CreateOnDemandSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnDemandSectionMutation, CreateOnDemandSectionMutationVariables>(CreateOnDemandSectionDocument, options);
      }
export type CreateOnDemandSectionMutationHookResult = ReturnType<typeof useCreateOnDemandSectionMutation>;
export type CreateOnDemandSectionMutationResult = Apollo.MutationResult<CreateOnDemandSectionMutation>;
export type CreateOnDemandSectionMutationOptions = Apollo.BaseMutationOptions<CreateOnDemandSectionMutation, CreateOnDemandSectionMutationVariables>;
export const UpdateOnDemandSectionDocument = gql`
    mutation UpdateOnDemandSection($input: UpdateOnDemandSectionInput!) {
  admin_updateOnDemandSection(input: $input) {
    id
  }
}
    `;
export type UpdateOnDemandSectionMutationFn = Apollo.MutationFunction<UpdateOnDemandSectionMutation, UpdateOnDemandSectionMutationVariables>;

/**
 * __useUpdateOnDemandSectionMutation__
 *
 * To run a mutation, you first call `useUpdateOnDemandSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnDemandSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnDemandSectionMutation, { data, loading, error }] = useUpdateOnDemandSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnDemandSectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnDemandSectionMutation, UpdateOnDemandSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnDemandSectionMutation, UpdateOnDemandSectionMutationVariables>(UpdateOnDemandSectionDocument, options);
      }
export type UpdateOnDemandSectionMutationHookResult = ReturnType<typeof useUpdateOnDemandSectionMutation>;
export type UpdateOnDemandSectionMutationResult = Apollo.MutationResult<UpdateOnDemandSectionMutation>;
export type UpdateOnDemandSectionMutationOptions = Apollo.BaseMutationOptions<UpdateOnDemandSectionMutation, UpdateOnDemandSectionMutationVariables>;
export const DeleteOnDemandSectionDocument = gql`
    mutation DeleteOnDemandSection($input: DeleteOnDemandSectionInput!) {
  admin_deleteOnDemandSection(input: $input) {
    success
  }
}
    `;
export type DeleteOnDemandSectionMutationFn = Apollo.MutationFunction<DeleteOnDemandSectionMutation, DeleteOnDemandSectionMutationVariables>;

/**
 * __useDeleteOnDemandSectionMutation__
 *
 * To run a mutation, you first call `useDeleteOnDemandSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnDemandSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnDemandSectionMutation, { data, loading, error }] = useDeleteOnDemandSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOnDemandSectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOnDemandSectionMutation, DeleteOnDemandSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOnDemandSectionMutation, DeleteOnDemandSectionMutationVariables>(DeleteOnDemandSectionDocument, options);
      }
export type DeleteOnDemandSectionMutationHookResult = ReturnType<typeof useDeleteOnDemandSectionMutation>;
export type DeleteOnDemandSectionMutationResult = Apollo.MutationResult<DeleteOnDemandSectionMutation>;
export type DeleteOnDemandSectionMutationOptions = Apollo.BaseMutationOptions<DeleteOnDemandSectionMutation, DeleteOnDemandSectionMutationVariables>;
export const ChangeOnDemandSectionsOrderDocument = gql`
    mutation ChangeOnDemandSectionsOrder($input: [ChangeOnDemandSectionsOrderInput!]!) {
  admin_changeOnDemandSectionsOrder(input: $input) {
    id
  }
}
    `;
export type ChangeOnDemandSectionsOrderMutationFn = Apollo.MutationFunction<ChangeOnDemandSectionsOrderMutation, ChangeOnDemandSectionsOrderMutationVariables>;

/**
 * __useChangeOnDemandSectionsOrderMutation__
 *
 * To run a mutation, you first call `useChangeOnDemandSectionsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOnDemandSectionsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOnDemandSectionsOrderMutation, { data, loading, error }] = useChangeOnDemandSectionsOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeOnDemandSectionsOrderMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOnDemandSectionsOrderMutation, ChangeOnDemandSectionsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOnDemandSectionsOrderMutation, ChangeOnDemandSectionsOrderMutationVariables>(ChangeOnDemandSectionsOrderDocument, options);
      }
export type ChangeOnDemandSectionsOrderMutationHookResult = ReturnType<typeof useChangeOnDemandSectionsOrderMutation>;
export type ChangeOnDemandSectionsOrderMutationResult = Apollo.MutationResult<ChangeOnDemandSectionsOrderMutation>;
export type ChangeOnDemandSectionsOrderMutationOptions = Apollo.BaseMutationOptions<ChangeOnDemandSectionsOrderMutation, ChangeOnDemandSectionsOrderMutationVariables>;
export const CreateOnDemandCollectionSectionDocument = gql`
    mutation CreateOnDemandCollectionSection($input: CreateOnDemandCollectionSectionInput!) {
  admin_createOnDemandCollectionSection(input: $input) {
    id
  }
}
    `;
export type CreateOnDemandCollectionSectionMutationFn = Apollo.MutationFunction<CreateOnDemandCollectionSectionMutation, CreateOnDemandCollectionSectionMutationVariables>;

/**
 * __useCreateOnDemandCollectionSectionMutation__
 *
 * To run a mutation, you first call `useCreateOnDemandCollectionSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnDemandCollectionSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnDemandCollectionSectionMutation, { data, loading, error }] = useCreateOnDemandCollectionSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnDemandCollectionSectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnDemandCollectionSectionMutation, CreateOnDemandCollectionSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnDemandCollectionSectionMutation, CreateOnDemandCollectionSectionMutationVariables>(CreateOnDemandCollectionSectionDocument, options);
      }
export type CreateOnDemandCollectionSectionMutationHookResult = ReturnType<typeof useCreateOnDemandCollectionSectionMutation>;
export type CreateOnDemandCollectionSectionMutationResult = Apollo.MutationResult<CreateOnDemandCollectionSectionMutation>;
export type CreateOnDemandCollectionSectionMutationOptions = Apollo.BaseMutationOptions<CreateOnDemandCollectionSectionMutation, CreateOnDemandCollectionSectionMutationVariables>;
export const UpdateOnDemandCollectionSectionDocument = gql`
    mutation UpdateOnDemandCollectionSection($input: UpdateOnDemandCollectionSectionInput!) {
  admin_updateOnDemandCollectionSection(input: $input) {
    id
  }
}
    `;
export type UpdateOnDemandCollectionSectionMutationFn = Apollo.MutationFunction<UpdateOnDemandCollectionSectionMutation, UpdateOnDemandCollectionSectionMutationVariables>;

/**
 * __useUpdateOnDemandCollectionSectionMutation__
 *
 * To run a mutation, you first call `useUpdateOnDemandCollectionSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnDemandCollectionSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnDemandCollectionSectionMutation, { data, loading, error }] = useUpdateOnDemandCollectionSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnDemandCollectionSectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnDemandCollectionSectionMutation, UpdateOnDemandCollectionSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnDemandCollectionSectionMutation, UpdateOnDemandCollectionSectionMutationVariables>(UpdateOnDemandCollectionSectionDocument, options);
      }
export type UpdateOnDemandCollectionSectionMutationHookResult = ReturnType<typeof useUpdateOnDemandCollectionSectionMutation>;
export type UpdateOnDemandCollectionSectionMutationResult = Apollo.MutationResult<UpdateOnDemandCollectionSectionMutation>;
export type UpdateOnDemandCollectionSectionMutationOptions = Apollo.BaseMutationOptions<UpdateOnDemandCollectionSectionMutation, UpdateOnDemandCollectionSectionMutationVariables>;
export const DeleteOnDemandCollectionSectionDocument = gql`
    mutation DeleteOnDemandCollectionSection($input: DeleteOnDemandCollectionSectionInput!) {
  admin_deleteOnDemandCollectionSection(input: $input) {
    success
  }
}
    `;
export type DeleteOnDemandCollectionSectionMutationFn = Apollo.MutationFunction<DeleteOnDemandCollectionSectionMutation, DeleteOnDemandCollectionSectionMutationVariables>;

/**
 * __useDeleteOnDemandCollectionSectionMutation__
 *
 * To run a mutation, you first call `useDeleteOnDemandCollectionSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnDemandCollectionSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnDemandCollectionSectionMutation, { data, loading, error }] = useDeleteOnDemandCollectionSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOnDemandCollectionSectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOnDemandCollectionSectionMutation, DeleteOnDemandCollectionSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOnDemandCollectionSectionMutation, DeleteOnDemandCollectionSectionMutationVariables>(DeleteOnDemandCollectionSectionDocument, options);
      }
export type DeleteOnDemandCollectionSectionMutationHookResult = ReturnType<typeof useDeleteOnDemandCollectionSectionMutation>;
export type DeleteOnDemandCollectionSectionMutationResult = Apollo.MutationResult<DeleteOnDemandCollectionSectionMutation>;
export type DeleteOnDemandCollectionSectionMutationOptions = Apollo.BaseMutationOptions<DeleteOnDemandCollectionSectionMutation, DeleteOnDemandCollectionSectionMutationVariables>;
export const ChangeOnDemandCollectionSectionsOrderDocument = gql`
    mutation ChangeOnDemandCollectionSectionsOrder($input: [ChangeOnDemandCollectionSectionsOrderInput!]!) {
  admin_changeOnDemandCollectionSectionsOrder(input: $input) {
    id
  }
}
    `;
export type ChangeOnDemandCollectionSectionsOrderMutationFn = Apollo.MutationFunction<ChangeOnDemandCollectionSectionsOrderMutation, ChangeOnDemandCollectionSectionsOrderMutationVariables>;

/**
 * __useChangeOnDemandCollectionSectionsOrderMutation__
 *
 * To run a mutation, you first call `useChangeOnDemandCollectionSectionsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOnDemandCollectionSectionsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOnDemandCollectionSectionsOrderMutation, { data, loading, error }] = useChangeOnDemandCollectionSectionsOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeOnDemandCollectionSectionsOrderMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOnDemandCollectionSectionsOrderMutation, ChangeOnDemandCollectionSectionsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOnDemandCollectionSectionsOrderMutation, ChangeOnDemandCollectionSectionsOrderMutationVariables>(ChangeOnDemandCollectionSectionsOrderDocument, options);
      }
export type ChangeOnDemandCollectionSectionsOrderMutationHookResult = ReturnType<typeof useChangeOnDemandCollectionSectionsOrderMutation>;
export type ChangeOnDemandCollectionSectionsOrderMutationResult = Apollo.MutationResult<ChangeOnDemandCollectionSectionsOrderMutation>;
export type ChangeOnDemandCollectionSectionsOrderMutationOptions = Apollo.BaseMutationOptions<ChangeOnDemandCollectionSectionsOrderMutation, ChangeOnDemandCollectionSectionsOrderMutationVariables>;
export const CreateOnDemandCollectionDocument = gql`
    mutation CreateOnDemandCollection($input: CreateOnDemandCollectionInput!) {
  admin_createOnDemandCollection(input: $input) {
    id
  }
}
    `;
export type CreateOnDemandCollectionMutationFn = Apollo.MutationFunction<CreateOnDemandCollectionMutation, CreateOnDemandCollectionMutationVariables>;

/**
 * __useCreateOnDemandCollectionMutation__
 *
 * To run a mutation, you first call `useCreateOnDemandCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnDemandCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnDemandCollectionMutation, { data, loading, error }] = useCreateOnDemandCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnDemandCollectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnDemandCollectionMutation, CreateOnDemandCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnDemandCollectionMutation, CreateOnDemandCollectionMutationVariables>(CreateOnDemandCollectionDocument, options);
      }
export type CreateOnDemandCollectionMutationHookResult = ReturnType<typeof useCreateOnDemandCollectionMutation>;
export type CreateOnDemandCollectionMutationResult = Apollo.MutationResult<CreateOnDemandCollectionMutation>;
export type CreateOnDemandCollectionMutationOptions = Apollo.BaseMutationOptions<CreateOnDemandCollectionMutation, CreateOnDemandCollectionMutationVariables>;
export const UpdateOnDemandCollectionDocument = gql`
    mutation UpdateOnDemandCollection($input: UpdateOnDemandCollectionInput!) {
  admin_updateOnDemandCollection(input: $input) {
    id
  }
}
    `;
export type UpdateOnDemandCollectionMutationFn = Apollo.MutationFunction<UpdateOnDemandCollectionMutation, UpdateOnDemandCollectionMutationVariables>;

/**
 * __useUpdateOnDemandCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateOnDemandCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnDemandCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnDemandCollectionMutation, { data, loading, error }] = useUpdateOnDemandCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnDemandCollectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnDemandCollectionMutation, UpdateOnDemandCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnDemandCollectionMutation, UpdateOnDemandCollectionMutationVariables>(UpdateOnDemandCollectionDocument, options);
      }
export type UpdateOnDemandCollectionMutationHookResult = ReturnType<typeof useUpdateOnDemandCollectionMutation>;
export type UpdateOnDemandCollectionMutationResult = Apollo.MutationResult<UpdateOnDemandCollectionMutation>;
export type UpdateOnDemandCollectionMutationOptions = Apollo.BaseMutationOptions<UpdateOnDemandCollectionMutation, UpdateOnDemandCollectionMutationVariables>;
export const DeleteOnDemandCollectionDocument = gql`
    mutation DeleteOnDemandCollection($input: DeleteOnDemandCollectionInput!) {
  admin_deleteOnDemandCollection(input: $input) {
    success
  }
}
    `;
export type DeleteOnDemandCollectionMutationFn = Apollo.MutationFunction<DeleteOnDemandCollectionMutation, DeleteOnDemandCollectionMutationVariables>;

/**
 * __useDeleteOnDemandCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteOnDemandCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnDemandCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnDemandCollectionMutation, { data, loading, error }] = useDeleteOnDemandCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOnDemandCollectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOnDemandCollectionMutation, DeleteOnDemandCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOnDemandCollectionMutation, DeleteOnDemandCollectionMutationVariables>(DeleteOnDemandCollectionDocument, options);
      }
export type DeleteOnDemandCollectionMutationHookResult = ReturnType<typeof useDeleteOnDemandCollectionMutation>;
export type DeleteOnDemandCollectionMutationResult = Apollo.MutationResult<DeleteOnDemandCollectionMutation>;
export type DeleteOnDemandCollectionMutationOptions = Apollo.BaseMutationOptions<DeleteOnDemandCollectionMutation, DeleteOnDemandCollectionMutationVariables>;
export const ChangeOnDemandCollectionOrderDocument = gql`
    mutation ChangeOnDemandCollectionOrder($input: [ChangeOnDemandCollectionsOrderInput!]!) {
  admin_changeOnDemandCollectionOrder(input: $input) {
    id
  }
}
    `;
export type ChangeOnDemandCollectionOrderMutationFn = Apollo.MutationFunction<ChangeOnDemandCollectionOrderMutation, ChangeOnDemandCollectionOrderMutationVariables>;

/**
 * __useChangeOnDemandCollectionOrderMutation__
 *
 * To run a mutation, you first call `useChangeOnDemandCollectionOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOnDemandCollectionOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOnDemandCollectionOrderMutation, { data, loading, error }] = useChangeOnDemandCollectionOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeOnDemandCollectionOrderMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOnDemandCollectionOrderMutation, ChangeOnDemandCollectionOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOnDemandCollectionOrderMutation, ChangeOnDemandCollectionOrderMutationVariables>(ChangeOnDemandCollectionOrderDocument, options);
      }
export type ChangeOnDemandCollectionOrderMutationHookResult = ReturnType<typeof useChangeOnDemandCollectionOrderMutation>;
export type ChangeOnDemandCollectionOrderMutationResult = Apollo.MutationResult<ChangeOnDemandCollectionOrderMutation>;
export type ChangeOnDemandCollectionOrderMutationOptions = Apollo.BaseMutationOptions<ChangeOnDemandCollectionOrderMutation, ChangeOnDemandCollectionOrderMutationVariables>;
export const AdminCreateCampaignDocument = gql`
    mutation AdminCreateCampaign($input: CreateCampaignInput!) {
  admin_createCampaign(input: $input) {
    id
  }
}
    `;
export type AdminCreateCampaignMutationFn = Apollo.MutationFunction<AdminCreateCampaignMutation, AdminCreateCampaignMutationVariables>;

/**
 * __useAdminCreateCampaignMutation__
 *
 * To run a mutation, you first call `useAdminCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateCampaignMutation, { data, loading, error }] = useAdminCreateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateCampaignMutation, AdminCreateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateCampaignMutation, AdminCreateCampaignMutationVariables>(AdminCreateCampaignDocument, options);
      }
export type AdminCreateCampaignMutationHookResult = ReturnType<typeof useAdminCreateCampaignMutation>;
export type AdminCreateCampaignMutationResult = Apollo.MutationResult<AdminCreateCampaignMutation>;
export type AdminCreateCampaignMutationOptions = Apollo.BaseMutationOptions<AdminCreateCampaignMutation, AdminCreateCampaignMutationVariables>;
export const AdminUpdateCampaignDocument = gql`
    mutation AdminUpdateCampaign($input: UpdateCampaignInput!) {
  admin_updateCampaign(input: $input) {
    id
  }
}
    `;
export type AdminUpdateCampaignMutationFn = Apollo.MutationFunction<AdminUpdateCampaignMutation, AdminUpdateCampaignMutationVariables>;

/**
 * __useAdminUpdateCampaignMutation__
 *
 * To run a mutation, you first call `useAdminUpdateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateCampaignMutation, { data, loading, error }] = useAdminUpdateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateCampaignMutation, AdminUpdateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateCampaignMutation, AdminUpdateCampaignMutationVariables>(AdminUpdateCampaignDocument, options);
      }
export type AdminUpdateCampaignMutationHookResult = ReturnType<typeof useAdminUpdateCampaignMutation>;
export type AdminUpdateCampaignMutationResult = Apollo.MutationResult<AdminUpdateCampaignMutation>;
export type AdminUpdateCampaignMutationOptions = Apollo.BaseMutationOptions<AdminUpdateCampaignMutation, AdminUpdateCampaignMutationVariables>;
export const AdminDeleteCampaignDocument = gql`
    mutation AdminDeleteCampaign($input: DeleteCampaignInput!) {
  admin_deleteCampaign(input: $input) {
    success
  }
}
    `;
export type AdminDeleteCampaignMutationFn = Apollo.MutationFunction<AdminDeleteCampaignMutation, AdminDeleteCampaignMutationVariables>;

/**
 * __useAdminDeleteCampaignMutation__
 *
 * To run a mutation, you first call `useAdminDeleteCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteCampaignMutation, { data, loading, error }] = useAdminDeleteCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDeleteCampaignMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteCampaignMutation, AdminDeleteCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteCampaignMutation, AdminDeleteCampaignMutationVariables>(AdminDeleteCampaignDocument, options);
      }
export type AdminDeleteCampaignMutationHookResult = ReturnType<typeof useAdminDeleteCampaignMutation>;
export type AdminDeleteCampaignMutationResult = Apollo.MutationResult<AdminDeleteCampaignMutation>;
export type AdminDeleteCampaignMutationOptions = Apollo.BaseMutationOptions<AdminDeleteCampaignMutation, AdminDeleteCampaignMutationVariables>;
export const AdminGrantVodAccessToUserDocument = gql`
    mutation AdminGrantVODAccessToUser($input: GrantVODAccessToUserInput!) {
  admin_grantVODAccessToUser(input: $input) {
    success
  }
}
    `;
export type AdminGrantVodAccessToUserMutationFn = Apollo.MutationFunction<AdminGrantVodAccessToUserMutation, AdminGrantVodAccessToUserMutationVariables>;

/**
 * __useAdminGrantVodAccessToUserMutation__
 *
 * To run a mutation, you first call `useAdminGrantVodAccessToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminGrantVodAccessToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminGrantVodAccessToUserMutation, { data, loading, error }] = useAdminGrantVodAccessToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminGrantVodAccessToUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminGrantVodAccessToUserMutation, AdminGrantVodAccessToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminGrantVodAccessToUserMutation, AdminGrantVodAccessToUserMutationVariables>(AdminGrantVodAccessToUserDocument, options);
      }
export type AdminGrantVodAccessToUserMutationHookResult = ReturnType<typeof useAdminGrantVodAccessToUserMutation>;
export type AdminGrantVodAccessToUserMutationResult = Apollo.MutationResult<AdminGrantVodAccessToUserMutation>;
export type AdminGrantVodAccessToUserMutationOptions = Apollo.BaseMutationOptions<AdminGrantVodAccessToUserMutation, AdminGrantVodAccessToUserMutationVariables>;
export const AdminRevokeVodAccessFromUserDocument = gql`
    mutation AdminRevokeVODAccessFromUser($input: RevokeVODAccessToUserInput!) {
  admin_revokeVODAccessFromUser(input: $input) {
    success
  }
}
    `;
export type AdminRevokeVodAccessFromUserMutationFn = Apollo.MutationFunction<AdminRevokeVodAccessFromUserMutation, AdminRevokeVodAccessFromUserMutationVariables>;

/**
 * __useAdminRevokeVodAccessFromUserMutation__
 *
 * To run a mutation, you first call `useAdminRevokeVodAccessFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRevokeVodAccessFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRevokeVodAccessFromUserMutation, { data, loading, error }] = useAdminRevokeVodAccessFromUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminRevokeVodAccessFromUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminRevokeVodAccessFromUserMutation, AdminRevokeVodAccessFromUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminRevokeVodAccessFromUserMutation, AdminRevokeVodAccessFromUserMutationVariables>(AdminRevokeVodAccessFromUserDocument, options);
      }
export type AdminRevokeVodAccessFromUserMutationHookResult = ReturnType<typeof useAdminRevokeVodAccessFromUserMutation>;
export type AdminRevokeVodAccessFromUserMutationResult = Apollo.MutationResult<AdminRevokeVodAccessFromUserMutation>;
export type AdminRevokeVodAccessFromUserMutationOptions = Apollo.BaseMutationOptions<AdminRevokeVodAccessFromUserMutation, AdminRevokeVodAccessFromUserMutationVariables>;
export const AdminSendEmailToReportedUserDocument = gql`
    mutation AdminSendEmailToReportedUser($input: SendEmailToReportedUserInput!) {
  admin_sendEmailToReportedUser(input: $input) {
    success
  }
}
    `;
export type AdminSendEmailToReportedUserMutationFn = Apollo.MutationFunction<AdminSendEmailToReportedUserMutation, AdminSendEmailToReportedUserMutationVariables>;

/**
 * __useAdminSendEmailToReportedUserMutation__
 *
 * To run a mutation, you first call `useAdminSendEmailToReportedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSendEmailToReportedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSendEmailToReportedUserMutation, { data, loading, error }] = useAdminSendEmailToReportedUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminSendEmailToReportedUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminSendEmailToReportedUserMutation, AdminSendEmailToReportedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminSendEmailToReportedUserMutation, AdminSendEmailToReportedUserMutationVariables>(AdminSendEmailToReportedUserDocument, options);
      }
export type AdminSendEmailToReportedUserMutationHookResult = ReturnType<typeof useAdminSendEmailToReportedUserMutation>;
export type AdminSendEmailToReportedUserMutationResult = Apollo.MutationResult<AdminSendEmailToReportedUserMutation>;
export type AdminSendEmailToReportedUserMutationOptions = Apollo.BaseMutationOptions<AdminSendEmailToReportedUserMutation, AdminSendEmailToReportedUserMutationVariables>;
export const AdminCreateLibraryItemDocument = gql`
    mutation AdminCreateLibraryItem($input: CreateLibraryItemInput!) {
  admin_createLibraryItem(input: $input) {
    ...LibraryFragment
  }
}
    ${LibraryFragmentFragmentDoc}`;
export type AdminCreateLibraryItemMutationFn = Apollo.MutationFunction<AdminCreateLibraryItemMutation, AdminCreateLibraryItemMutationVariables>;

/**
 * __useAdminCreateLibraryItemMutation__
 *
 * To run a mutation, you first call `useAdminCreateLibraryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateLibraryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateLibraryItemMutation, { data, loading, error }] = useAdminCreateLibraryItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateLibraryItemMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateLibraryItemMutation, AdminCreateLibraryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateLibraryItemMutation, AdminCreateLibraryItemMutationVariables>(AdminCreateLibraryItemDocument, options);
      }
export type AdminCreateLibraryItemMutationHookResult = ReturnType<typeof useAdminCreateLibraryItemMutation>;
export type AdminCreateLibraryItemMutationResult = Apollo.MutationResult<AdminCreateLibraryItemMutation>;
export type AdminCreateLibraryItemMutationOptions = Apollo.BaseMutationOptions<AdminCreateLibraryItemMutation, AdminCreateLibraryItemMutationVariables>;
export const AdminUpdateLibraryItemDocument = gql`
    mutation AdminUpdateLibraryItem($input: UpdateLibraryItemInput!) {
  admin_updateLibraryItem(input: $input) {
    ...LibraryFragment
  }
}
    ${LibraryFragmentFragmentDoc}`;
export type AdminUpdateLibraryItemMutationFn = Apollo.MutationFunction<AdminUpdateLibraryItemMutation, AdminUpdateLibraryItemMutationVariables>;

/**
 * __useAdminUpdateLibraryItemMutation__
 *
 * To run a mutation, you first call `useAdminUpdateLibraryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateLibraryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateLibraryItemMutation, { data, loading, error }] = useAdminUpdateLibraryItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateLibraryItemMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateLibraryItemMutation, AdminUpdateLibraryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateLibraryItemMutation, AdminUpdateLibraryItemMutationVariables>(AdminUpdateLibraryItemDocument, options);
      }
export type AdminUpdateLibraryItemMutationHookResult = ReturnType<typeof useAdminUpdateLibraryItemMutation>;
export type AdminUpdateLibraryItemMutationResult = Apollo.MutationResult<AdminUpdateLibraryItemMutation>;
export type AdminUpdateLibraryItemMutationOptions = Apollo.BaseMutationOptions<AdminUpdateLibraryItemMutation, AdminUpdateLibraryItemMutationVariables>;
export const AdminDeleteLibraryItemDocument = gql`
    mutation AdminDeleteLibraryItem($input: DeleteLibraryItemInput!) {
  admin_deleteLibraryItem(input: $input) {
    success
  }
}
    `;
export type AdminDeleteLibraryItemMutationFn = Apollo.MutationFunction<AdminDeleteLibraryItemMutation, AdminDeleteLibraryItemMutationVariables>;

/**
 * __useAdminDeleteLibraryItemMutation__
 *
 * To run a mutation, you first call `useAdminDeleteLibraryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteLibraryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteLibraryItemMutation, { data, loading, error }] = useAdminDeleteLibraryItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDeleteLibraryItemMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteLibraryItemMutation, AdminDeleteLibraryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteLibraryItemMutation, AdminDeleteLibraryItemMutationVariables>(AdminDeleteLibraryItemDocument, options);
      }
export type AdminDeleteLibraryItemMutationHookResult = ReturnType<typeof useAdminDeleteLibraryItemMutation>;
export type AdminDeleteLibraryItemMutationResult = Apollo.MutationResult<AdminDeleteLibraryItemMutation>;
export type AdminDeleteLibraryItemMutationOptions = Apollo.BaseMutationOptions<AdminDeleteLibraryItemMutation, AdminDeleteLibraryItemMutationVariables>;
export const AdminDeletePictureForReportedUserDocument = gql`
    mutation AdminDeletePictureForReportedUser($input: DeletePictureForReportedUser!) {
  admin_deletePictureForReportedUser(input: $input) {
    success
  }
}
    `;
export type AdminDeletePictureForReportedUserMutationFn = Apollo.MutationFunction<AdminDeletePictureForReportedUserMutation, AdminDeletePictureForReportedUserMutationVariables>;

/**
 * __useAdminDeletePictureForReportedUserMutation__
 *
 * To run a mutation, you first call `useAdminDeletePictureForReportedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeletePictureForReportedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeletePictureForReportedUserMutation, { data, loading, error }] = useAdminDeletePictureForReportedUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDeletePictureForReportedUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeletePictureForReportedUserMutation, AdminDeletePictureForReportedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeletePictureForReportedUserMutation, AdminDeletePictureForReportedUserMutationVariables>(AdminDeletePictureForReportedUserDocument, options);
      }
export type AdminDeletePictureForReportedUserMutationHookResult = ReturnType<typeof useAdminDeletePictureForReportedUserMutation>;
export type AdminDeletePictureForReportedUserMutationResult = Apollo.MutationResult<AdminDeletePictureForReportedUserMutation>;
export type AdminDeletePictureForReportedUserMutationOptions = Apollo.BaseMutationOptions<AdminDeletePictureForReportedUserMutation, AdminDeletePictureForReportedUserMutationVariables>;
export const AdminUpdateClassSessionDocument = gql`
    mutation AdminUpdateClassSession($input: AdminUpdateClassSessionInput!) {
  admin_updateClassSession(input: $input) {
    ...ClassSessionFragment
  }
}
    ${ClassSessionFragmentFragmentDoc}`;
export type AdminUpdateClassSessionMutationFn = Apollo.MutationFunction<AdminUpdateClassSessionMutation, AdminUpdateClassSessionMutationVariables>;

/**
 * __useAdminUpdateClassSessionMutation__
 *
 * To run a mutation, you first call `useAdminUpdateClassSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateClassSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateClassSessionMutation, { data, loading, error }] = useAdminUpdateClassSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateClassSessionMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateClassSessionMutation, AdminUpdateClassSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateClassSessionMutation, AdminUpdateClassSessionMutationVariables>(AdminUpdateClassSessionDocument, options);
      }
export type AdminUpdateClassSessionMutationHookResult = ReturnType<typeof useAdminUpdateClassSessionMutation>;
export type AdminUpdateClassSessionMutationResult = Apollo.MutationResult<AdminUpdateClassSessionMutation>;
export type AdminUpdateClassSessionMutationOptions = Apollo.BaseMutationOptions<AdminUpdateClassSessionMutation, AdminUpdateClassSessionMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($input: UpdateEmployeeInput!) {
  admin_updateEmployee(input: $input) {
    ...EmployeeFieldsFragment
  }
}
    ${EmployeeFieldsFragmentFragmentDoc}`;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const UpdateShopifyPicksDocument = gql`
    mutation UpdateShopifyPicks($input: UpdateEmployeeShopifyPicksInput!) {
  admin_updateEmployeeShopifyPicks(input: $input) {
    id
    name
    url
    pictureUrl
  }
}
    `;
export type UpdateShopifyPicksMutationFn = Apollo.MutationFunction<UpdateShopifyPicksMutation, UpdateShopifyPicksMutationVariables>;

/**
 * __useUpdateShopifyPicksMutation__
 *
 * To run a mutation, you first call `useUpdateShopifyPicksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopifyPicksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopifyPicksMutation, { data, loading, error }] = useUpdateShopifyPicksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopifyPicksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopifyPicksMutation, UpdateShopifyPicksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShopifyPicksMutation, UpdateShopifyPicksMutationVariables>(UpdateShopifyPicksDocument, options);
      }
export type UpdateShopifyPicksMutationHookResult = ReturnType<typeof useUpdateShopifyPicksMutation>;
export type UpdateShopifyPicksMutationResult = Apollo.MutationResult<UpdateShopifyPicksMutation>;
export type UpdateShopifyPicksMutationOptions = Apollo.BaseMutationOptions<UpdateShopifyPicksMutation, UpdateShopifyPicksMutationVariables>;
export const CreateInstructorBannerDocument = gql`
    mutation CreateInstructorBanner($input: CreateInstructorBannerInput!) {
  admin_createInstructorBanner(input: $input) {
    ...InstructorBannerFragment
  }
}
    ${InstructorBannerFragmentFragmentDoc}`;
export type CreateInstructorBannerMutationFn = Apollo.MutationFunction<CreateInstructorBannerMutation, CreateInstructorBannerMutationVariables>;

/**
 * __useCreateInstructorBannerMutation__
 *
 * To run a mutation, you first call `useCreateInstructorBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstructorBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstructorBannerMutation, { data, loading, error }] = useCreateInstructorBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInstructorBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstructorBannerMutation, CreateInstructorBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstructorBannerMutation, CreateInstructorBannerMutationVariables>(CreateInstructorBannerDocument, options);
      }
export type CreateInstructorBannerMutationHookResult = ReturnType<typeof useCreateInstructorBannerMutation>;
export type CreateInstructorBannerMutationResult = Apollo.MutationResult<CreateInstructorBannerMutation>;
export type CreateInstructorBannerMutationOptions = Apollo.BaseMutationOptions<CreateInstructorBannerMutation, CreateInstructorBannerMutationVariables>;
export const UpdateInstructorBannerDocument = gql`
    mutation UpdateInstructorBanner($input: UpdateInstructorBannerInput!) {
  admin_updateInstructorBanner(input: $input) {
    ...InstructorBannerFragment
  }
}
    ${InstructorBannerFragmentFragmentDoc}`;
export type UpdateInstructorBannerMutationFn = Apollo.MutationFunction<UpdateInstructorBannerMutation, UpdateInstructorBannerMutationVariables>;

/**
 * __useUpdateInstructorBannerMutation__
 *
 * To run a mutation, you first call `useUpdateInstructorBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstructorBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstructorBannerMutation, { data, loading, error }] = useUpdateInstructorBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInstructorBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstructorBannerMutation, UpdateInstructorBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstructorBannerMutation, UpdateInstructorBannerMutationVariables>(UpdateInstructorBannerDocument, options);
      }
export type UpdateInstructorBannerMutationHookResult = ReturnType<typeof useUpdateInstructorBannerMutation>;
export type UpdateInstructorBannerMutationResult = Apollo.MutationResult<UpdateInstructorBannerMutation>;
export type UpdateInstructorBannerMutationOptions = Apollo.BaseMutationOptions<UpdateInstructorBannerMutation, UpdateInstructorBannerMutationVariables>;
export const DeleteInstructorBannerDocument = gql`
    mutation DeleteInstructorBanner($input: DeleteInstructorBannerInput!) {
  admin_deleteInstructorBanner(input: $input) {
    success
  }
}
    `;
export type DeleteInstructorBannerMutationFn = Apollo.MutationFunction<DeleteInstructorBannerMutation, DeleteInstructorBannerMutationVariables>;

/**
 * __useDeleteInstructorBannerMutation__
 *
 * To run a mutation, you first call `useDeleteInstructorBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstructorBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstructorBannerMutation, { data, loading, error }] = useDeleteInstructorBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInstructorBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstructorBannerMutation, DeleteInstructorBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstructorBannerMutation, DeleteInstructorBannerMutationVariables>(DeleteInstructorBannerDocument, options);
      }
export type DeleteInstructorBannerMutationHookResult = ReturnType<typeof useDeleteInstructorBannerMutation>;
export type DeleteInstructorBannerMutationResult = Apollo.MutationResult<DeleteInstructorBannerMutation>;
export type DeleteInstructorBannerMutationOptions = Apollo.BaseMutationOptions<DeleteInstructorBannerMutation, DeleteInstructorBannerMutationVariables>;
export const AdminUpdateStudioDocument = gql`
    mutation AdminUpdateStudio($input: AdminUpdateStudioInput!) {
  admin_updateStudio(input: $input) {
    ...StudioFragment
  }
}
    ${StudioFragmentFragmentDoc}`;
export type AdminUpdateStudioMutationFn = Apollo.MutationFunction<AdminUpdateStudioMutation, AdminUpdateStudioMutationVariables>;

/**
 * __useAdminUpdateStudioMutation__
 *
 * To run a mutation, you first call `useAdminUpdateStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateStudioMutation, { data, loading, error }] = useAdminUpdateStudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateStudioMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateStudioMutation, AdminUpdateStudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateStudioMutation, AdminUpdateStudioMutationVariables>(AdminUpdateStudioDocument, options);
      }
export type AdminUpdateStudioMutationHookResult = ReturnType<typeof useAdminUpdateStudioMutation>;
export type AdminUpdateStudioMutationResult = Apollo.MutationResult<AdminUpdateStudioMutation>;
export type AdminUpdateStudioMutationOptions = Apollo.BaseMutationOptions<AdminUpdateStudioMutation, AdminUpdateStudioMutationVariables>;
export const AdminChangeInstructorBannersOrderDocument = gql`
    mutation AdminChangeInstructorBannersOrder($input: [ChangeInstructorBannersOrderInput!]!) {
  admin_changeInstructorBannersOrder(input: $input) {
    ...InstructorBannerFragment
  }
}
    ${InstructorBannerFragmentFragmentDoc}`;
export type AdminChangeInstructorBannersOrderMutationFn = Apollo.MutationFunction<AdminChangeInstructorBannersOrderMutation, AdminChangeInstructorBannersOrderMutationVariables>;

/**
 * __useAdminChangeInstructorBannersOrderMutation__
 *
 * To run a mutation, you first call `useAdminChangeInstructorBannersOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminChangeInstructorBannersOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminChangeInstructorBannersOrderMutation, { data, loading, error }] = useAdminChangeInstructorBannersOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminChangeInstructorBannersOrderMutation(baseOptions?: Apollo.MutationHookOptions<AdminChangeInstructorBannersOrderMutation, AdminChangeInstructorBannersOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminChangeInstructorBannersOrderMutation, AdminChangeInstructorBannersOrderMutationVariables>(AdminChangeInstructorBannersOrderDocument, options);
      }
export type AdminChangeInstructorBannersOrderMutationHookResult = ReturnType<typeof useAdminChangeInstructorBannersOrderMutation>;
export type AdminChangeInstructorBannersOrderMutationResult = Apollo.MutationResult<AdminChangeInstructorBannersOrderMutation>;
export type AdminChangeInstructorBannersOrderMutationOptions = Apollo.BaseMutationOptions<AdminChangeInstructorBannersOrderMutation, AdminChangeInstructorBannersOrderMutationVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser($id: ID!) {
  getUser(id: $id) {
    firstName
    lastName
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  admin_getUser(id: $id) {
    ...UserFieldsFragment
    reports {
      ...ReportFieldsFragment
    }
  }
}
    ${UserFieldsFragmentFragmentDoc}
${ReportFieldsFragmentFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetReportDocument = gql`
    query GetReport($id: ID!) {
  admin_getReport(id: $id) {
    reason {
      name
    }
    seen
    resolved
    reported {
      ...UserFieldsFragment
      reports {
        ...ReportFieldsFragment
      }
      isBlocked
    }
  }
}
    ${UserFieldsFragmentFragmentDoc}
${ReportFieldsFragmentFragmentDoc}`;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReportQuery(baseOptions: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
      }
export function useGetReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
        }
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<typeof useGetReportLazyQuery>;
export type GetReportQueryResult = Apollo.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const ListReportsDocument = gql`
    query ListReports($input: ListReportsInput) {
  admin_listReports(input: $input) {
    ...ReportFieldsFragment
  }
}
    ${ReportFieldsFragmentFragmentDoc}`;

/**
 * __useListReportsQuery__
 *
 * To run a query within a React component, call `useListReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListReportsQuery(baseOptions?: Apollo.QueryHookOptions<ListReportsQuery, ListReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReportsQuery, ListReportsQueryVariables>(ListReportsDocument, options);
      }
export function useListReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReportsQuery, ListReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReportsQuery, ListReportsQueryVariables>(ListReportsDocument, options);
        }
export type ListReportsQueryHookResult = ReturnType<typeof useListReportsQuery>;
export type ListReportsLazyQueryHookResult = ReturnType<typeof useListReportsLazyQuery>;
export type ListReportsQueryResult = Apollo.QueryResult<ListReportsQuery, ListReportsQueryVariables>;
export const LibraryFormOptionsDocument = gql`
    query LibraryFormOptions {
  listLibraryItemLabels {
    name
    type
  }
  listLibraryItemCategories {
    name
    type
  }
  listBodyParts {
    name
    type
  }
  listLibraryItemDurations {
    name
    type
  }
}
    `;

/**
 * __useLibraryFormOptionsQuery__
 *
 * To run a query within a React component, call `useLibraryFormOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryFormOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryFormOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLibraryFormOptionsQuery(baseOptions?: Apollo.QueryHookOptions<LibraryFormOptionsQuery, LibraryFormOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryFormOptionsQuery, LibraryFormOptionsQueryVariables>(LibraryFormOptionsDocument, options);
      }
export function useLibraryFormOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryFormOptionsQuery, LibraryFormOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryFormOptionsQuery, LibraryFormOptionsQueryVariables>(LibraryFormOptionsDocument, options);
        }
export type LibraryFormOptionsQueryHookResult = ReturnType<typeof useLibraryFormOptionsQuery>;
export type LibraryFormOptionsLazyQueryHookResult = ReturnType<typeof useLibraryFormOptionsLazyQuery>;
export type LibraryFormOptionsQueryResult = Apollo.QueryResult<LibraryFormOptionsQuery, LibraryFormOptionsQueryVariables>;
export const ListEquipmentDocument = gql`
    query ListEquipment($input: ListVodFiltersInput) {
  listEquipment(input: $input) {
    name
    type
  }
}
    `;

/**
 * __useListEquipmentQuery__
 *
 * To run a query within a React component, call `useListEquipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEquipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEquipmentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListEquipmentQuery(baseOptions?: Apollo.QueryHookOptions<ListEquipmentQuery, ListEquipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEquipmentQuery, ListEquipmentQueryVariables>(ListEquipmentDocument, options);
      }
export function useListEquipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEquipmentQuery, ListEquipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEquipmentQuery, ListEquipmentQueryVariables>(ListEquipmentDocument, options);
        }
export type ListEquipmentQueryHookResult = ReturnType<typeof useListEquipmentQuery>;
export type ListEquipmentLazyQueryHookResult = ReturnType<typeof useListEquipmentLazyQuery>;
export type ListEquipmentQueryResult = Apollo.QueryResult<ListEquipmentQuery, ListEquipmentQueryVariables>;
export const ListEquipmentForClassSessionDocument = gql`
    query ListEquipmentForClassSession {
  listEquipmentForClassSession {
    name
    type
  }
}
    `;

/**
 * __useListEquipmentForClassSessionQuery__
 *
 * To run a query within a React component, call `useListEquipmentForClassSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEquipmentForClassSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEquipmentForClassSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEquipmentForClassSessionQuery(baseOptions?: Apollo.QueryHookOptions<ListEquipmentForClassSessionQuery, ListEquipmentForClassSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEquipmentForClassSessionQuery, ListEquipmentForClassSessionQueryVariables>(ListEquipmentForClassSessionDocument, options);
      }
export function useListEquipmentForClassSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEquipmentForClassSessionQuery, ListEquipmentForClassSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEquipmentForClassSessionQuery, ListEquipmentForClassSessionQueryVariables>(ListEquipmentForClassSessionDocument, options);
        }
export type ListEquipmentForClassSessionQueryHookResult = ReturnType<typeof useListEquipmentForClassSessionQuery>;
export type ListEquipmentForClassSessionLazyQueryHookResult = ReturnType<typeof useListEquipmentForClassSessionLazyQuery>;
export type ListEquipmentForClassSessionQueryResult = Apollo.QueryResult<ListEquipmentForClassSessionQuery, ListEquipmentForClassSessionQueryVariables>;
export const ListInstructorsDocument = gql`
    query ListInstructors($input: ListInstructorsInput!) {
  listInstructors(input: $input) {
    ...InstructorFragment
  }
}
    ${InstructorFragmentFragmentDoc}`;

/**
 * __useListInstructorsQuery__
 *
 * To run a query within a React component, call `useListInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInstructorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListInstructorsQuery(baseOptions: Apollo.QueryHookOptions<ListInstructorsQuery, ListInstructorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInstructorsQuery, ListInstructorsQueryVariables>(ListInstructorsDocument, options);
      }
export function useListInstructorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInstructorsQuery, ListInstructorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInstructorsQuery, ListInstructorsQueryVariables>(ListInstructorsDocument, options);
        }
export type ListInstructorsQueryHookResult = ReturnType<typeof useListInstructorsQuery>;
export type ListInstructorsLazyQueryHookResult = ReturnType<typeof useListInstructorsLazyQuery>;
export type ListInstructorsQueryResult = Apollo.QueryResult<ListInstructorsQuery, ListInstructorsQueryVariables>;
export const ListAdminsDocument = gql`
    query ListAdmins {
  admin_listAdmins {
    firstName
    lastName
    isActiveAdmin
    isBlocked
    id
    email
    note
    createdAt
  }
}
    `;

/**
 * __useListAdminsQuery__
 *
 * To run a query within a React component, call `useListAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminsQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminsQuery, ListAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminsQuery, ListAdminsQueryVariables>(ListAdminsDocument, options);
      }
export function useListAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminsQuery, ListAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminsQuery, ListAdminsQueryVariables>(ListAdminsDocument, options);
        }
export type ListAdminsQueryHookResult = ReturnType<typeof useListAdminsQuery>;
export type ListAdminsLazyQueryHookResult = ReturnType<typeof useListAdminsLazyQuery>;
export type ListAdminsQueryResult = Apollo.QueryResult<ListAdminsQuery, ListAdminsQueryVariables>;
export const GetSignedUrlDocument = gql`
    query GetSignedUrl($options: GetSignedUrlInput!) {
  getSignedUrl(options: $options) {
    url
    publicUrl
    expiresAt
  }
}
    `;

/**
 * __useGetSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUrlQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSignedUrlQuery(baseOptions: Apollo.QueryHookOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, options);
      }
export function useGetSignedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, options);
        }
export type GetSignedUrlQueryHookResult = ReturnType<typeof useGetSignedUrlQuery>;
export type GetSignedUrlLazyQueryHookResult = ReturnType<typeof useGetSignedUrlLazyQuery>;
export type GetSignedUrlQueryResult = Apollo.QueryResult<GetSignedUrlQuery, GetSignedUrlQueryVariables>;
export const AdminGetPrivateSignedUrlDocument = gql`
    query AdminGetPrivateSignedUrl($input: GetSignedUrlInput!) {
  admin_getPrivateSignedUrl(input: $input) {
    url
    publicUrl
    fileName
  }
}
    `;

/**
 * __useAdminGetPrivateSignedUrlQuery__
 *
 * To run a query within a React component, call `useAdminGetPrivateSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetPrivateSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetPrivateSignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminGetPrivateSignedUrlQuery(baseOptions: Apollo.QueryHookOptions<AdminGetPrivateSignedUrlQuery, AdminGetPrivateSignedUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetPrivateSignedUrlQuery, AdminGetPrivateSignedUrlQueryVariables>(AdminGetPrivateSignedUrlDocument, options);
      }
export function useAdminGetPrivateSignedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetPrivateSignedUrlQuery, AdminGetPrivateSignedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetPrivateSignedUrlQuery, AdminGetPrivateSignedUrlQueryVariables>(AdminGetPrivateSignedUrlDocument, options);
        }
export type AdminGetPrivateSignedUrlQueryHookResult = ReturnType<typeof useAdminGetPrivateSignedUrlQuery>;
export type AdminGetPrivateSignedUrlLazyQueryHookResult = ReturnType<typeof useAdminGetPrivateSignedUrlLazyQuery>;
export type AdminGetPrivateSignedUrlQueryResult = Apollo.QueryResult<AdminGetPrivateSignedUrlQuery, AdminGetPrivateSignedUrlQueryVariables>;
export const ListBannersDocument = gql`
    query ListBanners {
  admin_listBanners {
    ...BannerFragment
  }
}
    ${BannerFragmentFragmentDoc}`;

/**
 * __useListBannersQuery__
 *
 * To run a query within a React component, call `useListBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBannersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListBannersQuery(baseOptions?: Apollo.QueryHookOptions<ListBannersQuery, ListBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBannersQuery, ListBannersQueryVariables>(ListBannersDocument, options);
      }
export function useListBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBannersQuery, ListBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBannersQuery, ListBannersQueryVariables>(ListBannersDocument, options);
        }
export type ListBannersQueryHookResult = ReturnType<typeof useListBannersQuery>;
export type ListBannersLazyQueryHookResult = ReturnType<typeof useListBannersLazyQuery>;
export type ListBannersQueryResult = Apollo.QueryResult<ListBannersQuery, ListBannersQueryVariables>;
export const AdminGetBannerDocument = gql`
    query AdminGetBanner($id: ID!) {
  admin_getBanner(id: $id) {
    ...BannerFragment
  }
}
    ${BannerFragmentFragmentDoc}`;

/**
 * __useAdminGetBannerQuery__
 *
 * To run a query within a React component, call `useAdminGetBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetBannerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetBannerQuery(baseOptions: Apollo.QueryHookOptions<AdminGetBannerQuery, AdminGetBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetBannerQuery, AdminGetBannerQueryVariables>(AdminGetBannerDocument, options);
      }
export function useAdminGetBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetBannerQuery, AdminGetBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetBannerQuery, AdminGetBannerQueryVariables>(AdminGetBannerDocument, options);
        }
export type AdminGetBannerQueryHookResult = ReturnType<typeof useAdminGetBannerQuery>;
export type AdminGetBannerLazyQueryHookResult = ReturnType<typeof useAdminGetBannerLazyQuery>;
export type AdminGetBannerQueryResult = Apollo.QueryResult<AdminGetBannerQuery, AdminGetBannerQueryVariables>;
export const AdminGetOnDemandCollectionDocument = gql`
    query AdminGetOnDemandCollection($id: ID!) {
  admin_getOnDemandCollection(id: $id) {
    id
  }
}
    `;

/**
 * __useAdminGetOnDemandCollectionQuery__
 *
 * To run a query within a React component, call `useAdminGetOnDemandCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetOnDemandCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetOnDemandCollectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetOnDemandCollectionQuery(baseOptions: Apollo.QueryHookOptions<AdminGetOnDemandCollectionQuery, AdminGetOnDemandCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetOnDemandCollectionQuery, AdminGetOnDemandCollectionQueryVariables>(AdminGetOnDemandCollectionDocument, options);
      }
export function useAdminGetOnDemandCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetOnDemandCollectionQuery, AdminGetOnDemandCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetOnDemandCollectionQuery, AdminGetOnDemandCollectionQueryVariables>(AdminGetOnDemandCollectionDocument, options);
        }
export type AdminGetOnDemandCollectionQueryHookResult = ReturnType<typeof useAdminGetOnDemandCollectionQuery>;
export type AdminGetOnDemandCollectionLazyQueryHookResult = ReturnType<typeof useAdminGetOnDemandCollectionLazyQuery>;
export type AdminGetOnDemandCollectionQueryResult = Apollo.QueryResult<AdminGetOnDemandCollectionQuery, AdminGetOnDemandCollectionQueryVariables>;
export const AdminGetOnDemandSectionDocument = gql`
    query AdminGetOnDemandSection($id: ID!) {
  admin_getOnDemandSection(id: $id) {
    id
    order
    name
    items {
      createdAt
      id
      order
      libraryItem {
        ...LibraryFragment
      }
    }
  }
}
    ${LibraryFragmentFragmentDoc}`;

/**
 * __useAdminGetOnDemandSectionQuery__
 *
 * To run a query within a React component, call `useAdminGetOnDemandSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetOnDemandSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetOnDemandSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetOnDemandSectionQuery(baseOptions: Apollo.QueryHookOptions<AdminGetOnDemandSectionQuery, AdminGetOnDemandSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetOnDemandSectionQuery, AdminGetOnDemandSectionQueryVariables>(AdminGetOnDemandSectionDocument, options);
      }
export function useAdminGetOnDemandSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetOnDemandSectionQuery, AdminGetOnDemandSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetOnDemandSectionQuery, AdminGetOnDemandSectionQueryVariables>(AdminGetOnDemandSectionDocument, options);
        }
export type AdminGetOnDemandSectionQueryHookResult = ReturnType<typeof useAdminGetOnDemandSectionQuery>;
export type AdminGetOnDemandSectionLazyQueryHookResult = ReturnType<typeof useAdminGetOnDemandSectionLazyQuery>;
export type AdminGetOnDemandSectionQueryResult = Apollo.QueryResult<AdminGetOnDemandSectionQuery, AdminGetOnDemandSectionQueryVariables>;
export const AdminListOnDemandCollectionSectionsDocument = gql`
    query AdminListOnDemandCollectionSections {
  admin_listOnDemandCollectionSections {
    id
    name
    createdAt
    collections {
      id
      name
      createdAt
      imageURL
      collectionItems {
        id
        libraryItem {
          ...LibraryFragment
        }
      }
    }
  }
}
    ${LibraryFragmentFragmentDoc}`;

/**
 * __useAdminListOnDemandCollectionSectionsQuery__
 *
 * To run a query within a React component, call `useAdminListOnDemandCollectionSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListOnDemandCollectionSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListOnDemandCollectionSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminListOnDemandCollectionSectionsQuery(baseOptions?: Apollo.QueryHookOptions<AdminListOnDemandCollectionSectionsQuery, AdminListOnDemandCollectionSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminListOnDemandCollectionSectionsQuery, AdminListOnDemandCollectionSectionsQueryVariables>(AdminListOnDemandCollectionSectionsDocument, options);
      }
export function useAdminListOnDemandCollectionSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminListOnDemandCollectionSectionsQuery, AdminListOnDemandCollectionSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminListOnDemandCollectionSectionsQuery, AdminListOnDemandCollectionSectionsQueryVariables>(AdminListOnDemandCollectionSectionsDocument, options);
        }
export type AdminListOnDemandCollectionSectionsQueryHookResult = ReturnType<typeof useAdminListOnDemandCollectionSectionsQuery>;
export type AdminListOnDemandCollectionSectionsLazyQueryHookResult = ReturnType<typeof useAdminListOnDemandCollectionSectionsLazyQuery>;
export type AdminListOnDemandCollectionSectionsQueryResult = Apollo.QueryResult<AdminListOnDemandCollectionSectionsQuery, AdminListOnDemandCollectionSectionsQueryVariables>;
export const AdminListOnDemandSectionsDocument = gql`
    query AdminListOnDemandSections($input: ListOnDemandSectionInput) {
  admin_listOnDemandSections(input: $input) {
    id
    name
    createdAt
    category {
      type
    }
  }
}
    `;

/**
 * __useAdminListOnDemandSectionsQuery__
 *
 * To run a query within a React component, call `useAdminListOnDemandSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListOnDemandSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListOnDemandSectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminListOnDemandSectionsQuery(baseOptions?: Apollo.QueryHookOptions<AdminListOnDemandSectionsQuery, AdminListOnDemandSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminListOnDemandSectionsQuery, AdminListOnDemandSectionsQueryVariables>(AdminListOnDemandSectionsDocument, options);
      }
export function useAdminListOnDemandSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminListOnDemandSectionsQuery, AdminListOnDemandSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminListOnDemandSectionsQuery, AdminListOnDemandSectionsQueryVariables>(AdminListOnDemandSectionsDocument, options);
        }
export type AdminListOnDemandSectionsQueryHookResult = ReturnType<typeof useAdminListOnDemandSectionsQuery>;
export type AdminListOnDemandSectionsLazyQueryHookResult = ReturnType<typeof useAdminListOnDemandSectionsLazyQuery>;
export type AdminListOnDemandSectionsQueryResult = Apollo.QueryResult<AdminListOnDemandSectionsQuery, AdminListOnDemandSectionsQueryVariables>;
export const AdminListLibraryItemsDocument = gql`
    query AdminListLibraryItems($input: AdminListLibraryItemsInput) {
  admin_listLibraryItems(input: $input) {
    ...LibraryFragment
    instructorCache {
      ...InstructorCacheFragment
    }
  }
}
    ${LibraryFragmentFragmentDoc}
${InstructorCacheFragmentFragmentDoc}`;

/**
 * __useAdminListLibraryItemsQuery__
 *
 * To run a query within a React component, call `useAdminListLibraryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListLibraryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListLibraryItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminListLibraryItemsQuery(baseOptions?: Apollo.QueryHookOptions<AdminListLibraryItemsQuery, AdminListLibraryItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminListLibraryItemsQuery, AdminListLibraryItemsQueryVariables>(AdminListLibraryItemsDocument, options);
      }
export function useAdminListLibraryItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminListLibraryItemsQuery, AdminListLibraryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminListLibraryItemsQuery, AdminListLibraryItemsQueryVariables>(AdminListLibraryItemsDocument, options);
        }
export type AdminListLibraryItemsQueryHookResult = ReturnType<typeof useAdminListLibraryItemsQuery>;
export type AdminListLibraryItemsLazyQueryHookResult = ReturnType<typeof useAdminListLibraryItemsLazyQuery>;
export type AdminListLibraryItemsQueryResult = Apollo.QueryResult<AdminListLibraryItemsQuery, AdminListLibraryItemsQueryVariables>;
export const GetCampaignDocument = gql`
    query GetCampaign($id: ID!) {
  admin_getCampaign(id: $id) {
    id
    body
    scheduledDateTime
    imageURL
    sent
    title
    sentDateTime
  }
}
    `;

/**
 * __useGetCampaignQuery__
 *
 * To run a query within a React component, call `useGetCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignQuery, GetCampaignQueryVariables>(GetCampaignDocument, options);
      }
export function useGetCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignQuery, GetCampaignQueryVariables>(GetCampaignDocument, options);
        }
export type GetCampaignQueryHookResult = ReturnType<typeof useGetCampaignQuery>;
export type GetCampaignLazyQueryHookResult = ReturnType<typeof useGetCampaignLazyQuery>;
export type GetCampaignQueryResult = Apollo.QueryResult<GetCampaignQuery, GetCampaignQueryVariables>;
export const ListCampaignsDocument = gql`
    query ListCampaigns($input: ListCampaignsInput) {
  admin_listCampaigns(input: $input) {
    id
    body
    scheduledDateTime
    imageURL
    sent
    title
    sentDateTime
  }
}
    `;

/**
 * __useListCampaignsQuery__
 *
 * To run a query within a React component, call `useListCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCampaignsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<ListCampaignsQuery, ListCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCampaignsQuery, ListCampaignsQueryVariables>(ListCampaignsDocument, options);
      }
export function useListCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCampaignsQuery, ListCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCampaignsQuery, ListCampaignsQueryVariables>(ListCampaignsDocument, options);
        }
export type ListCampaignsQueryHookResult = ReturnType<typeof useListCampaignsQuery>;
export type ListCampaignsLazyQueryHookResult = ReturnType<typeof useListCampaignsLazyQuery>;
export type ListCampaignsQueryResult = Apollo.QueryResult<ListCampaignsQuery, ListCampaignsQueryVariables>;
export const GetLibraryItemDocument = gql`
    query GetLibraryItem($id: ID!) {
  admin_getLibraryItem(id: $id) {
    ...LibraryFragment
  }
}
    ${LibraryFragmentFragmentDoc}`;

/**
 * __useGetLibraryItemQuery__
 *
 * To run a query within a React component, call `useGetLibraryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibraryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibraryItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLibraryItemQuery(baseOptions: Apollo.QueryHookOptions<GetLibraryItemQuery, GetLibraryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLibraryItemQuery, GetLibraryItemQueryVariables>(GetLibraryItemDocument, options);
      }
export function useGetLibraryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLibraryItemQuery, GetLibraryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLibraryItemQuery, GetLibraryItemQueryVariables>(GetLibraryItemDocument, options);
        }
export type GetLibraryItemQueryHookResult = ReturnType<typeof useGetLibraryItemQuery>;
export type GetLibraryItemLazyQueryHookResult = ReturnType<typeof useGetLibraryItemLazyQuery>;
export type GetLibraryItemQueryResult = Apollo.QueryResult<GetLibraryItemQuery, GetLibraryItemQueryVariables>;
export const ValidateClassSessionIdDocument = gql`
    query ValidateClassSessionID($classSessionId: ID!) {
  validateClassSessionID(classSessionID: $classSessionId) {
    valid
  }
}
    `;

/**
 * __useValidateClassSessionIdQuery__
 *
 * To run a query within a React component, call `useValidateClassSessionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateClassSessionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateClassSessionIdQuery({
 *   variables: {
 *      classSessionId: // value for 'classSessionId'
 *   },
 * });
 */
export function useValidateClassSessionIdQuery(baseOptions: Apollo.QueryHookOptions<ValidateClassSessionIdQuery, ValidateClassSessionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateClassSessionIdQuery, ValidateClassSessionIdQueryVariables>(ValidateClassSessionIdDocument, options);
      }
export function useValidateClassSessionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateClassSessionIdQuery, ValidateClassSessionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateClassSessionIdQuery, ValidateClassSessionIdQueryVariables>(ValidateClassSessionIdDocument, options);
        }
export type ValidateClassSessionIdQueryHookResult = ReturnType<typeof useValidateClassSessionIdQuery>;
export type ValidateClassSessionIdLazyQueryHookResult = ReturnType<typeof useValidateClassSessionIdLazyQuery>;
export type ValidateClassSessionIdQueryResult = Apollo.QueryResult<ValidateClassSessionIdQuery, ValidateClassSessionIdQueryVariables>;
export const ListVodFiltersDocument = gql`
    query ListVodFilters($category: LibraryItemCategoryEnum) {
  listVodFilters(input: {category: $category}) {
    bodyParts {
      name
      type
    }
    bodyPartsFilters {
      name
      type
    }
    equipment {
      name
      type
      image
    }
    equipmentFilters: equipmentFilters_v2 {
      name
      type
      image
    }
    labels {
      name
      type
    }
    types {
      name
      type
    }
    durations {
      name
      type
    }
    statuses {
      name
      type
    }
  }
}
    `;

/**
 * __useListVodFiltersQuery__
 *
 * To run a query within a React component, call `useListVodFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVodFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVodFiltersQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useListVodFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ListVodFiltersQuery, ListVodFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListVodFiltersQuery, ListVodFiltersQueryVariables>(ListVodFiltersDocument, options);
      }
export function useListVodFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListVodFiltersQuery, ListVodFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListVodFiltersQuery, ListVodFiltersQueryVariables>(ListVodFiltersDocument, options);
        }
export type ListVodFiltersQueryHookResult = ReturnType<typeof useListVodFiltersQuery>;
export type ListVodFiltersLazyQueryHookResult = ReturnType<typeof useListVodFiltersLazyQuery>;
export type ListVodFiltersQueryResult = Apollo.QueryResult<ListVodFiltersQuery, ListVodFiltersQueryVariables>;
export const ListLibraryItemCategoriesDocument = gql`
    query ListLibraryItemCategories($input: GetLibraryItemCategoriesTotalInput, $totalInput: GetLibraryItemCategoriesTotalInput) {
  listLibraryItemCategories(input: $input) {
    name
    type
    verticalImage
    total(input: $totalInput)
  }
}
    `;

/**
 * __useListLibraryItemCategoriesQuery__
 *
 * To run a query within a React component, call `useListLibraryItemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLibraryItemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLibraryItemCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      totalInput: // value for 'totalInput'
 *   },
 * });
 */
export function useListLibraryItemCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ListLibraryItemCategoriesQuery, ListLibraryItemCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLibraryItemCategoriesQuery, ListLibraryItemCategoriesQueryVariables>(ListLibraryItemCategoriesDocument, options);
      }
export function useListLibraryItemCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLibraryItemCategoriesQuery, ListLibraryItemCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLibraryItemCategoriesQuery, ListLibraryItemCategoriesQueryVariables>(ListLibraryItemCategoriesDocument, options);
        }
export type ListLibraryItemCategoriesQueryHookResult = ReturnType<typeof useListLibraryItemCategoriesQuery>;
export type ListLibraryItemCategoriesLazyQueryHookResult = ReturnType<typeof useListLibraryItemCategoriesLazyQuery>;
export type ListLibraryItemCategoriesQueryResult = Apollo.QueryResult<ListLibraryItemCategoriesQuery, ListLibraryItemCategoriesQueryVariables>;
export const CountLibraryItemsDocument = gql`
    query CountLibraryItems($input: AdminListLibraryItemsInput) {
  admin_countLibraryItems(input: $input) {
    total
    audio
    video
    previouslyLive
  }
}
    `;

/**
 * __useCountLibraryItemsQuery__
 *
 * To run a query within a React component, call `useCountLibraryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountLibraryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountLibraryItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountLibraryItemsQuery(baseOptions?: Apollo.QueryHookOptions<CountLibraryItemsQuery, CountLibraryItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountLibraryItemsQuery, CountLibraryItemsQueryVariables>(CountLibraryItemsDocument, options);
      }
export function useCountLibraryItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountLibraryItemsQuery, CountLibraryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountLibraryItemsQuery, CountLibraryItemsQueryVariables>(CountLibraryItemsDocument, options);
        }
export type CountLibraryItemsQueryHookResult = ReturnType<typeof useCountLibraryItemsQuery>;
export type CountLibraryItemsLazyQueryHookResult = ReturnType<typeof useCountLibraryItemsLazyQuery>;
export type CountLibraryItemsQueryResult = Apollo.QueryResult<CountLibraryItemsQuery, CountLibraryItemsQueryVariables>;
export const ListClassSessionsDocument = gql`
    query ListClassSessions($input: AdminListClassSessionsInput!) {
  admin_listClassSessions(input: $input) {
    ...ClassSessionFragment
  }
}
    ${ClassSessionFragmentFragmentDoc}`;

/**
 * __useListClassSessionsQuery__
 *
 * To run a query within a React component, call `useListClassSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClassSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClassSessionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListClassSessionsQuery(baseOptions: Apollo.QueryHookOptions<ListClassSessionsQuery, ListClassSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListClassSessionsQuery, ListClassSessionsQueryVariables>(ListClassSessionsDocument, options);
      }
export function useListClassSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListClassSessionsQuery, ListClassSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListClassSessionsQuery, ListClassSessionsQueryVariables>(ListClassSessionsDocument, options);
        }
export type ListClassSessionsQueryHookResult = ReturnType<typeof useListClassSessionsQuery>;
export type ListClassSessionsLazyQueryHookResult = ReturnType<typeof useListClassSessionsLazyQuery>;
export type ListClassSessionsQueryResult = Apollo.QueryResult<ListClassSessionsQuery, ListClassSessionsQueryVariables>;
export const GetClassSessionDocument = gql`
    query GetClassSession($id: ID!) {
  getClassSession(id: $id) {
    id
    name
    startDateTime
    endDateTime
    location {
      id
      name
    }
    region {
      id
      name
    }
    instructors {
      id
      firstName
      lastName
      fullName
    }
  }
}
    `;

/**
 * __useGetClassSessionQuery__
 *
 * To run a query within a React component, call `useGetClassSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassSessionQuery(baseOptions: Apollo.QueryHookOptions<GetClassSessionQuery, GetClassSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassSessionQuery, GetClassSessionQueryVariables>(GetClassSessionDocument, options);
      }
export function useGetClassSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassSessionQuery, GetClassSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassSessionQuery, GetClassSessionQueryVariables>(GetClassSessionDocument, options);
        }
export type GetClassSessionQueryHookResult = ReturnType<typeof useGetClassSessionQuery>;
export type GetClassSessionLazyQueryHookResult = ReturnType<typeof useGetClassSessionLazyQuery>;
export type GetClassSessionQueryResult = Apollo.QueryResult<GetClassSessionQuery, GetClassSessionQueryVariables>;
export const ListUsersDocument = gql`
    query ListUsers($input: AdminListUsersInput!) {
  admin_listUsers(input: $input) {
    ...UserFieldsFragment
  }
}
    ${UserFieldsFragmentFragmentDoc}`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const ListRegionsDocument = gql`
    query ListRegions($input: ListRegionsInput) {
  listRegions(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useListRegionsQuery__
 *
 * To run a query within a React component, call `useListRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRegionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListRegionsQuery(baseOptions?: Apollo.QueryHookOptions<ListRegionsQuery, ListRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRegionsQuery, ListRegionsQueryVariables>(ListRegionsDocument, options);
      }
export function useListRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRegionsQuery, ListRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRegionsQuery, ListRegionsQueryVariables>(ListRegionsDocument, options);
        }
export type ListRegionsQueryHookResult = ReturnType<typeof useListRegionsQuery>;
export type ListRegionsLazyQueryHookResult = ReturnType<typeof useListRegionsLazyQuery>;
export type ListRegionsQueryResult = Apollo.QueryResult<ListRegionsQuery, ListRegionsQueryVariables>;
export const ListStudiosDocument = gql`
    query ListStudios {
  listStudios {
    name
    regions {
      id
      name
      locations {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListStudiosQuery__
 *
 * To run a query within a React component, call `useListStudiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStudiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStudiosQuery({
 *   variables: {
 *   },
 * });
 */
export function useListStudiosQuery(baseOptions?: Apollo.QueryHookOptions<ListStudiosQuery, ListStudiosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStudiosQuery, ListStudiosQueryVariables>(ListStudiosDocument, options);
      }
export function useListStudiosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStudiosQuery, ListStudiosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStudiosQuery, ListStudiosQueryVariables>(ListStudiosDocument, options);
        }
export type ListStudiosQueryHookResult = ReturnType<typeof useListStudiosQuery>;
export type ListStudiosLazyQueryHookResult = ReturnType<typeof useListStudiosLazyQuery>;
export type ListStudiosQueryResult = Apollo.QueryResult<ListStudiosQuery, ListStudiosQueryVariables>;
export const ListEmployeesDocument = gql`
    query ListEmployees($input: ListEmployeesInput!) {
  admin_listEmployees(input: $input) {
    ...EmployeeFieldsFragment
  }
}
    ${EmployeeFieldsFragmentFragmentDoc}`;

/**
 * __useListEmployeesQuery__
 *
 * To run a query within a React component, call `useListEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListEmployeesQuery(baseOptions: Apollo.QueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
      }
export function useListEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
        }
export type ListEmployeesQueryHookResult = ReturnType<typeof useListEmployeesQuery>;
export type ListEmployeesLazyQueryHookResult = ReturnType<typeof useListEmployeesLazyQuery>;
export type ListEmployeesQueryResult = Apollo.QueryResult<ListEmployeesQuery, ListEmployeesQueryVariables>;
export const GetEmployeeDocument = gql`
    query GetEmployee($id: ID!) {
  admin_getEmployee(id: $id) {
    ...EmployeeFieldsFragment
    instagramHandle
    spotifyLink
    phoneNumber
    employeeID
    username
  }
}
    ${EmployeeFieldsFragmentFragmentDoc}`;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
      }
export function useGetEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
        }
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeQueryResult = Apollo.QueryResult<GetEmployeeQuery, GetEmployeeQueryVariables>;
export const ListShopifyPicksDocument = gql`
    query ListShopifyPicks($employeeID: ID!) {
  admin_listEmployeeShopifyPicks(employeeID: $employeeID) {
    id
    name
    url
    pictureUrl
  }
}
    `;

/**
 * __useListShopifyPicksQuery__
 *
 * To run a query within a React component, call `useListShopifyPicksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListShopifyPicksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListShopifyPicksQuery({
 *   variables: {
 *      employeeID: // value for 'employeeID'
 *   },
 * });
 */
export function useListShopifyPicksQuery(baseOptions: Apollo.QueryHookOptions<ListShopifyPicksQuery, ListShopifyPicksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListShopifyPicksQuery, ListShopifyPicksQueryVariables>(ListShopifyPicksDocument, options);
      }
export function useListShopifyPicksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListShopifyPicksQuery, ListShopifyPicksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListShopifyPicksQuery, ListShopifyPicksQueryVariables>(ListShopifyPicksDocument, options);
        }
export type ListShopifyPicksQueryHookResult = ReturnType<typeof useListShopifyPicksQuery>;
export type ListShopifyPicksLazyQueryHookResult = ReturnType<typeof useListShopifyPicksLazyQuery>;
export type ListShopifyPicksQueryResult = Apollo.QueryResult<ListShopifyPicksQuery, ListShopifyPicksQueryVariables>;
export const ListInstructorBannersDocument = gql`
    query ListInstructorBanners($input: ListInstructorBannersInput) {
  admin_listInstructorBanners(input: $input) {
    ...InstructorBannerFragment
  }
}
    ${InstructorBannerFragmentFragmentDoc}`;

/**
 * __useListInstructorBannersQuery__
 *
 * To run a query within a React component, call `useListInstructorBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInstructorBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInstructorBannersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListInstructorBannersQuery(baseOptions?: Apollo.QueryHookOptions<ListInstructorBannersQuery, ListInstructorBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInstructorBannersQuery, ListInstructorBannersQueryVariables>(ListInstructorBannersDocument, options);
      }
export function useListInstructorBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInstructorBannersQuery, ListInstructorBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInstructorBannersQuery, ListInstructorBannersQueryVariables>(ListInstructorBannersDocument, options);
        }
export type ListInstructorBannersQueryHookResult = ReturnType<typeof useListInstructorBannersQuery>;
export type ListInstructorBannersLazyQueryHookResult = ReturnType<typeof useListInstructorBannersLazyQuery>;
export type ListInstructorBannersQueryResult = Apollo.QueryResult<ListInstructorBannersQuery, ListInstructorBannersQueryVariables>;
export const GetInstructorBannerDocument = gql`
    query GetInstructorBanner($id: ID!) {
  admin_getInstructorBanner(id: $id) {
    ...InstructorBannerFragment
  }
}
    ${InstructorBannerFragmentFragmentDoc}`;

/**
 * __useGetInstructorBannerQuery__
 *
 * To run a query within a React component, call `useGetInstructorBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorBannerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstructorBannerQuery(baseOptions: Apollo.QueryHookOptions<GetInstructorBannerQuery, GetInstructorBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstructorBannerQuery, GetInstructorBannerQueryVariables>(GetInstructorBannerDocument, options);
      }
export function useGetInstructorBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstructorBannerQuery, GetInstructorBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstructorBannerQuery, GetInstructorBannerQueryVariables>(GetInstructorBannerDocument, options);
        }
export type GetInstructorBannerQueryHookResult = ReturnType<typeof useGetInstructorBannerQuery>;
export type GetInstructorBannerLazyQueryHookResult = ReturnType<typeof useGetInstructorBannerLazyQuery>;
export type GetInstructorBannerQueryResult = Apollo.QueryResult<GetInstructorBannerQuery, GetInstructorBannerQueryVariables>;
export const AdminListStudiosDocument = gql`
    query AdminListStudios($input: AdminListStudiosInput) {
  admin_listStudios(input: $input) {
    ...StudioFragment
  }
}
    ${StudioFragmentFragmentDoc}`;

/**
 * __useAdminListStudiosQuery__
 *
 * To run a query within a React component, call `useAdminListStudiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListStudiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListStudiosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminListStudiosQuery(baseOptions?: Apollo.QueryHookOptions<AdminListStudiosQuery, AdminListStudiosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminListStudiosQuery, AdminListStudiosQueryVariables>(AdminListStudiosDocument, options);
      }
export function useAdminListStudiosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminListStudiosQuery, AdminListStudiosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminListStudiosQuery, AdminListStudiosQueryVariables>(AdminListStudiosDocument, options);
        }
export type AdminListStudiosQueryHookResult = ReturnType<typeof useAdminListStudiosQuery>;
export type AdminListStudiosLazyQueryHookResult = ReturnType<typeof useAdminListStudiosLazyQuery>;
export type AdminListStudiosQueryResult = Apollo.QueryResult<AdminListStudiosQuery, AdminListStudiosQueryVariables>;
export const AdminGetStudioDocument = gql`
    query AdminGetStudio($id: ID!) {
  admin_getStudio(id: $id) {
    ...StudioFragment
  }
}
    ${StudioFragmentFragmentDoc}`;

/**
 * __useAdminGetStudioQuery__
 *
 * To run a query within a React component, call `useAdminGetStudioQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetStudioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetStudioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetStudioQuery(baseOptions: Apollo.QueryHookOptions<AdminGetStudioQuery, AdminGetStudioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetStudioQuery, AdminGetStudioQueryVariables>(AdminGetStudioDocument, options);
      }
export function useAdminGetStudioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetStudioQuery, AdminGetStudioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetStudioQuery, AdminGetStudioQueryVariables>(AdminGetStudioDocument, options);
        }
export type AdminGetStudioQueryHookResult = ReturnType<typeof useAdminGetStudioQuery>;
export type AdminGetStudioLazyQueryHookResult = ReturnType<typeof useAdminGetStudioLazyQuery>;
export type AdminGetStudioQueryResult = Apollo.QueryResult<AdminGetStudioQuery, AdminGetStudioQueryVariables>;