import { css } from 'styled-components'

export const font = {
  base: '"Benton Sans", sans-serif',
  headings: '"Ford Antenna", sans-serif',
}

export const allHeadingsStyle = css`
  font-family: ${font.headings};
  font-weight: 700;
  margin: 0;
`

export const fontSize = {
  h1: '3.2rem',
  h2: '2.4rem',
  h3: '2rem',
  large: '1.8rem',
  micro: '1.2rem',
  regular: '1.6rem',
  small: '1.4rem',
}

export const heading = {
  h1: css`
    font-size: ${fontSize.h1};
    ${allHeadingsStyle}
  `,
  h2: css`
    font-size: ${fontSize.h2};
    ${allHeadingsStyle}
  `,
  h3: css`
    font-size: ${fontSize.h3};
    ${allHeadingsStyle}
  `,
}
