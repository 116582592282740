import styled from 'styled-components'

import { theme } from '~/styles/theme'
import { fontSize } from '~/styles/typography'

export const ErrorMessageWrapper = styled.div`
  margin-bottom: 3.5rem;
  position: relative;
  width: 100%;
`

export const StyledErrorMessage = styled.div`
  color: ${theme.color.primary};
  font-size: ${fontSize.small};
  width: 100%;
  margin: ${theme.layout.spacing.xs} 0 0;
  position: absolute;
`
