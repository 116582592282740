import { gql } from '@apollo/client'

import {
  bannerFragment,
  classSessionFragment,
  employeeFieldsFragment,
  instructorBannerFragment,
  instructorCacheFragment,
  instructorFragment,
  libraryFragment,
  reportFieldsFragment,
  StudioFragment,
} from './fragments'

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($id: ID!) {
    getUser(id: $id) {
      firstName
      lastName
    }
  }
`

export const GET_USER = gql`
  query GetUser($id: ID!) {
    admin_getUser(id: $id) {
      ...UserFieldsFragment
      reports {
        ...ReportFieldsFragment
      }
    }
  }
`

export const GET_REPORT = gql`
  query GetReport($id: ID!) {
    admin_getReport(id: $id) {
      reason {
        name
      }
      seen
      resolved
      reported {
        ...UserFieldsFragment
        reports {
          ...ReportFieldsFragment
        }
        isBlocked
      }
    }
  }
`

export const LIST_REPORTS = gql`
  query ListReports($input: ListReportsInput) {
    admin_listReports(input: $input) {
      ...ReportFieldsFragment
    }
  }
  ${reportFieldsFragment}
`

export const LIBRARY_FORM_OPTIONS = gql`
  query LibraryFormOptions {
    listLibraryItemLabels {
      name
      type
    }
    listLibraryItemCategories {
      name
      type
    }
    listBodyParts {
      name
      type
    }
    listLibraryItemDurations {
      name
      type
    }
  }
`

export const LIST_EQUIPMENT = gql`
  query ListEquipment($input: ListVodFiltersInput) {
    listEquipment(input: $input) {
      name
      type
    }
  }
`

export const LIST_EQUIPMENT_FOR_CLASS_SESSION = gql`
  query ListEquipmentForClassSession {
    listEquipmentForClassSession {
      name
      type
    }
  }
`

export const LIST_INSTRUCTORS = gql`
  query ListInstructors($input: ListInstructorsInput!) {
    listInstructors(input: $input) {
      ...InstructorFragment
    }
  }
  ${instructorFragment}
`

export const LIST_ADMINS = gql`
  query ListAdmins {
    admin_listAdmins {
      firstName
      lastName
      isActiveAdmin
      isBlocked
      id
      email
      note
      createdAt
    }
  }
`

export const GET_SIGNED_URL = gql`
  query GetSignedUrl($options: GetSignedUrlInput!) {
    getSignedUrl(options: $options) {
      url
      publicUrl
      expiresAt
    }
  }
`

export const ADMIN_GET_PRIVATE_SIGNED_URL = gql`
  query AdminGetPrivateSignedUrl($input: GetSignedUrlInput!) {
    admin_getPrivateSignedUrl(input: $input) {
      url
      publicUrl
      fileName
    }
  }
`

export const LIST_BANNERS = gql`
  query ListBanners {
    admin_listBanners {
      ...BannerFragment
    }
  }
  ${bannerFragment}
`

export const ADMIN_GET_BANNER = gql`
  query AdminGetBanner($id: ID!) {
    admin_getBanner(id: $id) {
      ...BannerFragment
    }
  }
  ${bannerFragment}
`

export const ADMIN_GET_ON_DEMAND_COLLECTION = gql`
  query AdminGetOnDemandCollection($id: ID!) {
    admin_getOnDemandCollection(id: $id) {
      id
    }
  }
`

export const ADMIN_GET_ON_DEMAND_SECTION = gql`
  query AdminGetOnDemandSection($id: ID!) {
    admin_getOnDemandSection(id: $id) {
      id
      order
      name
      items {
        createdAt
        id
        order
        libraryItem {
          ...LibraryFragment
        }
      }
    }
  }
  ${libraryFragment}
`

export const ADMIN_LIST_ON_DEMAND_COLLECTION_SECTIONS = gql`
  query AdminListOnDemandCollectionSections {
    admin_listOnDemandCollectionSections {
      id
      name
      createdAt
      collections {
        id
        name
        createdAt
        imageURL
        collectionItems {
          id
          libraryItem {
            ...LibraryFragment
          }
        }
      }
    }
  }
  ${libraryFragment}
`

export const ADMIN_LIST_ON_DEMAND_SECTIONS = gql`
  query AdminListOnDemandSections($input: ListOnDemandSectionInput) {
    admin_listOnDemandSections(input: $input) {
      id
      name
      createdAt
      category {
        type
      }
    }
  }
`

export const ADMIN_LIST_LIBRARY_ITEMS = gql`
  query AdminListLibraryItems($input: AdminListLibraryItemsInput) {
    admin_listLibraryItems(input: $input) {
      ...LibraryFragment
      instructorCache {
        ...InstructorCacheFragment
      }
    }
  }
  ${libraryFragment}
  ${instructorCacheFragment}
`

export const ADMIN_GET_CAMPAIGN = gql`
  query GetCampaign($id: ID!) {
    admin_getCampaign(id: $id) {
      id
      body
      scheduledDateTime
      imageURL
      sent
      title
      sentDateTime
    }
  }
`

export const ADMIN_LIST_CAMPAIGNS = gql`
  query ListCampaigns($input: ListCampaignsInput) {
    admin_listCampaigns(input: $input) {
      id
      body
      scheduledDateTime
      imageURL
      sent
      title
      sentDateTime
    }
  }
`

export const ADMIN_GET_LIBRARY_ITEM = gql`
  query GetLibraryItem($id: ID!) {
    admin_getLibraryItem(id: $id) {
      ...LibraryFragment
    }
  }
  ${libraryFragment}
`

export const VALIDATE_CLASS_SESSION_ID = gql`
  query ValidateClassSessionID($classSessionId: ID!) {
    validateClassSessionID(classSessionID: $classSessionId) {
      valid
    }
  }
`

export const LIST_VOD_FILTERS = gql`
  query ListVodFilters($category: LibraryItemCategoryEnum) {
    listVodFilters(input: { category: $category }) {
      bodyParts {
        name
        type
      }
      bodyPartsFilters {
        name
        type
      }
      equipment {
        name
        type
        image
      }
      equipmentFilters: equipmentFilters_v2 {
        name
        type
        image
      }
      labels {
        name
        type
      }
      types {
        name
        type
      }
      durations {
        name
        type
      }
      statuses {
        name
        type
      }
    }
  }
`

export const LIST_LIBRARY_ITEM_CATEGORIES = gql`
  query ListLibraryItemCategories(
    $input: GetLibraryItemCategoriesTotalInput
    $totalInput: GetLibraryItemCategoriesTotalInput
  ) {
    listLibraryItemCategories(input: $input) {
      name
      type
      verticalImage
      total(input: $totalInput)
    }
  }
`

export const COUNT_LIBRARY_ITEMS = gql`
  query CountLibraryItems($input: AdminListLibraryItemsInput) {
    admin_countLibraryItems(input: $input) {
      total
      audio
      video
      previouslyLive
    }
  }
`

export const ADMIN_LIST_CLASS_SESSIONS = gql`
  query ListClassSessions($input: AdminListClassSessionsInput!) {
    admin_listClassSessions(input: $input) {
      ...ClassSessionFragment
    }
  }
  ${classSessionFragment}
`

export const GET_CLASS_SESSION = gql`
  query GetClassSession($id: ID!) {
    getClassSession(id: $id) {
      id
      name
      startDateTime
      endDateTime
      location {
        id
        name
      }
      region {
        id
        name
      }
      instructors {
        id
        firstName
        lastName
        fullName
      }
    }
  }
`

export const LIST_USERS = gql`
  query ListUsers($input: AdminListUsersInput!) {
    admin_listUsers(input: $input) {
      ...UserFieldsFragment
    }
  }
`

export const LIST_REGIONS = gql`
  query ListRegions($input: ListRegionsInput) {
    listRegions(input: $input) {
      id
      name
    }
  }
`

export const LIST_STUDIOS = gql`
  query ListStudios {
    listStudios {
      name
      regions {
        id
        name
        locations {
          id
          name
        }
      }
    }
  }
`

export const LIST_EMPLOYEES = gql`
  query ListEmployees($input: ListEmployeesInput!) {
    admin_listEmployees(input: $input) {
      ...EmployeeFieldsFragment
    }
  }
  ${employeeFieldsFragment}
`

export const GET_EMPLOYEE = gql`
  query GetEmployee($id: ID!) {
    admin_getEmployee(id: $id) {
      ...EmployeeFieldsFragment
      instagramHandle
      spotifyLink
      phoneNumber
      employeeID
      username
    }
  }
  ${employeeFieldsFragment}
`

export const LIST_SHOPIFY_PICKS = gql`
  query ListShopifyPicks($employeeID: ID!) {
    admin_listEmployeeShopifyPicks(employeeID: $employeeID) {
      id
      name
      url
      pictureUrl
    }
  }
`

export const LIST_INSTRUCTOR_BANNERS = gql`
  query ListInstructorBanners($input: ListInstructorBannersInput) {
    admin_listInstructorBanners(input: $input) {
      ...InstructorBannerFragment
    }
  }
  ${instructorBannerFragment}
`

export const GET_INSTRUCTOR_BANNERS = gql`
  query GetInstructorBanner($id: ID!) {
    admin_getInstructorBanner(id: $id) {
      ...InstructorBannerFragment
    }
  }
`

export const ADMIN_LIST_STUDIOS = gql`
  query AdminListStudios($input: AdminListStudiosInput) {
    admin_listStudios(input: $input) {
      ...StudioFragment
    }
  }
  ${StudioFragment}
`

export const ADMIN_GET_STUDIO = gql`
  query AdminGetStudio($id: ID!) {
    admin_getStudio(id: $id) {
      ...StudioFragment
    }
  }
`
