export const theme = {
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
  color: {
    black: '#000000',
    blackTransparent: 'rgba(0, 0, 0, 0.05)',
    grey: '#808080',
    greyLight: '#F5F5F5',
    greyMedium: '#D9D9D9',
    primary: '#D61638',
    primaryTransparent: 'rgba(214, 22, 56, 0.05)',
    success: '#009C22',
    successTransparent: 'rgba(0, 156, 34, 0.05)',
    warning: '#E46E00',
    warningTransparent: 'rgba(228, 110, 0, 0.05)',
    white: '#FFFFFF',
    whiteTransparent: 'rgba(255, 255, 255, 0.2)',
  },
  layout: {
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    spacing: {
      xxs: '0.1rem',
      xs: '0.5rem',
      sm: '1rem',
      md: '2rem',
      lg: '4rem',
      xl: '8rem',
      xxl: '10rem',
      xxxl: '16rem',
    },
    /* eslint-enable sort-keys-fix/sort-keys-fix */
  },
  sideMenuWidth: '35rem',
}
