import { useEffect } from 'react'

import { LibraryModalInputs, PublishInputs } from '../types'

import { processDataBeforeSubmit } from './helpers'
import { LibraryForm } from './library-form'

import {
  LibraryItemTypeEnum,
  useAdminCreateLibraryItemMutation,
  useAdminDeleteLibraryItemMutation,
  useAdminUpdateLibraryItemMutation,
  useGetLibraryItemLazyQuery,
  useLibraryFormOptionsQuery,
} from '~/@types/schema'
import { SelectOption } from '~/@types/models'
import { FetchLayoutWrapper } from '~/components/fetch-layout-wrapper'
import { useAppContext } from '~/context/app-context'
import { useGenericOnError } from '~/utils/hooks/use-generic-on-error'

const emptySelectOption: SelectOption = {
  label: '',
  value: '',
}

export const defaultValues = {
  bodyParts: [],
  duration: emptySelectOption,
  equipment: [],
  instructor: emptySelectOption,
  labels: [],
  parentCategory: emptySelectOption,
}

type Props = {
  isMinimized?: boolean
  libraryID?: string
  libraryItemType: LibraryItemTypeEnum
  onClose: () => void
  onMinimize: () => void
}

export const LibraryModal = ({
  isMinimized,
  libraryID,
  libraryItemType,
  onClose,
  onMinimize,
}: Props) => {
  const { addNotification } = useAppContext()

  const typeLabel =
    libraryItemType === LibraryItemTypeEnum.Audio ? 'Audio' : 'Video'

  const [
    getLibraryItem,
    { data: getLibraryItemData, loading: getLibraryItemLoading },
  ] = useGetLibraryItemLazyQuery({
    variables: {
      id: libraryID,
    },
  })

  useEffect(() => {
    if (libraryID) {
      getLibraryItem()
    }
  }, [libraryID, getLibraryItem])

  const { onError } = useGenericOnError()

  const [createLibraryItem, { loading: createLibraryItemLoading }] =
    useAdminCreateLibraryItemMutation({
      onCompleted: () => {
        addNotification({
          text: 'Library item created',
        })

        onClose()
      },
      onError,
    })

  const [updateLibraryItem, { loading: updateLibraryItemLoading }] =
    useAdminUpdateLibraryItemMutation({
      onCompleted: () => {
        addNotification({
          text: 'Library item updated',
        })

        onClose()
      },
      onError,
    })

  const [deleteLibraryItem, { loading: isDeleting }] =
    useAdminDeleteLibraryItemMutation({
      onCompleted: () => {
        addNotification({
          text: 'Library item deleted',
        })

        onClose()
      },
      variables: { input: { id: libraryID } },
      update(cache) {
        const normalizedId = cache.identify({
          id: libraryID,
          __typename: 'Library',
        })

        cache.evict({ id: normalizedId })
        cache.gc()
      },
      onError,
    })

  const onSubmit = (inputs: LibraryModalInputs & PublishInputs): void => {
    const data = processDataBeforeSubmit(inputs, libraryItemType)

    try {
      if (libraryID) {
        void updateLibraryItem({
          variables: {
            input: {
              ...data,
              id: libraryID,
            },
          },
        })
      } else {
        void createLibraryItem({ variables: { input: data } })
      }
    } catch (e) {
      onError(e)
    }
  }

  const {
    data: libraryFormOptionsData,
    error: libraryFormOptionsError,
    loading: libraryFormOptionsLoading,
  } = useLibraryFormOptionsQuery()

  return (
    <FetchLayoutWrapper
      error={libraryFormOptionsError}
      fixedLoader
      loading={
        libraryFormOptionsLoading ||
        getLibraryItemLoading ||
        (!getLibraryItemData && !!libraryID)
      }
    >
      <LibraryForm
        data={getLibraryItemData?.admin_getLibraryItem}
        isMinimized={isMinimized}
        onClose={onClose}
        onMinimize={onMinimize}
        onSubmit={onSubmit}
        libraryItemType={libraryItemType}
        formOptions={libraryFormOptionsData}
        isSubmitting={updateLibraryItemLoading || createLibraryItemLoading}
        onDelete={deleteLibraryItem}
        isDeleting={isDeleting}
        modalTitle={
          libraryID ? `Edit ${typeLabel} Item` : `Create ${typeLabel} Item`
        }
      />
    </FetchLayoutWrapper>
  )
}
