import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconLibrary: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path
        d="M3 4h4v16H3zM8 4h4v16H8zM13 4.8l3.9-1L21 19.2l-3.9 1z"
        fill="currentColor"
      />
    </Svg>
  )
}
