import styled, { css, keyframes } from 'styled-components'

import { NotificationType } from './types'

import { theme } from '~/styles/theme'
import { fontSize } from '~/styles/typography'

export const Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: ${theme.layout.spacing.sm};
  transform: translateX(-10%);
  margin-bottom: ${theme.layout.spacing.md};
  pointer-events: none;
  text-align: center;
  z-index: 10000;
`

const animateIn = keyframes`
  from {
    transform: translateX(50%);
    opacity: 0;
  }

  10% {
    transform: none;
    opacity: 1;
  }

  90% {
    transform: none;
    opacity: 1;
  }

  to {
    transform: translateY(-50%);
    opacity: 0;
  }
`

export const ItemContainer = styled.div<Partial<NotificationType>>`
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.color.white};
  font-size: ${fontSize.regular};
  padding: ${theme.layout.spacing.md};
  margin-top: ${theme.layout.spacing.sm};
  pointer-events: auto;
  animation: 3.5s ${animateIn} forwards;
  backdrop-filter: blur(24px);

  ${(props) => {
    return (
      props.type === 'error' &&
      css`
        color: ${theme.color.primary};
      `
    )
  }}

  ${(props) => {
    return (
      props.type === 'success' &&
      css`
        color: ${theme.color.success};
      `
    )
  }}
`
