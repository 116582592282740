import {
  ApolloClient,
  ApolloLink,
  FieldPolicy,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  fromPromise,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { useMemo } from 'react'

import { config } from '../config'

import { REFRESH_TOKEN } from './mutations'
import { TokenManager } from './token-manager'

import {
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from '~/@types/schema'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

const getNewToken = async (tokenManager: TokenManager, refreshToken) => {
  const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo(
    tokenManager,
    null
  )

  const response = await apolloClient.mutate<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >({
    mutation: REFRESH_TOKEN,
    variables: { refreshToken },
  })

  const { session } = response.data.refreshToken

  tokenManager.setAccessToken(session.accessToken)
  tokenManager.setRefreshToken(session.refreshToken)

  return session.accessToken
}

function createApolloClient(
  tokenManager: TokenManager
): ApolloClient<NormalizedCacheObject> {
  const pagination: FieldPolicy = {
    keyArgs: (args) => {
      return JSON.stringify(args.input?.filter)
    },
    merge: (existing = [], incoming, { args }) => {
      // console.log('merge', existing, incoming, args)
      if (args.input?.page === 1) {
        return incoming
      }

      return [...existing, ...incoming]
    },
  }

  const errorLink = onError(
    ({ forward, graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.extensions?.exception?.status) {
            case 401:
              {
                const { refreshToken } = tokenManager.get()

                if (refreshToken) {
                  return fromPromise(
                    getNewToken(tokenManager, refreshToken).catch((error) => {
                      console.log('[Get new token error]:', error)
                    })
                  ).flatMap((value) => {
                    const oldHeaders = operation.getContext().headers

                    operation.setContext({
                      headers: {
                        ...oldHeaders,
                        Authorization: `Bearer ${value}`,
                      },
                    })

                    return forward(operation)
                  })
                }
              }

              break

            default:
              console.log('[Error]:', err.extensions)
          }
        }
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        // if you would also like to retry automatically on
        // network errors, we recommend that you use
        // apollo-link-retry
      }
    }
  )

  const authLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext()
    const accessToken = tokenManager.get().accessToken

    if (accessToken && !context.headers?.['Authorization']) {
      operation.setContext({
        headers: {
          ...context.headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : ``,
        },
      })
    }

    return forward(operation).map((response) => {
      if (
        operation.operationName === 'SignIn' &&
        response.data?.signIn.user.isActiveAdmin
      ) {
        tokenManager.setAccessToken(response.data?.signIn.session.accessToken)
        tokenManager.setRefreshToken(response.data?.signIn.session.refreshToken)
      } else if (operation.operationName === 'RefreshToken') {
        tokenManager.setAccessToken(
          response.data?.refreshToken.session.accessToken
        )

        tokenManager.setRefreshToken(
          response.data?.refreshToken.session.refreshToken
        )
      }

      return response
    })
  })

  const httpLink = createHttpLink({
    uri: config.apiUrl,
  })

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            admin_listLibraryItems: pagination,
            admin_listResolvedReports: pagination,
            admin_listReports: pagination,
            admin_listClassSessions: pagination,
            admin_listUsers: pagination,
            admin_listEmployees: pagination,
            admin_listInstructorBanners: pagination,
            admin_listStudios: pagination,
          },
        },
      },
    }),

    link: ApolloLink.from([errorLink, authLink, httpLink]),
    ssrMode: typeof window === 'undefined',
  })
}

export function initializeApollo(
  tokenManager: TokenManager,
  initialState = null
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = createApolloClient(tokenManager)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  return _apolloClient
}

export function useApollo(
  initialState: NormalizedCacheObject,
  tokenManager: TokenManager
): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => {
    return initializeApollo(tokenManager, initialState)
  }, [initialState])

  return store
}

export function addApolloState(client, props) {
  if (props) {
    props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return props
}
