import { useEffect, useState } from 'react'

import { SpotifyPlaylist } from '~/@types/schema'
import { config } from '~/utils/config'
import { parseQueryString, spotifyRequest } from './utils'

const CLIENT_ID = '22e32a4af6f849f38249dc4ac8b874f1'
const SCOPES = 'user-read-private user-read-email'

export const useSpotify = () => {
  const [isAuthorized, setAuthorized] = useState(false)
  const [authStateString] = useState((Math.random() * 16).toString())
  // const accessToken = useRef('')
  // const refreshToken = useRef('')

  const handleLoginMessage = async (
    message: MessageEvent,
    cb: (t: string) => void
  ) => {
    const responseData = parseQueryString<{ state: string; code: string }>(
      message?.data
    )

    if (responseData?.state === authStateString) {
      const authResponse = await fetch('/api/spotify/auth', {
        method: 'POST',
        body: JSON.stringify({ code: responseData.code }),
      }).then(async (res) => await res.json())
      localStorage.setItem('spotifyAccessToken', authResponse.access_token)
      localStorage.setItem('spotifyRefreshToken', authResponse.refresh_token)
      setAuthorized(true)
      return cb(authResponse.access_token)
    }
  }

  const verifyToken = async () => {
    console.log('verifying token')
    const r = await spotifyRequest('/me')

    if (!r.ok) {
      throw new Error(r.statusText)
    }

    return r
  }

  const verifyAuthorization = () => {
    if (localStorage.getItem('spotifyAccessToken')) {
      // Access token already exists, verify if it's valid
      console.log('existing access token found')
      verifyToken()
        .then(() => setAuthorized(true))
        .catch(() => {
          console.log('verifyAuthorization not authorized')
        })
    }
  }

  const getToken = async () =>
    await new Promise((resolve, reject) => {
      const callbackUrl = encodeURIComponent(
        `${config.rootUrl}/spotify-callback`
      )
      const strWindowFeatures =
        'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

      const openAuthWindow = () => {
        console.log('openAuthWindow called')

        const listenerCallback = async (message: MessageEvent) =>
          await handleLoginMessage(message, resolve)

        window.addEventListener('message', listenerCallback, false)
        const authWindow = window.open(
          `https://accounts.spotify.com/en/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${callbackUrl}&state=${authStateString}&scope=${encodeURIComponent(
            SCOPES
          )}`,
          'Authorize Spotify',
          strWindowFeatures
        )

        if (authWindow) {
          authWindow.focus()
          authWindow.onclose = () => {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            window.removeEventListener('message', listenerCallback, false)

            if (!localStorage.getItem('spotifyAccessToken')) {
              reject(new Error('Authorization window closed'))
            }
          }
        }
      }

      // Access token does not exists, retrieve a new one
      console.log('access token not found')
      openAuthWindow()
    })

  const loadPlaylist = async (id: string): Promise<SpotifyPlaylist> => {
    const response = await spotifyRequest(`/playlists/${id}`)
    const playlist = await response.json()

    if (playlist?.error) {
      throw new Error(playlist.error.message)
    }

    return {
      playlistID: playlist.id,
      imageURL: playlist.images ? playlist.images[0]?.url : '',
      title: playlist.name,
    }
  }

  useEffect(() => {
    verifyAuthorization()
  }, [])

  return {
    isAuthorized,
    init: getToken,
    loadPlaylist,
  }
}
