import { FC, SVGAttributes } from 'react'
import { Svg } from '~/components/svg'

export const IconAppleMusic: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <Svg {...props} viewBox="0 0 24 24">
    <path
      d="M17.7599 1.91992H6.23992C3.85816 1.91992 1.91992 3.85768 1.91992 6.23992V17.7599C1.91992 20.1422 3.85816 22.0799 6.23992 22.0799H17.7599C20.1417 22.0799 22.0799 20.1422 22.0799 17.7599V6.23992C22.0799 3.85768 20.1417 1.91992 17.7599 1.91992ZM16.7999 12.9599V14.8799V14.89H16.799C16.7932 16.2086 15.7194 17.2799 14.3999 17.2799H14.1599C13.6862 17.2799 13.2321 17.0783 12.9138 16.727C12.5961 16.3761 12.441 15.9023 12.4881 15.4286C12.5735 14.5823 13.3377 13.9199 14.2286 13.9199H14.8799C15.4094 13.9199 15.8399 13.4894 15.8399 12.9599V8.25832L10.0799 9.33832V14.3999V16.3199C10.0799 17.6433 9.00328 18.7199 7.67992 18.7199H7.43992C6.96616 18.7199 6.51208 18.5183 6.19384 18.167C5.87608 17.8161 5.72104 17.3423 5.76808 16.8686C5.85352 16.0223 6.61768 15.3599 7.50856 15.3599H8.15992C8.68936 15.3599 9.11992 14.9294 9.11992 14.3999V7.36936C9.11992 6.90712 9.44968 6.51016 9.90376 6.42568L15.9474 5.29288C16.1582 5.25304 16.3751 5.30872 16.5398 5.446C16.7054 5.58328 16.7999 5.78536 16.7999 5.99992V12.9599Z"
      fill="currentColor"
    />
  </Svg>
)
