import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconArrowLeft: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path d="M19 12H5M12 5l-7 7 7 7" stroke="currentColor" strokeWidth="2" />
    </Svg>
  )
}
