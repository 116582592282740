import { StylesConfig } from 'react-select'
import styled, { css } from 'styled-components'

import { InputLabel } from '../input/styled'

import { theme } from '~/styles/theme'
import { font, fontSize } from '~/styles/typography'

export const customStyles: StylesConfig = {
  clearIndicator: (provided) => {
    return {
      ...provided,
      marginBottom: -20,

      '&:hover': {
        color: theme.color.primary,
        cursor: 'pointer',
      },
    }
  },
  control: (provided) => {
    return {
      ...provided,
      padding: 0,
      marginTop: -8,
      marginBottom: -8,
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent',

      '&:focus': {
        outline: 'none',
      },
    }
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    }
  },
  indicatorsContainer: (provided) => {
    return {
      ...provided,
      paddingBottom: theme.layout.spacing.md,
      paddingRight: theme.layout.spacing.md,
    }
  },
  menu: (provided) => {
    return {
      ...provided,
      borderRadius: 0,
    }
  },
  menuList: (provided) => {
    return {
      ...provided,
      maxHeight: '29rem',
      paddingBottom: 0,
      paddingTop: 0,
    }
  },
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    }
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      fontSize: fontSize.regular,
    }
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.color.primary,
        cursor: 'pointer',
      },
    }
  },
  option: (provided, state) => {
    const { isSelected } = state

    return {
      ...provided,
      '&:hover': {
        backgroundColor: theme.color.greyMedium,
        color: theme.color.black,
      },
      backgroundColor: isSelected ? theme.color.black : theme.color.white,
      padding: theme.layout.spacing.md,
      textTransform: 'initial',
      transition: 'all 0.2s ease',
    }
  },
  singleValue: (provided) => {
    return {
      ...provided,
    }
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: 0,
      marginLeft: -2,
      marginTop: -2,
      textTransform: 'initial',
    }
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      marginBottom: -37,
      marginLeft: -5,
      marginRight: -15,
      color: 'black',
      fontSize: '1.5em',
    }
  },
}

export const StyledSelect = styled(InputLabel)<{ $isSpotifySelect?: boolean }>`
  padding: 1.6rem 0 1.6rem ${theme.layout.spacing.md};
  font-weight: 400;

  > span:first-child {
    font-family: ${font.headings};
    color: black;
  }

  ${(props) =>
    props.$isSpotifySelect &&
    css`
      div[class$='multiValue'] {
        padding: 0.2rem 0.8rem;
        border-radius: 6px;
        background-color: #f9f9f9;

        &:first-child {
          margin-top: 1rem;
        }

        > div:first-child {
          flex: 1;
          padding: 0;
        }
      }
    `}
`
