import { useMemo, useState } from 'react'

type ProgressState = {
  [uploader: string]: number
}

export const useCompoundProgress = () => {
  const [progressState, setProgressState] = useState<ProgressState>({})

  const setProgress = (uploader: string) => (value: number) => {
    setProgressState((prev) => ({
      ...prev,
      [uploader]: value,
    }))
  }

  const setProgressDone = (uploader: string) => {
    setProgressState((prev) => ({
      ...prev,
      [uploader]: 101,
    }))
  }

  const progress = useMemo(() => {
    const uploaders = Object.values(progressState).filter(Boolean)

    const total = uploaders.reduce((sum, i) => sum + i, 0)
    const count = uploaders.length

    return total / count
  }, [progressState])

  return {
    setProgress,
    setProgressDone,
    progress,
  }
}
