import { FC } from 'react'

import { StyledBadge } from '~/components/badge/styled'
import { Props } from '~/components/badge/types'

export const Badge: FC<Props> = ({
  $type = 'default',
  children,
  hasIcon,
  variant = 'default',
  title,
}) => {
  return (
    <StyledBadge
      $type={$type}
      hasIcon={hasIcon}
      variant={variant}
      title={title}
    >
      {children}
    </StyledBadge>
  )
}
