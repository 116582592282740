import { Thumbnail } from './types'

export const getThumbnailSrc = (thumbnail: Thumbnail | string): string => {
  if (typeof thumbnail !== 'string' && thumbnail?.[0]) {
    return URL?.createObjectURL(thumbnail[0])
  }

  if (typeof thumbnail === 'string') {
    return thumbnail
  }

  return ''
}
