import { FC, useEffect, useRef, useState } from 'react'
import { LibraryItemTypeEnum } from '~/@types/schema'
import { ErrorMessage } from '~/components/error-message'
import { IconAttachment } from '~/components/icons/icon-attachment'
import { Progress } from '~/components/progress'
import { useTus } from '~/utils/hooks/use-tus'
import { UploaderWrapper, Text } from './styled'

type Props = {
  accept: string
  children?: string
  error?: string
  onFinish: (streamId: string, fileName: string) => void
  onProgress?: (progress: number) => void
  onStart?: (fileName: string) => void
}

export const Uploader: FC<Props> = ({
  accept,
  children = 'Add file',
  error,
  onFinish,
  onProgress,
  onStart,
}) => {
  const timeout = useRef(0)
  const [fileList, setFileList] = useState<FileList>()
  const selectedFile = fileList ? fileList[0] : null

  const { progress } = useTus({
    file: fileList,
    libraryItemType: LibraryItemTypeEnum.Video,
    onFinish,
  })

  useEffect(() => {
    if (onProgress) {
      const timestamp = Date.now()

      if (timestamp - timeout.current > 500) {
        timeout.current = timestamp
        onProgress(progress)
      }
    }
  }, [progress])

  const handleStart = (files: FileList) => {
    setFileList(files)
    onStart && onStart(files[0]?.name)
  }

  return (
    <>
      <UploaderWrapper error={error}>
        <IconAttachment />
        <div>
          {selectedFile ? fileList[0]?.name : children}
          <Text>Add file or drop file here</Text>
        </div>
        <input
          accept={accept}
          aria-label="upload"
          type="file"
          onChange={(evt) => {
            handleStart(evt.target.files)
          }}
        />
        <Progress value={progress} indefinite={progress === 100} />
      </UploaderWrapper>
      <ErrorMessage>
        {progress > 0 && progress < 100 && error
          ? 'Please wait for the upload to finish'
          : error}
      </ErrorMessage>
    </>
  )
}
