import { FC } from 'react'
import styled from 'styled-components'

import { VersionBadge } from '../badge/version-badge'
import { Main } from '../main'
import { SideMenu } from '../side-menu'

const LayoutWrapper = styled.div`
  min-height: 100vh;
  display: flex;
`

export const Layout: FC = ({ children }) => {
  return (
    <LayoutWrapper>
      <SideMenu />
      <Main>{children}</Main>
      <VersionBadge />
    </LayoutWrapper>
  )
}
