import { FC } from 'react'
import styled from 'styled-components'

import { theme } from '~/styles/theme'
import { fontSize } from '~/styles/typography'
import { config } from '~/utils/config'

const VersionBadgeStyled = styled.div`
  position: fixed;
  bottom: ${theme.layout.spacing.md};
  right: ${theme.layout.spacing.md};
  font-weight: 700;
  font-size: ${fontSize.micro};
  background-color: ${theme.color.greyMedium};
  padding: ${theme.layout.spacing.sm} ${theme.layout.spacing.md};
`

export const VersionBadge: FC = () => {
  return (
    <VersionBadgeStyled>
      v. {(config.version ?? '').substring(0, 8)} ({config.environment})
    </VersionBadgeStyled>
  )
}
