import { FC } from 'react'

import { ProgressWrapper } from './styled'

type Props = {
  indefinite?: boolean
  value?: number
}

export const Progress: FC<Props> = ({ indefinite, value }) => {
  return (
    <ProgressWrapper indefinite={indefinite} isVisible={!!value}>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <span style={{ width: `${value}%` }}></span>
    </ProgressWrapper>
  )
}
