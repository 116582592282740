import * as FullStory from '@fullstory/browser'
import SentryFullStory from '@sentry/fullstory'
import { CaptureConsole, RewriteFrames } from '@sentry/integrations'
import * as Sentry from '@sentry/node'

import { config } from './config'

if (process.env.NEXT_IS_SERVER === 'false') {
  FullStory.init({ orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID })
}

export const init = (): void => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = []

    if (
      process.env.NEXT_IS_SERVER === 'true' &&
      process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame.filename.replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
              'app:///'
            )

            frame.filename = frame.filename.replace('.next', '_next')

            return frame
          },
        })
      )
    }

    if (process.env.NEXT_IS_SERVER === 'false') {
      integrations.push(
        new SentryFullStory(process.env.NEXT_PUBLIC_SENTRY_ORG_SLUG)
      )
    }

    integrations.push(
      new CaptureConsole({
        levels: ['error'],
      })
    )

    Sentry.init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      enabled: process.env.NODE_ENV === 'production',
      environment: config.environment,
      integrations,
      normalizeDepth: 10,
      release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    })
  }
}
