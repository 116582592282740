import { SideMenuData } from './types'

import { IconAdmins } from '~/components/icons/icon-admins'
import { IconContentManagement } from '~/components/icons/icon-content-management'
import { IconLibrary } from '~/components/icons/icon-library'
import { IconStats } from '~/components/icons/icon-stats'
import { IconLiveClass } from '../icons/icon-live-class'
import { IconUsers } from '~/components/icons/icon-users'
import { IconInstructors } from '../icons/icon-instructors'
import { IconManagers } from '../icons/icon-managers'
import { IconStudio } from '../icons/icon-studio'
import { IconBanners } from '../icons/icon-banners'
import { config } from '~/utils/config'

export const sideMenuData = {
  'Barry’s X': [
    {
      icon: <IconStats />,
      href: '/stats',
      panelName: 'Stats',
    },
    {
      icon: <IconLibrary />,
      panelLink: [
        { content: 'Audio', href: '/library/audio' },
        { content: 'Video', href: '/library/video' },
        { content: 'Previously Live', href: '/library/previously-live' },
      ],
      panelName: 'Library',
    },
    {
      icon: <IconContentManagement />,
      panelLink: [
        {
          content: 'On Demand',
          href: '/content-manager/on-demand',
        },
        {
          content: 'Homepage Banners',
          href: '/content-manager/homepage-banners',
        },
        {
          content: 'Push Notifications',
          href: '/content-manager/push-notifications',
        },
      ],
      panelName: 'Content Manager',
    },
    {
      icon: <IconUsers />,
      panelLink: [
        {
          content: 'List of Users',
          href: '/users',
        },
        {
          content: 'Reported Users',
          href: '/users/reports',
        },
        {
          content: 'Solved Issues',
          href: '/users/solved-issues',
        },
      ],
      panelName: 'Users',
    },
    {
      href: '/admins',
      icon: <IconAdmins />,
      panelName: 'Admins',
    },
    ...(config.liveClassesVisible
      ? [
          {
            href: '/live-classes',
            icon: <IconLiveClass />,
            panelName: 'Live Classes',
          },
        ]
      : []),
  ],
  'Instructor Websites': [
    {
      href: '/instructor-websites/instructors',
      icon: <IconInstructors />,
      panelName: 'Instructors',
    },
    {
      href: '/instructor-websites/managers',
      icon: <IconManagers />,
      panelName: 'Managers',
    },
    {
      href: '/instructor-websites/studios',
      icon: <IconStudio />,
      panelName: 'Studios',
    },
    {
      href: '/instructor-websites/banners',
      icon: <IconBanners />,
      panelName: 'Banners',
    },
  ],
}
