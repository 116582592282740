import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'

import { Wrapper, Group, GroupItem } from './styled'

import { LibraryItemStatusEnum } from '~/@types/schema'
import { ButtonWithLoading } from '~/components/button/with-loading'
import { Input } from '~/components/input'
import { RadioInput } from '~/components/radio-input'
import { Textarea } from '~/components/textarea'
import { PublishInputs } from '~/containers/library/types'
import { publishSchema } from '../../../schema'

type Props = {
  onSubmit: (inputs: PublishInputs) => void
  isSubmitting: boolean
  initialFormData: PublishInputs
}

export const PublishOptions = ({
  onSubmit,
  isSubmitting,
  initialFormData,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method -- see more: https://github.com/react-hook-form/react-hook-form/issues/2887
  const { handleSubmit, register, watch, errors, control } =
    useForm<PublishInputs>({
      defaultValues: initialFormData,
      resolver: yupResolver(publishSchema),
    })

  const { status } = watch()

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Group>
          <GroupItem>
            <RadioInput
              name="status"
              ref={register}
              value={LibraryItemStatusEnum.Published}
            />{' '}
            Publish now
          </GroupItem>
          <GroupItem>
            <RadioInput
              name="status"
              ref={register}
              value={LibraryItemStatusEnum.Scheduled}
            />{' '}
            Schedule
            {status === LibraryItemStatusEnum.Scheduled && (
              <Input
                type="datetime-local"
                label="Choose date & time (PST)"
                name="statusEffectiveSince"
                register={register}
                error={errors.statusEffectiveSince?.message}
                defaultValue={initialFormData.statusEffectiveSince}
              />
            )}
          </GroupItem>
          <GroupItem>
            <RadioInput
              name="status"
              ref={register}
              value={LibraryItemStatusEnum.Hidden}
            />{' '}
            Hide
            {status === LibraryItemStatusEnum.Hidden && (
              <Controller
                name="statusComment"
                control={control}
                defaultValue=""
                render={(props) => (
                  <Textarea
                    label="Why it's hidden (optional)"
                    placeholder="Type"
                    charLimit={255}
                    rows={1}
                    {...props}
                  />
                )}
              />
            )}
          </GroupItem>
        </Group>
        <ButtonWithLoading
          type="submit"
          variant="primary"
          fullWidth
          isLoading={isSubmitting}
        >
          {status === LibraryItemStatusEnum.Published && 'Publish now'}
          {status === LibraryItemStatusEnum.Scheduled && 'Schedule'}
          {status === LibraryItemStatusEnum.Hidden && 'Save & hide'}
        </ButtonWithLoading>
      </form>
    </Wrapper>
  )
}
