import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconLogOut: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="M5 3H4v18h13v-3h-2v1H6V5h9v1h2V3H5z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path d="M10 12h11M17 16l4-4-4-4" stroke="currentColor" strokeWidth="2" />
    </Svg>
  )
}
