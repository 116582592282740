import styled from 'styled-components'

import { InputLabel } from '../input/styled'

import { font, fontSize } from '~/styles/typography'

export const StyledTextarea = styled(InputLabel)`
  textarea {
    resize: none;
    font-family: ${font.base};
    font-size: ${fontSize.regular};
    outline: none;
    border: none;
  }
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
