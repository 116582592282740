import styled, { css } from 'styled-components'

import { theme } from '~/styles/theme'

// eslint-disable-next-line arrow-body-style
export const customReactModalStyles = (isMinimized?: boolean) => ({
  content: {
    background: theme.color.white,
    border: 'none',
    borderRadius: 'none',
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    maxHeight: '90vh',
    maxWidth: '100rem',
    outline: 'none',
    overflow: 'auto',
    padding: `${theme.layout.spacing.xl} ${theme.layout.spacing.xxl}`,
    position: 'relative',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    ...(isMinimized ? { display: 'none' } : null),
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 110,
    ...(isMinimized ? { display: 'none' } : null),
  },
})

// eslint-disable-next-line arrow-body-style
export const customReactModalStylesCondensed = (
  isMinimized?: boolean,
  v2?: boolean
) => ({
  ...customReactModalStyles(isMinimized),
  content: {
    ...customReactModalStyles(isMinimized).content,
    maxWidth: '80vw',
    padding: v2 ? `7rem 11rem` : `${theme.layout.spacing.lg}`,
  },
})

export const CloseButton = styled.button`
  position: absolute;
  top: ${theme.layout.spacing.lg};
  right: ${theme.layout.spacing.lg};
  font-size: 2.4rem;
  transition: color 0.2s;
  z-index: 10;

  &:hover,
  &:active {
    color: ${theme.color.primary};
  }
`

export const MinimizeButton = styled(CloseButton)`
  margin-right: 4rem;
`

export const ModalHeader = styled.h2`
  margin-bottom: ${theme.layout.spacing.lg};
`

export const ContentWrapper = styled.div<{
  isMinimized?: boolean
  v2?: boolean
}>`
  display: ${(props) => {
    return props.isMinimized ? 'none' : 'contents'
  }};

  ${(props) =>
    props.v2 &&
    css`
      td:first-child > div,
      th:first-child {
        padding-left: 0;
      }
      td:last-child > div,
      th:last-child {
        padding-right: 0;
      }

      ${ModalHeader} {
        font-size: 3.2rem;
      }
    `}
`

export const IconBackButton = styled.button`
  display: block;
  position: absolute;
  top: 4rem;
  left: 4rem;
  font: inherit;
  font-size: 2.4rem;
  border: none;
  background: none;
`
