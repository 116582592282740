import styled, { css } from 'styled-components'

import { buttonTranstion } from '~/components/button'
import { theme } from '~/styles/theme'

export const MinimizedModalContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 1em 1.5em 1em 1em;
  margin-right: 1.4rem;
  max-width: 30rem;
  font-size: 1.2rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 8px -3px rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 0;

  > div {
    overflow: hidden;
  }
`

export const Title = styled.p`
  margin: 0;
  grid-area: title;
  font-size: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Thumbnail = styled.img`
  width: 3.5em;
  height: 3.5em;
  flex-shrink: 0;
  margin-right: 0.8em;
  background-color: #f6f6f6;
`

export const Description = styled.p`
  margin: 0;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Progress = styled.span<{ isFinished: boolean }>`
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  bottom: 3px;
  right: 3px;
  z-index: -1;

  --progress-color: ${theme.color.primary};

  ${(props) => {
    return (
      props.isFinished &&
      css`
        --progress-color: #abce35;
      `
    )
  }}

  > span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 100%;
    transition: width 1s linear;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: border-color 0.3s, background-color 0.3s;
      width: 100%;
    }

    &::before {
      border-bottom: 3px solid var(--progress-color);
    }

    &::after {
      height: 100%;
      opacity: 0.07;
      background-color: var(--progress-color);
    }
  }
`

export const ActiveArea = styled.button`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  border: none;
  transition: background-color 0.3s;
  background: none;

  ${buttonTranstion}

  &::before {
    opacity: 0.08;
  }
`
