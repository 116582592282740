import { FC } from 'react'

import { SvgProps } from '../svg/types'

import { Svg } from '~/components/svg'

export const IconLoading: FC<SvgProps> = (props) => {
  return (
    <Svg {...props} viewBox="0 0 80 100">
      <g transform="matrix(1.2999999523162842,0,0,1.2999999523162842,12.200000762939453,8.055999755859375)">
        <g transform="matrix(1.3121843338012695,0,0,1.3121843338012695,20.885000228881836,56.013999938964844)">
          <path
            className="star"
            d=" M-1.8910000324249268,-1.8109999895095825 C-1.8910000324249268,-1.8109999895095825 -8.003000259399414,-1.8109999895095825 -8.003000259399414,-1.8109999895095825 C-8.003000259399414,-1.8109999895095825 -3.056999921798706,1.809999942779541 -3.056999921798706,1.809999942779541 C-3.056999921798706,1.809999942779541 -4.947000026702881,7.673999786376953 -4.947000026702881,7.673999786376953 C-4.947000026702881,7.673999786376953 -0.0010000000474974513,4.052999973297119 -0.0010000000474974513,4.052999973297119 C-0.0010000000474974513,4.052999973297119 4.945000171661377,7.673999786376953 4.945000171661377,7.673999786376953 C4.945000171661377,7.673999786376953 3.055000066757202,1.809999942779541 3.055000066757202,1.809999942779541 C3.055000066757202,1.809999942779541 8.003000259399414,-1.8109999895095825 8.003000259399414,-1.8109999895095825 C8.003000259399414,-1.8109999895095825 1.8890000581741333,-1.8109999895095825 1.8890000581741333,-1.8109999895095825 C1.8890000581741333,-1.8109999895095825 -0.0010000000474974513,-7.673999786376953 -0.0010000000474974513,-7.673999786376953 C-0.0010000000474974513,-7.673999786376953 -1.8910000324249268,-1.8109999895095825 -1.8910000324249268,-1.8109999895095825z"
            fill="rgb(0,0,0)"
          ></path>
        </g>
        <g transform="matrix(1,0,0,1,20.885000228881836,42.07899856567383)">
          <path
            className="chevron1"
            d=" M-20.885000228881836,1.4359999895095825 C-20.885000228881836,1.4359999895095825 -20.882999420166016,16.84600067138672 -20.882999420166016,16.84600067138672 C-20.882999420166016,16.84600067138672 -3.8519999980926514,1.687000036239624 -3.8519999980926514,1.687000036239624 C-3.8519999980926514,1.687000036239624 -0.15600000321865082,-1.6130000352859497 -0.15600000321865082,-1.6130000352859497 C-0.15600000321865082,-1.6130000352859497 3.9560000896453857,2.0290000438690186 3.9560000896453857,2.0290000438690186 C3.9560000896453857,2.0290000438690186 20.885000228881836,17.091999053955078 20.885000228881836,17.091999053955078 C20.885000228881836,17.091999053955078 20.881999969482422,1.6929999589920044 20.881999969482422,1.6929999589920044 C20.881999969482422,1.6929999589920044 9.520000457763672,-8.467000007629395 9.520000457763672,-8.467000007629395 C9.520000457763672,-8.467000007629395 7.078999996185303,-10.64799976348877 7.078999996185303,-10.64799976348877 C7.078999996185303,-10.64799976348877 -0.13300000131130219,-17.091999053955078 -0.13300000131130219,-17.091999053955078 C-0.13300000131130219,-17.091999053955078 -20.885000228881836,1.4359999895095825 -20.885000228881836,1.4359999895095825z"
            fill="rgb(0,0,0)"
          ></path>
        </g>
        <g transform="matrix(1,0,0,1,21.163999557495117,17.066999435424805)">
          <path
            className="chevron2"
            d=" M-0.13199999928474426,-17.06599998474121 C-0.13199999928474426,-17.06599998474121 -20.836000442504883,1.434000015258789 -20.836000442504883,1.434000015258789 C-20.836000442504883,1.434000015258789 -20.834999084472656,16.82200050354004 -20.834999084472656,16.82200050354004 C-20.834999084472656,16.82200050354004 -3.8450000286102295,1.6859999895095825 -3.8450000286102295,1.6859999895095825 C-3.8450000286102295,1.6859999895095825 -0.17299999296665192,-1.5959999561309814 -0.17299999296665192,-1.5959999561309814 C-0.17299999296665192,-1.5959999561309814 4.74399995803833,2.7360000610351562 4.74399995803833,2.7360000610351562 C4.74399995803833,2.7360000610351562 20.836000442504883,17.06599998474121 20.836000442504883,17.06599998474121 C20.836000442504883,17.06599998474121 20.83300018310547,1.690999984741211 20.83300018310547,1.690999984741211 C20.83300018310547,1.690999984741211 12.593000411987305,-5.682000160217285 12.593000411987305,-5.682000160217285 C12.593000411987305,-5.682000160217285 12.505000114440918,-5.760000228881836 12.505000114440918,-5.760000228881836 C12.505000114440918,-5.760000228881836 9.496999740600586,-8.45199966430664 9.496999740600586,-8.45199966430664 C9.496999740600586,-8.45199966430664 9.496999740600586,-8.451000213623047 9.496999740600586,-8.451000213623047 C9.496999740600586,-8.451000213623047 6.638000011444092,-11.008000373840332 6.638000011444092,-11.008000373840332 C6.638000011444092,-11.008000373840332 -0.13199999928474426,-17.06599998474121 -0.13199999928474426,-17.06599998474121z"
            fill="rgb(0,0,0)"
          ></path>
        </g>
      </g>
    </Svg>
  )
}
