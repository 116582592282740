import styled, { keyframes } from 'styled-components'

import { IconLoading } from '../icons/icon-loading'

import { mq } from '~/styles/mq'

const starPulse = keyframes`
  from {
    opacity: 0.2;
    transform: none;
  }
  40% {
    opacity: 1;
    transform: scale(1.1);
  }
  60% {
    transform: none;
  }
  80% {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
`

const chevron1Pulse = keyframes`
  20% {
    opacity: 0.2;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
`

const chevron2Pulse = keyframes`
  40% {
    opacity: 0.2;
  }
  80% {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoadingIcon = styled(IconLoading)`
  max-height: 10rem;
  font-size: 10rem;
  margin: auto;

  ${mq.large} {
    max-height: 100%;
  }

  .star,
  .chevron1,
  .chevron2 {
    opacity: 0.2;
    animation: 1s ${starPulse} infinite;
  }

  .chevron1 {
    animation-name: ${chevron1Pulse};
  }

  .chevron2 {
    animation-name: ${chevron2Pulse};
  }
`
