import styled, { css, keyframes } from 'styled-components'

const rotate = keyframes`
  to { transform: rotateZ(360deg); }
`

export const Loading = styled.svg`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin: -1rem;
  overflow: visible;
  animation: 1s ${rotate} linear infinite;
  font-size: 1.4rem;
  width: 2rem;
`

export const ContentWrapper = styled.span`
  display: block;
  position: relative;
`

export const ChildrenContainer = styled.span<{ isLoading?: boolean }>`
  ${(props) =>
    props.isLoading
      ? css`
          visibility: hidden;
        `
      : css`
          display: contents;
        `}
`
