import { gql } from '@apollo/client'

export const userFragment = gql`
  fragment UserFieldsFragment on User {
    id
    firstName
    lastName
    username
    pictureUrl
    email
    isBlocked
    hasVODAccess
    hasGrantedVODAccess
    bio
    birthDate
    marianaTekID
    role
    homeLocation {
      name
    }
  }
`

export const reportFieldsFragment = gql`
  fragment ReportFieldsFragment on Report {
    id
    reporting {
      firstName
      lastName
    }
    reported {
      ...UserFieldsFragment
    }
    createdAt
    reason {
      name
    }
    seen
    resolved
  }
`

export const employeeFieldsFragment = gql`
  fragment EmployeeFieldsFragment on Employee {
    id
    firstName
    lastName
    title
    pictureUrl
    isPublished
    region {
      id
      name
      country {
        code
      }
    }
    locationID
    location {
      id
      name
    }
    promoCode
    emailAddress
    tiktokUsername
    linkedinUsername
    shopifyPicksCount
    instagramHandle
    regionName
  }
`

export const instructorBannerFragment = gql`
  fragment InstructorBannerFragment on InstructorBanner {
    id
    createdAt
    name
    description
    CTAText
    imageURL
    link
    location {
      id
      name
    }
    order
    region {
      id
      name
      country {
        code
        name
      }
    }
  }
`

export const sessionFragment = gql`
  fragment SessionFragment on Auth {
    session {
      accessToken
      expiresAt
      refreshToken
    }
  }
`

export const instructorFragment = gql`
  fragment InstructorFragment on Instructor {
    firstName
    lastName
    fullName
    id
  }
`

export const instructorCacheFragment = gql`
  fragment InstructorCacheFragment on InstructorCache {
    firstName
    lastName
    id
  }
`

export const bannerFragment = gql`
  fragment BannerFragment on Banner {
    id
    createdAt
    name
    category {
      name
      type
    }
    CTAText
    imageURL
    link
    order
  }
`

export const StudioFragment = gql`
  fragment StudioFragment on AdminStudioResult {
    id
    name
    regionName
    email
    phoneNumber
    phoneNumberForTexting
  }
`

export const classSessionFragment = gql`
  fragment ClassSessionFragment on ClassSession {
    id
    name
    spotifyPlaylistIDs {
      playlistID
      imageURL
      title
    }
    appleMusicPlaylistIDs
    startDateTime
    endDateTime
    location {
      id
      name
    }
    region {
      id
      name
    }
    instructors {
      id
      firstName
      lastName
      fullName
    }
    equipment {
      name
      type
      image
    }
  }
`

export const libraryFragment = gql`
  fragment LibraryFragment on Library {
    id
    title
    description
    createdAt
    spotifyPlaylistIDs {
      playlistID
      imageURL
      title
    }
    appleMusicPlaylistIDs
    instructor {
      ...InstructorFragment
    }
    additionalInstructor {
      ...InstructorFragment
    }
    parentCategory {
      name
      type
    }
    bodyParts {
      name
      type
    }
    equipment: equipment_v2 {
      name
      type
    }
    labels {
      name
      type
    }
    duration {
      name
      type
    }
    imageURL
    classID
    classSessionID
    fileID
    instructorFileID
    audioID
    hasClosedCaptionsInstructorFile
    hasClosedCaptions
    status
    statusEffectiveSince
    statusComment
    type
  }
  ${instructorFragment}
`
