import { FC } from 'react'

import { Badge } from '../badge'
import { Spacer } from '../spacer'
import { Spinner } from '../spinner'

import { SpinnerWrapper } from './styled'
import { FetchLayoutWrapperProps } from './types'

import { globalErrorMessage } from '~/data'

export const FetchLayoutWrapper: FC<FetchLayoutWrapperProps> = ({
  children,
  error,
  fixedLoader,
  loading,
}) => {
  return error ? (
    <>
      <Spacer space={{ sm: 'lg' }} />
      <Badge $type="error">{globalErrorMessage}</Badge>
    </>
  ) : loading ? (
    fixedLoader ? (
      <SpinnerWrapper>
        <span>
          <Spinner />
        </span>
      </SpinnerWrapper>
    ) : (
      <Spinner />
    )
  ) : (
    <>{children}</>
  )
}
