import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconContentManagement: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path
        d="M21 20H3V4h7.4l2.1 2.6H21V20zm-4-10v2H7v-2h10zm-3 6v-2H7v2h7z"
        fill="currentColor"
      />
    </Svg>
  )
}
