import { FC } from 'react'

import { NotificationItem } from './item'
import { Wrapper } from './styled'
import { NotificationType } from './types'

type Props = {
  items: NotificationType[]
}

export const Notifications: FC<Props> = ({ items }) => (
  <Wrapper>
    {items.map((notification) => {
      return <NotificationItem key={notification.id} {...notification} />
    })}
  </Wrapper>
)
