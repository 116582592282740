import { FC, SVGAttributes } from 'react'
import { Svg } from '~/components/svg'

export const IconSpotify: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <Svg {...props} viewBox="0 0 24 24">
    <path
      d="M12.0043 0.951172C5.91452 0.951172 0.959961 5.90573 0.959961 11.9955C0.959961 18.0853 5.91452 23.0398 12.0043 23.0398C18.094 23.0398 23.0486 18.0857 23.0486 11.9955C23.0486 5.90525 18.094 0.951172 12.0043 0.951172ZM16.679 16.9597C16.5403 17.168 16.3118 17.2803 16.079 17.2803C15.9417 17.2803 15.803 17.2414 15.6801 17.1593C14.4931 16.3678 12.48 15.8398 10.8 15.8403C9.01724 15.8413 7.68092 16.279 7.66748 16.2833C7.29116 16.411 6.88268 16.2056 6.75692 15.8283C6.63116 15.451 6.83516 15.043 7.21244 14.9177C7.2758 14.8966 8.78636 14.4017 10.8 14.4008C12.48 14.3998 14.8166 14.8529 16.4793 15.9613C16.8105 16.1821 16.8998 16.6289 16.679 16.9597ZM18.1152 14.0125C17.9592 14.263 17.6899 14.4008 17.4148 14.4008C17.266 14.4008 17.1153 14.3609 16.98 14.276C14.8276 12.9349 12.6158 12.6512 10.7102 12.668C8.55884 12.6872 6.83852 13.0976 6.80924 13.1062C6.37388 13.2301 5.91596 12.9757 5.79164 12.5384C5.66732 12.1001 5.9222 11.6446 6.35996 11.5208C6.49292 11.4829 8.20988 11.0605 10.5604 11.0408C12.7036 11.023 15.3744 11.3336 17.8521 12.8773C18.2376 13.1173 18.3561 13.6261 18.1152 14.0125ZM19.548 10.5661C19.3689 10.8704 19.0483 11.0398 18.719 11.0398C18.5539 11.0398 18.3868 10.9971 18.2337 10.9078C15.7252 9.43517 12.6753 9.12269 10.5585 9.11981C10.5484 9.11981 10.5384 9.11981 10.5283 9.11981C7.96844 9.11981 5.99708 9.57005 5.9774 9.57485C5.45996 9.69341 4.94492 9.37373 4.82492 8.85725C4.70492 8.34125 5.02508 7.82573 5.54108 7.70525C5.62988 7.68461 7.73612 7.19981 10.5283 7.19981C10.5393 7.19981 10.5504 7.19981 10.5614 7.19981C12.9158 7.20317 16.3267 7.56125 19.2062 9.25181C19.6632 9.52061 19.8163 10.1091 19.548 10.5661Z"
      fill="currentColor"
    />
  </Svg>
)
