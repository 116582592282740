export const loadScripts = async (url: string, id: string) =>
  await new Promise<void>((resolve, reject) => {
    const scriptTag = document.getElementById(id)

    if (!scriptTag) {
      const script = document.createElement('script')

      script.id = id
      script.type = 'text/javascript'
      script.async = false
      script.defer = true
      script.src = url
      script.onload = () => resolve()
      script.onerror = (error: any) =>
        reject(new Error(`loadScript: ${error.message}`))

      document.head.appendChild(script)
    } else {
      resolve()
    }
  })

export const parseQueryString = <T extends object>(queryString: string) => {
  if (typeof queryString !== 'string') {
    return {} as T
  }

  return queryString
    .replace(/^\?/u, '')
    .split('&')
    .map((t) => t.split('='))
    .reduce(
      (acc, pair) => ({
        ...acc,
        [pair[0]]: pair[1],
      }),
      {}
    ) as T
}

export const spotifyRequest = async (
  url: string,
  method: RequestInit['method'] = 'GET',
  params: RequestInit = null
) => {
  const req = async () => {
    const token = localStorage.getItem('spotifyAccessToken')
    return await fetch(`https://api.spotify.com/v1${url}`, {
      ...params,
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  }

  try {
    let response = await req()
    console.log(url, 'response', response)

    if (
      response.status === 401 &&
      localStorage.getItem('spotifyRefreshToken')
    ) {
      console.log('trying to refresh token', response)
      const authResponse = await fetch('/api/spotify/refresh', {
        method: 'POST',
        body: JSON.stringify({
          refresh_token: localStorage.getItem('spotifyRefreshToken'),
        }),
      }).then(async (res) => await res.json())

      localStorage.setItem('spotifyAccessToken', authResponse.access_token)

      if (authResponse.refresh_token) {
        localStorage.setItem('spotifyRefreshToken', authResponse.refresh_token)
      }

      response = await req()
    }

    return response
  } catch (err) {
    console.log('error', err)
    throw err
  }
}

export const sanitizeSearchString = (input: string) =>
  input.match(/spotify\.com\/playlist\/(?<id>[a-zA-Z0-9]+)/u)?.groups.id ??
  input
