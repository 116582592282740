import { ChangeEventHandler, forwardRef } from 'react'

import { UploaderWrapper } from './styled'

import { GreyText } from '~/components/grey-text'
import { IconPicture } from '~/components/icons/icon-picture'
import { Spacer } from '~/components/spacer'

type Props = {
  disabled?: boolean
  error: string
  heading: string
  inputKey?: number // Used to reset file input's value
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const Uploader = forwardRef<HTMLInputElement, Props>(
  ({ disabled, error, heading, inputKey, onChange }, ref) => {
    return (
      <UploaderWrapper isDisabled={disabled} error={error}>
        <span>
          <IconPicture />
          <h3>{heading}</h3>
          {!disabled && (
            <>
              <Spacer space={{ sm: 'md' }} />
              <GreyText>PNG, JPG, min. 500 × 500 px</GreyText>
              <br />
              <GreyText>Max. size 2 MB</GreyText>
            </>
          )}
        </span>
        <input
          key={inputKey}
          accept="image/*"
          disabled={disabled}
          onChange={onChange}
          aria-label="upload"
          type="file"
          ref={ref}
        />
      </UploaderWrapper>
    )
  }
)
