import { FC, Children, useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

const Section = styled.section<{ visible: boolean }>`
  width: 100%;
  flex-shrink: 0;
  transition: margin-left 0.6s;
  padding: 8rem 10rem;

  ${(props) =>
    props.visible
      ? css`
          position: relative;
        `
      : css`
          position: absolute;
          visibility: hidden;
          opacity: 0;
        `}
`

export const Wrapper = styled.div<{ screenIndex?: number }>`
  display: flex;
  margin: -8rem -10rem;
  overflow: hidden;

  ${Section}:first-child {
    margin-left: ${(props) => (props.screenIndex > 0 ? -100 : 0)}%;
  }
`

const HOME_SCREEN = 0

export const ModalScreens: FC<{ screenIndex: number }> = ({
  children,
  screenIndex,
}) => {
  const [fromIndex, setFromIndex] = useState(HOME_SCREEN)

  useEffect(() => {
    if (screenIndex !== HOME_SCREEN) {
      setFromIndex(screenIndex)
    }
  }, [screenIndex])

  const wrapperRef = useRef<HTMLDivElement>()

  useEffect(() => {
    wrapperRef.current?.scrollIntoView()
  }, [screenIndex])

  return (
    <Wrapper screenIndex={screenIndex} ref={wrapperRef}>
      {Children.map(children, (child, index) => (
        <Section key={index} visible={[HOME_SCREEN, fromIndex].includes(index)}>
          {child}
        </Section>
      ))}
    </Wrapper>
  )
}
