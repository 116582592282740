import { FC } from 'react'

import { Layout } from '../layout'
import LoadingScreen from '../loading-screen'
import { User } from '~/@types/models'
import { useUserSession } from './useUserSession'

type Props = {
  user: User
  onUserChange: (user: User) => void
  onLogout: () => void
}

export const ComponentWrapper: FC<Props> = ({
  children,
  user,
  onUserChange,
  onLogout,
}) => {
  const { isLoginScreen, isLoading } = useUserSession({
    onUserChange,
    onLogout,
    user,
  })

  if ((!user && !isLoginScreen) || isLoading) {
    return <LoadingScreen />
  }

  return isLoginScreen ? <>{children}</> : <Layout>{children}</Layout>
}
