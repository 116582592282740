import styled from 'styled-components'
import { font } from '~/styles/typography'

export const SelectedLabel = styled.span`
  display: block;
  position: relative;
  font-weight: bold;
  margin: -5rem 0 3.2rem;
  padding: 2.4rem 3.2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);

  > button {
    all: unset;
    display: block;
    width: 100%;
    font-size: 2rem;
    font-family: ${font.headings};
    cursor: pointer;

    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 0.4em;
      height: 0.4em;
      margin: -0.6em 0.5em 0;
      border-bottom: 2px solid currentColor;
      border-right: 2px solid currentColor;
      transform: rotateZ(45deg);
    }
  }
`

export const Overlay = styled.button`
  all: unset;
  display: block;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`

export const Menu = styled.ul`
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 1.2rem 0;
  margin: -1.4rem 4rem;
  list-style: none;
  border: 1px solid rgba(255, 255, 255, 0.25);
  font-size: 1.6rem;
  line-height: 1.4;
  background-color: #1d1d20;
  z-index: 1100;
`

export const Item = styled.li`
  display: block;

  button {
    all: unset;
    display: block;
    width: 100%;
    padding: 1.2rem 2.4rem;
    box-sizing: border-box;
    transition: background-color 0.3s;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: #38383e;
    }
  }
`
