import { useState, ChangeEvent, forwardRef, useEffect } from 'react'

import { ErrorMessage } from '../error-message'
import { ErrorMessageWrapper } from '../error-message/styled'

import { StyledTextarea, Label } from './styled'
import { TextareaProps } from './types'

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      error,
      label,
      rows = 3,
      charLimit,
      onChange,
      value,
      hasNativeOnChange,
      ...props
    },
    ref
  ) => {
    const [text, setText] = useState(value || '')

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const val = event.target.value

      if (!charLimit || (charLimit && val.length <= charLimit)) {
        setText(event.target.value)
        onChange && onChange(event)
      }
    }

    useEffect(() => {
      value && setText(value)
    }, [value])

    return (
      <ErrorMessageWrapper>
        <StyledTextarea error={error}>
          <Label>
            {label}
            {charLimit && (
              <span>
                {text.length}/{charLimit}
              </span>
            )}
          </Label>
          <textarea
            {...props}
            {...(!hasNativeOnChange
              ? { onChange: handleChange, value: text }
              : null)}
            ref={ref}
            rows={rows}
          />
        </StyledTextarea>
        <ErrorMessage>{error}</ErrorMessage>
      </ErrorMessageWrapper>
    )
  }
)
