import styled, { css } from 'styled-components'

import { ProgressProps } from './types'

import { theme } from '~/styles/theme'

export const ThumbnailUploaderWrapper = styled.div`
  position: relative;
  min-width: 30rem;
`

export const UploaderWrapper = styled.label<{
  error: string
  isDisabled?: boolean
}>`
  border: 2px dashed;
  color: ${theme.color.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${theme.layout.spacing.md};

  svg {
    font-size: 6rem;
    margin-bottom: ${theme.layout.spacing.md};
  }

  span {
    text-align: center;
  }

  ${(props) => {
    return props.error
      ? css`
          color: ${theme.color.primary};
          border-color: ${theme.color.primary};
        `
      : css`
          color: ${theme.color.grey};
          border-color: ${theme.color.greyMedium};

          ${!props.isDisabled &&
          css`
            &:hover,
            &:focus {
              border-color: ${theme.color.black};
              color: ${theme.color.black};
            }
          `}
        `
  }}

  input {
    visibility: hidden;
    position: absolute;
  }

  &::after {
    content: '';
    padding-bottom: 100%;
  }
`

export const UploadedWrapper = styled.div`
  position: relative;
  padding-top: 100%;
  text-align: center;

  img {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const ResetButton = styled.button`
  position: absolute;
  right: ${theme.layout.spacing.md};
  top: ${theme.layout.spacing.md};
  background-color: ${theme.color.white};
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  z-index: 100;
  font-size: 2.4rem;

  &:hover,
  &:focus {
    opacity: 1;
  }
`

export const StyledBannerPreview = styled.div`
  position: absolute;
  top: 0;
  color: ${theme.color.white};
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${theme.layout.spacing.md};
  }

  h1 {
    text-align: center;
    width: 100%;
  }

  button {
    margin: 0 auto;
    backdrop-filter: blur(10px);
  }
`

export const ProgressWrapper = styled.div<{ show: boolean }>`
  border: 2px dashed ${theme.color.greyMedium};
  color: ${theme.color.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${theme.layout.spacing.md};

  & > div {
    width: 100%;
  }

  &::after {
    content: '';
    padding-bottom: 100%;
  }

  ${(props) => {
    return props.show
      ? null
      : css`
          visibility: hidden;
          position: absolute;
        `
  }}
`

export const ProgressOuter = styled.div`
  background-color: ${theme.color.greyMedium};
  width: 100%;
`

export const ProgressInner = styled.div<Omit<ProgressProps, 'state'>>`
  background-color: ${theme.color.black};
  width: ${(props) => {
    return `${props.progress}%`
  }};
  height: 4px;
  transition: width 0.4s;
`
