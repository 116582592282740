import styled from 'styled-components'

import { font } from '~/styles/typography'

export const Wrapper = styled.div`
  width: 32rem;
  margin: 0 auto;
`

export const Group = styled.div`
  margin-bottom: 2rem;
`

export const GroupItem = styled.div`
  display: block;
  padding: 2rem 0;
  font-family: ${font.base};
  font-weight: 700;

  &:not(:last-child) {
    border-bottom: 0.1rem solid #e6e6e9;
  }

  div {
    margin: 2rem 0 0;
  }
`
