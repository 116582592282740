import { FC } from 'react'

import { ResetButton, UploadedWrapper } from './styled'
import { UploadedProps } from './types'
import { getThumbnailSrc } from './utils'

import { IconBin } from '~/components/icons/icon-bin'

export const Uploaded: FC<UploadedProps> = ({
  handleThumbnailReset,
  thumbnail,
  thumbnailSrc,
}) => {
  const thumbnailUrl = thumbnailSrc || getThumbnailSrc(thumbnail)

  return (
    <UploadedWrapper>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img src={thumbnailUrl} />
      <ResetButton
        type="button"
        onClick={() => {
          URL.revokeObjectURL(thumbnailUrl)
          handleThumbnailReset()
        }}
      >
        <IconBin />
      </ResetButton>
    </UploadedWrapper>
  )
}
