import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconDropdownUp: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path d="M0 5l5-5 5 5H0z" fill="currentColor" />
    </Svg>
  )
}
