import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { User } from '~/@types/models'
import {
  useGetCurrentUserLazyQuery,
  useRefreshTokenMutation,
} from '~/@types/schema'
import { getAllCookies, parseJwt } from '~/utils/api/utils'
import { config } from '~/utils/config'
import { useGenericOnError } from '~/utils/hooks/use-generic-on-error'

type Props = {
  onUserChange: (user: User) => void
  onLogout: () => void
  user: User
}

export const useUserSession = ({ onUserChange, onLogout, user }: Props) => {
  const [{ accessToken, refreshToken, userID }, setTokens] = useState(
    getAllCookies()
  )
  const [isLoading, setIsLoading] = useState(true)
  const router = useRouter()

  const isLoginScreen = router.asPath === '/'

  const removeTokens = () => {
    setTokens({ accessToken: '', refreshToken: '', userID: '' })
    onLogout()
    setIsLoading(false)
  }

  const [getNewAccessToken] = useRefreshTokenMutation({
    onCompleted: (data) => {
      setTokens({
        ...data.refreshToken.session,
        userID: parseJwt(data.refreshToken.session.accessToken).userID,
      })
    },
    onError: removeTokens,
  })

  const handleRefreshToken = async () => {
    await getNewAccessToken({ variables: { refreshToken } })
    setIsLoading(false)
  }

  useEffect(() => {
    if (!refreshToken && !isLoginScreen) {
      // user is NOT authenticated
      router.push('/').finally(() => setIsLoading(false))
    } else if (refreshToken && isLoginScreen) {
      // user is authenticated and on login screen
      router.push(config.defaultPage).finally(() => setIsLoading(false))
    } else if (!refreshToken && isLoginScreen) {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!accessToken && refreshToken) {
      void handleRefreshToken()
    }
  }, [accessToken, refreshToken])

  const { onError } = useGenericOnError()

  const [getCurrentUser] = useGetCurrentUserLazyQuery({
    onCompleted: ({ getUser }) => onUserChange(getUser),
    onError,
  })

  // get user (user is not available in the context)
  useEffect(() => {
    if (!user && userID) {
      getCurrentUser({
        variables: {
          id: userID,
        },
      })
        .catch(async () => await router.push('/'))
        .finally(() => setIsLoading(false))
    }
  }, [user, userID])

  return { isLoginScreen, isLoading }
}
