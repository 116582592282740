import styled, { css } from 'styled-components'

import { theme } from '~/styles/theme'

export const GreyText = styled.span<{ capitalize?: boolean }>`
  color: ${theme.color.grey};
  text-align: left;
  display: block;
  list-style: none;

  ${(props) =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `}
`
