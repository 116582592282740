import { FC, useState } from 'react'

import { ErrorMessage } from '../error-message'
import { ErrorMessageWrapper } from '../error-message/styled'
import { IconEyeClosed } from '../icons/icon-eye-closed'
import { IconEyeOpen } from '../icons/icon-eye-open'

import { InputLabel, ToggleVisibility } from './styled'
import { InputProps } from './types'

export const Input: FC<InputProps> = ({
  error,
  label,
  light,
  register,
  type = 'text',
  ...props
}) => {
  const [internalType, setInternalType] = useState(type)

  return (
    <ErrorMessageWrapper>
      <InputLabel error={error} light={light} readOnly={props.readOnly}>
        <span>{label}</span>
        <input {...props} ref={register} type={internalType} />
        {type === 'password' && (
          <ToggleVisibility
            aria-label={
              internalType === 'password' ? 'Show password' : 'Hide password'
            }
            type="button"
            onClick={() => {
              if (internalType === 'password') {
                setInternalType('text')
              } else {
                setInternalType('password')
              }
            }}
          >
            {internalType === 'password' ? <IconEyeOpen /> : <IconEyeClosed />}
          </ToggleVisibility>
        )}
      </InputLabel>
      <ErrorMessage>{error}</ErrorMessage>
    </ErrorMessageWrapper>
  )
}
