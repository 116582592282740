import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import { ErrorMessage } from '~/components/error-message'
import { IconAttachment } from '~/components/icons/icon-attachment'
import { Progress } from '~/components/progress'
import { useAppContext } from '~/context/app-context'
import { getAllCookies } from '~/utils/api/utils'
import { config } from '~/utils/config'
import { UploaderWrapper, Text } from './styled'

type Props = {
  accept: string
  children?: string
  error?: string
  onFinish: (streamId: string, fileName: string) => void
  onProgress?: (progress: number) => void
  onStart?: (fileName: string) => void
  videoID: string
}

export const Uploader: FC<Props> = ({
  accept,
  children = 'Add file',
  error,
  onFinish,
  onProgress,
  onStart,
  videoID,
}) => {
  const { addNotification } = useAppContext()
  const [fileList, setFileList] = useState<FileList>()
  const [progress, setProgress] = useState(0)
  const selectedFile = fileList ? fileList[0] : null

  const handleStart = (files: FileList) => {
    setFileList(files)
    onStart && onStart(files[0]?.name)
  }

  const abort = (err: any) => {
    console.log('aborting', err)
    onFinish('', '')
    setProgress(0)
    addNotification(new Error('An error occured during file upload.'))
  }

  useEffect(() => {
    if (videoID && selectedFile) {
      const url = config.apiUrl.replace(
        'graphql',
        `api/videos/${videoID}/audio`
      )
      const requestBody = new FormData()
      requestBody.append('file', selectedFile)

      void axios
        .request({
          method: 'put',
          url,
          data: requestBody,
          headers: {
            Authorization: `Bearer ${getAllCookies().accessToken}`,
          },
          onUploadProgress: (p) => {
            const perc = (p.loaded / p.total) * 100
            setProgress(perc)

            if (typeof onProgress === 'function') {
              onProgress(perc)
            }
          },
        })
        .then(({ data }) => {
          onFinish(data.result?.uid, data.result?.label)
        })
        .catch(abort)
    }
  }, [videoID, selectedFile])

  return (
    <>
      <UploaderWrapper error={error}>
        <IconAttachment />
        <div>
          {selectedFile ? fileList[0]?.name : children}
          <Text>Add file or drop file here</Text>
        </div>
        <input
          accept={accept}
          aria-label="upload"
          type="file"
          onChange={(evt) => {
            handleStart(evt.target.files)
          }}
        />
        <Progress value={progress} indefinite={progress === 100} />
      </UploaderWrapper>
      <ErrorMessage>
        {progress > 0 && progress < 100 && error
          ? 'Please wait for the upload to finish'
          : error}
      </ErrorMessage>
    </>
  )
}
