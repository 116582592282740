import { FC, KeyboardEventHandler, useState } from 'react'
import ReactSelect from 'react-select/creatable'

import { ErrorMessage } from '../../error-message'
import { ErrorMessageWrapper } from '../../error-message/styled'
import {
  ClearIndicator,
  NullDropdownIndicator,
  MultiValueRemove,
  NullMenu,
} from '../common'
import { StyledSelect, customStyles } from '../styled'
import { SelectProps } from '../types'
import { OptionLabel } from './option-label'

export const AppleMusicSelect: FC<SelectProps> = ({
  error,
  isMulti,
  label,
  onChange,
  placeholder = 'Start typing',
  register,
  value,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')

  const handleCreate = (input: string) => {
    const matches = input.match(
      /apple\.com\/(?:[^/]+\/)?playlist\/(?:[^/]+\/)?(?<id>[a-z0-9.]+)/u
    )
    const newValue = matches?.groups.id || input
    onChange([...(value ?? []), { value: newValue, label: newValue }])
  }

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) {
      return
    }

    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ',':
        handleCreate(inputValue)
        setInputValue('')
        event.preventDefault()
    }
  }

  return (
    <ErrorMessageWrapper>
      <StyledSelect error={error} $isSpotifySelect>
        <span>{label}</span>
        <ReactSelect
          components={{
            ClearIndicator,
            DropdownIndicator: NullDropdownIndicator,
            Menu: NullMenu,
            MultiValueRemove,
          }}
          styles={{
            ...customStyles,
            multiValue: (base) => ({
              ...base,
              padding: 0,
              width: '100%',
              backgroundColor: 'white',
            }),
          }}
          isMulti={isMulti}
          placeholder={placeholder}
          ref={register}
          value={value}
          onChange={onChange}
          onCreateOption={handleCreate}
          formatOptionLabel={(option) => <OptionLabel id={option.value} />}
          onInputChange={(newValue) => setInputValue(newValue)}
          onKeyDown={handleKeyDown}
          inputValue={inputValue}
          {...props}
        />
      </StyledSelect>
      <ErrorMessage>{error}</ErrorMessage>
    </ErrorMessageWrapper>
  )
}
