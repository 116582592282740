import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconInstructors: FC<SvgProps> = (props) => {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM9 12.5455C9.55228 12.5455 10 12.0978 10 11.5455C10 10.9932 9.55228 10.5455 9 10.5455C8.44772 10.5455 8 10.9932 8 11.5455C8 12.0978 8.44772 12.5455 9 12.5455ZM16 11.5455C16 12.0978 15.5523 12.5455 15 12.5455C14.4477 12.5455 14 12.0978 14 11.5455C14 10.9932 14.4477 10.5455 15 10.5455C15.5523 10.5455 16 10.9932 16 11.5455ZM14 13.8787C14 14.9832 13.1046 15.8787 12 15.8787C10.8954 15.8787 10 14.9832 10 13.8787H14Z"
        fill="currentColor"
      />
    </Svg>
  )
}
