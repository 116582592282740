import { NextPageContext } from 'next'
import { destroyCookie, parseCookies, setCookie } from 'nookies'

export type TokenManager = {
  get: () => {
    accessToken: string
    refreshToken: string
  }
  setAccessToken: (value: string) => void
  setRefreshToken: (value: string) => void
  destroyTokens: () => void
}

const cookieSettings = {
  maxAge: 30 * 24 * 60 * 60,
  path: '/',
}

export const createTokenManager = (ctx?: NextPageContext) => {
  let { accessToken, refreshToken } = parseCookies(ctx)

  return {
    get: () => ({ accessToken, refreshToken }),
    setAccessToken: (value?: string) => {
      accessToken = value

      if (value) {
        setCookie(ctx, 'accessToken', value, cookieSettings)
      } else {
        destroyCookie(ctx, 'accessToken')
      }
    },
    setRefreshToken: (value?: string) => {
      refreshToken = value
      if (value) {
        setCookie(ctx, 'refreshToken', value, cookieSettings)
      } else {
        destroyCookie(ctx, 'refreshToken')
      }
    },
    destroyTokens: () => {
      accessToken = ''
      refreshToken = ''
      destroyCookie(ctx, 'accessToken')
      destroyCookie(ctx, 'refreshToken')
    },
  }
}
