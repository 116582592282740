import { FC } from 'react'

import { IconArrowLeft } from '../icons/icon-arrow-left'

import { IconBackButton } from './styled'

export const BackButton: FC<{ onClick?: () => void }> = (props) => (
  <IconBackButton {...props}>
    <IconArrowLeft />
  </IconBackButton>
)
