import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconStats: FC<SvgProps> = (props) => {
  return (
    <Svg {...props} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8 4.5H13.2L13.2 19.5H10.8L10.8 4.5ZM5.55005 19.5V12H7.95005V19.5H5.55005ZM16.05 8.25V19.5H18.45V8.25H16.05Z"
        fill="currentColor"
      />
    </Svg>
  )
}
