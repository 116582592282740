import { FC } from 'react'

import { LogOutButton, LogOutSectionWrapper } from './styled'

import { IconLogOut } from '~/components/icons/icon-log-out'
import { useAppContext } from '~/context/app-context'
import { useRouter } from 'next/router'

export const LogOutSection: FC = () => {
  const { logout, user } = useAppContext()
  const router = useRouter()

  const handleLogout = async () => {
    if (window.confirm('Are you sure you want to log out?')) {
      logout()
      await router.push('/')
    }
  }

  return (
    <LogOutSectionWrapper showUser={!!user}>
      <span>{`${user?.firstName} ${user?.lastName}`}</span>
      <LogOutButton aria-label="Log out" onClick={handleLogout}>
        <IconLogOut />
      </LogOutButton>
    </LogOutSectionWrapper>
  )
}
