import { parseCookies, setCookie } from 'nookies'

import { Scalars } from '~/@types/schema'

type AuthCookies = {
  accessToken: Scalars['String']
  expiresAt: Scalars['String']
  refreshToken: Scalars['String']
}

type UserCookies = {
  userID: Scalars['ID']
}

type AllCookies = Omit<AuthCookies, 'expiresAt'> & UserCookies

export const setAuthCookies = ({
  accessToken,
  expiresAt,
  refreshToken,
}: AuthCookies): void => {
  const expiresAtParsed = Date.parse(expiresAt)
  const now = Date.now()
  const maxAccessTokenAge = (expiresAtParsed - now) / 1000
  const maxRefreshTokenAge = 30 * 24 * 60 * 60 // 30 days

  setCookie(null, 'accessToken', accessToken, {
    maxAge: maxAccessTokenAge,
    path: '/',
  })

  setCookie(null, 'refreshToken', refreshToken, {
    maxAge: maxRefreshTokenAge,
    path: '/',
  })
}

export const parseJwt = (
  token: string
): { sessionId: string; userID: string } => {
  if (!token) {
    return { sessionId: '', userID: '' }
  }

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/gu, '+').replace(/_/gu, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const getAllCookies = (): AllCookies => {
  const { accessToken, refreshToken } = parseCookies()

  return { accessToken, refreshToken, userID: parseJwt(accessToken).userID }
}

export const destroyAllCookies = (): void => {
  const cookiesOptions = {
    maxAge: 0,
    path: '/',
  }

  setCookie(null, 'accessToken', '', cookiesOptions)
  setCookie(null, 'refreshToken', '', cookiesOptions)
  setCookie(null, 'userID', '', cookiesOptions)
}
