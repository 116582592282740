import styled, { css, keyframes } from 'styled-components'

import { theme } from '~/styles/theme'

const animation = keyframes`
  from { background-position: 100% center; }
  to   { background-position: 0 center; }
`

export const ProgressWrapper = styled.div<{
  indefinite?: boolean
  isVisible: boolean
}>`
  display: ${(props) => {
    return props.isVisible ? 'block' : 'none'
  }};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  > span {
    display: block;
    position: relative;
    height: 5px;
    transition: width 0.2s linear;
    background-color: ${theme.color.primary};
  }

  ${(props) =>
    /* eslint-disable-next-line arrow-body-style */
    props.indefinite &&
    css`
      > span {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 5px;
          z-index: 2;
          animation: 1s ${animation} linear infinite;
          background-size: 300% 100%;
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0) 33.333%,
            rgba(255, 255, 255, 0.4) 33.333%,
            rgba(255, 255, 255, 0.4) 66.666%,
            rgba(255, 255, 255, 0) 66.666%
          );
        }
      }
    `}
`
