import { FC } from 'react'

import { IconMenu } from '../icons/icon-menu'

import { StyledMobileMenu } from './styled'

export const MobileMenu: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <StyledMobileMenu>
      <button aria-label="menu" type="button" onClick={onClick}>
        <IconMenu />
      </button>
    </StyledMobileMenu>
  )
}
