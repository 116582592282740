import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconDropdownDown: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path d="M10 0L5 5 0 0h10z" fill="currentColor" />
    </Svg>
  )
}
