import { FC } from 'react'

import { ExpandIconProps } from './types'

import { theme } from '~/styles/theme'

export const ExpandIcon: FC<ExpandIconProps> = ({ isActive }) => {
  return (
    // eslint-disable-next-line react/forbid-dom-props
    <i style={{ marginRight: theme.layout.spacing.xs }}>
      <svg
        fill="none"
        height="10"
        // eslint-disable-next-line react/forbid-dom-props
        style={{
          transform: `rotate(${isActive ? 90 : 0}deg)`,
          transition: 'transform 0.2s',
          verticalAlign: '-0.125eem',
        }}
        viewBox="0 0 5 10"
        width="5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0L5 5L-2.46302e-08 10L0 0Z" fill="white" />
      </svg>
    </i>
  )
}
