import { forwardRef, useEffect, useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { SelectOption } from '~/@types/models'

import {
  LibraryItemCategoryEnum,
  useListEquipmentLazyQuery,
} from '~/@types/schema'
import { Select } from '~/components/select'
import { SelectProps } from '~/components/select/types'
import { normalizeSelectOptions } from '~/utils'

type Props = ControllerRenderProps &
  SelectProps & {
    category?: LibraryItemCategoryEnum
    error: string
  }

export const EquipmentSelect = forwardRef<any, Props>(
  ({ category, error, ...props }, register) => {
    const [options, setOptions] = useState<SelectOption[]>([])

    const [listEquipment, { loading }] = useListEquipmentLazyQuery({
      onCompleted: (data) => {
        props.onChange(
          props.value.filter((o) => {
            return data.listEquipment.find((e) => {
              return e.type === o.value
            })
          })
        )

        return setOptions(data.listEquipment.map(normalizeSelectOptions))
      },
    })

    useEffect(() => {
      listEquipment({
        variables: { input: { category } },
      })
    }, [category, listEquipment])

    return (
      <Select
        isMulti
        error={error}
        label="Equipment"
        options={options}
        register={register}
        isDisabled={loading}
        {...props}
      />
    )
  }
)
