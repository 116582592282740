import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconStudio: FC<SvgProps> = (props) => {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <path
        d="M12 3L4 9.32454V19.8421H9.95834V13.7763H14.0417V19.8421H20V9.32454L12 3Z"
        fill="currentColor"
      />
    </Svg>
  )
}
