import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconAdmins: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path
        d="M12 3l2.6 5.4 6 .8-4.3 4.2 1 5.9-5.3-2.8-5.3 2.8 1-6-4.3-4 6-1L12 3z"
        fill="currentColor"
      />
    </Svg>
  )
}
