import { createContext, useContext } from 'react'

import { AppContextType } from '~/@types/models'

export const AppContext = createContext<AppContextType | null>(null)

AppContext.displayName = 'AppContext'

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext)

  return context
}
