import { FC } from 'react'
import ReactSelect from 'react-select'

import { ErrorMessage } from '../error-message'
import { ErrorMessageWrapper } from '../error-message/styled'

import { ClearIndicator, DropdownIndicator, MultiValueRemove } from './common'
import { StyledSelect, customStyles } from './styled'
import { SelectProps } from './types'

export const Select: FC<SelectProps> = ({
  error,
  isMulti,
  isSearchable = false,
  label,
  onChange,
  options,
  placeholder = 'Select',
  register,
  value,
  ...props
}) => {
  return (
    <ErrorMessageWrapper>
      <StyledSelect error={error}>
        <span>{label}</span>
        <ReactSelect
          components={{ ClearIndicator, DropdownIndicator, MultiValueRemove }}
          isMulti={isMulti}
          isSearchable={isSearchable}
          options={options}
          placeholder={placeholder}
          ref={register}
          styles={customStyles}
          value={value}
          onChange={onChange}
          {...props}
        />
      </StyledSelect>
      <ErrorMessage>{error}</ErrorMessage>
    </ErrorMessageWrapper>
  )
}
