import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconBin: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path
        d="M17.4 5.5h-3.2c0-.6-.5-1.1-1.1-1.1h-2.2a1 1 0 00-1 1v.1H6.5c-.3 0-.6.3-.6.6v.6c0 .4.3.6.6.6h10.8c.3 0 .6-.2.6-.6v-.6c0-.3-.3-.6-.6-.6zM16.6 8.2H7.4c-.2 0-.3.1-.3.3l.5 9.3a2 2 0 001.9 1.8h5a2 2 0 002-1.8l.4-9.3c0-.2-.2-.3-.3-.3zm-5.6 9c0 .2-.2.4-.5.4a.5.5 0 01-.5-.5v-6.4c0-.3.2-.5.5-.5s.5.2.5.5V17zm3 0c0 .2-.2.4-.5.4a.5.5 0 01-.5-.5v-6.4c0-.3.2-.5.5-.5s.5.2.5.5V17z"
        fill="currentColor"
      />
    </Svg>
  )
}
