import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconAttachment: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path
        d="M14.6 3.4h.2s0 0 0 0h-.2zm0 0c-1.4.1-2.6.8-3.3 2l3.3-2zm.4-.2h0-.1s0 0 0 0h-.3 0c-1.5 0-2.8.8-3.5 2.1h0L5 15.8l.1.1-.2-.1c-1 1.6-.4 3.7 1.2 4.6h0c1.7 1 3.7.4 4.7-1.2h0l5-8.7s0 0 0 0a2.2 2.2 0 10-4-2.2l-4.3 7.6h0a.5.5 0 10.7.4l4.4-7.6s0 0 0 0a1.3 1.3 0 112.4 1.4h0l-5 8.6s0 0 0 0a2.5 2.5 0 11-4.3-2.5h0l6-10.4a3.5 3.5 0 016 3.5l-5.3 9.2v.4h0a.4.4 0 00.5.3h0l.3-.2 5.4-9.3c1.2-2 .5-4.8-1.6-6h0c-.7-.3-1.4-.5-2-.5z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".5"
      />
    </Svg>
  )
}
