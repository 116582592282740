import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { initializeApollo } from '~/utils/api/apollo-client'
import { VALIDATE_CLASS_SESSION_ID } from '~/utils/api/queries'
import { createTokenManager } from '~/utils/api/token-manager'

import {
  ValidateClassSessionIdQuery,
  ValidateClassSessionIdQueryVariables,
} from '~/@types/schema'

let lastClassSessionTest = {
  result: false,
  value: '',
}

export const classSessionIDValidator = {
  message: 'Class Session ID not found',
  name: 'classSessionIdTest',
  test: (classSessionID: string) => {
    if (!classSessionID) {
      return false
    }

    if (classSessionID === lastClassSessionTest.value) {
      return lastClassSessionTest.result
    }

    const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo(
      createTokenManager(),
      null
    )

    return apolloClient
      .query<ValidateClassSessionIdQuery, ValidateClassSessionIdQueryVariables>(
        {
          query: VALIDATE_CLASS_SESSION_ID,
          variables: {
            classSessionId: classSessionID,
          },
        }
      )
      .then((res) => {
        lastClassSessionTest = {
          result: res.data.validateClassSessionID.valid,
          value: classSessionID,
        }

        return res.data.validateClassSessionID.valid
      })
      .catch(() => {
        lastClassSessionTest = {
          result: false,
          value: classSessionID,
        }

        return false
      })
  },
}
