import { FC } from 'react'

import { Svg } from '../svg'

export const IconSpinner: FC<{ thickness?: number }> = ({ thickness = 4 }) => {
  return (
    <Svg viewBox="0 0 64 64">
      <circle
        cx="32"
        cy="32"
        fill="none"
        r={32 - thickness / 2}
        stroke="currentColor"
        strokeDasharray={`${52 - thickness * 0.8} ${52 - thickness * 0.8}`}
        strokeWidth={thickness}
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="2s"
          from="0 32 32"
          repeatCount="indefinite"
          to="360 32 32"
          type="rotate"
        />
      </circle>
    </Svg>
  )
}
