import styled from 'styled-components'

import { mq } from '~/styles/mq'
import { theme } from '~/styles/theme'

const { spacing } = theme.layout

export const Main = styled.main`
  background-color: ${theme.color.greyLight};
  width: 100%;
  padding: ${spacing.xxl} ${spacing.lg} ${spacing.xl};
  position: relative;
  height: 100vh;
  overflow-y: auto;

  ${mq.large} {
    max-width: calc(100% - ${theme.sideMenuWidth});
    padding: ${spacing.xxl} ${spacing.xl};
  }
`
