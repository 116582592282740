import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'
import {
  GetLibraryItemQuery,
  LibraryFormOptionsQuery,
  LibraryItemStatusEnum,
  LibraryItemTypeEnum,
} from '~/@types/schema'
import { Button } from '~/components/button'
import { ButtonWithLoading } from '~/components/button/with-loading'
import { IconBin } from '~/components/icons/icon-bin'
import { Input } from '~/components/input'
import { Modal, ModalProps } from '~/components/modal'
import { ModalScreen } from '~/components/modal/modal-screen'
import { ModalScreens } from '~/components/modal/modal-screens'
import { Select } from '~/components/select'
import { Spacer } from '~/components/spacer'
import { Textarea } from '~/components/textarea'
import { AudioUploader } from '~/components/uploader/audio'
import { FileUploader } from '~/components/uploader/file'
import { ClosedCaptionsUploader } from '~/components/uploader/closed-captions'
import { ThumbnailUploader } from '~/components/uploader/thumbnail'
import { EquipmentSelect } from '~/components/equipment-select'
import { AudioTrackUploader } from '~/components/uploader/audio-track'
import { SpotifySelect } from '~/components/select/spotify-select'
import { AppleMusicSelect } from '~/components/select/apple-music-select'
import { dateToObject, normalizeSelectOptions } from '~/utils'
import { librarySchema } from '../../schema'
import { LeftColumn, ModalGrid, RightColumn } from '../../styled'
import { LibraryModalInputs, PublishInputs } from '../../types'
import { processDataOnLoad } from '../helpers'
import { InstructorSelect } from '../instructor-select'
import { MinimizedModal } from './minimized-modal'
import { PublishOptions } from './publish-options'
import { useCompoundProgress } from './use-compound-progress'

type Props = Pick<ModalProps, 'onMinimize' | 'isMinimized' | 'onClose'> & {
  data: GetLibraryItemQuery['admin_getLibraryItem']
  formOptions: LibraryFormOptionsQuery
  isDeleting: boolean
  isSubmitting: boolean
  libraryItemType: LibraryItemTypeEnum
  modalTitle: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete: () => Promise<any>
  onSubmit: (inputs: LibraryModalInputs) => void
}

export const LibraryForm: FC<Props> = ({
  data,
  formOptions,
  isDeleting,
  isMinimized,
  isSubmitting,
  libraryItemType,
  modalTitle,
  onClose,
  onDelete,
  onMinimize,
  onSubmit,
}) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method -- see more: https://github.com/react-hook-form/react-hook-form/issues/2887
  const { control, errors, register, getValues, setValue, trigger, watch } =
    useForm<LibraryModalInputs>({
      defaultValues: processDataOnLoad(data, libraryItemType),
      resolver: yupResolver(librarySchema),
    })

  const { progress, setProgress, setProgressDone } = useCompoundProgress()
  const [activeScreenIndex, setActiveScreenIndex] = useState(0)

  const isAudioType = libraryItemType === LibraryItemTypeEnum.Audio
  const MainFileComponent = isAudioType ? AudioUploader : FileUploader

  const {
    fileID,
    instructorFileID,
    audioID,
    classID,
    hasClosedCaptions,
    imageURL,
    parentCategory,
    title,
  } = watch()

  const processSubmit = (publishData: PublishInputs) => {
    onSubmit({ ...getValues(), ...publishData })
  }

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete the file?')) {
      await onDelete()
    }
  }

  const handleContinue = async () => {
    const result = await trigger()

    if (result) {
      setActiveScreenIndex(1)
    }
  }

  return (
    <>
      <Modal
        isOpen
        isMinimized={isMinimized}
        onClose={onClose}
        onMinimize={onMinimize}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
      >
        <ModalScreens screenIndex={activeScreenIndex}>
          <ModalScreen title={modalTitle}>
            <form>
              <ModalGrid>
                <LeftColumn>
                  <Spacer space={{ sm: 'lg' }}>
                    <Input
                      error={errors.title?.message}
                      label="Title"
                      name="title"
                      register={register}
                    />
                  </Spacer>
                  <Controller
                    control={control}
                    name="type"
                    render={({ ref, ...props }) => {
                      return <input type="hidden" {...props} ref={ref} />
                    }}
                  />
                  <Controller
                    control={control}
                    name="classID"
                    render={({ ref, ...props }) => {
                      return <input type="hidden" {...props} ref={ref} />
                    }}
                  />
                  <MainFileComponent
                    onProgress={setProgress('fileID')}
                    control={control}
                    error={errors.fileID?.message}
                    onReset={() => {
                      setValue('classID', '')
                      setValue('fileID', null)
                    }}
                    onStart={(newClassID: string) => {
                      if (!classID) {
                        setValue('classID', newClassID)
                      }
                    }}
                    onFinish={(streamId: string) => {
                      setValue('fileID', streamId)
                      setProgressDone('fileID')
                    }}
                    value={fileID}
                    uploaderText={`Instructor's Voice + Music (${
                      isAudioType ? 'Audio' : 'Video'
                    })`}
                    name="fileID"
                  />
                  {!isAudioType && (
                    <>
                      <AudioTrackUploader
                        name="instructorFileID"
                        control={control}
                        error={errors.instructorFileID?.message}
                        onReset={() => {
                          setValue('instructorFileID', null)
                        }}
                        onFinish={(streamId: string) => {
                          setValue('instructorFileID', streamId)
                          setProgressDone('instructorFileID')
                        }}
                        onProgress={setProgress('instructorFileID')}
                        uploaderText="Instructor's Voice (Audio)"
                        videoID={fileID}
                        value={instructorFileID}
                      />
                      <AudioTrackUploader
                        name="audioID"
                        control={control}
                        error={errors.audioID?.message}
                        accept="audio/*"
                        onReset={() => {
                          setValue('audioID', null)
                        }}
                        onFinish={(streamId: string) => {
                          setValue('audioID', streamId)
                          setProgressDone('audioID')
                        }}
                        onProgress={setProgress('audioID')}
                        uploaderText="Music (Audio)"
                        videoID={fileID}
                        value={audioID}
                      />
                      <ClosedCaptionsUploader
                        onReset={() => {
                          setValue('hasClosedCaptions', false)
                        }}
                        fileID={fileID}
                        // instructorFileID={instructorFileID}
                        name="hasClosedCaptions"
                        register={register}
                        hasClosedCaptions={hasClosedCaptions}
                        uploaderText="Closed captions"
                        onUploadComplete={() => {
                          setValue('hasClosedCaptions', true)
                        }}
                      />
                    </>
                  )}
                  <ThumbnailUploader
                    name="imageURL"
                    register={register}
                    value={imageURL}
                    onChange={setValue}
                    error={errors.imageURL?.message}
                  />
                </LeftColumn>

                <RightColumn>
                  <Spacer space={{ sm: 'xxxl' }} />
                  <Input
                    error={errors.classID?.message}
                    label="Class ID"
                    name="classID"
                    register={register}
                  />
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={(props) => (
                      <Textarea
                        error={errors.description?.message}
                        label="Description"
                        {...props}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="appleMusicPlaylistIDs"
                    render={({ ref, ...props }) => {
                      return (
                        <AppleMusicSelect
                          error={
                            (
                              errors.appleMusicPlaylistIDs as unknown as FieldError
                            )?.message
                          }
                          label="Apple Music Playlist ID"
                          isMulti
                          register={ref}
                          {...props}
                        />
                      )
                    }}
                  />
                  <Controller
                    control={control}
                    name="spotifyPlaylistIDs"
                    render={({ ref, ...props }) => {
                      return (
                        <SpotifySelect
                          error={
                            (errors.spotifyPlaylistIDs as unknown as FieldError)
                              ?.message
                          }
                          label="Spotify Playlists"
                          isMulti
                          register={ref}
                          {...props}
                        />
                      )
                    }}
                  />
                  <Controller
                    control={control}
                    name="parentCategory"
                    render={({ ref, ...props }) => {
                      return (
                        <Select
                          error={(errors.parentCategory as FieldError)?.message}
                          label="parent category"
                          options={formOptions.listLibraryItemCategories.map(
                            normalizeSelectOptions
                          )}
                          register={ref}
                          {...props}
                        />
                      )
                    }}
                  />
                  <Controller
                    control={control}
                    name="bodyParts"
                    render={({ ref, ...props }) => {
                      return (
                        <Select
                          isMulti
                          error={
                            (errors.bodyParts as unknown as FieldError)?.message
                          }
                          label="Body parts"
                          options={formOptions.listBodyParts.map(
                            normalizeSelectOptions
                          )}
                          register={ref}
                          {...props}
                        />
                      )
                    }}
                  />
                  <Controller
                    control={control}
                    name="equipment"
                    render={({ ref, ...props }) => {
                      return (
                        <EquipmentSelect
                          error={
                            (errors.equipment as unknown as FieldError)?.message
                          }
                          category={parentCategory?.value}
                          ref={ref}
                          {...props}
                        />
                      )
                    }}
                  />
                  <Controller
                    control={control}
                    name="instructors"
                    render={({ ref, ...props }) => {
                      return (
                        <InstructorSelect
                          error={
                            (errors.instructors as unknown as FieldError)
                              ?.message
                          }
                          isMulti
                          menuPlacement="top"
                          ref={ref}
                          {...props}
                        />
                      )
                    }}
                  />
                  <Controller
                    control={control}
                    name="duration"
                    render={({ ref, ...props }) => {
                      return (
                        <Select
                          error={(errors.duration as FieldError)?.message}
                          label="duration"
                          options={formOptions.listLibraryItemDurations.map(
                            normalizeSelectOptions
                          )}
                          menuPlacement="top"
                          register={ref}
                          {...props}
                        />
                      )
                    }}
                  />
                  {libraryItemType === LibraryItemTypeEnum.PreviouslyLive && (
                    <Input
                      error={errors.classSessionID?.message}
                      label="Class Session ID"
                      name="classSessionID"
                      register={register}
                    />
                  )}
                </RightColumn>
              </ModalGrid>

              <ModalGrid>
                <LeftColumn>
                  {!!data?.id && (
                    <div>
                      <ButtonWithLoading
                        hasIcon
                        isLoading={isDeleting}
                        variant="outline"
                        onClick={handleDelete}
                      >
                        <IconBin />
                        Delete
                      </ButtonWithLoading>
                    </div>
                  )}
                </LeftColumn>
                <RightColumn>
                  <Button
                    type="button"
                    variant="primary"
                    onClick={handleContinue}
                  >
                    Continue
                  </Button>
                </RightColumn>
              </ModalGrid>
            </form>
          </ModalScreen>

          <ModalScreen title="Publish" onBack={() => setActiveScreenIndex(0)}>
            <PublishOptions
              initialFormData={{
                status: data?.status || LibraryItemStatusEnum.Published,
                statusEffectiveSince: dateToObject(
                  data?.statusEffectiveSince
                ).toFormat(`yyyy-LL-dd'T'HH:mm`),
                statusComment: data?.statusComment,
              }}
              onSubmit={processSubmit}
              isSubmitting={isSubmitting}
            />
          </ModalScreen>
        </ModalScreens>
      </Modal>

      {isMinimized && (
        <MinimizedModal
          fileName={classID}
          hasCC={hasClosedCaptions}
          imageURL={imageURL}
          title={title}
          onClick={() => {
            onMinimize(false)
          }}
          uploadProgress={progress}
          type={libraryItemType}
        />
      )}
    </>
  )
}
