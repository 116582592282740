/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components'

import { ButtonProps } from './types'

import { theme } from '~/styles/theme'
import { font, fontSize } from '~/styles/typography'

export const buttonTranstion = css`
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    opacity: 0.18;
    transition: 0.4s left, 0.4s transform-origin, 0.4s width 0.2s;
    transform: skew(-45deg);
    transform-origin: 0 0;
    background: currentColor;
  }

  &:not([disabled]) {
    a:hover &,
    &:focus &,
    &:hover,
    &:focus {
      &::before {
        left: 100%;
        width: 100%;
        transform-origin: 0 100%;
        transition: 0.4s width, 0.4s left 0.2s, 0.4s transform-origin 0.2s;
      }
    }
  }
`

export const Button = styled.button<ButtonProps>`
  display: inline-block;
  position: relative;
  margin: 0;
  appearance: none;
  overflow: hidden;
  text-align: center;
  transition: opacity 0.3s;
  text-decoration: none;
  font-size: ${fontSize.small};
  text-transform: uppercase;
  font-weight: bold;
  font-family: ${font.headings};
  max-height: 7.2rem;

  ${buttonTranstion}

  &[disabled] {
    opacity: 0.3;
  }

  ${(props) => {
    return (
      (props.hasIcon || props.centeredIconButton) &&
      css`
        display: flex;
        align-items: center;

        svg {
          margin-right: ${theme.layout.spacing.sm};
          font-size: 1.6em;
        }
      `
    )
  }}

  ${(props) => {
    switch (props.size) {
      case 'small':
        return css`
          padding: 1rem 4rem;
          font-size: ${fontSize.micro};
        `

      case 'large':
        return css`
          padding: 2.8rem;
          width: 34rem;
        `

      case 'xlarge':
        return css`
          padding: 4rem;
          display: flex;
          justify-content: center;
        `

      default:
        return css`
          padding: 2.8rem 5rem;
        `
    }
  }}

  ${(props) => {
    switch (props.variant) {
      case 'primary':
        return css`
          background: ${theme.color.primary};
          color: ${theme.color.white};
        `

      case 'secondary':
        return css`
          background: ${theme.color.greyLight};
          color: ${theme.color.black};

          &::before {
            opacity: 0.05;
          }
        `

      case 'inverted':
        return css`
          background: ${theme.color.white};
          color: ${theme.color.black};
        `

      case 'invisible':
        return css`
          background: none;
          color: ${theme.color.black};
        `

      case 'outline':
        return css`
          background: transparent;
          color: ${theme.color.black};
          border: ${theme.layout.spacing.xxs} solid ${theme.color.black};

          &::before {
            opacity: 0.07;
          }
        `

      case 'outlineInverted':
        return css`
          background: transparent;
          color: ${theme.color.white};
          border: ${theme.layout.spacing.xxs} solid ${theme.color.white};
        `

      case 'success':
        return css`
          background: transparent;
          color: ${theme.color.success};
          background: ${theme.color.successTransparent};
        `

      default:
        return css`
          background: ${theme.color.black};
          color: ${theme.color.white};
        `
    }
  }}

  ${(props) => {
    return (
      (props.fullWidth || props.centeredIconButton) &&
      css`
        width: 100%;
      `
    )
  }}

  ${(props) => {
    return (
      props.centeredIconButton &&
      css`
        padding-left: 1rem;
        padding-right: 1rem;
        justify-content: center;
      `
    )
  }}
`

Button.defaultProps = {
  type: 'button',
}
