import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconInfo: FC<SvgProps> = (props) => {
  return (
    <Svg {...props} viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM10 8.75C10 8.19898 9.55102 7.75 9 7.75C8.44898 7.75 8 8.19898 8 8.75V12.75C8 13.301 8.44898 13.75 9 13.75C9.55102 13.75 10 13.301 10 12.75V8.75ZM10 5.25001C10 4.69899 9.55102 4.25 9 4.25C8.44898 4.25 8 4.69899 8 5.25001C8 5.80103 8.44898 6.25001 9 6.25001C9.55102 6.25001 10 5.80103 10 5.25001Z"
        fill="currentColor"
      />
    </Svg>
  )
}
