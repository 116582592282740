import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconEyeOpen: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="M4 11.8S7 6 12 6c5.2 0 8.1 5.8 8.1 5.8s-3 5.7-8 5.7c-5.2 0-8.1-5.8-8.1-5.8z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12 14a2.3 2.3 0 100-4.5 2.3 2.3 0 000 4.6z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </Svg>
  )
}
