/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC } from 'react'
import ReactSelect from 'react-select/async'

import { ErrorMessage } from '../error-message'
import { ErrorMessageWrapper } from '../error-message/styled'

import { ClearIndicator, DropdownIndicator, MultiValueRemove } from './common'
import { StyledSelect, customStyles } from './styled'
import { SelectProps } from './types'

export const AsyncSelect: FC<SelectProps> = ({
  error,
  label,
  loadOptions,
  onInputChange,
  options,
  register,
  ...props
}) => {
  return (
    <ErrorMessageWrapper>
      <StyledSelect error={error}>
        <span>{label}</span>
        <ReactSelect
          components={{ ClearIndicator, DropdownIndicator, MultiValueRemove }}
          label="instructor"
          loadOptions={loadOptions}
          options={options}
          register={register}
          styles={customStyles}
          onInputChange={onInputChange}
          {...props}
        />
      </StyledSelect>
      <ErrorMessage>{error}</ErrorMessage>
    </ErrorMessageWrapper>
  )
}
