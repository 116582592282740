import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconLiveClass: FC<SvgProps> = (props) => {
  return (
    <Svg {...props} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 4.5H3V19.5H21V4.5ZM10 8.5V15.5L16 12.0004L10 8.5Z"
        fill="currentColor"
      />
    </Svg>
  )
}
