import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

import { StyledLink } from './styled'
import { PanelLinkProps } from './types'

export const PanelLink: FC<PanelLinkProps> = ({
  children,
  href,
  onActiveKeyChange,
  panelName,
}) => {
  const router = useRouter()
  const prefetch = !router && false

  useEffect(() => {
    if (router?.pathname?.startsWith(href)) {
      onActiveKeyChange(panelName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this effect should run only on the mount
  }, [])

  const activeClassName = router?.pathname === href ? 'is-active' : ''

  return (
    <li>
      <Link passHref href={href} prefetch={prefetch}>
        <StyledLink className={`${activeClassName}`}>{children}</StyledLink>
      </Link>
    </li>
  )
}
