/* eslint-disable consistent-return */
import styled, { css } from 'styled-components'

import { SpacerProps } from './types'

import { mq } from '~/styles/mq'
import { theme } from '~/styles/theme'

export const Spacer = styled.span<SpacerProps>`
  ${({ $direction = 'vertical' }) => {
    if ($direction === 'vertical') {
      return css`
        display: block;
      `
    } else {
      return css`
        display: inline-block;
      `
    }
  }}

  ${({ $direction = 'vertical', space }) => {
    if (space.sm) {
      return $direction === 'vertical'
        ? css`
            margin-bottom: ${theme.layout.spacing[space.sm]};
          `
        : css`
            margin-right: ${theme.layout.spacing[space.sm]};
          `
    }
  }}

  ${({ $direction, space }) => {
    if (space.md) {
      return $direction === 'vertical'
        ? css`
            ${mq.medium} {
              margin-bottom: ${theme.layout.spacing[space.md]};
            }
          `
        : css`
            ${mq.medium} {
              margin-right: ${theme.layout.spacing[space.md]};
            }
          `
    }
  }}

  ${({ $direction, space }) => {
    if (space.lg) {
      return $direction === 'vertical'
        ? css`
            ${mq.large} {
              margin-bottom: ${theme.layout.spacing[space.lg]};
            }
          `
        : css`
            ${mq.large} {
              margin-right: ${theme.layout.spacing[space.lg]};
            }
          `
    }
  }}
`
