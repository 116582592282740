import { animated } from 'react-spring'
import styled, { css } from 'styled-components'

import { Logo } from '../logo'

import { ScreenSize, mq } from '~/styles/mq'
import { theme } from '~/styles/theme'
import { fontSize } from '~/styles/typography'

const menuItemStyle = css`
  padding: 2.4rem 3.2rem;
  font-weight: 500;
`

export const PanelHeaderLink = styled.a`
  ${menuItemStyle}
  width: 100%;
  display: block;
  text-decoration: none;
`

export const StyledLink = styled(PanelHeaderLink)`
  padding-left: 8.8rem;
  border: none;
  text-align: left;
  font-size: inherit;
  cursor: pointer;
`

export const StyledSideMenu = styled(animated.aside)`
  min-width: ${theme.sideMenuWidth};
  background-color: ${theme.color.black};
  color: ${theme.color.white};
  z-index: 110;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  height: 100vh;

  .rc-collapse {
    width: 100%;
    border: none;
    flex-grow: 1;
    background-color: transparent;

    & > .rc-collapse-item {
      border: none;

      & > .rc-collapse-header {
        ${menuItemStyle}
        color: inherit;
        flex-direction: row-reverse;

        div {
          width: 100%;
        }

        &:hover,
        &:focus {
          text-decoration: none;
          background-color: #222;
        }
      }
    }

    &-content {
      padding: 0;
      color: inherit;
      background-color: transparent;
    }

    &-content-box {
      margin: 0;
    }

    ${PanelHeaderLink}, ${StyledLink} {
      &:hover,
      &:focus {
        text-decoration: none;
        background-color: #222;
      }

      &.is-active {
        background-color: ${theme.color.white};
        color: ${theme.color.black};
      }
    }
  }
`

export const SideMenuLogo = styled(Logo)`
  margin-bottom: 5rem;
  padding-left: 2.7rem;
  font-size: 3.6rem;
`

export const Overlay = styled.div<{ visible: boolean }>`
  @media (max-width: ${ScreenSize.LG / 16}em) {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    position: fixed;
    transition: opacity 300ms ease;
    opacity: 0;
    visibility: hidden;

    ${({ visible }) => {
      return (
        visible &&
        css`
          opacity: 1;
          visibility: visible;
        `
      )
    }}
  }
`

export const LogOutSectionWrapper = styled.div<{ showUser: boolean }>`
  ${menuItemStyle}
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${theme.color.whiteTransparent};
  width: 100%;

  ${({ showUser }) => {
    return (
      !showUser &&
      css`
        span {
          opacity: 0;
        }
      `
    )
  }}

  svg {
    font-size: 2.4rem;
  }
`

export const LogOutButton = styled.button`
  all: unset;
  display: flex;
  padding: 1rem;
  margin: -1rem;

  &,
  svg {
    flex-shrink: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: #222;
  }
`

export const PanelHeaderWrapper = styled.div`
  display: flex;

  svg {
    margin-right: 1.6rem;
    font-size: 2.4rem;
  }
`

export const StyledMobileMenu = styled.div`
  position: fixed;
  right: 0;
  z-index: 100;
  background-color: ${theme.color.greyLight};
  width: 100%;
  text-align: left;
  padding: ${theme.layout.spacing.md};
  box-shadow: ${theme.boxShadow};

  ${mq.large} {
    display: none;
  }

  button {
    font-size: ${fontSize.h2};
  }
`
