import { FC, useMemo, useState } from 'react'
import ReactSelect from 'react-select/creatable'

import { ErrorMessage } from '~/components/error-message'
import { ErrorMessageWrapper } from '~/components/error-message/styled'
import { IconInfo } from '~/components/icons/icon-info'
import { useAppContext } from '~/context/app-context'
import {
  ClearIndicator,
  NullDropdownIndicator,
  MultiValueRemove,
  NullMenu,
} from '../common'
import { StyledSelect, customStyles } from '../styled'
import { SelectProps } from '../types'
import { useSpotify } from './spotify-client'
import { sanitizeSearchString } from './utils'
import { InfoWrapper, RelativeWrapper } from './styled'
import { OptionLabel } from './option-label'

export const SpotifySelect: FC<SelectProps> = ({
  error,
  isMulti,
  label,
  onChange,
  placeholder = 'Start typing',
  register,
  value,
  ...props
}) => {
  const { addNotification } = useAppContext()
  const { isAuthorized, init, loadPlaylist } = useSpotify()
  const [isLoading, setLoading] = useState(false)
  const valueWithKeys = useMemo(
    () => (value ?? []).map((v) => ({ ...v, value: v.playlistID })),
    [value]
  )

  const handleCreate = async (input: string) => {
    setLoading(true)

    try {
      await fetch('/api/spotify/parser', {
        method: 'POST',
        body: JSON.stringify({
          id: sanitizeSearchString(input),
        }),
      })
        .then(async (res) => await res.json())
        .then((playlist) => {
          if (!playlist?.title) {
            throw new Error('No playlist information')
          }
          onChange([...value, playlist])
        })
    } catch (err1) {
      try {
        if (!isAuthorized) {
          await init()
        }

        const playlist = await loadPlaylist(sanitizeSearchString(input))
        onChange([...value, playlist])
      } catch (err) {
        addNotification(err)
      }
    }

    setLoading(false)
  }

  return (
    <ErrorMessageWrapper>
      <RelativeWrapper>
        <StyledSelect error={error} $isSpotifySelect>
          <span>{label}</span>
          <ReactSelect
            components={{
              ClearIndicator,
              DropdownIndicator: NullDropdownIndicator,
              Menu: NullMenu,
              MultiValueRemove,
            }}
            isLoading={isLoading}
            isMulti={isMulti}
            placeholder={placeholder}
            ref={register}
            styles={{
              ...customStyles,
              multiValue: (base) => ({
                ...base,
                padding: 0,
                width: '100%',
                backgroundColor: 'white',
              }),
            }}
            value={valueWithKeys}
            formatOptionLabel={(option) => <OptionLabel option={option} />}
            onCreateOption={handleCreate}
            onChange={onChange}
            {...props}
          />
        </StyledSelect>
        <InfoWrapper title="Can be playlist URL or an ID confirmed by pressing Enter key. Spotify authentication may be required.">
          <IconInfo />
        </InfoWrapper>
      </RelativeWrapper>
      <ErrorMessage>{error}</ErrorMessage>
    </ErrorMessageWrapper>
  )
}
