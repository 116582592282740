import styled from 'styled-components'

import { theme } from '~/styles/theme'

export const SpinnerWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: calc(${theme.sideMenuWidth});
  backdrop-filter: blur(2px);
  background-color: #f5f5f588;

  > span {
    display: block;
    padding: 3rem;
    box-shadow: 0 10px 14px -5px rgba(0, 0, 0, 0.2);
    background-color: white;
  }

  svg {
    transform: scale(0.6);
  }
`
