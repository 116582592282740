import styled, { css } from 'styled-components'

import { LogoProps } from './types'

export const StyledLogo = styled.div<LogoProps>`
  ${({ large }) => {
    return large
      ? css`
          font-size: 12rem;
        `
      : css`
          font-size: 6rem;
        `
  }}
`
