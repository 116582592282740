import Collapse, { Panel } from 'rc-collapse'
import { FC, Fragment, useState } from 'react'
import { useTransition } from 'react-spring'
import { useDebounce, useWindowSize } from 'react-use'
import { useRouter } from 'next/router'
import 'rc-collapse/assets/index.css'

import { Spacer } from '../spacer'

import { ExpandIcon } from './expand-icon'
import { LogOutSection } from './log-out-section'
import { MobileMenu } from './mobile-header'
import { PanelHeader } from './panel-header'
import { PanelLink } from './panel-link'
import { sideMenuData } from './side-menu-data'
import { Overlay, SideMenuLogo, StyledSideMenu } from './styled'

import { ScreenSize } from '~/styles/mq'
import { theme } from '~/styles/theme'
import { config } from '~/utils/config'
import { MenuToggle } from './menu-toggle'

export const SideMenu: FC = () => {
  const router = useRouter()
  const [activeKey, setActiveKey] = useState<string[]>([])
  const [activeMenu, setActiveMenu] = useState(
    Object.keys(sideMenuData)[
      router.asPath.startsWith('/instructor-websites') ? 1 : 0
    ]
  )
  const { width } = useWindowSize()

  const isMobile = width <= ScreenSize.LG

  const [isOpen, setIsOpen] = useState(isMobile ? false : true)

  useDebounce(
    () => {
      if (width >= ScreenSize.LG && !isOpen) {
        setIsOpen(true)
      }
    },
    400,
    [width]
  )

  const transitions = useTransition(isOpen, null, {
    enter: {
      transform: `translateX(0)`,
    },
    from: {
      transform: `translateX(-${theme.sideMenuWidth})`,
    },
    initial: {
      transform: isMobile
        ? `translateX(-${theme.sideMenuWidth})`
        : `translateX(0)`,
    },
    leave: {
      transform: `translateX(-${theme.sideMenuWidth})`,
    },
  })

  return (
    <>
      <MobileMenu
        onClick={() => {
          setIsOpen(true)
        }}
      />
      {transitions.map(({ item, key: transitionKey, props }) => {
        return (
          <Fragment key={transitionKey}>
            {item && (
              <StyledSideMenu style={props}>
                <Spacer space={{ sm: 'lg' }} />
                <SideMenuLogo light href={config.defaultPage} />
                <MenuToggle
                  menus={Object.keys(sideMenuData)}
                  activeMenuLabel={activeMenu}
                  onChange={setActiveMenu}
                />
                <Collapse
                  accordion={false}
                  activeKey={activeKey}
                  expandIcon={ExpandIcon}
                  onChange={(key: string[]) => {
                    setActiveKey(key)
                  }}
                >
                  {sideMenuData[activeMenu].map(
                    ({ href: PanelHeaderHref, icon, panelLink, panelName }) => {
                      if (PanelHeaderHref) {
                        return (
                          <PanelHeader
                            href={PanelHeaderHref}
                            icon={icon}
                            key={panelName}
                            name={panelName}
                          />
                        )
                      }

                      return (
                        <Panel
                          forceRender
                          header={<PanelHeader icon={icon} name={panelName} />}
                          key={panelName}
                          showArrow={panelLink ? true : false}
                        >
                          <ul>
                            {panelLink?.map(({ content, href }) => {
                              return (
                                <PanelLink
                                  href={href}
                                  key={href}
                                  panelName={panelName}
                                  onActiveKeyChange={(key) => {
                                    setActiveKey(
                                      Array.from(new Set([...activeKey, key]))
                                    )
                                  }}
                                >
                                  {content}
                                </PanelLink>
                              )
                            })}
                          </ul>
                        </Panel>
                      )
                    }
                  )}
                </Collapse>

                <LogOutSection />
              </StyledSideMenu>
            )}
          </Fragment>
        )
      })}
      <Overlay
        visible={isOpen}
        onClick={() => {
          setIsOpen(false)
        }}
      />
    </>
  )
}
