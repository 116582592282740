import Head from 'next/head'
import { FC } from 'react'

import { SeoProps } from './types'

import { StructuredData } from '~/@types/data'

export const defaultTitle = "Barry's X Admin"

export const Seo: FC<SeoProps> = ({
  children,
  image,
  keywords = [],
  structuredData = [],
  title,
  type = 'website',
}) => {
  const websiteSD: StructuredData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    alternateName: title,
    name: title,
  }

  return (
    <Head>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <script type="application/ld+json">
        {JSON.stringify([...structuredData, websiteSD])}
      </script>
      <meta content={(keywords || []).join(', ')} name="keywords" />
      <meta content={title} property="og:title" />
      <meta content={type} property="og:type" />
      <meta content={image} property="og:image" />
      {children}
    </Head>
  )
}
