import { FC } from 'react'
import { IndicatorProps, components } from 'react-select'

import { IconClose } from '../icons/icon-close'
import { IconDropdownDown } from '../icons/icon-dropdown-down'
import { IconDropdownUp } from '../icons/icon-dropdown-up'

export const DropdownIndicator: FC = (props: IndicatorProps<unknown>) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <IconDropdownUp /> : <IconDropdownDown />}
    </components.DropdownIndicator>
  )
}

export const NullDropdownIndicator = () => null

export const NullMenu = () => null

export const ClearIndicator: FC = (props: IndicatorProps<unknown>) => {
  return (
    <components.ClearIndicator {...props}>
      <IconClose />
    </components.ClearIndicator>
  )
}

export const MultiValueRemove: FC = (props: IndicatorProps<unknown>) => {
  return (
    <components.MultiValueRemove {...props}>
      <IconClose />
    </components.MultiValueRemove>
  )
}
