import axios from 'axios'
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'

import { useGetSignedUrlLazyQuery } from '~/@types/schema'
import { ErrorMessage } from '~/components/error-message'
import { ErrorMessageWrapper } from '~/components/error-message/styled'
import { Progress } from '~/components/progress'
import { ThumbnailUploaderWrapper } from '~/components/uploader/thumbnail/styled'
import { Uploaded } from '~/components/uploader/thumbnail/uploaded'
import { Uploader } from '~/components/uploader/thumbnail/uploader'
import { useAppContext } from '~/context/app-context'

type Props = {
  disabled?: boolean
  error?: string
  heading?: string
  name?: string
  onChange: (name: string, value?: string) => void
  register: any
  value?: string
}

export const ThumbnailUploader: FC<Props> = ({
  children,
  disabled = false,
  error,
  heading = 'Upload thumbnail',
  name,
  onChange,
  register,
  value,
}) => {
  const { addNotification } = useAppContext()
  const [thumbnailList, setThumbnailList] = useState<FileList>()
  const [fileName, setFileName] = useState('')
  const [progress, setProgress] = useState(0)
  const [fileError, setFileError] = useState('')
  const [fileInputKey, setFileInputKey] = useState(0)
  const selectedFile = thumbnailList ? thumbnailList[0] || null : null

  const handleThumbnailUpload = async ({ publicUrl, url }) => {
    if (!selectedFile || !publicUrl || !url) {
      return
    }

    try {
      await axios
        .request({
          data: selectedFile,
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          method: 'put',
          onUploadProgress: (p) => {
            setProgress(p.loaded / p.total)
          },
          url,
        })
        .then(() => {
          onChange('imageURL', publicUrl)
        })
        .catch((err) => {
          setFileError(err.message)
        })
        .finally(() => {
          setProgress(0)
          setFileInputKey(Date.now())
        })
    } catch (err) {
      addNotification(err)
      onChange(name, null)
    }
  }

  const [getSignedUrl] = useGetSignedUrlLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      await handleThumbnailUpload(data?.getSignedUrl)
    },
  })

  useEffect(() => {
    if (selectedFile) {
      const revalidate = () => {
        if (selectedFile) {
          const lastDot = selectedFile?.name?.lastIndexOf('.')
          const fileExtension = selectedFile?.name.substring(lastDot + 1)

          const size = selectedFile.size
          const limit = 2_000_000 // 2 Megabytes

          if (size > limit) {
            return setFileError(
              `The selected file exceeds maximum size of ${Math.round(
                limit / 1_000_000
              )} MB`
            )
          } else {
            setFileError('')
          }

          getSignedUrl({
            variables: {
              options: {
                extension: fileExtension,
              },
            },
          })
        }
      }

      void revalidate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `U` generic should not be in dependency array
  }, [getSignedUrl, fileName])

  return (
    <ThumbnailUploaderWrapper>
      <ErrorMessageWrapper>
        <Progress value={progress * 100} />
        <input type="hidden" name={name} ref={register} />
        {value ? (
          <Uploaded
            handleThumbnailReset={() => {
              onChange(name, null)
              setFileInputKey(Date.now())
            }}
            thumbnail={thumbnailList}
            thumbnailSrc={value}
          />
        ) : (
          <Uploader
            inputKey={fileInputKey}
            disabled={disabled}
            error={error || fileError}
            heading={heading}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              setThumbnailList(evt.target.files)
              setFileName(Date.now().toString())
            }}
          />
        )}
        <ErrorMessage>{error || fileError}</ErrorMessage>
      </ErrorMessageWrapper>
      {value && children}
    </ThumbnailUploaderWrapper>
  )
}
