import { FC, useState } from 'react'
import { config } from '~/utils/config'
import { SelectedLabel, Overlay, Menu, Item } from './styled'

type Props = {
  menus: string[]
  activeMenuLabel: string
  onChange: (m: string) => void
}

export const MenuToggle: FC<Props> = ({ menus, activeMenuLabel, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (m: string) => {
    onChange(m)
    setIsOpen(false)
  }

  return config.instructorsVisible ? (
    <>
      {isOpen && <Overlay onClick={() => setIsOpen(false)} />}
      <SelectedLabel>
        <button onClick={() => setIsOpen((v) => !v)}>{activeMenuLabel}</button>

        {isOpen && (
          <Menu>
            {menus.map((m) => (
              <Item key={m}>
                <button onClick={() => handleClick(m)}>{m}</button>
              </Item>
            ))}
          </Menu>
        )}
      </SelectedLabel>
    </>
  ) : null
}
