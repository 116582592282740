import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconPicture: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path
        d="M9 10.7v-.2l-.3.2h0L5 14.2V5h14v9.3L16.7 12h0a.6.6 0 00-.8 0s0 0 0 0l-2.5 2.7-3.8-4h0a.6.6 0 00-.5-.2v.2zm0 0c.2 0 .3 0 .4.2H9l.2-.2zM4 3.8h-.2v16.4h16.4V3.8H4zM13 16h0c.2.3.7.2.9 0 0 0 0 0 0 0l2.4-2.7L19 16v3H5v-3.2L9.1 12l3.9 4zm.2-9.7c-1.2 0-2.2 1-2.2 2.3 0 1.3 1 2.3 2.2 2.3 1.3 0 2.3-1 2.3-2.3 0-1.2-1-2.3-2.3-2.3zm0 1.3c.6 0 1 .4 1 1s-.4 1-1 1a1 1 0 01-1-1c0-.6.5-1 1-1z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".4"
      />
    </Svg>
  )
}
