import { FC } from 'react'

import { IconSpinner } from '../icons/icon-spinner'

import { StyledSpinner } from './styled'

export const Spinner: FC<{ className?: string }> = ({ className = '' }) => {
  return (
    <StyledSpinner className={className} aria-label="spinner">
      <IconSpinner />
    </StyledSpinner>
  )
}
