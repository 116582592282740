export const config = {
  rootUrl: process.env.NEXT_PUBLIC_ROOT_URL,
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  defaultPage: '/stats',
  environment: process.env.NEXT_PUBLIC_API_URL?.replace(
    /https:\/\/api\.(?<environment>[a-z]+)\.athome\.barrys\.com\/graphql/u,
    '$1'
  ),
  liveClassesVisible: process.env.NEXT_PUBLIC_LIVE_CLASSES_VISIBILITY !== 'off',
  version:
    process.env.NEXT_PUBLIC_SHOW_VERSION !== 'off'
      ? process.env.appVersion
      : '',
  marianaTekAdminUrl: process.env.NEXT_PUBLIC_MT_ADMIN_URL,
  instructorsVisible: process.env.NEXT_PUBLIC_INSTRUCTORS_VISIBILITY !== 'off',
  spotifyClient: {
    id: process.env.NEXT_PUBLIC_SPOTIFY_CLIENT_ID,
    secret: process.env.NEXT_PUBLIC_SPOTIFY_CLIENT_SECRET,
  },
}
