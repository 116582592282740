import { ApolloError } from '@apollo/client'
import { useAppContext } from '~/context/app-context'
import { globalErrorMessage } from '~/data'

const getErrorMessage = (error?: unknown) => {
  if (error) {
    if (error instanceof ApolloError) {
      return error.graphQLErrors.map((err) => err.message).join('\n')
    }
  }

  return globalErrorMessage
}

export const useGenericOnError = () => {
  const { addNotification } = useAppContext()

  const onError = (error?: unknown): void => {
    addNotification({
      text: getErrorMessage(error),
      type: 'error',
    })
  }

  return { onError }
}
