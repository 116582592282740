import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

import { Svg } from '../svg'

import { StyledLogo } from './styled'
import { LogoProps } from './types'

import { theme } from '~/styles/theme'

export const Logo: FC<LogoProps> = ({ href, ...props }) => {
  const fill = props.light ? theme.color.white : theme.color.black

  const SVG: FC = () => {
    return (
      <Svg
        fill="none"
        height="100%"
        viewBox="0 0 77 120"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M41.9285 102.582L38.5562 91.8379L35.0715 102.582H23.7182L32.8234 109.255L29.3387 120L38.5562 113.44L47.6613 120L44.1766 109.255L53.2818 102.582H41.9285Z"
          fill={fill}
        />
        <path
          d="M54.181 14.0245L38.5562 0L0 34.3827V62.6579L54.181 14.0245Z"
          fill={fill}
        />
        <path
          d="M77 62.6579V55.5325V34.3827L56.092 15.6079L40.2423 29.7455L77 62.6579Z"
          fill={fill}
        />
        <path
          d="M38.4438 45.919L0 80.1885V108.577L54.181 59.9435L38.4438 45.919Z"
          fill={fill}
        />
        <path
          d="M56.092 61.5269L40.2423 75.5514L77 108.577V80.1885L56.092 61.5269Z"
          fill={fill}
        />
      </Svg>
    )
  }

  const router = useRouter()
  const prefetch = !router && false

  return (
    <StyledLogo {...props}>
      {href ? (
        <Link passHref href={href} prefetch={prefetch}>
          <a aria-label="Home">
            <SVG />
          </a>
        </Link>
      ) : (
        <SVG />
      )}
    </StyledLogo>
  )
}
