import { FC } from 'react'

import { StyledSvg } from './styled'
import { SvgProps } from './types'

export const Svg: FC<SvgProps> = ({
  children,
  fill = 'none',
  viewBox = '0 0 24 24',
  ...props
}) => {
  return (
    <StyledSvg
      fill={fill}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
    </StyledSvg>
  )
}
