import styled from 'styled-components'
import { theme } from '~/styles/theme'

export const Playlist = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem 0;
  min-height: 6rem;

  > div {
    overflow: hidden;
  }
`

export const AlbumCover = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 6px;
  flex-shrink: 0;
  background-color: #ddd;
`

/* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
export const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`

export const ID = styled.div`
  color: #999;
  font-size: 1.2rem;
  font-weight: 500;
`

export const ActionButton = styled.span`
  cursor: pointer;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    color: ${theme.color.primary};
  }

  svg {
    margin-top: -0.3em;
  }
`

export const RelativeWrapper = styled.div`
  position: relative;
`

export const InfoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 100%;
  margin: 0 1rem;
  transform: translateY(-50%);
  color: #999;
`
