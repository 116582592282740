import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

import { PanelHeaderLink, PanelHeaderWrapper } from './styled'
import { PanelHeaderProps } from './types'

export const PanelHeader: FC<PanelHeaderProps> = ({ href, icon, name }) => {
  const router = useRouter()

  if (href) {
    const activeClassName = router?.pathname === href ? 'is-active' : ''
    const prefetch = !router && false

    return (
      <Link passHref href={href} prefetch={prefetch}>
        <PanelHeaderLink className={activeClassName}>
          <PanelHeaderWrapper>
            {icon}
            {name}
          </PanelHeaderWrapper>
        </PanelHeaderLink>
      </Link>
    )
  }

  return (
    <PanelHeaderWrapper>
      {icon}
      {name}
    </PanelHeaderWrapper>
  )
}
