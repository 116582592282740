import { FC } from 'react'

import {
  ActiveArea,
  Description,
  MinimizedModalContainer,
  Progress,
  Thumbnail,
  Title,
} from './styled'

import { LibraryItemTypeEnum } from '~/@types/schema'

type Props = {
  fileName?: string
  hasCC: boolean
  imageURL?: string
  onClick: () => void
  title: string
  type: LibraryItemTypeEnum
  uploadProgress?: number
}

export const MinimizedModal: FC<Props> = ({
  fileName,
  hasCC,
  imageURL,
  onClick,
  title,
  type,
  uploadProgress,
}) => {
  return (
    <MinimizedModalContainer>
      {imageURL ? <Thumbnail src={imageURL} /> : <Thumbnail as="span" />}
      <div>
        <Title>{title || '[Untitled Library Item]'}</Title>
        <Description>
          {type === LibraryItemTypeEnum.Audio ? 'Audio' : 'Video'} file:{' '}
          {uploadProgress && uploadProgress < 100
            ? `${Math.round(uploadProgress)}%`
            : fileName
            ? 'Yes'
            : 'No'}{' '}
          &bull; CC: {hasCC ? 'Yes' : 'No'}
        </Description>
      </div>
      <Progress isFinished={uploadProgress === 101}>
        {/* eslint-disable-next-line react/forbid-dom-props */}
        <span style={{ width: `${uploadProgress}%` }}></span>
      </Progress>
      <ActiveArea onClick={onClick} />
    </MinimizedModalContainer>
  )
}
