import { FC } from 'react'
import { SpotifyPlaylist } from '~/@types/schema'
import { IconSpotify } from '~/components/icons/spotify'
import { useAppContext } from '~/context/app-context'
import { ActionButton, AlbumCover, ID, Playlist, Title } from './styled'

export const OptionLabel: FC<{ option: SpotifyPlaylist }> = ({ option }) => {
  const { addNotification } = useAppContext()

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      addNotification({ type: 'success', text: 'Playlist ID has been copied' })
    }, addNotification)
  }

  return (
    <Playlist>
      <AlbumCover src={option.imageURL} />
      <div>
        <Title>{option.title}</Title>
        <ID>
          <ActionButton onClick={() => handleCopy(option.playlistID)}>
            Copy ID
          </ActionButton>{' '}
          &bull;{' '}
          <ActionButton
            as="a"
            href={`https://open.spotify.com/playlist/${option.playlistID}`}
            target="_blank"
          >
            <IconSpotify /> Open
          </ActionButton>
        </ID>
      </div>
    </Playlist>
  )
}
