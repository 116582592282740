import styled, { css } from 'styled-components'

import { theme } from '~/styles/theme'
import { fontSize } from '~/styles/typography'

const commonStyles = css`
  border: 1px solid ${theme.color.greyMedium};
  padding: ${theme.layout.spacing.sm};
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  align-items: center;
  font-weight: bold;
  min-height: 7.2rem;
  margin-bottom: 3.5rem;
  justify-content: center;
  color: ${theme.color.greyMedium};
`

export const Disabled = styled.div`
  ${commonStyles};
  text-align: center;
`

export const Uploader = styled.label`
  ${commonStyles};
  position: relative;
  color: ${theme.color.grey};

  &:hover,
  &:focus {
    cursor: pointer;
    border-color: ${theme.color.black};
    color: ${theme.color.black};
  }

  input {
    visibility: hidden;
    position: absolute;
  }
`

export const Uploaded = styled.div`
  ${commonStyles};
  border: 1px solid ${theme.color.black};
  color: ${theme.color.black};
  justify-content: space-between;

  button {
    color: ${theme.color.grey};
    font-size: 2.4rem;
  }

  button:hover,
  button:focus {
    color: ${theme.color.black};
  }
`

export const HiddenInput = styled.input`
  position: absolute;
  visibility: hidden;
`

export const Text = styled.small`
  display: block;
  margin-top: 0.5rem;
  font-weight: 400;
  letter-spacing: 0.005em;
  font-size: ${fontSize.small};
`

export const ResetButton = styled.button`
  padding: 0.4rem;

  svg {
    display: block;
  }
`
