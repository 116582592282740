import { FC, ReactNode, useEffect } from 'react'
import ReactModal from 'react-modal'

import { IconClose } from '../icons/icon-close'

import {
  CloseButton,
  ContentWrapper,
  MinimizeButton,
  ModalHeader,
  customReactModalStyles,
  customReactModalStylesCondensed,
} from './styled'

ReactModal.setAppElement('body')

export type ModalProps = {
  isCondensed?: boolean
  isMinimized?: boolean
  isOpen: boolean
  onClose: () => void
  onMinimize?: (value?: boolean) => void
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  title?: ReactNode
  v2?: boolean
}

export const Modal: FC<ModalProps> = ({
  children,
  isCondensed,
  isMinimized,
  isOpen,
  onClose,
  onMinimize,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  title,
  v2,
}) => {
  const style = isCondensed
    ? customReactModalStylesCondensed(isMinimized, v2)
    : customReactModalStyles(isMinimized)

  useEffect(() => {
    if (document) {
      if (isOpen && !isMinimized) {
        document.body.classList.add('locked')
      } else {
        document.body.classList.remove('locked')
      }

      return () => {
        document.body.classList.remove('locked')
      }
    }
  }, [isOpen, isMinimized])

  return (
    <ReactModal
      ariaHideApp={false}
      contentLabel="Example Modal"
      isOpen={isOpen}
      style={style}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      <ContentWrapper isMinimized={isMinimized} v2={v2}>
        {!!title && <ModalHeader>{title}</ModalHeader>}
        {typeof onMinimize === 'function' && (
          <MinimizeButton
            aria-label="Minimize modal"
            onClick={() => {
              onMinimize()
            }}
          >
            _
          </MinimizeButton>
        )}
        <CloseButton aria-label="Close modal" onClick={onClose}>
          <IconClose />
        </CloseButton>
        {children}
      </ContentWrapper>
    </ReactModal>
  )
}
