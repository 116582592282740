import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { ErrorMessage } from '~/components/error-message'
import { ErrorMessageWrapper } from '~/components/error-message/styled'
import { IconBin } from '~/components/icons/icon-bin'
import { LibraryModalInputs } from '~/containers/library/types'
import { IconAttachment } from '~/components/icons/icon-attachment'
import { Uploaded, ResetButton } from './styled'
import { Uploader } from './uploader'

type Props = {
  accept?: string
  name: string
  control: Control<LibraryModalInputs>
  error: string
  onStart?: (fileName: string) => void
  onFinish: (streamId: string, newFileName: string) => void
  onProgress?: (progress: number) => void
  onReset: () => void
  uploaderText: string
  value: string
  videoID?: string
}

export const AudioTrackUploader: FC<Props> = ({
  accept = 'audio/*',
  name,
  control,
  error,
  onStart,
  onFinish,
  onProgress,
  onReset,
  uploaderText,
  value,
  videoID = '',
}) => (
  <ErrorMessageWrapper>
    <Controller
      control={control}
      name={name}
      render={({ ref, ...props }) => {
        return <input type="hidden" {...props} ref={ref} />
      }}
    />
    {value ? (
      <>
        <Uploaded>
          <IconAttachment />
          <span>{uploaderText}</span>
          <ResetButton type="button" onClick={onReset}>
            <IconBin />
          </ResetButton>
        </Uploaded>
        <ErrorMessage>{error}</ErrorMessage>
      </>
    ) : (
      <Uploader
        accept={accept}
        children={uploaderText}
        error={error}
        onStart={onStart}
        onFinish={onFinish}
        onProgress={onProgress}
        videoID={videoID}
      />
    )}
  </ErrorMessageWrapper>
)
