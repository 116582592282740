import { createGlobalStyle } from 'styled-components'

import { theme } from '~/styles/theme'
import { font, fontSize, heading } from '~/styles/typography'

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  html {
    font-size: 62.5%;
    font-family: ${font.base};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: antialiased; /* stylelint-disable-line property-no-unknown */
  }

  body {
    font-size: ${fontSize.regular};
    color: ${theme.color.black};
    background-color: ${theme.color.white};

    &.locked {
      overflow: hidden;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  input {
    font: inherit;
    color: inherit;
  }

  button {
    border: none;
    background-color: transparent;
    font-size: ${fontSize.regular};

    &:hover,
    &:focus {
      cursor: pointer;
    }

    &:hover:disabled,
    &:focus:disabled {
      border: none;
      cursor: not-allowed;
    }
  }

  h1 {
   ${heading.h1}
  }

  h2 {
   ${heading.h2}
  }

  h3 {
   ${heading.h3}
  }
`
