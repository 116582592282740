import { FC } from 'react'

import { BackButton } from './back-button'
import { ModalHeader } from './styled'

type Props = {
  onBack?: () => void
  title?: string
}

export const ModalScreen: FC<Props> = ({ children, onBack, title }) => (
  <>
    {!!onBack && <BackButton onClick={onBack} />}
    {!!title && <ModalHeader>{title}</ModalHeader>}
    {children}
  </>
)
