import * as yup from 'yup'
import { LibraryItemStatusEnum, LibraryItemTypeEnum } from '~/@types/schema'
import { classSessionIDValidator } from './validators/class-session-id'

export const librarySchema = yup.object().shape({
  bodyParts: yup.array().nullable(),
  description: yup.string().required('Description is required'),
  duration: yup.mixed().required('Duration is required'),
  equipment: yup.array().nullable(),
  fileID: yup.string().required('Please select a file to upload'),
  classID: yup.string().required('Class ID is required'),
  imageURL: yup.string().required('Please select a thumbnail'),
  instructors: yup
    .array()
    .required('Instructor is required')
    .max(2, 'Maximum of 2 instructors is currently supported'),
  parentCategory: yup.mixed().required('Parent category is required'),
  title: yup.string().required('Title is required'),
  type: yup.string().required(),
  classSessionID: yup.string().when('type', {
    is: (type) => type === LibraryItemTypeEnum.PreviouslyLive,
    then: yup.string().required().test(classSessionIDValidator),
  }),
})

export const publishSchema = yup.object().shape({
  status: yup.string().required(),
  statusComment: yup.string().optional(),
  statusEffectiveSince: yup.string().when('status', {
    is: (status) => status === LibraryItemStatusEnum.Scheduled,
    then: yup.string().required(),
  }),
})
