import { useEffect, useState } from 'react'

import { IconAppleMusic } from '~/components/icons/icon-apple-music'
import { useAppContext } from '~/context/app-context'
import {
  ActionButton,
  AlbumCover,
  ID,
  Playlist,
  Title,
} from '../spotify-select/styled'

export const OptionLabel = ({ id }: { id: string }) => {
  const { addNotification } = useAppContext()
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<{ title: string; image: string }>(null)

  useEffect(() => {
    setLoading(true)
    fetch('/api/apple-music/parser', {
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    })
      .then(async (res) => await res.json())
      .then((r) => setData(r))
      .catch(() =>
        addNotification(
          new Error(`Could not load information for playlist ${id}`)
        )
      )
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      addNotification({ type: 'success', text: 'Playlist ID has been copied' })
    }, addNotification)
  }

  const actions = (
    <ID>
      <ActionButton onClick={() => handleCopy(id)}>Copy ID</ActionButton> &bull;{' '}
      <ActionButton
        as="a"
        href={`https://music.apple.com/playlist/${id}`}
        target="_blank"
      >
        <IconAppleMusic /> Open
      </ActionButton>
    </ID>
  )

  return data ? (
    <Playlist>
      <AlbumCover src={data.image} />
      <div>
        <Title>{data.title}</Title>
        {actions}
      </div>
    </Playlist>
  ) : (
    <Playlist>
      {isLoading && <AlbumCover as="span" />}
      <div>
        {id}
        {isLoading ? <ID>Loading…</ID> : actions}
      </div>
    </Playlist>
  )
}
