import { FC } from 'react'
import { animated, useTransition } from 'react-spring'

import { StyledErrorMessage } from './styled'

const sleep = async (timeout: number): Promise<void> => {
  return await new Promise((res) => {
    return setTimeout(res, timeout)
  })
}

export const ErrorMessage: FC = ({ children }) => {
  const transitions = useTransition(children, null, {
    config: {
      duration: 200,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    enter: () => {
      // eslint-disable-next-line no-shadow
      return async (next) => {
        await sleep(200)

        return next({ opacity: 1, transform: 'translate3d(0,0px,0)' })
      }
    },
    from: {
      opacity: 0,
      transform: 'translate3d(0,-8px,0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0,-8px,0)',
    },
  })

  return (
    <StyledErrorMessage>
      {transitions.map(({ item, key, props }) => {
        return (
          item && (
            <animated.div key={key} style={props}>
              {children}
            </animated.div>
          )
        )
      })}
    </StyledErrorMessage>
  )
}
