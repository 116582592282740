import { forwardRef } from 'react'
import { ControllerRenderProps } from 'react-hook-form'

import { SelectOption } from '~/@types/models'
import { useListInstructorsQuery } from '~/@types/schema'
import { AsyncSelect } from '~/components/select/async'
import { SelectProps } from '~/components/select/types'
import { getInstructorName } from '~/utils'

type Props = { error?: string } & Omit<ControllerRenderProps, 'ref'> &
  SelectProps

export const InstructorSelect = forwardRef<typeof AsyncSelect, Props>(
  ({ error, ...props }, ref) => {
    const { loading: listInstructorsLoading, refetch: listInstructorsRefetch } =
      useListInstructorsQuery({
        variables: {
          input: {},
        },
      })

    // eslint-disable-next-line @typescript-eslint/promise-function-async
    const mapOptions = (filter: string): Promise<SelectOption[]> =>
      listInstructorsRefetch({
        input: {
          query: filter,
          size: 5,
        },
      }).then(({ data }) => {
        return data.listInstructors.map((i) => {
          return {
            label: getInstructorName(i),
            value: i.id,
          }
        })
      })

    return (
      <AsyncSelect
        error={error}
        isLoading={listInstructorsLoading}
        label="instructor"
        loadOptions={mapOptions}
        defaultOptions
        register={ref}
        {...props}
      />
    )
  }
)
