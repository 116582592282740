import * as Sentry from '@sentry/node'
import axios from 'axios'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IconAttachment } from '~/components/icons/icon-attachment'
import { IconBin } from '~/components/icons/icon-bin'
import { Progress } from '~/components/progress'
import { getAllCookies } from '~/utils/api/utils'
import { config } from '~/utils/config'
import { HiddenInput, Uploaded, Uploader, Text, ResetButton } from './styled'

type Props = {
  hasClosedCaptions?: boolean
  name: string
  onReset: () => void
  onUploadComplete: () => void
  register: ReturnType<typeof useForm>['register']
  fileID?: string
  instructorFileID?: string
  uploaderText?: string
}

export const ClosedCaptionsUploader = ({
  hasClosedCaptions,
  name,
  onReset,
  onUploadComplete,
  register,
  fileID,
  instructorFileID,
  uploaderText = 'Add Closed Captions',
}: Props) => {
  const fileRef = useRef<HTMLInputElement>()
  const [progress, setProgress] = useState(0)
  const { accessToken, userID } = getAllCookies()
  const [isUploading, setUploading] = useState(false)
  const [file, setFile] = useState<File>()

  const handleUpload = async (videoID: string) => {
    setUploading(true)

    const url = config.apiUrl.replace(
      'graphql',
      `api/videos/${videoID}/closed-captions`
    )

    const formData = new FormData()
    formData.append('file', file)

    await axios
      .request({
        data: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'put',
        onUploadProgress: (p) => {
          setProgress(p.loaded / p.total)
        },
        url,
      })
      .then(() => {
        onUploadComplete()
      })
      .catch((err) => {
        Sentry.captureMessage(err.message, {
          extra: {
            error: err,
            file,
            handler: 'handleTusError',
          },
          level: Sentry.Severity.Error,
          user: { userID: userID || '' },
        })
      })
      .finally(() => {
        setProgress(0)
        setUploading(false)

        if (fileRef.current) {
          fileRef.current.value = null
        }
      })
  }

  const handleFileChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = evt.target.files[0]

    if (selectedFile) {
      setFile(selectedFile)
    }
  }

  useEffect(() => {
    if (fileID && file) {
      void handleUpload(fileID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileID, file])

  useEffect(() => {
    if (instructorFileID && file) {
      void handleUpload(instructorFileID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructorFileID, file])

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <HiddenInput type="checkbox" disabled name={name} ref={register} />

      {hasClosedCaptions ? (
        <Uploaded>
          <IconAttachment />
          <span>{uploaderText}</span>
          <ResetButton type="button" onClick={onReset}>
            <IconBin />
          </ResetButton>
        </Uploaded>
      ) : (
        <Uploader>
          <IconAttachment />
          <div>
            {uploaderText}
            <Text>Add file or drop file here</Text>
          </div>
          <input
            accept="text/vtt"
            aria-label="upload"
            type="file"
            onChange={handleFileChange}
            ref={fileRef}
          />
          {isUploading && (
            <Progress value={progress} indefinite={progress === 100} />
          )}
        </Uploader>
      )}
    </>
  )
}
