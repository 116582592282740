import { InputHTMLAttributes, forwardRef } from 'react'

import { Label, UI } from './styled'

export const RadioInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    className?: string
  }
>(({ className, ...rest }, ref) => (
  <Label className={className}>
    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
    <input type="radio" ref={ref} {...rest} />
    <UI />
  </Label>
))
