import { forwardRef } from 'react'

import { ChildrenContainer, Loading } from './styled'
import { ButtonWithLoadingProps } from './types'

import { Button } from '.'

export const ButtonWithLoading = forwardRef<
  HTMLButtonElement,
  ButtonWithLoadingProps
>(({ children, disabled, isLoading, onClick, ...props }, ref) => {
  return (
    <Button
      disabled={disabled || isLoading}
      onClick={onClick}
      ref={ref}
      {...props}
      aria-label={isLoading ? 'loading' : null}
    >
      <ChildrenContainer
        aria-hidden={isLoading ? true : false}
        isLoading={isLoading}
      >
        {children}
      </ChildrenContainer>

      {isLoading && (
        <Loading viewBox="0 0 80 80">
          <circle
            cx="40"
            cy="40"
            fill="none"
            r="40"
            stroke="currentColor"
            strokeDasharray="180"
            strokeWidth="8"
          />
        </Loading>
      )}
    </Button>
  )
})
