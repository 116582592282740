import styled, { css } from 'styled-components'

import { InputLabelProps } from './types'

import { theme } from '~/styles/theme'
import { fontSize } from '~/styles/typography'

const getBaseBorderColor = ({ error, light }: InputLabelProps) => {
  if (error) {
    return theme.color.primary
  }

  if (light) {
    return theme.color.whiteTransparent
  }

  return theme.color.greyMedium
}

const getFocusBorderColor = ({ error, light }: InputLabelProps) => {
  if (error) {
    return theme.color.primary
  }

  if (light) {
    return theme.color.greyMedium
  }

  return theme.color.black
}

const getFocusLabelColor = ({ error, light }: InputLabelProps) => {
  if (error) {
    return theme.color.primary
  }

  if (light) {
    return theme.color.white
  }

  return theme.color.black
}

export const InputLabel = styled.label<InputLabelProps>`
  border: ${theme.layout.spacing.xxs} solid
    ${(props) => getBaseBorderColor(props)};
  display: flex;
  flex-direction: column;
  font-size: ${fontSize.regular};
  padding: ${theme.layout.spacing.md};
  width: 100%;
  text-transform: uppercase;
  position: relative;
  transition: all 0.2s ease;

  ${(props) =>
    !props.readOnly &&
    css`
      &:focus-within {
        border: ${theme.layout.spacing.xxs} solid ${getFocusBorderColor(props)};

        > span {
          color: ${getFocusLabelColor(props)};
        }
      }
    `}

  > span {
    color: ${({ error, light }) => {
      if (error) {
        return theme.color.primary
      }

      if (light) {
        return theme.color.white
      }

      return theme.color.grey
    }};
    font-size: ${fontSize.small};
    margin-bottom: ${theme.layout.spacing.sm};
  }

  > input {
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent !important;
    color: ${({ error, light }) => {
      if (error) {
        return theme.color.primary
      }

      if (light) {
        return theme.color.white
      }

      return theme.color.black
    }} !important;
    &::placeholder {
      color: ${theme.color.grey};
    }
  }

  input:disabled,
  textarea:disabled {
    cursor: not-allowed;
    color: ${theme.color.grey} !important;
  }

  ${(props) =>
    props.readOnly &&
    css`
      padding-left: 0;
      padding-right: 0;
      border: none;
    `}
`

export const ToggleVisibility = styled.button`
  color: ${theme.color.greyMedium};
  position: absolute;
  right: 1rem;
  bottom: 1.6rem;
  font-size: 2.4rem;

  &:hover,
  &:focus {
    color: ${theme.color.white};
  }
`
