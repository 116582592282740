import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconBanners: FC<SvgProps> = (props) => {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 3H2V21H22V3ZM4 19V5H20V19H4ZM17.3202 10.0628C17.6486 9.72646 17.8332 9.27025 17.8333 8.79448C17.8335 8.31871 17.6492 7.86237 17.321 7.52584C16.9928 7.18931 16.5476 7.00016 16.0833 7C15.619 6.99984 15.1737 7.18869 14.8453 7.52499C14.5169 7.8613 14.3323 8.31752 14.3322 8.79328C14.332 9.26905 14.5163 9.72539 14.8445 10.0619C15.1727 10.3985 15.6179 10.5876 16.0822 10.5878C16.5465 10.5879 16.9918 10.3991 17.3202 10.0628ZM5 18H19L15 12.5L13 15L9.5 9L5 18Z"
        fill="currentColor"
      />
    </Svg>
  )
}
