import { FC } from 'react'

import { Svg } from '../svg'
import { SvgProps } from '../svg/types'

export const IconEyeClosed: FC<SvgProps> = (props) => {
  return (
    <Svg {...props}>
      <path
        d="M12.5 6c4.8.3 7.6 5.8 7.6 5.8l-.7 1.1M17 15.5c-1.2 1.1-3 2-5 2-5 0-8-5.8-8-5.8s1.6-3 4.5-4.7l8.5 8.5zM14.2 12.6a2.3 2.3 0 11-3-3M4.5 3l16 16"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </Svg>
  )
}
