import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import { useAdminGetPrivateSignedUrlLazyQuery } from '~/@types/schema'
import { ErrorMessage } from '~/components/error-message'
import { IconAttachment } from '~/components/icons/icon-attachment'
import { Progress } from '~/components/progress'
import { useAppContext } from '~/context/app-context'
import { UploaderWrapper, Text } from './styled'

type Props = {
  accept: string
  children?: string
  error?: string
  onStart?: (fileName: string) => void
  onFinish: (streamId: string, fileName: string) => void
  onProgress?: (progress: number) => void
}

export const Uploader: FC<Props> = ({
  accept,
  children = 'Add file',
  error,
  onStart,
  onFinish,
  onProgress,
}) => {
  const [progress, setProgress] = useState(0)
  const { addNotification } = useAppContext()
  const [fileList, setFileList] = useState<FileList>()
  const selectedFile = fileList ? fileList[0] : null

  const handleFileUpload = async ({ fileName, publicUrl, url }) => {
    if (!selectedFile || !publicUrl || !url) {
      return
    }

    try {
      await axios
        .request({
          data: selectedFile,
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          method: 'put',
          onUploadProgress: (p) => {
            const value = (p.loaded / p.total) * 100
            setProgress(value)
            onProgress(value)
          },
          url,
        })
        .then(() => {
          onFinish(fileName, fileName)
        })
        .catch(() => {
          addNotification({ text: 'Audio upload failed', type: 'error' })
        })
        .finally(() => {
          setProgress(0)
          onProgress(0)
        })
    } catch (err) {
      addNotification({ text: 'Audio upload failed', type: 'error' })
    }
  }

  const [getSignedUrl] = useAdminGetPrivateSignedUrlLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      await handleFileUpload(data?.admin_getPrivateSignedUrl)
    },
  })

  useEffect(() => {
    if (selectedFile) {
      const revalidate = () => {
        if (selectedFile) {
          const lastDot = selectedFile?.name?.lastIndexOf('.')
          const fileExtension = selectedFile?.name.substring(lastDot + 1)

          getSignedUrl({
            variables: {
              input: {
                extension: fileExtension,
              },
            },
          })
        }
      }

      void revalidate()
    }
  }, [getSignedUrl, selectedFile])

  const handleStart = (files: FileList) => {
    setFileList(files)
    onStart && onStart(files[0]?.name)
  }

  return (
    <>
      <UploaderWrapper error={error}>
        <IconAttachment />
        <div>
          {selectedFile ? fileList[0]?.name : children}
          <Text>Add file or drop file here</Text>
        </div>
        <input
          accept={accept}
          aria-label="upload"
          type="file"
          onChange={(evt) => {
            handleStart(evt.target.files)
          }}
        />
        <Progress value={progress} indefinite={progress === 100} />
      </UploaderWrapper>
      <ErrorMessage>
        {progress > 0 && progress < 100 && error
          ? 'Please wait for the upload to finish'
          : error}
      </ErrorMessage>
    </>
  )
}
