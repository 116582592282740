import styled, { css } from 'styled-components'

import { Props } from '~/components/badge/types'
import { theme } from '~/styles/theme'
import { fontSize } from '~/styles/typography'

export const StyledBadge = styled.span<Props>`
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${fontSize.micro};

  ${({ variant }) => {
    switch (variant) {
      case 'small':
        return css`
          padding: ${theme.layout.spacing.xs} ${theme.layout.spacing.sm};
        `

      case 'wide':
        return css`
          padding: ${theme.layout.spacing.sm} ${theme.layout.spacing.lg};
        `

      default:
        return css`
          padding: ${theme.layout.spacing.sm} ${theme.layout.spacing.md};
        `
    }
  }}

  ${({ $type }) => {
    switch ($type) {
      case 'warning':
        return css`
          color: ${theme.color.warning};
          background-color: ${theme.color.warningTransparent};
        `

      case 'success':
        return css`
          color: ${theme.color.success};
          background-color: ${theme.color.successTransparent};
        `

      case 'error':
        return css`
          color: ${theme.color.primary};
          background-color: ${theme.color.primaryTransparent};
        `

      case 'grey':
        return css`
          color: ${theme.color.white};
          background-color: ${theme.color.grey};
        `

      default:
        return css`
          color: ${theme.color.black};
          background-color: ${theme.color.blackTransparent};
        `
    }
  }}

${({ hasIcon }) => {
    return (
      hasIcon &&
      css`
        display: flex;
        align-items: center;

        svg {
          font-size: 1.6em;

          &:first-child {
            margin-right: 0.2em;
          }
        }
      `
    )
  }}
`
