import { LibraryModalInputs, PublishInputs } from '../types'

import {
  BodyPartEnum,
  EquipmentEnum,
  GetLibraryItemQuery,
  LibraryItemCategoryEnum,
  LibraryItemLabelEnum,
  LibraryItemStatusEnum,
  LibraryItemTypeEnum,
} from '~/@types/schema'
import { dateToObject, getInstructorName } from '~/utils'

export const processDataBeforeSubmit = (
  inputs: LibraryModalInputs & PublishInputs,
  libraryItemType: LibraryItemTypeEnum
) => {
  return {
    bodyParts: (inputs.bodyParts?.map(({ value }) => {
      return value
    }) || []) as BodyPartEnum[],
    description: inputs.description,
    duration: Number(inputs.duration.value),
    equipment: (inputs.equipment?.map(({ value }) => {
      return value
    }) || []) as EquipmentEnum[],
    fileID: inputs.fileID ?? '',
    instructorFileID: inputs.instructorFileID ?? '',
    audioID: inputs.audioID,
    classID: inputs.classID,
    hasClosedCaptions: inputs.hasClosedCaptions,
    hasClosedCaptionsInstructorFile: inputs.hasClosedCaptionsInstructorFile,
    imageURL: inputs.imageURL,
    instructorID: inputs.instructors[0]?.value,
    additionalInstructorID: inputs.instructors[1]?.value ?? '',
    labels: (inputs.labels?.map(({ value }) => {
      return value
    }) || []) as LibraryItemLabelEnum[],
    parentCategory: inputs.parentCategory.value,
    title: inputs.title,
    type: libraryItemType,
    status: inputs.status as LibraryItemStatusEnum,
    statusEffectiveSince: dateToObject(inputs.statusEffectiveSince).toISO(),
    statusComment: inputs.statusComment,
    classSessionID: inputs.classSessionID || '',
    appleMusicPlaylistIDs:
      inputs.appleMusicPlaylistIDs?.map(({ value }) => value) || [],
    spotifyPlaylistIDs: (inputs.spotifyPlaylistIDs || []).map(
      ({ imageURL, playlistID, title }) => ({ imageURL, playlistID, title })
    ),
  }
}

export const processDataOnLoad = (
  libraryItem: GetLibraryItemQuery['admin_getLibraryItem'],
  libraryItemType: LibraryItemTypeEnum
) => {
  if (!libraryItem) {
    return {
      bodyParts: [],
      duration: null,
      equipment: [],
      fileID: '',
      instructorFileID: '',
      audioID: '',
      classID: '',
      instructors: [],
      labels: [],
      type: libraryItemType,
      parentCategory: null as {
        label: string
        value: LibraryItemCategoryEnum
      },
      status: LibraryItemStatusEnum.Published,
      statusEffectiveSince: null,
      statusComment: null,
      classSessionID: null,
      appleMusicPlaylistIDs: [],
      spotifyPlaylistIDs: [],
    }
  }

  return {
    ...libraryItem,
    bodyParts: libraryItem.bodyParts.map((p) => {
      return {
        label: p.name,
        value: p.type,
      }
    }),
    duration: {
      label: libraryItem.duration.name,
      value: libraryItem.duration.type.toString(),
    },
    equipment: libraryItem.equipment.map((e) => {
      return {
        label: e.name,
        value: e.type,
      }
    }),
    instructors: [
      {
        label: getInstructorName(libraryItem.instructor),
        value: libraryItem.instructor.id,
      },
      ...(libraryItem.additionalInstructor?.id
        ? [
            {
              label: getInstructorName(libraryItem.additionalInstructor),
              value: libraryItem.additionalInstructor.id,
            },
          ]
        : []),
    ],
    labels: libraryItem.labels.map((l) => {
      return {
        label: l.name,
        value: l.type,
      }
    }),
    parentCategory: {
      label: libraryItem.parentCategory?.name,
      value: libraryItem.parentCategory?.type,
    } as {
      label: string
      value: LibraryItemCategoryEnum
    },
    type: libraryItemType,
    appleMusicPlaylistIDs:
      libraryItem.appleMusicPlaylistIDs?.map((val) => ({
        label: val,
        value: val,
      })) || [],
    spotifyPlaylistIDs:
      libraryItem.spotifyPlaylistIDs?.map(({ __typename, ...p }) => p) || [],
  }
}
