import { DateTime } from 'luxon'

import { SelectOption } from '~/@types/models'
import {
  InstructorFragmentFragment,
  InstructorCacheFragmentFragment,
} from '~/@types/schema'

export const dateToObject = (inputDate: string, convertToPst?: boolean) => {
  const zoneString = 'America/Los_Angeles'

  if (convertToPst) {
    return DateTime.fromISO(inputDate).setZone(zoneString).setLocale('en-US')
  }

  return DateTime.fromISO(inputDate, { zone: 'America/Los_Angeles' }).setLocale(
    'en-US'
  )
}

export const formatDate = (
  date: string,
  options = {
    withTime: true,
  }
): string => {
  if (!DateTime.fromISO(date).isValid) {
    console.error(
      `Please provide a valid date to \`formatDate\` function. You have provided: "${date}"`
    )

    return ''
  }

  const { withTime } = options

  const regex = /^[\d]{4}-[\d]{2}-[\d]{2}$/u // YYYY-MM-DD ('1994-09-14')

  if (regex.test(date)) {
    return DateTime.fromISO(date).setLocale('en-US').toLocaleString({
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
  }

  return `${dateToObject(date, true).toFormat(withTime ? 'f' : 'D', {
    locale: 'en-US',
  })} (PST)`
}

type NormalizeSelectOptionsParam = {
  name: string
  type: string | number
}

export const normalizeSelectOptions = (
  param: NormalizeSelectOptionsParam
): SelectOption => {
  const { name, type } = param

  if (!name || !type) {
    console.error(
      `Please provide an object with \`name\` and \`type\` keys to \`normalizeSelectOptions\` function. You have provided: "${JSON.stringify(
        param
      )}"`
    )

    return {
      label: '',
      value: '',
    }
  }

  return {
    label: name,
    value: String(type),
  }
}

export const getEarliestDatetimeToAccept = (): string => {
  const RE_DATETIME = /(?<dateTime>[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2})/iu

  const {
    groups: { dateTime },
  } = RE_DATETIME.exec(new Date().toISOString())

  return dateTime
}

/**
 * @param {date} date date object
 * @param {minutes} minutes number
 * @returns {number} Unix Timestamp
 */
export const addMinutesToDate = (date: Date, minutes: number): number => {
  return date.getTime() + minutes * 60000
}

export const getInstructorName = (
  instructor: InstructorFragmentFragment | InstructorCacheFragmentFragment
): string => {
  if ('fullName' in instructor && instructor.fullName) {
    return instructor.fullName
  }

  return `${instructor.firstName ? instructor.firstName : ''} ${
    instructor.lastName ? instructor.lastName : ''
  }`.trim()
}

export const enumToReadable = (enumValue: string) =>
  enumValue.replace('_', ' ').replace(/\w\S*/gu, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
