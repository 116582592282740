import styled, { css } from 'styled-components'

import { theme } from '~/styles/theme'

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'left right';
  margin: 0 -4rem;
`

const commonColumStyles = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  overflow: hidden;
  padding: 0 4rem;
`

export const RightColumn = styled.div`
  grid-area: right;
  ${commonColumStyles}
`

export const LeftColumn = styled.div`
  grid-area: left;
  ${commonColumStyles}
`

export const LibraryActionsWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

export const LibraryModalsContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: ${theme.sideMenuWidth};
  right: 0;
  padding: 0 3rem 3rem;
  z-index: 100;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200%;
    pointer-events: none;
    background: linear-gradient(to top, #f5f5f5 30%, #f5f5f500);
    z-index: -1;
  }
`
