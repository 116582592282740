import { ApolloProvider } from '@apollo/client'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { NextPage } from 'next'
import { useState } from 'react'
import { ThemeProvider } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css'
import 'regenerator-runtime/runtime'

import { AppContextType } from '~/@types/models'
import { LibraryItemTypeEnum } from '~/@types/schema'
import { ComponentWrapper } from '~/components/component-wrapper'
import { Notifications } from '~/components/notifications'
import { defaultTitle } from '~/components/seo'
import { LibraryModal } from '~/containers/library/partial/library-modal'
import { LibraryModalsContainer } from '~/containers/library/styled'
import { AppContext } from '~/context/app-context'
import { GlobalStyle } from '~/styles/global'
import { theme } from '~/styles/theme'
import { initializeApollo } from '~/utils/api/apollo-client'
import { createTokenManager } from '~/utils/api/token-manager'
import { destroyAllCookies } from '~/utils/api/utils'
import { init } from '~/utils/sentry'

init()

const apolloClient = initializeApollo(createTokenManager(), null)

const App: NextPage<AppProps> = ({ Component, pageProps }) => {
  const [user, setUser] = useState<AppContextType['user']>(null)

  const logout: AppContextType['logout'] = () => {
    setUser(null)
    destroyAllCookies()
  }

  const [libraryModals, setLibraryModals] = useState<
    AppContextType['libraryModals']
  >([])

  const [notifications, setNotifications] = useState<
    AppContextType['notifications']
  >([])

  const addNotification: AppContextType['addNotification'] = (options) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      'message' in options
        ? {
            id: Date.now().toString(),
            text: options.message,
            type: 'error',
          }
        : {
            id: Date.now().toString(),
            text: options.text,
            type: options.type || 'success',
          },
    ])
  }

  const removeNotification: AppContextType['removeNotification'] = (
    id: string
  ) => {
    setNotifications(
      notifications.filter((notification) => {
        return notification.id !== id
      })
    )
  }

  const removeLibraryModal = async (id: number) => {
    const index = libraryModals.findIndex((m) => {
      return m.id === id
    })

    if (index < 0) {
      return
    }

    if (libraryModals[index].beforeClose) {
      await libraryModals[index].beforeClose()
    }

    setLibraryModals((prevModals) => [
      ...prevModals.slice(0, index),
      ...prevModals.slice(index + 1),
    ])
  }

  const toggleMinimizeModal = (id: number, value: boolean) => {
    setLibraryModals((prevModals) => {
      return prevModals.map((m) => {
        return m.id === id ? { ...m, isMinimized: value } : m
      })
    })
  }

  const addLibraryModal = (
    id: number,
    type: LibraryItemTypeEnum,
    libraryID?: string,
    beforeClose?: () => Promise<void>
  ) => {
    const newModal = { id, libraryID, type, beforeClose }

    if (libraryID) {
      // eslint-disable-next-line arrow-body-style
      const modalID = libraryModals.find((m) => m.libraryID === libraryID)?.id

      if (modalID) {
        return toggleMinimizeModal(modalID, false)
      }
    }

    setLibraryModals((prevModals) => {
      return [newModal, ...prevModals]
    })
  }

  return (
    <ApolloProvider client={apolloClient}>
      <AppContext.Provider
        value={{
          addLibraryModal,
          addNotification,
          libraryModals,
          logout,
          notifications,
          removeLibraryModal,
          removeNotification,
          setUser,
          toggleMinimizeModal,
          user,
        }}
      >
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <Head>
            <link href="/favicon.svg" rel="icon" type="image/svg+xml" />
            <link href="/favicon.png" rel="alternate icon" />
            <link href="/fonts/Antenna/stylesheet.css" rel="stylesheet" />
            <link href="/fonts/BentonSans/stylesheet.css" rel="stylesheet" />
            <title>{defaultTitle}</title>
          </Head>
          <ComponentWrapper
            onLogout={logout}
            onUserChange={setUser}
            user={user}
          >
            <Component {...pageProps} />
          </ComponentWrapper>
          <Notifications items={notifications} />
        </ThemeProvider>

        {libraryModals.length > 0 && (
          <LibraryModalsContainer>
            {libraryModals.map((m) => {
              return (
                <LibraryModal
                  key={m.id}
                  libraryID={m.libraryID}
                  onClose={async () => {
                    await removeLibraryModal(m.id)
                  }}
                  onMinimize={(minimized = true) => {
                    toggleMinimizeModal(m.id, minimized)
                  }}
                  libraryItemType={m.type}
                  isMinimized={m.isMinimized}
                />
              )
            })}
          </LibraryModalsContainer>
        )}
      </AppContext.Provider>
    </ApolloProvider>
  )
}

export default App
