import * as Sentry from '@sentry/node'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as tus from 'tus-js-client'

import { LibraryItemTypeEnum, useRefreshTokenMutation } from '~/@types/schema'
import { useAppContext } from '~/context/app-context'
import { getAllCookies } from '~/utils/api/utils'
import { config } from '~/utils/config'

type Props = {
  file: FileList
  libraryItemType: LibraryItemTypeEnum
  onFinish: (fileID: string, fileName: string) => void
  apiUrl?: string
}

let lastProgress: number

export const useTus = ({
  file,
  libraryItemType,
  onFinish,
  apiUrl = 'api/videos',
}: Props) => {
  const { addNotification } = useAppContext()
  const [progress, setProgress] = useState<number>()
  const uploadInitiated = useRef(false)
  const [refreshToken] = useRefreshTokenMutation()

  const url = config.apiUrl.replace('graphql', apiUrl)

  useEffect(() => {
    if (
      url &&
      file?.[0] &&
      libraryItemType === LibraryItemTypeEnum['Video'] &&
      !uploadInitiated.current
    ) {
      tus.defaultOptions.onBeforeRequest = (req) => {
        if (req.getURL().includes('/api/videos')) {
          req.setHeader(
            'Authorization',
            `Bearer ${getAllCookies().accessToken}`
          )
        }
      }

      const fileSize = file[0].size
      let streamId = ''
      uploadInitiated.current = true

      new tus.Upload(file[0], {
        chunkSize: 209_715_200,
        endpoint: url,
        metadata: {
          filename: file[0].name,
          filetype: file[0].type,
        },
        onSuccess: () => {
          uploadInitiated.current = false
          onFinish(streamId, file[0].name)
        },
        onAfterResponse: (_req, res) => {
          const streamMediaId = res.getHeader('stream-media-id')

          if (streamMediaId) {
            streamId = streamMediaId
          }
        },
        onError: (error) => {
          console.log('onError')
          const { userID } = getAllCookies()
          uploadInitiated.current = false

          if (process.env.NODE_ENV === 'development') {
            console.log('An error occured during file upload')
            console.error(error)
          }

          Sentry.captureMessage(error.message, {
            extra: {
              error,
              file: file[0],
              handler: 'handleTusError',
            },
            level: Sentry.Severity.Error,
            user: { userID: userID || '' },
          })

          addNotification({ text: 'Upload failed', type: 'error' })
        },
        onProgress: (bytesUploaded) => {
          const newProgress = (bytesUploaded / fileSize) * 100

          if (
            (newProgress > 0 && newProgress < 0.5) ||
            newProgress > 99 ||
            newProgress - lastProgress > 1 ||
            !lastProgress
          ) {
            lastProgress = newProgress
            setProgress(newProgress)
          }
        },
        // @ts-expect-error boolean is expected, but Promise<boolean> works just fine
        onShouldRetry: async (error: Error, attempt: number) => {
          try {
            await refreshToken({
              variables: { refreshToken: getAllCookies().refreshToken },
            })
          } catch (err) {
            console.log('error while trying to refresh token')
          }

          Sentry.captureMessage(error.message, {
            extra: {
              error,
              file: file[0],
            },
            level: Sentry.Severity.Error,
          })

          return attempt < 2
        },
        retryDelays: [10000],
      }).start()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, url])

  const resetFile = useCallback((): void => {
    onFinish(null, null)
  }, [])

  return { progress, resetFile }
}
