import styled, { css } from 'styled-components'
import { theme } from '~/styles/theme'
import { fontSize } from '~/styles/typography'

const commonStyles = css`
  border: 1px solid ${theme.color.greyMedium};
  padding: ${theme.layout.spacing.sm};
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  align-items: center;
  font-weight: bold;
  min-height: 7.2rem;
  justify-content: center;
  color: ${theme.color.greyMedium};
`

export const Uploaded = styled.div`
  ${commonStyles};
  border: 1px solid ${theme.color.black};
  color: ${theme.color.black};
  justify-content: space-between;

  button {
    color: ${theme.color.grey};
    font-size: 2.4rem;
  }

  button:hover,
  button:focus {
    color: ${theme.color.black};
  }
`

export const UploaderWrapper = styled.label<{ error?: string }>`
  ${commonStyles};
  position: relative;
  color: ${theme.color.grey};

  &:hover,
  &:focus {
    cursor: pointer;
    border-color: ${theme.color.black};
    color: ${theme.color.black};
  }

  input {
    visibility: hidden;
    position: absolute;
  }

  ${(props) => {
    return props.error
      ? css`
          color: ${theme.color.primary};
          border-color: ${theme.color.primary};
        `
      : css`
          color: ${theme.color.grey};
          border-color: ${theme.color.greyMedium};

          &:hover,
          &:focus {
            border-color: ${theme.color.black};
            color: ${theme.color.black};
          }
        `
  }}
`

export const ResetButton = styled.button`
  padding: 0.4rem;

  svg {
    display: block;
  }
`

export const Text = styled.small`
  display: block;
  margin-top: 0.5rem;
  font-weight: 400;
  letter-spacing: 0.005em;
  font-size: ${fontSize.small};
`
